export enum IRolePermissionAccess {
  NOT_ACCESSIBLE = 1,
  ACCESSIBLE = 2,
}
export enum IModalKeyEnums {
  APPOINTMENT = "appointment",
  AVAILABILITY_SETTING = "availabilitySetting",
  DOCTOR = "doctor",
  MEDICAL_RECORD = "medicalRecord",
  MEDICINE = "medicine",
  PATIENT_MEMBER = "patientMember",
  PRESCRIPTION = "prescription",
  PRESCRIPTION_ATTRIBUTES = "prescriptionAttributes",
  PRESCRIPTION_TEMPLATE = "prescriptionTemplate",
  REVIEW = "review",
  SCHEDULE_SLOTS = "scheduleSlots",
  TEST = "test",
  UPLOAD_DOCUMENT = "uploadDocument",
  USER = "user",
}
export enum IAppointmentPermissionEnums {
  CREATE_APPOINTMENT = "createAppointment",
  DELETE_APPOINTMENT = "deleteAppointment",
  RESCHEDULE_APPOINTMENT = "rescheduleAppointment",
  SEARCH_APPOINTMENT = "searchAppointment",
}
export enum IAvailabilitySettingPermissionEnums {
  CREATE_AVAILABILITY = "createAvailability",
  DELETE_AVAILABILITY = "deleteAvailability",
  GET_AVAILABILITY_LIST = "getAvailabilityList",
  UPDATE_AVAILABILITY = "updateAvailability",
  VIEW_SCHEDULE_LIST = "viewScheduleList",
}
export enum IDoctorPermissionEnums {
  GET_DOCTOR_LINKED_CLINICS = "getDoctorLinkedClinics",
  GET_DOCTOR_PROFILE_BY_DOCTOR = "getDoctorProfileByDoctor",
}
export enum IMedicalRecordPermissionEnums {
  VIEW_MEDICAL_RECORDS = "viewMedicalRecords",
}
export enum IMedicinePermssionEnums {
  SEARCH_MEDICINE = "searchMedicine",
}
export enum ITestPermissionEnums {
  SEARCH_TEST = "searchTest",
}
export enum IReviewPermissionEnums {
  REPLY_TO_REVIEW = "replyToReview",
  VIEW_REVIEW = "viewReview",
}
export enum IPatientMemberPermissionEnums {
  VIEW_PATIENT_MEMBER_LIST = "viewPatientMemberList",
}
export enum IScheduleSlotsPermissionEnums {
  VIEW_SCHEDULE_SLOT_BY_DOCTOR = "viewScheduleSlotByDoctor",
}
export enum IUploadDocumentEnums {
  UPLOAD_TO_S3 = "uploadToS3",
}
export enum IPrescriptionAttributesPermissionEnums {
  SEARCH_PRESCRIPTION_ATTRIBUTES = "searchPrescriptionAttributes",
}
export enum IPrescriptionTemplatePermissionEnums {
  CREATE_PRESCRIPTION_TEMPLATE = "createPrescriptionTemplate",
  DELETE_PRESCRIPTION_TEMPLATE = "deletePrescriptionTemplate",
  UPDATE_PRESCRIPTION_TEMPLATE = "updatePrescriptionTemplate",
  VIEW_PRESCRIPTION_TEMPLATE = "viewPrescriptionTemplate",
}

export enum IPrescriptionPermissionEnums {
  CREATE_PRESCRIPTION = "createPrescription",
  LAST_PRESCRIPTION = "lastPrescription",
  SAVE_PRESCRIPTION_MEDICAL_RECORD = "savePrescriptionMedicalRecord",
  UPDATE_PRESCRIPTION = "updatePrescription",
}

export enum IUserPermssionEnums {
  GET_USER_BASIC_INFO = "getUserBasicInfo", // Access token
  OTP_TO_CREATE_PATIENT_BY_DOCTOR = "otpToCreatePatientByDoctor",
  SEARCH_PATIENT = "searchPatient",
}
