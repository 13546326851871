import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import GetImages from "../../../../components/GetImages";
import { Star, ThumbUp } from "@mui/icons-material";

type BlogActionsMobileProps = {
  isLiked?: boolean;
  isFavourited?: boolean;
  handleLike: () => void;
  handleUnlike: () => void;
  handleFavourite: () => void;
  handleUnfavourite: () => void;
  handleShare: () => void;
  likeCount: number;
};

const BlogActionsMobile: React.FC<BlogActionsMobileProps> = ({
  isLiked,
  isFavourited,
  handleLike,
  handleUnlike,
  handleFavourite,
  handleUnfavourite,
  handleShare,
  likeCount,
}) => {
  return (
    <Grid className={styles.actionButtonsContainer}>
      <Grid className={styles.actionButtonsWrapper}>
        <div
          className={`${styles.likeButton} ${isLiked ? styles.liked : ""}`}
          onClick={isLiked ? handleUnlike : handleLike}
        >
          <ThumbUp fontSize="small" className={styles.likeIcon} />
          <span>{likeCount}</span>
        </div>
        <div
          className={`${styles.actionButton} ${
            isFavourited ? styles.favourited : ""
          }`}
          onClick={isFavourited ? handleUnfavourite : handleFavourite}
        >
          <Star fontSize="small" className={styles.favouriteIcon} />
        </div>
        <div className={styles.actionButton} onClick={handleShare}>
          <GetImages name="ShareLongVideoMobileIcon" height="20" width="20" />
        </div>
      </Grid>
    </Grid>
  );
};

export default BlogActionsMobile;
