import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

interface IProps {
  title: string;
  children?: React.ReactNode;
}
const Section: React.FC<IProps> = (props) => {
  const { children, title } = props;
  return (
    <Grid item>
      <Typography component="p" className={style.title}>
        <FormattedMessage id={title} defaultMessage="Section Title" />
      </Typography>
      {children}
    </Grid>
  );
};

export default Section;
