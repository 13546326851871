import {
  ICheckNumberAppointment,
  ICreatePatientVerifyOtpRequest,
  ICreatePatientVerifyOtpResponse,
  IDoctorAssociatedClinicMemberProfileResponse,
  IDoctorReviewRatingResponse,
  IDoctorVideoReviewsResponse,
  IHelpSupportRequest,
  IHelpSupportResponse,
  IReviewReplyRequest,
  IReviewReplyResponse,
  ISearchMemberResponse,
  ISendOTPParams,
} from "../../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { get, post } from "../../TypedApi";
import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDoctorRatingReviewListApi = async (
  doctorId?: string,
  noOfStars?: string,
  repliedByDoctor?: string,
  keyword?: string,
  startDate?: string,
  endDate?: string
): Promise<IDoctorReviewRatingResponse | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.doctorReviewList;
    const queryParams = [
      doctorId && `doctor=${doctorId}`,
      noOfStars && `noOfStars=${noOfStars}`,
      repliedByDoctor && `repliedByDoctor=${repliedByDoctor}`,
      keyword && `keyword=${keyword}`,
      startDate && `startDate=${startDate}`,
      endDate && `endDate=${endDate}`,
    ].filter(Boolean);

    const finalUrl = queryParams.length
      ? `${url}&${queryParams.join("&")}`
      : url;

    const response = await get<IDoctorReviewRatingResponse>(finalUrl);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorVideoReviewsApi = async (
  doctorId: string
): Promise<IDoctorVideoReviewsResponse | GBSCError> => {
  try {
    const response = await get<IDoctorVideoReviewsResponse>(
      `${APIEndpoint.doctor.videoReviews}?doctorId=${doctorId}&limit=100&offset=0`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const postReplyToPatientReviewApi = async (
  requestBody: IReviewReplyRequest
): Promise<IReviewReplyResponse | GBSCError> => {
  try {
    const url = APIEndpoint.doctor.reviewReply;
    const response = await post<IReviewReplyResponse>(url, requestBody);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const checkMobileNumberApi = async (
  params: ISendOTPParams
): Promise<ICheckNumberAppointment | GBSCError> => {
  try {
    let url = APIEndpoint.doctor.checkNumber;
    const response = await get<ICheckNumberAppointment>(
      `${url}?phoneNumber=${params.mobileNo}&countryCode=${params.countryCode}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createPatientVerifyOtpApi = async (
  requestBody: ICreatePatientVerifyOtpRequest
): Promise<ICreatePatientVerifyOtpResponse | GBSCError> => {
  try {
    const response = await post<ICreatePatientVerifyOtpResponse>(
      APIEndpoint.doctor.createPatient,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorAssociatedClinicProfiles = async (): Promise<
  IDoctorAssociatedClinicMemberProfileResponse | GBSCError
> => {
  try {
    const response = await get<IDoctorAssociatedClinicMemberProfileResponse>(
      APIEndpoint.doctor.doctorAssociatedTeams
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const searchMemberAddToVideoCall = async (
  params: ISendOTPParams
): Promise<ISearchMemberResponse | GBSCError> => {
  try {
    let url = APIEndpoint.searchMember;
    const response = await get<ISearchMemberResponse>(
      `${url}?phoneNumber=${params.mobileNo}&countryCode=${params.countryCode}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const createHelpSupportTicketApi = async (
  requestBody: IHelpSupportRequest
): Promise<IHelpSupportResponse | GBSCError> => {
  try {
    const response = await post<IHelpSupportResponse>(
      APIEndpoint.doctor.helpSupportTicket,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
