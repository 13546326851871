import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";

import ChipSelect from "../../ChipSelect";
import AccordionWithTitle from "../AccordionWithTitle";

import style from "./index.module.css";

import { IChipVariant } from "../../../models/button";
import { IHealthCategory } from "../../../models/ApiRequestResponse/doctor";
import { IAccordionInputChipListEnums } from "../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setPrescriptionValues } from "../../../store/slices/doctor/prescription";

const MemoizedChipSelect = React.memo(ChipSelect);
const MemoizedAccordionWithTitle = React.memo(AccordionWithTitle);

interface IProps {
  title: string;
  identifier: IAccordionInputChipListEnums;
  hasInputField?: boolean;
}

const AccordionInputChip: React.FC<IProps> = (props) => {
  const { title, hasInputField = true, identifier } = props;

  const [selectedValue, setSelectedValue] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const prescription = useAppSelector((state) => state.prescription);

  const handleInputValues = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setPrescriptionValues({
        identifier,
        value: [selectedValue, event.target.value],
      })
    );
  };

  useEffect(() => {
    if (identifier === IAccordionInputChipListEnums.HEALTH_CATEGORIES) {
      dispatch(
        setPrescriptionValues({
          identifier,
          value: selectedValue,
        })
      );
    }
  }, [selectedValue]);

  const chipSelectData = hasInputField
    ? prescription?.config?.nextVisitType?.map((nextVisit: string) => ({
        label: nextVisit,
        value: nextVisit,
      }))
    : prescription?.config?.healthCategory?.map(
        (category: IHealthCategory) => ({
          label: (
            <Grid item className={style.categoryContainer}>
              <img
                src={category.icon}
                alt="Category icon"
                width={22}
                height={16}
              />
              {category.name}
            </Grid>
          ),
          value: category.id,
        })
      );

  useEffect(() => {
    if (Object.keys(prescription?.create).length > 0) {
      if (identifier === IAccordionInputChipListEnums.HEALTH_CATEGORIES) {
        setSelectedValue(prescription?.create?.healthCategoryId);
      } else {
        setSelectedValue(prescription?.create?.nextVisitType);
      }
    }
  }, [prescription?.create]);

  return (
    <Grid item className={style.container}>
      <MemoizedAccordionWithTitle title={title}>
        <Grid item className={style.inputBtnContainer}>
          {hasInputField && (
            <input
              type="text"
              placeholder="Type here"
              ref={inputRef}
              value={prescription?.create?.nextVisitNo}
              onChange={handleInputValues}
              autoFocus
            />
          )}
          <Grid item className={style.nextVisitTypeSelector}>
            <MemoizedChipSelect
              variant={
                !hasInputField ? IChipVariant.ROUNDED : IChipVariant.DEFAULT
              }
              data={chipSelectData}
              showCarousel={!hasInputField}
              selectedValue={selectedValue}
              muiclass={{
                border: "0.1rem solid var(--gray-200)",
                backgroundColor: "var(--neutral-100)",
              }}
              setSelectedValue={setSelectedValue}
            />
          </Grid>
        </Grid>
      </MemoizedAccordionWithTitle>
    </Grid>
  );
};

export default AccordionInputChip;
