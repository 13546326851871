import { lazy } from "react";

const PharmacyClinicDiagnostic = lazy(
  () => import("../../views/PharmacyClinicDiagnostic")
);
const Profile = lazy(() => import("../../views/Common/Profile"));

const CommonRoutes = [
  { path: "/orders", component: <PharmacyClinicDiagnostic /> },

  {
    path: "/profile-details",
    component: <Profile />,
  },
];

export default CommonRoutes;
