import { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import css from "./index.module.css";

import { IDiagnosticCenterList } from "../../models/ApiRequestResponse/patient";

import GetImages from "../GetImages";
import SwitchDiagnosticCenterModal from "../SwitchDiagnosticCenterModal";

import {
  getDateFormat,
  roundToTwoDecimals,
  timeInAmPmFormat,
} from "../../utils";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setSelectedCenterDetails,
  setStepsTest,
} from "../../store/slices/patient/tests";
import { setIsShowUniversalSearch } from "../../store/slices/config";

const DiagnosticList: React.FC = () => {
  const [showAlert, setAlert] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { centerList, cartDetails } = useAppSelector((state) => state.tests);

  const handleSelectCard = (data: IDiagnosticCenterList) => {
    if (cartDetails?.tests?.length > 0) {
      setAlert(true);
    } else {
      const today = new Date();
      let selectedDate = getDateFormat(today.toDateString());
      const newObj = { ...data };
      let selectDate = new Date(selectedDate);
      let dateTimeStmp = Math.floor(selectDate.getTime() / 1000);
      newObj.selectedDate = dateTimeStmp;
      dispatch(setSelectedCenterDetails(newObj));
      dispatch(setIsShowUniversalSearch(false));
      navigate("/test-reports");
      dispatch(setStepsTest(1));
    }
  };

  return (
    <Grid container className={css.diagnosticBox}>
      {centerList?.length > 0 &&
        centerList.map((center: IDiagnosticCenterList) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={4}
            key={center.user_id}
            className={css.diagnosticCardGrid}
            sx={{
              borderBottom: {
                xs: "0.1rem solid var(--gray-200)",
              },
            }}
          >
            <Card
              className={css.cardDiagnostic}
              onClick={() => handleSelectCard(center)}
            >
              <CardContent>
                <Grid className={css.centerGrid}>
                  <Grid className={css.centerNameGrid}>
                    <GetImages name={"ClinicIcon"} width="20" height="20" />
                    &nbsp;
                    <Typography className={css.centerName}>
                      {center.name}
                    </Typography>
                  </Grid>
                  <Typography className={css.kmTextMobile}>
                    {roundToTwoDecimals(center?.distance)}km
                  </Typography>
                </Grid>

                <Grid className={css.centreDetails}>
                  <Typography className={css.secondaryText}>
                    {`${center.address}, ${center.city}`}
                  </Typography>
                  <Typography className={css.kmText}>
                    {roundToTwoDecimals(center?.distance)}km
                  </Typography>
                  <Grid className={css.centerNameGrid}>
                    <Typography className={css.openTime}>
                      <FormattedMessage id="open_at" defaultMessage="Open At" />
                      &nbsp;
                      {center.start_time} &nbsp;
                    </Typography>
                    <Typography className={css.secondaryText}>
                      <li>
                        {`${timeInAmPmFormat(
                          center.start_time
                        )} - ${timeInAmPmFormat(center.end_time)}`}
                      </li>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}

      {showAlert && (
        <SwitchDiagnosticCenterModal
          open={showAlert}
          onClose={() => setAlert(false)}
        />
      )}
    </Grid>
  );
};

export default DiagnosticList;
