import { styled, TextField as InputField } from "@mui/material";

// const InputField = styled(TextField)<TextFieldProps>(() => {
//   return {
//     "& .MuiInputBase-input.Mui-disabled": {
//       color: "black",
//       "-webkit-text-fill-color": "black",
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderColor: "var(--neutral-100)",
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-disabled": {
//         "& fieldset": {
//           borderColor: "white",
//         },
//       },
//       "&:hover fieldset": {
//         borderColor: "var(--neutral-100)",
//         borderRadius: "0.8rem",
//         borderBottom: "0.1rem solid var(--gray-200)",
//       },
//       "&.Mui-focused fieldset": {
//         borderColor: "var(--neutral-100)",
//         borderRadius: "0.8rem",
//         borderBottom: "0.1rem solid var(--gray-200)",
//       },
//       borderRadius: "0.8rem",
//     },
//     "&.Mui-disabled": {
//       "& .MuiOutlinedInput-root": {
//         borderColor: "white",
//       },
//     },
//     "& .MuiInputLabel-root": {
//       color: "var(--gray-400)",
//     },
//     "& .MuiInputLabel-root.Mui-focused, & .MuiInputLabel-root.MuiFormLabel-filled":
//       {
//         color: "var(--gray-400)",
//         transform: "translate(1rem, -0.1rem) scale(0.85)", // Moves label inside the field
//         padding: "0 5px", // Padding to make it look cleaner
//         borderRadius: "5px",
//       },
//   };
// });

const style = {
  textArea: {
    "& textarea": {
      width: "-webkit-fill-available",
      fontFamily: "Poppins",
      color: "var(--black-900)",
      border: "none",
      padding: "2.6rem 0.8rem",
      borderRadius: "0.8rem",
      borderBottom: "0.1rem solid var(--gray-200)",
      background: "var(--neutral-100)",
      "&:focus": {
        outline: "none",
      },
      "&::placeholder": {
        color: "var(--gray-400)",
        fontSize: "1.4rem",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
      },
    },
  },
};
const TextArea = styled("textarea")(
  ({ theme }) => style.textArea["& textarea"]
);

export { InputField, TextArea };
