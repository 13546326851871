import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import style from "./index.module.css";

import CopyWriteFooter from "./CopyWriteFooter";
import GetImages from "../../../GetImages";
import Button from "../../../Button";
import DownloadAppBanner from "../../../DownloadAppBanner";

import { IButtonVariant } from "../../../../models/button";

import { patientFooterData } from "../../../../utils/common";

const DesktopBottomNavigation: React.FC = () => {
  const {
    secondColumnLinks,
    thirdColumnLinks,
    fourthColumnLinks,
    socialIconsList,
  } = patientFooterData;
  return (
    <Grid item>
      <DownloadAppBanner />
      <Grid item className={style.bottomNavigationContainer}>
        <Grid item className={style.gbscAboutContainer}>
          <Grid item>
            <GetImages name="GBSCLargeLogo" width="120" height="70" />
          </Grid>
          <Grid item className={style.gbscAboutText}>
            <Typography component={"p"}>
              <FormattedMessage
                id="download_description"
                defaultMessage="Download description"
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid item>
          {secondColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {thirdColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          {fourthColumnLinks.map((navItem, index) => (
            <Grid item key={index}>
              <Typography component={"p"} className={style.linkTitle}>
                <FormattedMessage id={navItem.title} defaultMessage="Title" />
              </Typography>
              {navItem.items.map((item, index) => (
                <Typography
                  component={"p"}
                  key={index}
                  className={style.linkText}
                >
                  <FormattedMessage id={item} defaultMessage="Items" />
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Grid item className={style.downloadAppBtnContainer}>
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="MobileIcon" width="28" height="24" />
              <FormattedMessage
                id="download_app"
                defaultMessage="Download the app"
              />
            </Button>
          </Grid>
          <Grid item className={style.customerSupportContainer}>
            <Typography component={"p"}>
              <FormattedMessage
                id="customer_support"
                defaultMessage="Customer Support"
              />
            </Typography>
            <Grid item className={style.telePhoneNumber}>
              <GetImages name="TelePhoneIcon" width="24" height="20" />
              <Typography component={"p"}>+91 - 9876543210</Typography>
            </Grid>
            <Grid item className={style.timingContactEmailContainer}>
              <Typography component={"p"}>10am - 8pm (Mon-Sat)</Typography>
              <Grid item className={style.emailContainer}>
                <GetImages name="MessageOrangeIcon" width="32" height="20" />
                <Typography component={"p"}>hello@brainandspine.com</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={style.socialAccountsContainer}>
            <Typography component={"p"}>
              <FormattedMessage
                id="connect_with_us"
                defaultMessage="Connect with us on"
              />
            </Typography>
            <Grid item className={style.socialLinks}>
              {socialIconsList?.map((social, index) => (
                <Link
                  to={social?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Grid item key={index} className={style.iconOuterContainer}>
                    <GetImages name={social?.iconName} width="20" height="20" />
                  </Grid>
                </Link>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CopyWriteFooter />
    </Grid>
  );
};

export default DesktopBottomNavigation;
