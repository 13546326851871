import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import VerifyPatientContact from "../../../../components/AdmitPatient/Modals/VerifyPatientContact";
import VerifyOTP from "../../../../components/AdmitPatient/Modals/VerifyOTP";

import {
  IAddPatient,
  IPatientExistOrNotModule,
} from "../../../../models/doctor";
import { IMobileVerify, IOtpVerify } from "../../../../models/onboarding";

import {
  fetchDoctorHospitalListing,
  fetchHospitalRoomsListing,
} from "../../../../store/slices/doctor/ipDashboard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../../../store/slices/doctor/checkMobilePatientExistOrNot";
import { setAddPatient } from "../../../../store/slices/doctor/topNavigation";

import { INotifyEnum, notify } from "../../../../utils/toaster";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import useMobileCheckPatientCreate from "../../../../utils/hooks/useMobileCheckPatientCreate";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

const PreAdmitPatientPopUps: React.FC = () => {
  const [mobileNo, setMobileNo] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isVerifyPatient, setIsVerifyPatient] = useState(false);

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const { selectedHospitalId, hospitals } = useAppSelector(
    (state) => state.ipDashboard
  );
  const { checkNumberError, moduleName } = useAppSelector(
    (state) => state.patientExistOrNot
  );
  const { mobileCheck, otpVerify } = useAppSelector(
    (state) => state.topNavigation.addPatient
  );

  const dispatch = useAppDispatch();

  const {
    checkMobileNumberDetail,
    createPatientAfterVerifyOtp,
    sendOtpToCreatePatient,
  } = useMobileCheckPatientCreate({ mobileNo });

  const openCloseMobileCheckOTPVerify = (
    field: IAddPatient,
    value: boolean
  ) => {
    dispatch(
      setAddPatient({
        field,
        value,
      })
    );
  };

  const checkMobileNumberSubmission = (values: IMobileVerify) => {
    setIsBtnLoading(true);
    try {
      setMobileNo(values.mobileNo);
      checkMobileNumberDetail(values.mobileNo);
      dispatch(setModuleName(IPatientExistOrNotModule.ADMIT_PATIENT));
      dispatch(resetPatientDetailErrorMessages());
    } catch (error) {
      console.log(error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const otpSubmitHandler = (value: IOtpVerify) => {
    setIsBtnLoading(true);
    try {
      createPatientAfterVerifyOtp(value.otp);
      openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, false);
      openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
      dispatch(
        fetchHospitalRoomsListing({
          hospitalId: selectedHospitalId,
          isAdmitPatient: true,
          available: true,
        })
      );
      if (isMobile || isTablet) {
        navigate("/admit-patient");
      } else {
        openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  useEffect(() => {
    setIsVerifyPatient(mobileCheck);
  }, [mobileCheck]);

  useEffect(() => {
    if (moduleName === IPatientExistOrNotModule.ADMIT_PATIENT) {
      if (!checkNumberError) return;
      const { message, statusCode } = checkNumberError;
      if (statusCode === IAPIStatusCode.BAD_REQUEST) {
        sendOtpToCreatePatient();
        openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, true);

        //close admit-patient modal
        setIsVerifyPatient(false);
      } else if (statusCode === IAPIStatusCode.FORBIDDEN) {
        notify(INotifyEnum.ERROR, message);
      } else if (statusCode === IAPIStatusCode.SUCCESS) {
        if (isMobile || isTablet) {
          navigate("/admit-patient");
        } else {
          openCloseMobileCheckOTPVerify(IAddPatient.ADMIT_PATIENT, true);
        }

        dispatch(
          fetchHospitalRoomsListing({
            hospitalId: selectedHospitalId,
            isAdmitPatient: true,
            available: true,
          })
        );

        openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
      }
    }
  }, [checkNumberError, moduleName]);

  useEffect(() => {
    if (hospitals.length === 0) {
      dispatch(fetchDoctorHospitalListing());
    }
  }, []);

  return (
    <>
      <VerifyPatientContact
        isOpen={isVerifyPatient}
        closeHandler={() => {
          setIsVerifyPatient(false);
          openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
          dispatch(resetPatientDetailErrorMessages());
          dispatch(setModuleName(""));
        }}
        mobileSubmitHandler={checkMobileNumberSubmission}
      />
      {otpVerify && (
        <VerifyOTP
          isBtnLoading={isBtnLoading}
          handleOTPSubmit={otpSubmitHandler}
          mobileNo={mobileNo}
          navigateToMobileVerify={() => {
            setIsVerifyPatient(true);
            openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, true);
            openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, false);
            dispatch(resetPatientDetailErrorMessages());
          }}
          onClose={() => {
            openCloseMobileCheckOTPVerify(IAddPatient.MOBILE_CHECK, false);
            openCloseMobileCheckOTPVerify(IAddPatient.OTP_VERIFY, false);
            dispatch(resetPatientDetailErrorMessages());
          }}
          resendOTP={sendOtpToCreatePatient}
        />
      )}
    </>
  );
};

export default PreAdmitPatientPopUps;
