import { GBSCError } from "../../../models/GBSCError";
import { IMobileVerifyOrSendOTPRes } from "../../../models/onboarding";
import {
  ICreateMember,
  ICreateEditMemberRequest,
  IDoctorProfile,
  IMemeberList,
  IPatientPhoneNumber,
  IPatientProfile,
  IPatientProfileUpdateRequest,
  IUpdateMember,
} from "../../../models/ApiRequestResponse/patient";

import { get, patch, post } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";
import { getLocation } from "../..";

export const getPatientProfileDetailApi = async (): Promise<
  IPatientProfile | GBSCError
> => {
  try {
    const response = await get<IPatientProfile>(APIEndpoint.patient.profile);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updatePatientProfileDetailApi = async (
  requestBody: IPatientProfileUpdateRequest
): Promise<IPatientProfile | GBSCError> => {
  try {
    const response = await patch<IPatientProfile>(
      APIEndpoint.patient.profile,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getPatientNewMobileNumberApi = async (
  requestBody: IPatientPhoneNumber
): Promise<IMobileVerifyOrSendOTPRes | GBSCError> => {
  try {
    const response = await post<IMobileVerifyOrSendOTPRes>(
      APIEndpoint.patient.updateMobileChangeOtp,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getMemberListApi = async (): Promise<IMemeberList | GBSCError> => {
  try {
    const response = await get<IMemeberList>(APIEndpoint.patient.patientMember);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const addMemberApi = async (
  requestBody: ICreateEditMemberRequest
): Promise<ICreateMember | GBSCError> => {
  try {
    const response = await post<ICreateMember>(
      APIEndpoint.patient.patientMember,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updateMemberApi = async (
  memberId: string,
  requestBody: ICreateEditMemberRequest
): Promise<IUpdateMember | GBSCError> => {
  try {
    let url = APIEndpoint.patient.patientMember;
    const response = await patch<IUpdateMember>(
      `${url}/${memberId}`,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorProfileByIdApi = async (
  doctorId?: string
): Promise<IDoctorProfile | GBSCError> => {
  try {
    const location = getLocation();
    const url = APIEndpoint.patient.doctorProfile;
    const response = await get<IDoctorProfile>(
      doctorId
        ? `${url}/${doctorId}${
            location?.latitude && location?.longitude
              ? `?latitude=${location?.latitude}&longitude=${location?.longitude}`
              : ""
          }`
        : url
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
