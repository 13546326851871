export interface IStatusCodeMessage {
  statusCode: number;
  message: string;
}

export enum IOperatingSystemEnums {
  WINDOWS = "WINDOWS",
  MAC_OS = "MAC OS",
  LINUX = "LINUX",
  ANDROID = "ANDROID",
  IOS = "IOS",
  UNKNOWN_OS = "UNKNOWN OS",
}

export enum IAddClinicTeamMemberEnums {
  STATIC = "STATIC",
  ASSOCIATED_CLINICAL_TEAM = "ASSOCIATED_CLINICAL_TEAM",
  SEARCHED_MEMEBER = "SEARCHED_MEMEBER",
}

export interface IKeyValueChip {
  label: string | React.ReactNode;
  value: string;
  isBooked?: boolean;
  isSelection?: boolean;
}
export enum NavDropdownType {
  ADD_PATIENT = "ADD_PATIENT",
  PROFILE = "PROFILE",
  PHARMACY = "PHARMACY",
  DIAGNOSTIC_CENTER = "DIAGNOSTIC_CENTER",
}

export enum IResetAppState {
  RESET_ALL = "RESET_ALL",
}
