import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchAssessmentHealthQuestionList,
  resetAssessmentState,
} from "../../../../../store/slices/patient/healthAssessment";
import { useAppDispatch } from "../../../../../store/hooks";
import { randomCardColorGenerator } from "../../../../../utils";

interface IProps {
  name: string;
  imageUrl: string;
  uniqueId?: string;
}
const DiseaseCard: React.FC<IProps> = (props) => {
  const { name, imageUrl, uniqueId } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const appendQueryParam = () => {
    const params = new URLSearchParams(searchParams);
    params.set("assessmentId", uniqueId!);
    params.set("type", name);
    setSearchParams(params);
    dispatch(resetAssessmentState());
    dispatch(
      fetchAssessmentHealthQuestionList({
        healthAssessmentId: uniqueId!,
      })
    );
  };

  return (
    <Grid
      item
      sx={{
        backgroundColor: randomCardColorGenerator(),
        textAlign: "center",
        cursor: "pointer",
        borderRadius: {
          xs: "0.8rem",
          sm: "0.8rem",
          md: "1.6rem",
          lg: "1.6rem",
        },
        padding: {
          xs: "0.4rem 0.4rem 0.8rem 0.2rem",
          sm: "0.4rem 0.4rem 0.8rem 0.2rem",
          md: "1.2rem 1.6rem 1.6rem 1.6rem",
          lg: "1.2rem 1.6rem 1.6rem 1.6rem",
        },
        "& > p": {
          fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.4rem", lg: "1.4rem" },
          marginTop: "0.4rem'",
          color: "var(--black-900)",
          textAlign: "center",
          fontWeight: "400",
          lineHeight: "normal",
          textTransform: "capitalize",
        },
        width: {
          xs: "10.2rem",
          md: "17.6rem",
        },
        height: {
          xs: "9.8rem",
          md: "15.7rem",
        },
      }}
      onClick={appendQueryParam}
    >
      <Box
        component="img"
        src={imageUrl}
        alt="no image"
        sx={{
          height: { md: "9.6rem", xs: "6rem" },
          width: { md: "9.6rem", xs: "6rem" },
          objectFit: "contain",
        }}
      />
      <Typography component={"p"}>{name}</Typography>
    </Grid>
  );
};

export default DiseaseCard;
