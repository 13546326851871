import React from "react";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

import styles from "./index.module.css";

import { IHealthHubPlaylistVideo } from "../../../../../models/ApiRequestResponse/patient";

type NextVideoCardProps = {
  videoData: IHealthHubPlaylistVideo;
};

const NextVideoCard: React.FC<NextVideoCardProps> = ({ videoData }) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const handleNextVideoClick = () => {
    navigate("/health-hub/video", { state: { videoId: videoData.id } });
  };

  return (
    <Grid className={styles.nextVideoCard} onClick={handleNextVideoClick}>
      <Grid>
        <img
          src={videoData.thumbnailUrl}
          className={styles.videoThumbnail}
          alt={videoData.name}
        />
      </Grid>
      <Grid className={styles.videoContentContainer}>
        <Typography variant="body1" className={styles.videoTitle}>
          {videoData.name}
        </Typography>
        <Typography variant="body2" className={styles.videoDescription}>
          {videoData.description}
        </Typography>
        <Grid className={styles.viewsAndLikesContainer}>
          <Typography variant="body2" className={styles.viewsCount}>
            {videoData.views}+{" "}
            {intl.formatMessage({
              id: "views",
              defaultMessage: "Views",
            })}
          </Typography>
          <Typography variant="body2" className={styles.likesCount}>
            {videoData.likes}+{" "}
            {intl.formatMessage({
              id: "likes",
              defaultMessage: "Likes",
            })}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NextVideoCard;
