import { INotifyEnum, notify } from "../toaster";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { storage } from "../Storage";
import { getCartListApi } from "../apis/patient/tests";
import { setSelectedCartDetails } from "../../store/slices/patient/tests";

const useGetCartDetails = () => {
  const token = storage.getSessionToken("token");

  const dispatch = useAppDispatch();

  const getCartDetails = async () => {
    const cartDetailRes = await getCartListApi();
    if ("result" in cartDetailRes) {
      dispatch(setSelectedCartDetails(cartDetailRes.result));
      return;
    }
    notify(INotifyEnum.ERROR, cartDetailRes.message);
  };

  useEffect(() => {
    if (token) {
      getCartDetails();
    }
  }, [token]);

  return { getCartDetails };
};

export default useGetCartDetails;
