import { Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Breadcrumb from "../../../components/Breadcrumb";
import BlogContent from "./BlogContent";
import BlogListSection from "../HealthHub/BlogListSection";
import Loader from "../../../components/Loader";

import style from "./index.module.css";

import { useAppSelector } from "../../../store/hooks";

import {
  IHealthHubBlog,
  IHealthHubBlogsResult,
  IHealthHubData,
  IHealthHubResultType,
} from "../../../models/ApiRequestResponse/patient";
import { incrementBlogViewCountApi } from "../../../utils/apis/patient/healthHub";

export const getCurrentBloglist = (blogId: string, data: IHealthHubData) => {
  const blogs = data.result.find(
    (i): i is IHealthHubBlogsResult => i.type === IHealthHubResultType.BLOGS
  );

  if (!blogs) return;

  const blog = (blogs.data as IHealthHubBlog[]).find((b) => b.id === blogId);

  if (!blog) return;

  const relatedBlogs = (blogs.data as IHealthHubBlog[]).filter(
    (b) => b.id !== blogId
  );

  return {
    id: blogs.id,
    name: blogs.name,
    type: blogs.type,
    data: relatedBlogs,
  };
};

const BlogDetails = () => {
  const { data, isLoading } = useAppSelector((state) => state.healthHub);
  const [count, setCount] = useState(0);
  const [blogData, setBlogData] = useState<IHealthHubBlog | null>(null);
  const [currentBloglist, setCurrentBloglist] = useState<
    ReturnType<typeof getCurrentBloglist> | undefined
  >(undefined);

  const { state, search } = useLocation();

  const id = search.split("=")[1];

  useEffect(() => {
    if ((state?.blogId || id) && data.result) {
      findBlogData();
    }
  }, [state?.blogId, data, id]);

  useEffect(() => {
    if (blogData) {
      setCurrentBloglist(getCurrentBloglist(blogData.id, data));
    }
  }, [blogData]);

  useEffect(() => {
    if (blogData && count === 0) {
      updateBlogViewCount();
      setCount(1);
    }
  }, [blogData, count]);

  const updateBlogViewCount = async () => {
    await incrementBlogViewCountApi(state?.blogId || id);
  };

  const findBlogData = () => {
    const blogs = data.result.find(
      (b) => b.type === IHealthHubResultType.BLOGS
    );

    if (!blogs) return;

    const blog = (blogs.data as IHealthHubBlog[]).find(
      (b) => b.id === (state?.blogId || id)
    );

    if (blog) {
      setBlogData(blog);
    }
  };

  if (!blogData || isLoading || !data.result) {
    return <Loader />;
  }

  return (
    <Grid>
      <Grid
        sx={{
          padding: {
            xs: "0",
            sm: "2rem 3rem 4rem 3rem",
            md: "2.8rem 9.2rem 6rem 9.2rem",
            lg: "3.2rem 11.2rem 6.4rem 11.2rem",
          },
          minHeight: { xs: "100vh", sm: "auto" },
          backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
        }}
        className={style.outerContainer}
      >
        <Grid className={style.innerContainer}>
          <Grid
            className={style.breadcrumbContainer}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Breadcrumb
              items={[
                { label: "Health Hub", link: "/health-hub" },
                {
                  label: "Blogs",
                  link: "/health-hub/blog-list",
                  id: currentBloglist?.id,
                },
                {
                  label: blogData?.title || "",
                  link: "/health-hub/blog",
                  isCurrent: true,
                },
              ]}
            />
          </Grid>
          <BlogContent
            likeCount={blogData?.likes || 0}
            userInteraction={blogData?.user_interaction || []}
            blogId={blogData?.id || ""}
            content={blogData?.content || ""}
            blogTitle={blogData?.title || ""}
            blogDescription={blogData?.description || ""}
            blogImage={blogData?.thumbnail || ""}
          />
        </Grid>
      </Grid>
      <Grid
        sx={{ display: { xs: "none", sm: "block" } }}
        className={style.relatedBlogsContainer}
      >
        {currentBloglist && (
          <BlogListSection isRelated data={currentBloglist} />
        )}
      </Grid>
    </Grid>
  );
};

export default BlogDetails;
