import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import GetImages from "../GetImages";
import { storage } from "../../utils/Storage";

import { IRoleType } from "../../models/role";

interface IProps {
  heading: string;
  icon?: boolean;
  tapHandler: () => void;
}
const NotFoundRecord: React.FC<IProps> = (props) => {
  const { heading, tapHandler, icon = true } = props;
  const roleType = storage.getSessionToken("roleType");

  return (
    <Grid item className={style.container} onClick={tapHandler}>
      {icon && (
        <Grid item className={style.settingIconContainer}>
          <GetImages
            name={
              roleType === IRoleType.PHARMACY
                ? "AddAppointmentIcon"
                : "SettingOrangeIcon"
            }
            width="36"
            height="36"
          />
        </Grid>
      )}
      <Typography component={"h6"} className={style.mainTitle}>
        <FormattedMessage id={heading} defaultMessage="Not Found Message" />
      </Typography>
      <Typography component={"p"} className={style.footerText}>
        <FormattedMessage
          id="tap_anywhere_screen"
          defaultMessage="Tap anywhere on the screen"
        />
      </Typography>
    </Grid>
  );
};

export default NotFoundRecord;
