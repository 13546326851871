import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid, InputAdornment, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";

import style from "./index.module.css";

import Button from "../../../../components/Button";
import ChipMultiSelect from "../../../../components/ChipMultiSelect";
import { InputField } from "../../../../components/InputField";
import DropDownSelect from "../../../../components/DropDownSelect";
import ChipSelect from "../../../../components/ChipSelect";
import TimePicker from "../../../../components/TimePicker";
import DateSelect from "../../../../components/DateSelect";
import Confirmation from "../../../../components/Confirmation";

import { IButtonVariant } from "../../../../models/button";
import {
  IDropDownSelectionType,
  IDoctorConsultationSelection,
  ITimePickerType,
  IDoctorConsultationType,
} from "../../../../models/doctor";
import {
  IAvailabilitySettingPermissionEnums,
  IDoctorPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
} from "../../../../models/permission";

import {
  getPermissionAccess,
  getTimeDifferenceInMinutes,
  tranformToDays,
} from "../../../../utils";
import {
  createAvailabilitySettingApi,
  deleteAvailabilitySettingApi,
  getClinicListApi,
  updateAvailabilitySettingApi,
} from "../../../../utils/apis/doctor/availabilitySetting";
import { INotifyEnum, notify } from "../../../../utils/toaster";
import useIsTablet from "../../../../utils/hooks/useIsTablet";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import {
  availabilityInputsNames,
  consultationTypes,
  daysList,
  deleteAfterList,
  IDeleteAfterEnum,
  typeOfConsultation,
} from "../../../../utils/common";
import useIsMobile from "../../../../utils/hooks/useIsMobile";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  detail,
  fetchAvailabilitySettings,
} from "../../../../store/slices/doctor/availabilitySettings";
import { fetchConfiguration } from "../../../../store/slices/config";
import { setShowPermissionAlert } from "../../../../store/slices/permission";

interface IAvailabilityFormValues {
  consultationType: string;
  clinicName: string;
  startTime: string;
  endTime: string;
  slotDuration: string;
  bufferDuration: string;
  consultationFee: string;
  days: string[];
  deleteAfterDate: any;
  appointmentDisplay: string;
  totalSlots: string;
}
interface IClinicList {
  label: string;
  value: string;
}
interface IAvailabilityFormSelectionOpen {
  consultationType: boolean;
  clinicName: boolean;
}

const CreateEditAvailability: React.FC = () => {
  const navigate = useNavigate();

  const intl = useIntl();

  const dispatch = useAppDispatch();
  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const configDetail: any = useAppSelector((state) => state.config);
  const { permission } = useAppSelector((state) => state.rolePermission);

  const [isOpen, setIsOpen] = useState<IAvailabilityFormSelectionOpen>({
    consultationType: false,
    clinicName: false,
  });
  const [selectedDeleteAfter, setSelectedDeleteAfter] = useState<string>(
    availabilityDetail?.delete_after
      ? IDeleteAfterEnum.SELECT_DATE
      : IDeleteAfterEnum.NEVER
  );
  const [clinicListing, setClinicListing] = useState<IClinicList[]>([]);
  const [scheduleBtnDisabled, setScheduleBtnDisabled] = useState<boolean>(true);
  const [confirmDeleteAvailability, setConfirmDeleteAvailability] =
    useState<boolean>(false);

  const [values, setValues] = useState<IAvailabilityFormValues>({
    consultationType: "",
    clinicName: "",
    startTime: "",
    endTime: "",
    slotDuration: "",
    bufferDuration: "",
    consultationFee: "",
    days: [],
    deleteAfterDate: null,
    appointmentDisplay: "",
    totalSlots: "",
  });

  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();

  const closeAvailabilitySelectionModal = () => {
    const availabilityValues: any = {};
    for (let key in isOpen) {
      availabilityValues[key] = false;
    }
    setIsOpen(availabilityValues);
  };

  const saveSelectedValues = (value: string, type: IDropDownSelectionType) => {
    closeAvailabilitySelectionModal();
    switch (type) {
      case IDropDownSelectionType.CONSULTATION:
        return setValues({ ...values, consultationType: value });
      case IDropDownSelectionType.CLINIC_NAME:
        return setValues({ ...values, clinicName: value });
      case IDropDownSelectionType.SLOT_DURATION:
        return setValues({ ...values, slotDuration: value });
      case IDropDownSelectionType.BUFFER_TIME:
        return setValues({ ...values, bufferDuration: value });
      default:
        return null;
    }
  };

  const saveStartEndTime = (value: string, type: ITimePickerType) => {
    switch (type) {
      case ITimePickerType.START_TIME:
        return setValues({ ...values, startTime: value });
      case ITimePickerType.END_TIME:
        const endTime = dayjs(value, "HH:mm", true);
        const startTime = dayjs(values.startTime, "HH:mm", true);
        if (endTime.isValid()) {
          if (endTime.isAfter(startTime)) {
            return setValues({ ...values, endTime: value });
          } else if (endTime.isBefore(startTime)) {
            notify(
              INotifyEnum.ERROR,
              "End time should not less than start time"
            );
            return setValues({ ...values, endTime: "" });
          }
        }
        return;
      default:
        return null;
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!/^\d*$/.test(value)) return;
    const newValues = {
      ...values,
      [name]: availabilityInputsNames.deleteAfterDate === name ? e : value,
    };
    if (/^\d*$/.test(value)) {
      setValues(newValues);
    }
    const { startTime, endTime, slotDuration } = newValues;
    if (startTime && endTime && slotDuration) {
      const diffInMins = getTimeDifferenceInMinutes(startTime, endTime);
      const totalSlots = Math.floor(diffInMins / +slotDuration).toString();
      setValues({ ...newValues, totalSlots: totalSlots ?? "" });
    }
  };

  const getClinicListInfo = async () => {
    try {
      const clinicListResponse = await getClinicListApi();
      if ("result" in clinicListResponse) {
        const result = clinicListResponse.result.map((item, index) => ({
          label: item.name,
          value: item.id,
        }));
        setClinicListing(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateUpdateSchedule = async () => {
    try {
      const payload = {
        type_of_consultation: values.consultationType,
        start_time: values.startTime,
        end_time: values.endTime,
        slot_duration: +values.slotDuration,
        buffer_between_slots: +values.bufferDuration,
        consultation_fee: +values.consultationFee,
        days: values.days,
        ...(values.deleteAfterDate && {
          delete_after:
            IDeleteAfterEnum.SELECT_DATE === selectedDeleteAfter
              ? dayjs(values.deleteAfterDate?.$d).format("YYYY-MM-DD")
              : null,
        }),
        ...(values?.clinicName &&
          values.consultationType === IDoctorConsultationType.IN_CLINIC && {
            clinic_id: values?.clinicName,
            appointment_display: values?.appointmentDisplay,
            total_slots: values?.totalSlots,
          }),
      };
      const actionType = availabilityDetail
        ? IAvailabilitySettingPermissionEnums.UPDATE_AVAILABILITY
        : IAvailabilitySettingPermissionEnums.CREATE_AVAILABILITY;

      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.AVAILABILITY_SETTING,
        actionType,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }

      if (availabilityDetail) {
        const updatedScheduleResponse = await updateAvailabilitySettingApi(
          payload,
          availabilityDetail?.id!
        );
        if ("result" in updatedScheduleResponse) {
          notify(INotifyEnum.SUCCESS, updatedScheduleResponse.message);
          dispatch(fetchAvailabilitySettings());
        } else {
          notify(INotifyEnum.ERROR, updatedScheduleResponse.message);
        }
      } else {
        const createScheduleResponse = await createAvailabilitySettingApi(
          payload
        );
        if ("result" in createScheduleResponse) {
          notify(INotifyEnum.SUCCESS, createScheduleResponse.message);
          dispatch(fetchAvailabilitySettings());
        } else {
          notify(INotifyEnum.ERROR, createScheduleResponse.message);
        }
      }
      if (isTablet || isMobile) {
        navigate("/availability-settings");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectedValuesList = (selectedValues: string[]) => {
    const result: string[] = [];

    selectedValues.forEach((item, index) => {
      daysList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(`${ind + 1}`);
        }
      });
    });
    setValues({ ...values, days: result });
  };

  const getClinicNameById = (clinicId: string) => {
    return clinicListing.filter(
      (clinic, index) => clinic?.value === clinicId
    )[0]?.label;
  };

  const deleteAvailabilitySetting = async () => {
    const deleteAvailabilityResp = await deleteAvailabilitySettingApi(
      availabilityDetail?.id!
    );
    try {
      if (deleteAvailabilityResp.statusCode === IAPIStatusCode.SUCCESS) {
        dispatch(fetchAvailabilitySettings());
        dispatch(detail(null));
        setConfirmDeleteAvailability(!confirmDeleteAvailability);
        notify(INotifyEnum.SUCCESS, deleteAvailabilityResp.message);
      } else {
        setConfirmDeleteAvailability(!confirmDeleteAvailability);
        notify(INotifyEnum.ERROR, deleteAvailabilityResp.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (Object.keys(configDetail.config).length === 0) {
      dispatch(fetchConfiguration());
    }
  }, []);

  useEffect(() => {
    if (
      IDoctorConsultationSelection.IN_CLINIC ==
      consultationTypes[values.consultationType]
    ) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.DOCTOR,
        IDoctorPermissionEnums.GET_DOCTOR_LINKED_CLINICS,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      getClinicListInfo();
    }
  }, [values.consultationType]);

  useEffect(() => {
    const {
      consultationType,
      startTime,
      endTime,
      slotDuration,
      bufferDuration,
      consultationFee,
      days,
    } = values;

    setScheduleBtnDisabled(
      !(
        consultationType.length > 0 &&
        startTime.length > 0 &&
        endTime.length > 0 &&
        slotDuration.length > 0 &&
        bufferDuration.length > 0 &&
        consultationFee.length > 0 &&
        days.length > 0
      )
    );
  }, [values]);

  useEffect(() => {
    const diffInMins = getTimeDifferenceInMinutes(
      availabilityDetail?.start_time!,
      availabilityDetail?.end_time!
    );
    const totalSlots = availabilityDetail
      ? Math.floor(diffInMins / +availabilityDetail?.slot_duration!).toString()
      : "";

    setValues({
      consultationType: availabilityDetail?.type_of_consultation ?? "",
      clinicName: availabilityDetail?.clinic_id ?? "",
      startTime: availabilityDetail?.start_time ?? "",
      endTime: availabilityDetail?.end_time ?? "",
      slotDuration: `${availabilityDetail?.slot_duration ?? ""}`,
      bufferDuration: `${availabilityDetail?.buffer_between_slots ?? ""}`,
      consultationFee: availabilityDetail?.consultation_fee?.toString() ?? "",
      days: availabilityDetail?.days ? availabilityDetail?.days : [],
      deleteAfterDate: availabilityDetail?.delete_after
        ? dayjs(availabilityDetail?.delete_after)
        : null,
      appointmentDisplay: availabilityDetail?.appointment_display ?? "",
      totalSlots: totalSlots ? totalSlots : "",
    });
    setSelectedDeleteAfter(
      availabilityDetail?.delete_after
        ? IDeleteAfterEnum.SELECT_DATE
        : IDeleteAfterEnum.NEVER
    );
  }, [availabilityDetail]);

  return (
    <Grid item position={"relative"}>
      <Grid item className={style.createInnerContainer}>
        <Grid item className={style.createFormContainer}>
          <Grid item className={style.createAvailInputs}>
            <FormControl variant="outlined" fullWidth>
              <InputField
                type={"text"}
                label={intl.formatMessage({
                  id: "typeOfConsultation",
                  defaultMessage: "Type of consultation",
                })}
                placeholder={intl.formatMessage({
                  id: "typeOfConsultation",
                  defaultMessage: "Type of consultation",
                })}
                disabled={true}
                value={consultationTypes[values.consultationType] ?? ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        component={"p"}
                        className={style.selectText}
                        onClick={() =>
                          setIsOpen({ ...isOpen, consultationType: true })
                        }
                      >
                        <FormattedMessage id="select" defaultMessage="Select" />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          {values.consultationType.length > 0 &&
            IDoctorConsultationSelection.VIDEO_CONSULTATION !==
              consultationTypes[values.consultationType] && (
              <Grid item>
                <FormControl
                  variant="outlined"
                  className={style.createAvailInputs}
                  fullWidth
                >
                  <InputField
                    type={"text"}
                    label={intl.formatMessage({
                      id: "clinic_name",
                      defaultMessage: "Clinic Name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "clinic_name",
                      defaultMessage: "Clinic Name",
                    })}
                    disabled={true}
                    value={getClinicNameById(values.clinicName) ?? ""}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography
                            component={"p"}
                            className={style.selectText}
                            onClick={() =>
                              setIsOpen({ ...isOpen, clinicName: true })
                            }
                          >
                            <FormattedMessage
                              id="select"
                              defaultMessage="Select"
                            />
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            )}
          <Grid item className={style.createAvailInputs}>
            <FormControl fullWidth>
              <TimePicker
                type={ITimePickerType.START_TIME}
                label="start_time"
                value={values.startTime}
                handleSaveTime={saveStartEndTime}
                disabled={true}
              />
            </FormControl>
            <FormControl fullWidth>
              <TimePicker
                type={ITimePickerType.END_TIME}
                label="end_time"
                value={values.endTime}
                handleSaveTime={saveStartEndTime}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item className={style.createAvailInputs}>
            <FormControl fullWidth>
              <InputField
                type={"text"}
                label={intl.formatMessage({
                  id: "slot_duration",
                  defaultMessage: "Slot Duration",
                })}
                placeholder={intl.formatMessage({
                  id: "slot_duration",
                  defaultMessage: "Slot Duration",
                })}
                value={values.slotDuration}
                onChange={handleInputChange}
                name={availabilityInputsNames.slotDuration}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography component={"p"} className={style.selectText}>
                        <FormattedMessage id="min" defaultMessage="min" />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputField
                type={"text"}
                label={intl.formatMessage({
                  id: "buffer_slots",
                  defaultMessage: "Buffer slots",
                })}
                placeholder={intl.formatMessage({
                  id: "buffer_slots",
                  defaultMessage: "Buffer slots",
                })}
                value={values.bufferDuration}
                onChange={handleInputChange}
                name={availabilityInputsNames.bufferDuration}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography component={"p"} className={style.selectText}>
                        <FormattedMessage id="min" defaultMessage="min" />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item className={style.createAvailInputs}>
            {values.consultationType === IDoctorConsultationType.IN_CLINIC && (
              <FormControl
                fullWidth
                className={style.createAvailabilityRightSpace}
              >
                <InputField
                  type="text"
                  value={values.totalSlots}
                  label={intl.formatMessage({
                    id: "total_slots",
                    defaultMessage: "Total Slots",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "total_slots",
                    defaultMessage: "Total Slots",
                  })}
                  disabled
                />
              </FormControl>
            )}
            <FormControl fullWidth>
              <InputField
                value={values.consultationFee}
                onChange={handleInputChange}
                name={availabilityInputsNames.consultationFee}
                label={intl.formatMessage({
                  id: "consultation_fee",
                  defaultMessage: "Consultation Fee (₹)",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "consultation_fee",
                  defaultMessage: "Consultation Fee (₹)",
                })}
              />
            </FormControl>
          </Grid>
          {values.consultationType === IDoctorConsultationType.IN_CLINIC && (
            <Grid item className={style.createAvailInputs}>
              <InputField
                type="text"
                value={values.appointmentDisplay}
                onChange={handleInputChange}
                name={availabilityInputsNames.appointmentDisplay}
                label={intl.formatMessage({
                  id: "appointment_display",
                  defaultMessage: "Appointment Display",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "appointment_display",
                  defaultMessage: "Appointment Display",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography component={"p"} className={style.selectText}>
                        <FormattedMessage id="min" defaultMessage="min" />
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Grid>
          )}
        </Grid>
        <Grid item textAlign={"left"}>
          <Typography component={"h5"} className={style.title}>
            <FormattedMessage id="days" defaultMessage="Days" />
          </Typography>
          <Typography component={"p"} className={style.selectTitle}>
            <FormattedMessage id="select" defaultMessage="Select" />
          </Typography>
          <Grid item>
            <ChipMultiSelect
              value={tranformToDays(values.days)}
              list={daysList}
              selectedValuesList={selectedValuesList}
            />
          </Grid>
        </Grid>
        <Grid item className={style.deleteAfterContainer}>
          <Typography component={"p"} className={style.title}>
            <FormattedMessage id="delete_after" defaultMessage="Delete After" />
          </Typography>
          <Grid item className={style.deleteAfterSelectionContainer}>
            <ChipSelect
              data={deleteAfterList}
              selectedValue={selectedDeleteAfter}
              setSelectedValue={setSelectedDeleteAfter}
            />
          </Grid>
          {selectedDeleteAfter === IDeleteAfterEnum.SELECT_DATE && (
            <Grid item className={style.dateContainer}>
              <DateSelect
                label={intl.formatMessage({
                  id: "select_date",
                  defaultMessage: "Select Date",
                })}
                value={values.deleteAfterDate}
                onChange={(e: any) =>
                  setValues({ ...values, deleteAfterDate: e })
                }
                minDate={dayjs()}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item className={style.btnContainer}>
        {!(isTablet || isMobile) && availabilityDetail && (
          <Grid item className={style.btn}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() =>
                setConfirmDeleteAvailability(!confirmDeleteAvailability)
              }
            >
              <FormattedMessage id="delete" defaultMessage="Delete" />
            </Button>
          </Grid>
        )}
        <Grid item className={style.btn}>
          <Button
            variant={IButtonVariant.WHITE}
            btnTrigger={() => {
              if (isTablet || isMobile) {
                navigate(-1);
              }
              dispatch(detail(null));
            }}
          >
            <FormattedMessage
              id={isTablet || isMobile ? "cancel" : "discard_changes"}
              defaultMessage={
                isTablet || isMobile ? "Cancel" : "Discard Changes"
              }
            />
          </Button>
        </Grid>
        <Button
          btnTrigger={handleCreateUpdateSchedule}
          disabled={scheduleBtnDisabled}
        >
          <FormattedMessage
            id={availabilityDetail ? "update" : "save"}
            defaultMessage={availabilityDetail ? "Update" : "Save"}
          />
        </Button>
      </Grid>
      {isOpen.consultationType && (
        <DropDownSelect
          type={IDropDownSelectionType.CONSULTATION}
          isOpen={isOpen.consultationType}
          closeHandler={closeAvailabilitySelectionModal}
          title="typeOfConsultation"
          values={typeOfConsultation}
          selectedValue={values.consultationType}
          saveSelectedValues={saveSelectedValues}
        />
      )}
      {isOpen.clinicName && (
        <DropDownSelect
          type={IDropDownSelectionType.CLINIC_NAME}
          isOpen={isOpen.clinicName}
          closeHandler={closeAvailabilitySelectionModal}
          title="clinic_name"
          values={clinicListing}
          selectedValue={values.clinicName}
          saveSelectedValues={saveSelectedValues}
        />
      )}

      {confirmDeleteAvailability && (
        <Confirmation
          isOpen={confirmDeleteAvailability}
          closeHandler={() =>
            setConfirmDeleteAvailability(!confirmDeleteAvailability)
          }
          title="delete_availability"
          alterMessage={"confirm_delete"}
          name={
            availabilityDetail?.type_of_consultation ===
            IDoctorConsultationType.IN_CLINIC
              ? availabilityDetail?.clinic_name ?? ""
              : IDoctorConsultationSelection.VIDEO_CONSULTATION
          }
          actionHandler={deleteAvailabilitySetting}
        />
      )}
    </Grid>
  );
};

export default CreateEditAvailability;
