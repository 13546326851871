import {
  IAccordionInputChipListEnums,
  ITitleInputValuesListEnums,
  IVitalsIdentifiers,
} from "../../../../models/doctor";

const vitalsInputList = [
  { label: "bp_mmhg", identifier: IVitalsIdentifiers.BP },
  { label: "pulse_bpm", identifier: IVitalsIdentifiers.PULSE },
  { label: "temp_cal", identifier: IVitalsIdentifiers.TEMP },
  { label: "weight_kg", identifier: IVitalsIdentifiers.WEIGHT },
  { label: "height_cms", identifier: IVitalsIdentifiers.HEIGHT },
  { label: "bmi_kg", identifier: IVitalsIdentifiers.BMI },
];

const titleInputValueList = [
  {
    title: "tags",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.TAG,
  },
  {
    title: "other_health_problems",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.OTHER_HEALTH_PROBLEMS,
  },
  {
    title: "complaint",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.COMPLAINT,
  },
  {
    title: "diagnosis",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.DIAGNOSIS,
  },
  {
    title: "diet_exercise",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.DIET_AND_EXERCISE,
  },
  {
    title: "note",
    hasActionButton: false,
    buttonLabel: null,
    identifier: ITitleInputValuesListEnums.NOTE,
  },
];

const uploadDataList = [
  {
    title: "medicines",
    hasActionButton: true,
    buttonLabel: { text: "Add Medicine", icon: "MedicineSmallIcon" },
    identifier: ITitleInputValuesListEnums.MEDICINE,
  },
  {
    title: "tests",
    hasActionButton: true,
    buttonLabel: { text: "Add Test", icon: "TestSmallIcon" },
    identifier: ITitleInputValuesListEnums.TEST,
  },
];

const accordionInputChipList = [
  {
    hasInputField: true,
    title: "Next Visit",
    identifier: IAccordionInputChipListEnums.NEXT_VISIT,
  },
  {
    hasInputField: false,
    title: "Health categories",
    identifier: IAccordionInputChipListEnums.HEALTH_CATEGORIES,
  },
];

export {
  vitalsInputList,
  titleInputValueList,
  accordionInputChipList,
  uploadDataList,
};
