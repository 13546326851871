import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Button as MUIButton,
  Stack,
  DialogActions,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import style from "../styles/index.module.css";

import Modal from "../../../Modal";
import { InputField } from "../../../InputField";
import Button from "../../../Button";
import Dialog from "../../../Dialog";

import { transformClasses } from "../../../../utils";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

import { IOtpVerify } from "../../../../models/onboarding";
import { IButtonType, IButtonVariant } from "../../../../models/button";

interface IProps {
  isBtnLoading?: boolean;
  handleOTPSubmit: (value: IOtpVerify) => void;
  resendOTP: () => void;
  navigateToMobileVerify: () => void;
  mobileNo: string;
  onClose: () => void;
}
const VerifyOTP: React.FC<IProps> = (props) => {
  const [seconds, setSeconds] = useState<number>(60);
  const [isTimerStart, setIsTimerStart] = useState<boolean>(false);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const intl = useIntl();

  const {
    handleOTPSubmit,
    resendOTP,
    navigateToMobileVerify,
    mobileNo,
    isBtnLoading,
    onClose,
  } = props;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    getValues,
  } = useForm<IOtpVerify>();

  const handleResendOTP = () => {
    if (isTimerStart) return;
    setIsTimerStart(true);
    resendOTP();
  };

  const handleOTPChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setValue("otp", value);
    trigger("otp");
  };

  const verifyOTPScreen = useCallback(() => {
    return (
      <>
        <DialogTitle textAlign={"center"} variant="h5" fontWeight={500}>
          <FormattedMessage id="enterOtpText" />
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <DialogContentText>
            <FormattedMessage
              id="subHeadingOtp"
              defaultMessage="We have sent a 6 digit code on your mobile Number"
            />
            {mobileNo}
          </DialogContentText>
          <MUIButton
            variant="text"
            onClick={navigateToMobileVerify}
            sx={{ p: 0 }}
          >
            <FormattedMessage
              id="changeNumber"
              defaultMessage="change number"
            />
          </MUIButton>
          <Stack
            component={"form"}
            onSubmit={handleSubmit(handleOTPSubmit)}
            gap={2}
            mt={2}
          >
            <InputField
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              inputProps={{ maxLength: 6 }}
              {...register("otp", {
                required: intl.formatMessage({
                  id: "otpRequired",
                  defaultMessage: "OTP is required",
                }),
                maxLength: 6,
                onChange: handleOTPChange,
                validate: (value) =>
                  value.length === 6 ||
                  intl.formatMessage({
                    id: "otpSixDigits",
                    defaultMessage: "OTP should have 6 digits",
                  }),
              })}
              label={intl.formatMessage({
                id: "otp",
                defaultMessage: "OTP",
              })}
              variant="outlined"
              placeholder={intl.formatMessage({
                id: "otp",
                defaultMessage: "OTP",
              })}
              fullWidth
            />

            <Stack direction={"row"} alignItems={"center"}>
              <Typography variant="subtitle2" fontWeight={400}>
                {isTimerStart && seconds}
              </Typography>
              <MUIButton
                variant="text"
                color={isTimerStart ? "secondary" : "primary"}
                onClick={handleResendOTP}
                disabled={isTimerStart}
              >
                <FormattedMessage id="resendOTP" defaultMessage="Resend OTP" />
              </MUIButton>
            </Stack>

            {errors.otp && (
              <Typography component={"span"} className={style.errorMessage}>
                {errors.otp.message}
              </Typography>
            )}

            <Typography
              variant={"subtitle2"}
              textAlign={"center"}
              fontWeight={400}
            >
              <FormattedMessage
                id="enterOtpAgree"
                defaultMessage="By Entering OTP, I agree with the"
              />
              <span
                className={transformClasses(
                  style.orangeText,
                  style.otpLabeling
                )}
              >
                <FormattedMessage
                  id="term&condition"
                  defaultMessage="Terms and condition"
                />
              </span>
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            <Grid item xs={5}>
              <Button variant={IButtonVariant.WHITE} btnTrigger={onClose}>
                <FormattedMessage id="cancel" />
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                btnTrigger={handleSubmit(handleOTPSubmit)}
                variant={
                  errors.otp?.message || getValues("otp")?.length < 6
                    ? IButtonVariant.GREY
                    : IButtonVariant.ORANGE
                }
                disabled={
                  !!(errors.otp?.message || getValues("otp")?.length < 6)
                }
                isLoading={isBtnLoading}
              >
                <FormattedMessage
                  id="verifyDone"
                  defaultMessage="Verify and Done"
                />
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    );
  }, [isTimerStart, seconds]);

  useEffect(() => {
    if (isTimerStart) {
      if (seconds > 0) {
        window.setTimeout(() => setSeconds(seconds - 1), 1000);
      } else {
        setIsTimerStart(false);
        setSeconds(60);
      }
    }
  }, [isTimerStart, seconds]);

  useEffect(() => {
    setIsTimerStart(true);
  }, []);

  return (
    <>
      {isMobile || isTablet ? (
        <Modal lineBottomBackground="white">{verifyOTPScreen()}</Modal>
      ) : (
        <Dialog fullWidth maxWidth="sm">
          {verifyOTPScreen()}
        </Dialog>
      )}
    </>
  );
};

export default VerifyOTP;
