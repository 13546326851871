import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

import css from "./index.module.css";

import Button from "../Button";

import { IButtonVariant } from "../../models/button";

import useClearCart from "../../utils/hooks/useClearCart";

type Props = {
  open: boolean;
  onClose: () => void;
};

const SwitchDiagnosticCenterModal: React.FC<Props> = ({ open, onClose }) => {
  const { onClearCart } = useClearCart();
  return (
    <Modal open={open} onClose={onClose}>
      <Box className={css.modalBox}>
        <InfoOutlined sx={{ fontSize: "7rem", color: "var(--orange-500)" }} />

        <Typography className={css.modalTitle}>
          <FormattedMessage
            id="switch_diagnostic_center"
            defaultMessage="Switch Diagnostic Center?"
          />
        </Typography>
        <Typography className={css.modalDes}>
          <FormattedMessage
            id="switch_diagnostic_description"
            defaultMessage="You can only add tests from one diagnostic center at a time. Would you like to remove existing tests and add new ones?"
          />
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
          <Button
            variant={IButtonVariant.WHITE}
            btnTrigger={() => {
              onClearCart();
              onClose();
            }}
          >
            <FormattedMessage id="remove_test" defaultMessage="Remove Test" />
          </Button>
          <Button btnTrigger={() => onClose()}>
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SwitchDiagnosticCenterModal;
