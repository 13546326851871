import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { GBSCError } from "../../../models/GBSCError";
import {
  IAdmittedPatientDetail,
  IDoctorHospitalDetail,
  IDoctorHospitalListResponse,
  IHospitalPatient,
  IHospitalRooms,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IAdmitPatientPopUps,
  IRoomType,
  ISelectedDate,
} from "../../../models/doctor";
import { IPDashboardSliceType } from "./models/ipDashboardSliceModel";

import {
  getAdmittedPatientDetailApi,
  getDoctorHospitalListApi,
  getDoctorHospitalPatientListApi,
  getHospitalRoomsListApi,
} from "../../../utils/apis/doctor/ipDashboard";
import { RootState } from "../../store";

interface IDoctorHospitalPatients {
  hospitalId: string;
  discharged?: boolean;
  start_date?: string;
  end_date?: string;
  room_id?: string;
  room_bed_id?: string;
  keyword?: string;
}
export const fetchDoctorHospitalListing = createAsyncThunk<
  IDoctorHospitalListResponse | GBSCError
>("doctor/fetchDoctorHospitalListing", async () => {
  const response = await getDoctorHospitalListApi();
  return response;
});

export const fetchDoctorHospitalPatientListing = createAsyncThunk(
  "doctor/fetchDoctorHospitalPatientListing",
  async ({
    hospitalId,
    start_date,
    end_date,
    discharged,
    keyword,
    room_id,
    room_bed_id,
  }: IDoctorHospitalPatients) => {
    const response = await getDoctorHospitalPatientListApi(
      hospitalId,
      start_date,
      end_date,
      discharged,
      room_id,
      room_bed_id,
      keyword
    );
    return response;
  }
);

export const fetchHospitalRoomsListing = createAsyncThunk(
  "doctor/fetchHospitalRoomsListing",
  async ({
    hospitalId,
    available,
    isAdmitPatient = false,
  }: {
    hospitalId: string;
    available?: boolean;
    isAdmitPatient?: boolean;
  }) => {
    const response = await getHospitalRoomsListApi(hospitalId, available);
    return response;
  }
);

export const fetchAdmitPatientDetails = createAsyncThunk(
  "doctor/fetchAdmitPatientDetails",
  async ({ admitPatientId }: { admitPatientId: string }) => {
    const response = await getAdmittedPatientDetailApi(admitPatientId);
    return response;
  }
);

const initialState = {
  hospitals: [] as IDoctorHospitalDetail[],
  hospitalPatients: [] as IHospitalPatient[],
  patientDetail: {} as IAdmittedPatientDetail,
  rooms: {
    allRooms: [] as IHospitalRooms[],
    ward: [] as IHospitalRooms[],
    icu: [] as IHospitalRooms[],
  },
  admitPatientRooms: {
    allRooms: [] as IHospitalRooms[],
    ward: [] as IHospitalRooms[],
    icu: [] as IHospitalRooms[],
  },
  admitPatientPopUps: {
    isAdmitted: false,
    isDischarged: false,
  } as IAdmitPatientPopUps,
  isEditAdmitPatient: false as boolean,
  isReAdmitPatient: false as boolean,
  selectedHospitalId: "",
  isLoading: true as boolean,
  isPatientListLoading: true as boolean,
  isRoomLoading: true as boolean,
  selectRoomId: "" as string,
  chipFilter: null as string | null,
  selectedDate: null as ISelectedDate | null,
  totalActivePatientCount: 0 as number,
  isWardIcuColumnShown: true as boolean,
} as IPDashboardSliceType;

const ipDashboardSlice = createSlice({
  name: "ipDashboard",
  initialState,
  reducers: {
    setSelectedHospitalId: (state, action) => {
      state.selectedHospitalId = action.payload;
    },
    setEditAdmitPatient: (state, action) => {
      state.isEditAdmitPatient = action.payload;
    },
    setReAdmitPatient: (state, action) => {
      state.isReAdmitPatient = action.payload;
    },
    resetAdmitPatientDetail: (state) => {
      state.patientDetail = {} as IAdmittedPatientDetail;
    },
    setOpenCloseAdmittedDischargeModal: (
      state: IPDashboardSliceType,
      action
    ) => {
      const key: keyof IAdmitPatientPopUps = action.payload;
      state.admitPatientPopUps = {
        ...state.admitPatientPopUps,
        [action.payload]: !state.admitPatientPopUps[key],
      };
    },
    resetAdmitPatients: (state) => {
      state.hospitalPatients = [] as IHospitalPatient[];
    },
    setSelectRoomId: (state, action) => {
      state.selectRoomId = action.payload;
    },
    setChipFilter: (state, action) => {
      state.chipFilter = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setTotalActivePatientCount: (state, action) => {
      state.totalActivePatientCount = action.payload;
    },
    setIsWardIcuColumnShown: (state) => {
      state.isWardIcuColumnShown = !state.isWardIcuColumnShown;
    },
    resetIPdashbpard: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // doctor hospital listing
      .addCase(fetchDoctorHospitalListing.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDoctorHospitalListing.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.hospitals = action.payload.result;
          state.selectedHospitalId =
            state.selectedHospitalId || action.payload.result[0]?.hospital_id;
        }
      })
      .addCase(fetchDoctorHospitalListing.rejected, (state) => {
        state.isLoading = false;
      })
      //  doctor hospital patient listing
      .addCase(fetchDoctorHospitalPatientListing.pending, (state) => {
        state.isPatientListLoading = true;
      })
      .addCase(fetchDoctorHospitalPatientListing.fulfilled, (state, action) => {
        state.isPatientListLoading = false;
        if ("result" in action.payload) {
          state.hospitalPatients = action.payload.result;
        }
      })
      .addCase(fetchDoctorHospitalPatientListing.rejected, (state) => {
        state.isPatientListLoading = false;
      })
      //  hospital room listing
      .addCase(fetchHospitalRoomsListing.pending, (state) => {
        state.isRoomLoading = true;
      })
      .addCase(fetchHospitalRoomsListing.fulfilled, (state, action) => {
        state.isRoomLoading = false;

        if ("result" in action.payload) {
          if (action.meta.arg.isAdmitPatient) {
            state.admitPatientRooms.allRooms = action.payload.result;
            state.admitPatientRooms.icu = action.payload.result.filter(
              (room) => room.type === IRoomType.ICU
            );
            state.admitPatientRooms.ward = action.payload.result.filter(
              (room) => room.type === IRoomType.WARD
            );
          } else {
            state.rooms.allRooms = action.payload.result;
            state.rooms.icu = action.payload.result.filter(
              (room, index) => room.type === IRoomType.ICU
            );
            state.rooms.ward = action.payload.result.filter(
              (room, index) => room.type === IRoomType.WARD
            );
          }
        }
      })
      .addCase(fetchHospitalRoomsListing.rejected, (state) => {
        state.isRoomLoading = false;
      })
      //  admit patient detail
      .addCase(fetchAdmitPatientDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdmitPatientDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.patientDetail = action.payload.result;
        }
      })
      .addCase(fetchAdmitPatientDetails.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const ipDashboardRootSelector = (state: RootState) => state.ipDashboard;

/**
 * Selector to select all the hospitals
 */
export const selectHospitals = createSelector(
  [ipDashboardRootSelector],
  (state) => state.hospitals
);

/**
 * Selector to select the patient detail
 */
export const selectPatientDetail = createSelector(
  [ipDashboardRootSelector],
  (state) => state.patientDetail
);

export const {
  setSelectRoomId,
  resetAdmitPatients,
  setSelectedHospitalId,
  setEditAdmitPatient,
  setReAdmitPatient,
  resetAdmitPatientDetail,
  setOpenCloseAdmittedDischargeModal,
  setChipFilter,
  setSelectedDate,
  setTotalActivePatientCount,
  setIsWardIcuColumnShown,
  resetIPdashbpard,
} = ipDashboardSlice.actions;
export default ipDashboardSlice.reducer;
