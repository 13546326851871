import React from "react";
import { Button as ButtonMUI, CircularProgress } from "@mui/material";

import style from "./index.module.css";

import { IButtonType, IButtonVariant } from "../../models/button";

import { transformClasses } from "../../utils";

interface IProps {
  children: React.ReactNode;
  isLoading?: boolean;
  btnTrigger?: (event: any) => void;
  type?: IButtonType;
  variant?: IButtonVariant;
  disabled?: boolean;
}
const Button: React.FC<IProps> = (props) => {
  const {
    children,
    btnTrigger,
    variant = IButtonVariant.ORANGE,
    isLoading = false,
    type = IButtonType.BUTTON,
    disabled = false,
  } = props;

  const getButtonClass = () => {
    if (!disabled) {
      switch (variant) {
        case IButtonVariant.TEXT:
          return style.text;
        case IButtonVariant.ORANGE:
          return style.orangeDefault;
        case IButtonVariant.ORANGE_ROUNDED:
          return transformClasses(style.orangeDefault, style.orangeRounded);
        case IButtonVariant.WHITE:
          return style.whiteBtn;
        case IButtonVariant.GREY:
          return style.greyedBtn;
        default:
          return style.orangeDefault;
      }
    }
  };
  return (
    <ButtonMUI
      type={type}
      onClick={btnTrigger}
      sx={{
        "&.Mui-disabled": {
          backgroundColor: "lightgray",
          border: "0.1rem solid lightgray",
          color: "darkgray",
        },
      }}
      className={transformClasses(style.btn, getButtonClass())}
      disabled={disabled}
    >
      {isLoading ? (
        <CircularProgress
          size={20}
          sx={{
            color:
              variant === IButtonVariant.ORANGE ? "white" : "var(--orange-500)",
          }}
        />
      ) : (
        children
      )}
    </ButtonMUI>
  );
};

export default Button;
