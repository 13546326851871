import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IDoctorProfleDetail } from "../../../models/ApiRequestResponse/patient";
import {
  IDoctorAssociatedClinicMemberProfile,
  IDoctorReviewRatingDetail,
  IDoctorVideoReview,
  ISendOTPParams,
} from "../../../models/ApiRequestResponse/doctor";

import { getDoctorProfileByIdApi } from "../../../utils/apis/patient/profile";
import {
  getDoctorAssociatedClinicProfiles,
  getDoctorRatingReviewListApi,
  getDoctorVideoReviewsApi,
  searchMemberAddToVideoCall,
} from "../../../utils/apis/doctor/profile";
import { IAPIStatusCode } from "../../../utils/apis/APIEndpointConfig";

interface IFectchDoctorReviewParams {
  doctorId?: string;
  noOfStars?: string;
  repliedByDoctor?: string;
  keyword?: string;
  startDate?: string;
  endDate?: string;
}
export const fetchDoctorProfile = createAsyncThunk(
  "doctor/profile",
  async (id?: string) => {
    const response = await getDoctorProfileByIdApi(id);
    return response;
  }
);

export const fetchDoctorReviewRatingList = createAsyncThunk(
  "doctor/reviewRatingList",
  async ({
    doctorId,
    noOfStars,
    repliedByDoctor,
    keyword,
    startDate,
    endDate,
  }: IFectchDoctorReviewParams) => {
    const response = await getDoctorRatingReviewListApi(
      doctorId,
      noOfStars,
      repliedByDoctor,
      keyword,
      startDate,
      endDate
    );
    return response;
  }
);

export const fetchDoctorAssociatedClinics = createAsyncThunk(
  "doctor/clinics",
  async () => {
    const response = await getDoctorAssociatedClinicProfiles();
    return response;
  }
);
export const fetchSearchedMembers = createAsyncThunk(
  "doctor/members",
  async ({ countryCode, mobileNo }: ISendOTPParams) => {
    const response = await searchMemberAddToVideoCall({
      countryCode,
      mobileNo,
    });
    return response;
  }
);

export const fetchDoctorVideoReviews = createAsyncThunk(
  "doctor/videoReviews",
  async (doctorId: string) => {
    const response = await getDoctorVideoReviewsApi(doctorId);
    return response;
  }
);

const doctorProfileSlice = createSlice({
  name: "doctorProfile",
  initialState: {
    detail: null as IDoctorProfleDetail | null,
    isDetailLoading: true as boolean,
    ratingReviewList: {} as IDoctorReviewRatingDetail,
    isRatingReviewLoading: true as boolean,
    clinicTeams: [] as IDoctorAssociatedClinicMemberProfile[],
    isClinicTeamsLoading: true as boolean,
    searchedMember: [] as IDoctorAssociatedClinicMemberProfile[],
    isLoading: true as boolean,
    videoReviews: [] as IDoctorVideoReview[],
    isVideoReviewsLoading: true as boolean,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // doctor profile
      .addCase(fetchDoctorProfile.pending, (state) => {
        state.isDetailLoading = true;
      })
      .addCase(fetchDoctorProfile.fulfilled, (state, action) => {
        state.isDetailLoading = false;
        if ("result" in action.payload) {
          state.detail = action.payload.result;
        }
      })
      .addCase(fetchDoctorProfile.rejected, (state) => {
        state.isDetailLoading = false;
      })
      // Doctor review rating list
      .addCase(fetchDoctorReviewRatingList.pending, (state) => {
        state.isRatingReviewLoading = true;
      })
      .addCase(fetchDoctorReviewRatingList.fulfilled, (state, action) => {
        state.isRatingReviewLoading = false;
        if ("result" in action.payload) {
          state.ratingReviewList = action.payload.result;
        }
      })
      .addCase(fetchDoctorReviewRatingList.rejected, (state) => {
        state.isRatingReviewLoading = false;
      })
      // Doctor Video review rating list
      .addCase(fetchDoctorVideoReviews.pending, (state) => {
        state.isVideoReviewsLoading = true;
      })
      .addCase(fetchDoctorVideoReviews.fulfilled, (state, action) => {
        state.isVideoReviewsLoading = false;
        if ("result" in action.payload) {
          state.videoReviews = action.payload.result;
        }
      })
      .addCase(fetchDoctorVideoReviews.rejected, (state) => {
        state.isVideoReviewsLoading = false;
      })
      // Doctor associated clinics
      .addCase(fetchDoctorAssociatedClinics.pending, (state) => {
        state.isClinicTeamsLoading = true;
      })
      .addCase(fetchDoctorAssociatedClinics.fulfilled, (state, action) => {
        state.isClinicTeamsLoading = false;
        if ("result" in action.payload) {
          state.clinicTeams = action.payload.result;
        }
      })
      .addCase(fetchDoctorAssociatedClinics.rejected, (state) => {
        state.isClinicTeamsLoading = false;
      })
      // searched members
      .addCase(fetchSearchedMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSearchedMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.statusCode === IAPIStatusCode.BAD_REQUEST) {
          state.searchedMember = [];
        }
        action.payload.statusCode;
        if ("result" in action.payload) {
          state.searchedMember = [action.payload.result];
        }
      })
      .addCase(fetchSearchedMembers.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default doctorProfileSlice.reducer;
