export const style = {
  navigationContainer: {
    position: "fixed",
    zIndex: 1000,
    bottom: 0,
    width: "100%",
  },
  navigationText: {
    color: "var(--gray-30)",
    fontSize: "1.2rem",
    lineHeight: "1.6rem",
    fontWeight: 600,
    "& .MuiBottomNavigationAction-label": {
      marginTop: "0.2rem",
    },
  },
  navigation: {
    padding: "0.5rem 0",
  },
  navigationSelectedText: {
    color: "var(--orange-500)",
    fontSize: "1.2rem",
    fontWeight: "600",
    lineHeight: "1.6rem",
    "& .MuiBottomNavigationAction-label": {
      marginTop: "0.2rem",
    },
  },
};
