import { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import style from "./index.module.css";

import CreateMedicineTestModal from "../../../CreateMedicineTestModal";

import { IProps } from "../..";

import { ITitleInputValuesListEnums } from "../../../../../models/doctor";

const Medicine: React.FC<IProps> = ({
  name,
  mealType,
  frequency,
  note,
  identifier,
  removeItemHandler,
}) => {
  const intl = useIntl();
  const [showCreateMedicineModal, setShowCreateMedicineModal] = useState(false);

  return (
    <>
      <Grid
        className={style.medicineCardContainer}
        onClick={() => setShowCreateMedicineModal(true)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Stack>
            <Typography className={style.medicineName}>{name}</Typography>
            <Typography variant="body1" className={style.medicineMealType}>
              {mealType}
            </Typography>
            <Typography variant="body1" className={style.medicineFrequency}>
              {frequency}
            </Typography>
          </Stack>
          <button
            className={style.removeMedicineBtn}
            onClick={() => removeItemHandler(identifier, name)}
          >
            {intl.formatMessage({
              id: "remove",
              defaultMessage: "remove".toLowerCase(),
            })}
          </button>
        </Stack>
        {note && (
          <Grid className={style.noteContainer}>
            <Typography className={style.note}>{note}</Typography>
          </Grid>
        )}
      </Grid>
      {showCreateMedicineModal && (
        <CreateMedicineTestModal
          isOpen={showCreateMedicineModal}
          closeHandler={() => setShowCreateMedicineModal(false)}
          type={ITitleInputValuesListEnums.MEDICINE}
        />
      )}
    </>
  );
};

export default Medicine;
