import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Dialog from "../../Dialog";
import GetImages from "../../GetImages";

import style from "./index.module.css";

import { ISharePrescriptionIcons } from "../../../models/doctor";

import { downloadReport } from "../../../utils";

import { setAppointmentDetail } from "../../../store/slices/doctor/appointment";
import { useAppDispatch } from "../../../store/hooks";
import { setVideoAppointmentDetail } from "../../../store/slices/videoAppointment";

const shareIconsList = [
  ISharePrescriptionIcons.WHATSAPP_ICON,
  ISharePrescriptionIcons.GMAIL_ICON,
  ISharePrescriptionIcons.PRINT_ICON,
];

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
  pdfUrl: string;
}
const SharePrescription: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, pdfUrl } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openPrescriptionPDF = () => {
    navigate("/schedule");
    downloadReport(pdfUrl, "prescription");
  };

  const handleClick = (icon: ISharePrescriptionIcons) => {
    if (icon === ISharePrescriptionIcons.PRINT_ICON) {
      openPrescriptionPDF();
    } else if (icon === ISharePrescriptionIcons.WHATSAPP_ICON) {
      const imageUrl = encodeURIComponent(pdfUrl);
      const whatsappURL = `https://api.whatsapp.com/send?text=Check%20this%20out:%20${imageUrl}`;
      window.open(whatsappURL, "_blank");
    }
    closeHandler();
    dispatch(setAppointmentDetail({}));
    dispatch(setVideoAppointmentDetail({}));
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      isCloseIcon
      isOpen={isOpen}
      closeHandler={closeHandler}
    >
      <Grid item className={style.container}>
        <Typography component={"h4"}>Share your Prescription</Typography>
        <Grid item className={style.iconContainer}>
          {shareIconsList.map((icon, index) => (
            <Grid
              item
              key={`${icon}-${index}`}
              onClick={() => handleClick(icon)}
            >
              <GetImages name={icon} width="32" height="32" />
            </Grid>
          ))}
        </Grid>
        <Typography component={"p"} className={style.footerText}>
          user can also get the Prescription from Appointment chat and Medical
          Record
        </Typography>
      </Grid>
    </Dialog>
  );
};

export default SharePrescription;
