import React from "react";
import { Grid, SxProps, Theme } from "@mui/material";

import Button from "../../Button";
import GetImages from "../../GetImages";

import { IButtonVariant } from "../../../models/button";

import style from "./index.module.css";

interface IProps {
  isShowIcon?: boolean;
  saveLabel?: string;
  saveHandler: () => void;
  cancelHandler: () => void;
  containerStyles?: SxProps<Theme>;
  isLoading?: boolean;
}
const SaveCancelTemplate: React.FC<IProps> = (props) => {
  const {
    isShowIcon = false,
    saveLabel = "Save",
    cancelHandler,
    saveHandler,
    containerStyles,
    isLoading,
  } = props;
  return (
    <Grid
      item
      className={style.saveTemplateActionContainer}
      sx={{
        backgroundColor: "var(--gray-200)",
        borderTop: "var(--gray-300)",
        ...containerStyles,
      }}
    >
      <Grid item>
        <Button variant={IButtonVariant.WHITE} btnTrigger={cancelHandler}>
          Cancel
        </Button>
      </Grid>
      <Button
        isLoading={isLoading}
        btnTrigger={saveHandler}
        disabled={isLoading}
      >
        {isShowIcon && (
          <GetImages name="PrescriptionSmallIcon" width="20" height="20" />
        )}
        {saveLabel}
      </Button>
    </Grid>
  );
};

export default SaveCancelTemplate;
