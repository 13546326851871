import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

import { IHealthHubBlog } from "../../../../../models/ApiRequestResponse/patient";

import style from "./index.module.css";

type BlogCardProps = {
  blog: IHealthHubBlog;
};

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  const intl = useIntl();

  const navigate = useNavigate();
  const handleBlogClick = (blogId: string) => {
    navigate("/health-hub/blog", {
      state: {
        blogId,
      },
    });
  };

  return (
    <Grid
      className={style.outerContainer}
      onClick={() => handleBlogClick(blog.id)}
    >
      <img src={blog.thumbnail} alt={blog.title} className={style.thumbnail} />
      <Grid className={style.titleContainer}>
        <Typography variant="h6" className={style.title}>
          {blog.title}
        </Typography>
        <Typography variant="body1" className={style.readCount}>
          {blog.reads}{" "}
          {intl.formatMessage({ id: "read", defaultMessage: "Read" })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BlogCard;
