import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import style from "./index.module.css";

import { InputField } from "../InputField";
import Button from "../Button";
import useHelpMeChooseDoctor from "../../utils/hooks/useHelpMeChooseDoctor";
import { countryCode } from "../../utils/text";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    note: "",
  });

  const intl = useIntl();
  const { createSupportHandler } = useHelpMeChooseDoctor();

  const isFormValid =
    formData.name.length > 0 &&
    formData.phoneNumber.length > 0 &&
    formData.note.length > 0;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event?.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRequestCallBack = async () => {
    await createSupportHandler({
      ...formData,
      countryCode: countryCode,
    });
    setFormData({
      ...formData,
      note: "",
    });
  };

  useEffect(() => {
    const storedName = localStorage.getItem("name") || "";
    const storedMobile = localStorage.getItem("phone_number") || "";
    setFormData((prev) => ({
      ...prev,
      name: storedName,
      phoneNumber: storedMobile,
    }));
  }, []);
  return (
    <Grid
      item
      sx={{
        display: { xs: "none", sm: "flex", md: "flex", lg: "flex", xl: "flex" },
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderRadius: "1.8rem",
        background: "var(--blue-gray-100)",
        padding: "1.6rem 2rem",
      }}
    >
      <Grid item className={style.textContainer}>
        <Typography component={"h4"}>
          <FormattedMessage id="confused" defaultMessage="Confused?" /> <br />{" "}
          <FormattedMessage
            id="fill_the_form"
            defaultMessage="Fill the form our team will contact you"
          />
        </Typography>
        <Typography component={"p"}>
          <FormattedMessage
            id="team_will_assist"
            defaultMessage="Just tell us your problem, our team will assist"
          />
        </Typography>
      </Grid>
      <Grid item className={style.inputContainer}>
        <Grid item>
          <InputField
            label={intl.formatMessage({ id: "name", defaultMessage: "Name" })}
            variant="outlined"
            placeholder={
              intl.formatMessage({
                id: "name",
                defaultMessage: "Name*",
              }) + "*"
            }
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            disabled={!!formData.name}
            fullWidth
          />
        </Grid>

        <Grid item>
          <InputField
            type="text"
            inputMode="numeric"
            inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
            label={intl.formatMessage({
              id: "mobileNumberText",
              defaultMessage: "Mobile Number*",
            })}
            variant="outlined"
            placeholder={intl.formatMessage({
              id: "mobileNumberText",
              defaultMessage: "Mobile Number*",
            })}
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            disabled={!!formData.phoneNumber}
            fullWidth
          />
        </Grid>

        <Grid item>
          <InputField
            label={intl.formatMessage({
              id: "problem",
              defaultMessage: "Problem",
            })}
            variant="outlined"
            placeholder={intl.formatMessage({
              id: "problem",
              defaultMessage: "Problem",
            })}
            name="note"
            value={formData.note}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item className={style.submitBtn}>
          <Button disabled={!isFormValid} btnTrigger={handleRequestCallBack}>
            <FormattedMessage id="submit" defaultMessage="Submit" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
