import React from "react";
import { Modal as BasicModal, Box, Grid, SxProps, Theme } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../GetImages";

interface IProps {
  backgroundColor?: string;
  children: React.ReactNode;
  isOpen?: boolean;
  closeHandler?: () => void;
  isCloseIcon?: boolean;
  lineBottomBackground?: string;
  muiStyles?: SxProps<Theme>;
}

const Modal: React.FC<IProps> = (props) => {
  const {
    children,
    isOpen = true,
    closeHandler,
    isCloseIcon = false,
    lineBottomBackground = "var(--gray-50)",
    backgroundColor = "var(--gray-50)",
    muiStyles,
  } = props;

  const styles = {
    position: "absolute" as "absolute",
    bottom: 0,
    width: "-webkit-fill-available",
    backgroundColor: backgroundColor,
    outline: "none",
    borderTopLeftRadius: "1.6rem",
    borderTopRightRadius: "1.6rem",
  };
  return (
    <BasicModal sx={muiStyles} open={isOpen} onClose={closeHandler}>
      <Box sx={styles}>
        {isCloseIcon && (
          <Grid
            item
            className={style.closeIconContainer}
            onClick={closeHandler}
          >
            <GetImages name="CloseIcon" width="14" height="14" />
          </Grid>
        )}
        <Grid item xs={12} className={style.lineContainer}>
          <Grid item className={style.line}></Grid>
        </Grid>
        {children}
        <Grid
          item
          className={style.lineContainer}
          sx={{ backgroundColor: lineBottomBackground }}
        >
          <Grid item className={style.lineBottom}></Grid>
        </Grid>
      </Box>
    </BasicModal>
  );
};
export default Modal;
