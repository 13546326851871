import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select as BasicSelect,
  styled,
} from "@mui/material";

const StyledInputLabel = styled(InputLabel)(() => ({
  color: "var(--gray-400)",
  "&.Mui-focused": {
    color: "var(--gray-400)",
  },
}));

const Selector = styled(BasicSelect)(() => ({
  width: "100%",
  backgroundColor: "white",
  color: "black",
  borderRadius: "0.8rem",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--neutral-100)",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--neutral-100)",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--neutral-100)",
    borderBottom: "0.1rem solid var(--gray-200)",
  },
}));

interface IKeyValue {
  key: string;
  value: string;
}
interface IProps {
  label: string;
  data: IKeyValue[];
  value?: string;
  receivedSelectedValue: (value: string) => void;
  onOpen?: () => void;
  disabled?: boolean;
}
const Select: React.FC<IProps> = (props) => {
  const { label, data, receivedSelectedValue, value, onOpen, disabled } = props;

  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<any>) => {
    setSelectedValue(event.target.value as string);
    receivedSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    }
  }, [value]);
  return (
    <FormControl fullWidth>
      <StyledInputLabel id="demo-simple-select-label">{label}</StyledInputLabel>
      <Selector
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue || ""}
        label={label}
        onChange={handleChange}
        onOpen={onOpen}
        fullWidth
        disabled={disabled}
      >
        {data?.length > 0 &&
          data.map((item, index) => (
            <MenuItem value={item.value} key={`${item.key}-${index}`}>
              {item.key}
            </MenuItem>
          ))}
      </Selector>
    </FormControl>
  );
};

export default Select;
