import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import BlogActionsDesktop from "../BlogActionsDesktop";
import BlogActionsMobile from "../BlogActionsMobile";
import ShareDialog from "../../VideoDetails/ShareDialog";

import {
  IHealthHubUserInteraction,
  IHealthHubInteractionType,
} from "../../../../models/ApiRequestResponse/patient";
import {
  favouriteBlogApi,
  likeBlogApi,
} from "../../../../utils/apis/patient/healthHub";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import useIsMobile from "../../../../utils/hooks/useIsMobile";

type BlogContentProps = {
  content: string;
  blogId: string;
  blogTitle: string;
  blogDescription: string;
  blogImage: string;
  userInteraction: IHealthHubUserInteraction[];
  likeCount: number;
};

const BlogContent: React.FC<BlogContentProps> = ({
  content,
  blogId,
  blogTitle,
  blogDescription,
  blogImage,
  userInteraction,
  likeCount,
}) => {
  const navigate = useNavigate();
  const [isBlogLiked, setIsBlogLiked] = useState(false);
  const [isBlogFavourited, setIsBlogFavourited] = useState(false);
  const [likes, setLikes] = useState(likeCount);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setLikes(likeCount);
  }, [likeCount]);

  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (userInteraction) {
      const isLiked = userInteraction.some(
        (interaction) => interaction.type === IHealthHubInteractionType.LIKE
      );
      const isFavourited = userInteraction.some(
        (interaction) =>
          interaction.type === IHealthHubInteractionType.FAVOURITE
      );

      setIsBlogLiked(isLiked);
      setIsBlogFavourited(isFavourited);
    }
  }, [userInteraction.length, blogId]);

  const handleLike = async () => {
    const response = await likeBlogApi(blogId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsBlogLiked(true);
      setLikes(likes + 1);
    }
  };

  const handleUnlike = async () => {
    const response = await likeBlogApi(blogId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsBlogLiked(false);
      setLikes(likes - 1);
    }
  };

  const handleFavourite = async () => {
    const response = await favouriteBlogApi(blogId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsBlogFavourited(true);
    }
  };

  const handleUnfavourite = async () => {
    const response = await favouriteBlogApi(blogId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsBlogFavourited(false);
    }
  };

  const handleShare = () => {
    setIsDialogOpen(true);
  };

  const backHandler = () => {
    navigate("/health-hub", { state: { navItem: 4 } });
  };

  const shareUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/health-hub/blog"
      : "https://gbsc-web-dev.appskeeper.in/health-hub/blog";

  return (
    <Grid>
      <Grid>
        <Grid
          className={style.backContainer}
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "1.6rem",
            backgroundColor: "var(--neutral-100)",
            borderBottom: "1px solid var(--gray-50)",
          }}
        >
          <ChevronLeft
            className={style.goBackIcon}
            onClick={backHandler}
            fontSize="large"
            sx={{
              display: { xs: "block", sm: "none" },
              cursor: "pointer",
            }}
          />
          <Typography
            className={style.blogTopTitle}
            sx={{
              fontWeight: { xs: "500", sm: "600" },
              color: "var(--black-900)",
              fontSize: {
                xs: "1.6rem",
                sm: "2.4rem",
                md: "2.8rem",
                lg: "3.2rem",
              },
              lineHeight: {
                xs: "2.4rem",
                sm: "3.6rem",
                md: "4rem",
                lg: "4.8rem",
              },
            }}
          >
            {blogTitle}
          </Typography>
        </Grid>
        <Grid
          sx={{
            padding: { xs: "2rem 1.6rem 0rem 1.6rem", sm: "0rem" },
            backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
          }}
          className={style.blogMetaContainer}
        >
          <Typography
            className={style.blogTitle}
            sx={{
              fontWeight: "600",
              color: "var(--black-900)",
              fontSize: {
                xs: "1.6rem",
                sm: "2.4rem",
                md: "2.8rem",
                lg: "3.2rem",
              },
              lineHeight: {
                xs: "2.4rem",
                sm: "3.6rem",
                md: "4rem",
                lg: "4.8rem",
              },
            }}
          >
            {blogTitle}
          </Typography>
          <Typography
            className={style.blogDescription}
            sx={{
              fontWeight: "400",
              color: "var(--black-900)",
              fontSize: { xs: "1.4rem", sm: "1.6rem" },
              lineHeight: { xs: "2.1rem", sm: "2.4rem" },
            }}
          >
            {blogDescription}
          </Typography>
          <Grid
            sx={{
              width: { xs: "100%", sm: "auto" },
              height: {
                xs: "15.2rem",
                sm: "22rem",
                md: "30rem",
                lg: "37.1rem",
              },
              borderRadius: { xs: "1.6rem", sm: "1.6rem" },
              overflow: "hidden",
            }}
            className={style.blogImageContainer}
          >
            <img src={blogImage} alt={blogTitle} />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={style.blogContent}
        sx={{
          backgroundColor: { xs: "var(--neutral-100)", sm: "transparent" },
          padding: { xs: "0rem 1.6rem", sm: "0rem" },
          marginBottom: { xs: "6rem", sm: "1rem" },
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Grid>
      <Grid
        className={style.blogActionsContainer}
        sx={{
          padding: { xs: "0rem 1.6rem 1.6rem 1.6rem", sm: "0rem" },
        }}
      >
        {isMobile ? (
          <BlogActionsMobile
            isLiked={isBlogLiked}
            isFavourited={isBlogFavourited}
            handleLike={handleLike}
            handleUnlike={handleUnlike}
            handleFavourite={handleFavourite}
            handleUnfavourite={handleUnfavourite}
            handleShare={handleShare}
            likeCount={likes}
          />
        ) : (
          <BlogActionsDesktop
            likeCount={likes}
            isLiked={isBlogLiked}
            isFavourited={isBlogFavourited}
            handleLike={handleLike}
            handleUnlike={handleUnlike}
            handleFavourite={handleFavourite}
            handleUnfavourite={handleUnfavourite}
            handleShare={handleShare}
          />
        )}
      </Grid>
      <ShareDialog
        url={`${shareUrl}?id=${blogId}`}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </Grid>
  );
};

export default BlogContent;
