import React, { useState } from "react";

import { rescheduleCancelledAppointmentsApi } from "../apis/doctor/appointment";

import { useAppSelector } from "../../store/hooks";

const useRescheduleCancelledAppointments = () => {
  const [rescheduleSlotNo, setRescheduleSlotNo] = useState<string>("");

  const { appointmentDetail, makePatientAppointment } = useAppSelector(
    (state) => state.appointment
  );

  const rescheduleCancelledAppointments = async () => {
    try {
      const rescheduleAppointmentResponse =
        await rescheduleCancelledAppointmentsApi({
          id: appointmentDetail?.id,
          paymentAmount: makePatientAppointment?.fee,
          slotId: makePatientAppointment?.slotId,
        });
      if ("result" in rescheduleAppointmentResponse) {
        setRescheduleSlotNo(
          rescheduleAppointmentResponse?.result?.slotNo.toString()
        );
      } else {
        return { message: rescheduleAppointmentResponse?.message };
      }
    } catch (e) {
      console.log(e);
    }
  };
  return { rescheduleCancelledAppointments, rescheduleSlotNo };
};

export default useRescheduleCancelledAppointments;
