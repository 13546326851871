import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import Header from "../../../components/Header";
import GetImages from "../../../components/GetImages";
import DropDownSelect from "../../../components/DropDownSelect";
import Confirmation from "../../../components/Confirmation";
import FullScreenDialog from "../../../components/FullScreenDialog";
import HelpSupport from "../../Doctor/HelpSupport";

import style from "./index.module.css";

import {
  languagesListInfo,
  topBarDropdownDispatchingEnums,
  transformClasses,
} from "../../../utils";
import { storage } from "../../../utils/Storage";
import useLogout from "../../../utils/hooks/useLogout";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchConfiguration,
  setIsSettingPreferencesOpenClose,
} from "../../../store/slices/config";
import { setTopBarDropdownItems } from "../../../store/slices/doctor/topNavigation";

import {
  IDropDownSelectionType,
  IProfileDropdownEnums,
} from "../../../models/doctor";
import { ILanguageEnums } from "../../../models/onboarding";
import { IFilterType } from "../../../models/patient";
import { IRoleType } from "../../../models/role";

const SettingPreferences: React.FC = () => {
  const [isLanguageSelectOpen, setIsLanguageSelectOpen] =
    useState<boolean>(false);
  const [selectedLang, setSelectedLang] = useState<string>("");
  const [isShowLogoutWarningModal, setIsShowLogoutWarningModal] =
    useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");

  const { logout } = useLogout();

  const dispatch = useAppDispatch();
  const configDetail: any = useAppSelector((state) => state.config);
  const topBarItems = useAppSelector(
    (state) => state.topNavigation.topBarItems
  );
  const handleDialog = () => {
    setIsShowLogoutWarningModal((state) => !state);
  };

  const openCloseLanguageSelectionModal = () => {
    setIsLanguageSelectOpen(!isLanguageSelectOpen);
  };

  const saveFilteredLanguagesValues = (
    value: string,
    type: IDropDownSelectionType
  ) => {
    openCloseLanguageSelectionModal();
    setSelectedLang(value);
    storage.setSessionToken("lang", value);
    window.location.reload();
    return;
  };

  const openCloseHelpSupportModalHandler = () => {
    dispatch(
      setTopBarDropdownItems(
        topBarDropdownDispatchingEnums[IProfileDropdownEnums.HELP_SUPPORT]
      )
    );
  };

  useEffect(() => {
    if (Object.keys(configDetail?.config).length === 0) {
      dispatch(fetchConfiguration(IFilterType.FIND_DOCTOR));
    }
  }, []);

  useEffect(() => {
    setSelectedLang(storage.getSessionToken("lang") ?? ILanguageEnums.ENG);
  }, [configDetail]);

  return (
    <FullScreenDialog>
      <Grid item>
        <Header
          heading="Settings & Preferences"
          previousPage={() => dispatch(setIsSettingPreferencesOpenClose())}
        />
        {roleType === IRoleType.PATIENT && (
          <Grid
            item
            className={transformClasses(style.boxContainer, style.spacing)}
          >
            <Typography component={"p"} className={style.languageHeading}>
              <FormattedMessage defaultMessage={"Language"} id="language" />
            </Typography>
            <Grid item className={style.selectedLanguageContainer}>
              <Typography component={"p"} className={style.selectedLanguage}>
                {languagesListInfo[selectedLang]}
              </Typography>
              <Typography
                component={"p"}
                className={style.languageChangeHandle}
                onClick={openCloseLanguageSelectionModal}
              >
                <FormattedMessage defaultMessage={"Change"} id="change" />
              </Typography>
            </Grid>
          </Grid>
        )}
        {roleType === IRoleType.DOCTOR && (
          <Grid
            item
            className={transformClasses(
              style.boxContainer,
              style.logoutAction,
              style.spacingTop
            )}
            onClick={openCloseHelpSupportModalHandler}
          >
            <GetImages name="HelpSupportIcon" width="28" height="20" />
            <Typography component={"p"}>
              <FormattedMessage
                defaultMessage={"Help & Support"}
                id="help_support"
              />
            </Typography>
          </Grid>
        )}
        <Grid
          item
          className={transformClasses(
            style.boxContainer,
            style.logoutAction,
            roleType !== IRoleType.PATIENT ? style.spacingTop : ""
          )}
          onClick={handleDialog}
        >
          <GetImages name="LogoutIcon" width="28" height="20" />
          <Typography component={"p"}>
            <FormattedMessage defaultMessage={"Log Out"} id="logout" />
          </Typography>
        </Grid>
        {isLanguageSelectOpen && (
          <DropDownSelect
            btnLabel="Apply"
            type={IDropDownSelectionType.LANGUAGES}
            isOpen={isLanguageSelectOpen}
            closeHandler={openCloseLanguageSelectionModal}
            title="Select Language"
            values={configDetail?.config?.findDoctor?.language.map(
              (name: ILanguageEnums, index: number) => ({
                label: languagesListInfo[name],
                value: name,
              })
            )}
            selectedValue={selectedLang}
            saveSelectedValues={saveFilteredLanguagesValues}
          />
        )}
      </Grid>
      <Confirmation
        isOpen={isShowLogoutWarningModal}
        closeHandler={handleDialog}
        title="logout"
        alterMessage={"logout_alert_message"}
        actionHandler={logout}
      />
      {topBarItems?.isHelpSupportOpen && (
        <HelpSupport
          isOpen={topBarItems?.isHelpSupportOpen}
          closeHandler={openCloseHelpSupportModalHandler}
        />
      )}
    </FullScreenDialog>
  );
};

export default SettingPreferences;
