import { storage } from "../Storage";

import { getAddressFromCoordinates } from "../apis/config";

import { useAppDispatch } from "../../store/hooks";
import { setLocation } from "../../store/slices/config";

interface ILocationCoordinates {
  latitude: number;
  longitude: number;
}
const useLocationAddress = () => {
  const dispatch = useAppDispatch();

  const fetchAddress = async (locationAddress?: ILocationCoordinates) => {
    try {
      let location;
      if (!locationAddress) {
        const locationValues = storage.getSessionToken("location");
        location = locationValues && JSON.parse(locationValues);
      } else {
        location = locationAddress;
      }
      if (location) {
        const response = await getAddressFromCoordinates(
          location?.latitude,
          location?.longitude
        );
        const { suburb, city, postcode, state_district, state } =
          response?.address;
        dispatch(
          setLocation(
            `${suburb ?? state_district}, ${city ?? state} - ${postcode}`
          )
        );
      }
    } catch (err) {
      console.error("Error fetching address:", err);
    }
  };

  return { fetchAddress };
};

export default useLocationAddress;
