import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

type SectionHeaderProps = {
  title: string;
  subtitle?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle }) => {
  return (
    <Grid className={style.outerContainer}>
      <Typography
        variant="h5"
        className={style.title}
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem", md: "2.4rem" } }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={style.subtitle}
        sx={{ fontSize: { xs: "1rem", sm: "1.2rem", md: "1.6rem" } }}
      >
        {subtitle}
      </Typography>
    </Grid>
  );
};

export default SectionHeader;
