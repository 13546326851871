import React from "react";
import { Grid, RadioGroup } from "@mui/material";

import style from "./index.module.css";

import MemberDetailTile from "./MemberDetailTile";

import { IPatientMemberDetailEnums } from "../../models/patient";

interface IProps {
  isDefault?: boolean;
  type: IPatientMemberDetailEnums;
  memberId: string;
  name: string;
  age: number;
  selectedMember: string;
  memberSelectorHandler?: (memberId: string) => void;
  handleSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const MemberDetail: React.FC<IProps> = (props) => {
  const {
    type,
    age,
    memberId,
    name,
    selectedMember,
    handleSelection,
    isDefault = true,
    memberSelectorHandler,
  } = props;

  const memberSelectionHandler = () => {
    if (type === IPatientMemberDetailEnums.CHECKOUT || !age || !name) return;
    memberSelectorHandler && memberSelectorHandler(memberId);
  };
  const memberDetailScreen = () => (
    <Grid
      item
      className={style.cardSelectionContainer}
      onClick={memberSelectionHandler}
    >
      <MemberDetailTile
        type={type}
        memberId={memberId}
        name={name}
        age={age}
        isDefault={isDefault}
      />
    </Grid>
  );
  return (
    <RadioGroup
      value={selectedMember}
      onChange={handleSelection}
      className={style.subContainer}
    >
      {memberDetailScreen()}
    </RadioGroup>
  );
};

export default MemberDetail;
