import { Grid, Stack } from "@mui/material";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";

import SearchSuggestions from "../../SearchSuggestions";
import { InputField } from "../../InputField";
import GetImages from "../../GetImages";

import { IMedicineTest } from "../../../models/ApiRequestResponse/doctor";
import {
  IMedicineTestSuggestionData,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";
import {
  IModalKeyEnums,
  IMedicinePermssionEnums,
  IRolePermissionAccess,
  ITestPermissionEnums,
} from "../../../models/permission";

import { searchMedicineTestApi } from "../../../utils/apis/doctor/prescription";
import useDebounce from "../../../utils/hooks/useDebouncer";
import { getPermissionAccess } from "../../../utils";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setShowPermissionAlert } from "../../../store/slices/permission";

import style from "./index.module.css";
interface IProps {
  type: ITitleInputValuesListEnums;
  label: string;
  getMedicineTestDetailHandler?: (
    values: IMedicineTestSuggestionData,
    type: ITitleInputValuesListEnums
  ) => void;
  getMedicineTestDetailEnterEventHandler?: (
    values: IMedicineTestSuggestionData,
    type: ITitleInputValuesListEnums
  ) => void;
  closeSearch?: () => void;
  hideTrashIcon?: boolean;
  isInDrawer?: boolean;
}

const initialState: IMedicineTestSuggestionData = {
  testId: "0",
  name: "",
  medicineId: "0",
};

const addMedicineTestValues = (state: any, action: any) => {
  switch (action.type) {
    case ITitleInputValuesListEnums.TEST:
    case ITitleInputValuesListEnums.MEDICINE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

const MedicineTestSuggestions: React.FC<IProps> = (props) => {
  const {
    label,
    type,
    getMedicineTestDetailHandler,
    getMedicineTestDetailEnterEventHandler,
    closeSearch,
    hideTrashIcon = false,
    isInDrawer = false,
  } = props;
  const [findMedicineTest, setFindMedicineTest] = useState<IMedicineTest[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [selectSearchItem, setSelectSearchItem] = useState<string>("");
  const [state, dispatch] = useReducer(addMedicineTestValues, initialState);

  const appDispatch = useAppDispatch();
  const { permission } = useAppSelector((state) => state.rolePermission);

  const debounceSearchMedicineTest = useDebounce(searchText, 500);

  const searchMedicineTest = async () => {
    const searchMedicineTestResponse = await searchMedicineTestApi(
      type,
      searchText
    );
    if ("result" in searchMedicineTestResponse) {
      setFindMedicineTest(searchMedicineTestResponse?.result);
    }
  };

  const clearSearchMedicineTest = () => {
    setFindMedicineTest([]);
    setSelectSearchItem("");
  };

  const resetMedicineTestId = useCallback(() => {
    const isSearchFound = findMedicineTest.some((list, index) =>
      list.name.includes(searchText)
    );
    if (!isSearchFound) {
      dispatch({
        type: type,
        field:
          type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId",
        value: "0",
      });
    }
    clearSearchMedicineTest();
  }, [findMedicineTest]);

  const getMedicineTestEnteredDetailHandler = (event: any) => {
    if (event.key === "Enter" && searchText.trim()) {
      const newState = {
        ...state,
        name: searchText.trim(),
        [type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId"]:
          findMedicineTest.find(
            (item) => item.name.toLowerCase() === searchText.toLowerCase()
          )?.id || "0",
      };
      getMedicineTestDetailEnterEventHandler?.(newState, type);
      clearSearchMedicineTest();
    }
  };

  useEffect(() => {
    const permissionAccess = getPermissionAccess(
      type === ITitleInputValuesListEnums.TEST
        ? IModalKeyEnums.TEST
        : IModalKeyEnums.MEDICINE,
      type === ITitleInputValuesListEnums.TEST
        ? ITestPermissionEnums.SEARCH_TEST
        : IMedicinePermssionEnums.SEARCH_MEDICINE,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      appDispatch(setShowPermissionAlert());
      return;
    }
    if (!debounceSearchMedicineTest?.length) {
      clearSearchMedicineTest();
    } else {
      dispatch({
        type: type,
        field: "name",
        value: searchText,
      });
      if (selectSearchItem?.length === 0) {
        searchMedicineTest();
      }
      // resetMedicineTestId();
    }
  }, [debounceSearchMedicineTest]);

  useEffect(() => {
    if (selectSearchItem?.length > 0) {
      const { name } =
        findMedicineTest.find((list) => list.id === selectSearchItem) || {};
      if (name) {
        setSearchText(name!);
        dispatch({
          type: type,
          field: "name",
          value: name,
        });
        dispatch({
          type: type,
          field:
            type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId",
          value: selectSearchItem?.length === 0 ? "0" : selectSearchItem,
        });
      }
      getMedicineTestDetailEnterEventHandler?.(
        {
          ...state,
          name: name ?? "",
          [type === ITitleInputValuesListEnums.TEST ? "testId" : "medicineId"]:
            selectSearchItem?.length === 0 ? "0" : selectSearchItem,
        },
        type
      );
    }
  }, [selectSearchItem]);

  useEffect(() => {
    if (getMedicineTestDetailHandler) {
      setTimeout(() => getMedicineTestDetailHandler(state, type), 300);
    }
  }, [state]);

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        alignItems="start"
        justifyContent="space-between"
        gap={2}
      >
        {isInDrawer ? (
          <InputField
            sx={{ width: "100%" }}
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setSearchText(event.target.value)
            }
            onKeyDown={getMedicineTestEnteredDetailHandler}
            autoFocus
            id="searchText"
            label={label}
            placeholder={label}
          />
        ) : (
          <Grid className={style.inputFieldContainer}>
            <label htmlFor="searchText">{label}</label>
            <input
              value={searchText}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearchText(event.target.value)
              }
              onKeyDown={getMedicineTestEnteredDetailHandler}
              autoFocus
              id="searchText"
            />
          </Grid>
        )}
        {!hideTrashIcon && (
          <Grid
            item
            className={style.trashIcon}
            onClick={() => closeSearch && closeSearch()}
          >
            <GetImages
              name="TrashIcon"
              width="24"
              height="24"
              fill="var(--red-600)"
            />
          </Grid>
        )}
      </Stack>
      {findMedicineTest.length > 0 && (
        <SearchSuggestions
          typeOfSearch={type}
          clearSearchSuggestions={clearSearchMedicineTest}
          foundDataList={findMedicineTest.map((record, index) => ({
            label: record.name,
            value: record.id,
          }))}
          seachingText={searchText}
          selectSearchItem={selectSearchItem}
          setSelectSearchItem={setSelectSearchItem}
        />
      )}
    </Stack>
  );
};

export default MedicineTestSuggestions;
