import React from "react";
import { Grid, Typography, Button } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../../GetImages";

import {
  capitalizeFirstLetter,
  getMedicalReportDocumentName,
  transformDateToMMDDYYY,
} from "../../../utils";

import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IRoleType } from "../../../models/role";

import { storage } from "../../../utils/Storage";

interface IProps extends IPharmacyDiagnosticOrderDetail {
  onSelect: () => void;
  isSelected?: boolean;
}

const OrderRequestCard: React.FC<IProps> = (props) => {
  const {
    pdfurl,
    thumbnail_url,
    name,
    status,
    created_at,
    address,
    age,
    gender,
    booking_timestamp,
    isSelected = false,
    onSelect,
  } = props;

  const roleType = storage.getSessionToken("roleType");

  return (
    <Grid
      container
      component={Button}
      className={`${style.container} ${isSelected && style.active}`}
      direction={"column"}
      gap={0.5}
      alignItems={"flex-start"}
      onClick={onSelect}
      sx={{
        backgroundColor: isSelected
          ? "var(--warm-neutral-50)"
          : "var(--neutral-100)",
        "&:hover": {
          backgroundColor: isSelected
            ? "var(--warm-neutral-50)"
            : "var(--neutral-100)",
        },
      }}
    >
      <Grid item className={style.nameDate}>
        <Typography component={"p"} className={style.name}>
          {name}
        </Typography>
        <Typography component={"p"} className={style.date}>
          {transformDateToMMDDYYY(
            created_at ?? new Date(Number(`${booking_timestamp}000`))
          )}
        </Typography>
      </Grid>
      <Typography component={"p"} className={style.address}>
        {address}
      </Typography>
      <Grid item className={style.pdf}>
        {roleType !== IRoleType.DIAGNOSTIC_CENTER && (
          <Grid item className={style.pdfContainer}>
            <GetImages
              name={thumbnail_url ? "PDFGreyIcon" : "GalleryImageIcon"}
              width="24"
              height={thumbnail_url ? "24" : "18"}
            />
            <Typography component={"p"} noWrap width={"15ch"}>
              {getMedicalReportDocumentName(pdfurl!)}
            </Typography>
          </Grid>
        )}
        {roleType === IRoleType.DIAGNOSTIC_CENTER && (
          <Grid item className={style.ageGenderContainer}>
            <Typography component={"p"}>
              Age {age}, {gender}
            </Typography>
          </Grid>
        )}
        <Typography component={"p"}>{capitalizeFirstLetter(status)}</Typography>
      </Grid>
    </Grid>
  );
};

export default OrderRequestCard;
