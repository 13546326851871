import { lazy } from "react";

const Clinic = lazy(
  () => import("../../views/PharmacyClinicDiagnostic/Clinic")
);

const ClinicRoutes = [
  {
    path: "/clinic-patient",
    component: <Clinic />,
  },
];

export default ClinicRoutes;
