import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import GetImages from "../../GetImages";
import Title from "../../Title";

import style from "./index.module.css";

interface IProps {
  title: string;
  children: React.ReactNode;
  subTitle?: string;
}
const AccordionWithTitle: React.FC<IProps> = (props) => {
  const { title, children, subTitle } = props;

  const [expanded, setExpanded] = useState<string | null>(null);

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <Accordion
      expanded={expanded === title}
      onChange={handleChange(title)}
      className={style.accordionContainer}
    >
      <AccordionSummary
        expandIcon={
          <Grid item className={style.arrowDownIcon}>
            <GetImages name="ArrowDownOrangeIcon" width="16" height="16" />
          </Grid>
        }
        aria-controls={`panel${title}-content`}
        id={`panel${title}-header`}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: 0,
          margin: 0,
          "&.Mui-expanded": {
            minHeight: "unset",
          },
          ".MuiAccordionSummary-content": {
            margin: 0,
            "&.Mui-expanded": {
              margin: 0,
            },
          },
        }}
      >
        <Stack direction="column">
          <Title>{title}</Title>
          {subTitle && (
            <Typography className={style.subTitle}>{subTitle}</Typography>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0rem" }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWithTitle;
