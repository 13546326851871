import React, { useState } from "react";

import { countryCode } from "../text";

import { sendOtpPatientCreationApi } from "../apis/doctor/appointment";

import { INotifyEnum, notify } from "../toaster";

import { useAppDispatch } from "../../store/hooks";
import {
  checkMobileNumber,
  createPatientVerifyOTP,
} from "../../store/slices/doctor/checkMobilePatientExistOrNot";

import { ILanguageEnums } from "../../models/onboarding";

import { storage } from "../Storage";

interface IProps {
  mobileNo: string;
}
const useMobileCheckPatientCreate = (props: IProps) => {
  const [otpId, setOtpId] = useState<string>("");

  const dispatch = useAppDispatch();

  const checkMobileNumberDetail = (mobileNumber: string) =>
    dispatch(checkMobileNumber(mobileNumber));

  const createPatientAfterVerifyOtp = (otp: string) => {
    const language = storage.getSessionToken("lang");
    dispatch(
      createPatientVerifyOTP({
        otp: otp,
        language: language ?? ILanguageEnums.ENG,
        otpId,
      })
    );
  };

  const sendOtpToCreatePatient = async () => {
    const sendOtpPatientCreateResponse = await sendOtpPatientCreationApi({
      countryCode: countryCode,
      phoneNumber: props?.mobileNo,
    });
    if ("result" in sendOtpPatientCreateResponse) {
      notify(INotifyEnum.SUCCESS, sendOtpPatientCreateResponse?.message);
      setOtpId(sendOtpPatientCreateResponse?.result?.OtpId);
    }
  };

  return {
    sendOtpToCreatePatient,
    otpId,
    setOtpId,
    checkMobileNumberDetail,
    createPatientAfterVerifyOtp,
  };
};

export default useMobileCheckPatientCreate;
