export const style = {
  container: {
    padding: {
      sm: "1rem",
      md: "1rem",
      lg: "1.6rem 4.8rem",
    },
    background: "var(--neutral-100)",
    display: {
      xs: "none",
      sm: "none",
      md: "flex",
      lg: "flex",
    },
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 999,
  },
  helplineTime: {
    position: "absolute",
    top: "-0.5rem",
    left: "50%",
    transform: "translateX(-50%)",
    "& > p": {
      color: "var(--neutral-100)",
      fontFamily: "Roboto",
      fontSize: { md: "0.7rem", lg: "1rem", xl: "1rem" },
      fontWeight: 600,
      borderRadius: "0.8rem",
      background: "var(--orange-500)",
      width: "max-content",
      padding: "0rem 0.8rem",
    },
  },
  telephoneEmergencyContainer: {
    position: "relative",
  },
  telephoneContainer: {
    padding: {
      md: "0.8rem",
      lg: "1.6rem 0.8rem 0.4rem 0.8rem",
      xl: "1.6rem 0.8rem 0.4rem 0.8rem",
    },
    borderRadius: "1.8rem",
    background: "var(--gray-50)",
    width: "max-content",
    display: "flex",
    gap: "0.4rem",
    alignItems: "center",
    "& > p": {
      color: "var(--black-900)",
      fontFamily: "Roboto",
      fontSize: { md: "1rem", lg: "1.4rem", xl: "1.4rem" },
      fontWeight: 600,
    },
  },
  locationNameInputContainer: {
    borderRadius: "1.8rem 0rem 0rem 1.8rem",
    borderRight: "0.1rem solid var(--gray-200)",
    background: "var(--gray-20)",
    height: "4rem",
    padding: "0.4rem 0.8rem",
    display: "flex",
    alignItems: "center",
    "& > p": {
      color: "var(--black-900)",
      fontSize: "1.2rem",
      fontWeight: 400,
    },
  },
  searchInputLocationContainer: {
    display: "flex",
    position: "relative",
    "& > input": {
      width: { sm: "12rem", md: "15rem", lg: "30rem", xl: "40rem" },
      borderRadius: "0rem 1.8rem 1.8rem 0rem",
      background: "var(--gray-50)",
      border: "none",
      padding: "0rem 0.8rem",
      "&:focus": {
        outline: "none",
      },
      "@media screen and (max-width: 65rem)": {
        width: "23rem",
      },
    },
  },
  searchIcon: {
    position: "absolute",
    right: "1.2rem",
    top: "50%",
    transform: "translateY(-50%)",
  },
  navItemsContainer: {
    display: "flex",
    gap: "1.2rem",
    justifyContent: "space-between",
    alignItems: "center",
    "@media screen and (max-width: 65rem)": {
      gap: "0.1rem",
    },
  },
  navItemLabel: {
    "& > p": {
      paddingRight: { md: "0.5rem", lg: "1.6rem", xl: "1.6rem" },
      color: "var(--gray-700)",
      fontSize: { md: "1rem", lg: "1.4rem", xl: "1.4rem" },
      fontWeight: 400,
      lineHeight: "1.6rem",
      cursor: "pointer",
    },
    "&:hover": {
      borderRadius: "2rem",
      "& > p": {
        color: "var(--orange-500)",
        fontWeight: 600,
      },
    },
  },
  languageSelectionContainer: {
    padding: "1.2rem 0.8rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "2.9rem",
    border: "0.1rem solid var(--gray-20)",
    background: "var(--neutral-100)",
    cursor: "pointer",
    "& > p": {
      color: "var(--gray-700)",
      fontSize: "1.4rem",
      fontWeight: 500,
    },
  },
  languageButton: {
    "& > button": {
      padding: {
        md: "0.8rem",
        lg: "1.2rem 0.8rem",
        xl: "1.2rem 0.8rem",
      },
      gap: "0.4rem",
      borderRadius: "2.9rem!important",
      "@media screen and (max-width: 65rem)": {
        fontSize: "1.2rem",
        paddingBlock: "0.4rem",
        "& > p": {
          fontSize: "1.4rem",
        },
      },
    },
  },
  loginButton: {
    "& > button": {
      width: "9.8rem",
    },
  },
  profileImage: {
    "& > img": {
      width: { md: "3rem", lg: "4.8rem", xl: "4.8rem" },
      height: { md: "3rem", lg: "4.8rem", xl: "4.8rem" },
      borderRadius: "50%",
      border: "0.2rem solid var(--orange-500)",
    },
  },
  menuContainer: {
    marginTop: "1.2rem",
    "& .MuiPaper-root": {
      borderRadius: "0.8rem",
    },
    "& .MuiList-root": { padding: 0 },
  },
  menuItemContainer: {
    padding: "1.2rem 0.8rem",
    margin: "0rem",
    borderBottom: "0.1rem solid var(--gray-20)",
    "& .MuiListItemIcon-root": { minWidth: "min-content" },
  },
  dropdownText: {
    color: "var(--gray-700)",
    fontSize: "1.4rem",
    fontWeight: 500,
  },
  activeNavItem: {
    "& > p": {
      padding: "1.2rem 1.6rem",
      fontSize: "1.4rem",
      color: "var(--orange-500)",
      fontWeight: 600,
      lineHeight: "1.6rem",
      cursor: "pointer",
      backgroundColor: "#fff6ea",
      borderRadius: "2rem",
    },
  },
};
