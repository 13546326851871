import React from "react";
import { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputAdornment, Typography } from "@mui/material";

import { InputField } from "../InputField";

import style from "./index.module.css";
import { FormattedMessage } from "react-intl";
interface IProps {
  label?: string;
  value: any;
  onChange: (value: any) => void;
  maxDate?: Dayjs;
  disabled?: boolean;
  minDate?: Dayjs;
}
const DateSelect: React.FC<IProps> = (props) => {
  const {
    label = "Date",
    value,
    onChange,
    maxDate,
    minDate,
    disabled = false,
  } = props;
  const [open, setOpen] = React.useState(false);

  const openCloseCalender = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        value={value}
        onChange={onChange}
        label={label}
        open={open}
        onClose={openCloseCalender}
        maxDate={maxDate}
        minDate={minDate}
        slotProps={{
          popper: {
            style: {
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1300,
            },
          },
        }}
        slots={{
          textField: (params) => (
            <InputField
              {...params}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography
                      component={"p"}
                      className={style.selectText}
                      onClick={openCloseCalender}
                    >
                      <FormattedMessage id="select" defaultMessage="Select" />
                    </Typography>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DateSelect;
