import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import GetImages from "../../../../components/GetImages";
import style from "./index.module.css";
import {
  IPrescriptionTemplateList,
  IPrescriptionTemplateValue,
} from "../../../../models/ApiRequestResponse/doctor";
import { useAppSelector } from "../../../../store/hooks";

import Modal from "../../../../components/Modal";

type TemplateSelectorProps = {
  onSelect: (value: IPrescriptionTemplateValue) => void;
};

type TemplateItemProps = {
  value: any;
  onClick: () => void;
  isSelected: boolean;
  isEmpty?: boolean;
};

const TemplateItem: React.FC<TemplateItemProps> = ({
  value,
  onClick,
  isSelected,
  isEmpty,
}) => {
  return (
    <div
      onClick={onClick}
      className={isSelected ? style.selectedItem : style.item}
    >
      {isEmpty && !isEmpty && (
        <GetImages
          name="PrescriptionDefaultSmallIcon"
          width="18.73"
          height="20.33"
        />
      )}
      <Typography component="span">{value?.name}</Typography>
    </div>
  );
};

type BrowseTemplateModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
  list?: IPrescriptionTemplateList[];
  onSelect: (value: IPrescriptionTemplateValue) => void;
};

const BrowseTemplateModal: React.FC<BrowseTemplateModalProps> = ({
  isOpen,
  closeHandler,
  list,
  onSelect,
}) => {
  return (
    <Modal
      muiStyles={{ display: { xs: "block", md: "none" } }}
      isOpen={isOpen}
      closeHandler={closeHandler}
    >
      <Grid className={style.modalTemplateList}>
        {list?.map((item) => (
          <TemplateItem
            key={item.id}
            value={item}
            isEmpty={list?.length === 0}
            onClick={() => {
              onSelect(item);
              closeHandler();
            }}
            isSelected={false}
          />
        ))}
        {list?.length === 0 && (
          <TemplateItem
            value={{ id: 0, name: "No templates found" }}
            onClick={closeHandler}
            isEmpty={true}
            isSelected={false}
          />
        )}
      </Grid>
    </Modal>
  );
};

const TemplateSelector: React.FC<TemplateSelectorProps> = ({ onSelect }) => {
  const [selectedValue, setSelectedValue] =
    useState<IPrescriptionTemplateValue | null>(null);
  const [showTemplateList, setShowTemplateList] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    template: { list },
  } = useAppSelector((s) => s.prescription);

  const handleSelect = (value: IPrescriptionTemplateValue) => {
    setSelectedValue(value);
    onSelect(value);
    setShowTemplateList(false);
  };

  const handleShowTemplateList = () => {
    setShowTemplateList(!showTemplateList);
    setIsOpen(true);
  };

  return (
    <Stack direction="column" spacing={showTemplateList ? 1 : 0}>
      <div
        className={style.browseTemplateContainer}
        onClick={handleShowTemplateList}
      >
        <Grid className={style.browseTemplateimage}>
          <GetImages
            name={selectedValue ? "checkIcon" : "PrescriptionDefaultSmallIcon"}
            width="18.73"
            height="20.33"
          />
          <Typography component="span">
            {selectedValue ? selectedValue.name : "Browse Templates"}
          </Typography>
        </Grid>
      </div>
      <Grid
        sx={{
          opacity: showTemplateList ? 1 : 0,
          height: showTemplateList ? "auto" : 0,
          display: { xs: "none", md: "flex" },
        }}
        className={style.browseTemplateBox}
      >
        {list.length > 0 ? (
          list.map((detail: any) => (
            <TemplateItem
              key={detail.id}
              value={detail}
              onClick={() => handleSelect(detail)}
              isSelected={selectedValue?.id === detail.id}
            />
          ))
        ) : (
          <TemplateItem
            value={{ id: 0, name: "No templates found" }}
            onClick={() => {}}
            isEmpty={true}
            isSelected={false}
          />
        )}
      </Grid>
      <Grid sx={{ display: { xs: "none", md: "block" }, position: "relative" }}>
        <BrowseTemplateModal
          isOpen={isOpen}
          list={list}
          onSelect={handleSelect}
          closeHandler={() => setIsOpen(false)}
        />
      </Grid>
    </Stack>
  );
};

export default TemplateSelector;
