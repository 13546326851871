import { useMemo, useState } from "react";
import Container from "@mui/material/Container";
import { Dialog } from "@mui/material";

import Modal from "../../../components/Modal";
import OrderDetails from "./OrderDetails";
import OrderCompleteUpdate from "./OrderCompleteUpdate";

import {
  IOrderStatusEnum,
  IPharmacyOrderDetailUpdate,
} from "../../../models/ApiRequestResponse/pharmacy";

import { useAppDispatch } from "../../../store/hooks";
import {
  resetMedicalRecordIds,
  saveSelectedOrder,
} from "../../../store/slices/pharmacyClinicDiagnostic";

import useHandlePharmacyOrderUpdate from "../../../utils/hooks/useHandlePharmacyOrderUpdate";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const OrderModals = ({ isOpen, onClose }: Props) => {
  const [isCheckout, setIsCheckout] = useState(false);
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const { handlePharmacyOrderUpdate } = useHandlePharmacyOrderUpdate();
  const handleCheckout = () => {
    setIsCheckout((state) => !state);
  };

  const handleClose = () => {
    onClose();
    setIsCheckout(false);
    dispatch(resetMedicalRecordIds());
  };

  const handleUpdateOrderStatus = async (
    status: IOrderStatusEnum,
    values: IPharmacyOrderDetailUpdate | null
  ) => {
    handlePharmacyOrderUpdate(status, values);
    onClose();
    dispatch(saveSelectedOrder(null));
  };

  const isWebScreen = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );

  if (isWebScreen) {
    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <Container sx={{ p: 2 }}>
          {!isCheckout ? (
            <OrderDetails
              onComplete={handleCheckout}
              onUpdate={(values) => handleUpdateOrderStatus(values, null)}
            />
          ) : (
            <OrderCompleteUpdate
              onUpdate={(values: IPharmacyOrderDetailUpdate) =>
                handleUpdateOrderStatus(IOrderStatusEnum.COMPLETED, values)
              }
              onBack={handleCheckout}
            />
          )}
        </Container>
      </Dialog>
    );
  }

  return (
    <Modal
      isCloseIcon
      isOpen={isOpen}
      closeHandler={handleClose}
      backgroundColor="var(--neutral-100)"
      lineBottomBackground="var(--neutral-100)"
    >
      <Container>
        {!isCheckout ? (
          <OrderDetails
            onComplete={handleCheckout}
            onUpdate={(values) => handleUpdateOrderStatus(values, null)}
          />
        ) : (
          <OrderCompleteUpdate
            onUpdate={(values: IPharmacyOrderDetailUpdate) =>
              handleUpdateOrderStatus(IOrderStatusEnum.COMPLETED, values)
            }
            onBack={handleCheckout}
          />
        )}
      </Container>
    </Modal>
  );
};

export default OrderModals;
