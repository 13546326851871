import { useEffect, useState } from "react";
import { Box, CardContent, Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import css from "./index.module.css";

import { useAppSelector } from "../../store/hooks";

import { IDiagnosticTestList } from "../../models/ApiRequestResponse/patient";

import useClearCart from "../../utils/hooks/useClearCart";

const DiagnosticTestList = () => {
  const { onCartFun } = useClearCart();
  const { testList, centreDetails, cartDetails } = useAppSelector(
    (state) => state.tests
  );
  const [selectedTest, setSelectedTest] = useState<string[]>([]);
  const cartList = cartDetails?.diagnosticTestIds || [];

  useEffect(() => {
    if (cartList) {
      setSelectedTest(cartList);
    }
  }, [cartList]);

  return (
    <>
      {testList && testList?.length > 0 ? (
        <Box className={css.boxContent}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: {
                xs: "0rem",
                sm: "1.6rem",
                md: "1.6rem",
                lg: "1.6rem",
                xl: "1.6rem",
              },
            }}
          >
            {testList.map((test: IDiagnosticTestList) => (
              <Grid item xs={12} sm={5} md={5} lg={3} xl={2} key={test.name}>
                <Box
                  className={`${css.testCard} ${
                    selectedTest.includes(test.id) ? css.testActive : ""
                  }`}
                  sx={{
                    borderRadius: { xs: "0rem", sm: "1.2rem", md: "1.2rem" },
                  }}
                >
                  <CardContent>
                    <Typography className={css.testName}>
                      {test.name}
                    </Typography>
                    <Typography className={css.priceText}>
                      {`₹${test.price}  •  ${centreDetails?.name}`}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Grid
                      className={css.addButton}
                      onClick={() => onCartFun(test.id, "ADD")}
                    >
                      {selectedTest.includes(test.id) ? (
                        <FormattedMessage id="remove" defaultMessage="Remove" />
                      ) : (
                        <FormattedMessage id="add" defaultMessage="Add" />
                      )}
                    </Grid>
                  </CardContent>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Grid className={css.noFound}>
          <Typography component="h4">
            <FormattedMessage
              id="no_test_found"
              defaultMessage="No Test Found"
            />
          </Typography>
        </Grid>
      )}
    </>
  );
};
export default DiagnosticTestList;
