import React, {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import Title from "../../Title";
import Button from "../../Button";
import GetImages from "../../GetImages";
import Chip from "../Chip";
import MedicineTestListRemoveManager from "../MedicineTestListRemoveManager";
import SearchSuggestions from "../../SearchSuggestions";

import style from "./index.module.css";

import { IButtonVariant } from "../../../models/button";
import {
  IMedicineTestModalShow,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";
import {
  ISearchPrescriptionAttributesDetail,
  ITagOtherHealthProblemDiagnosis,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IModalKeyEnums,
  IPrescriptionAttributesPermissionEnums,
  IRolePermissionAccess,
} from "../../../models/permission";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  removePrescriptionItem,
  removePrescriptionTemplateItem,
  setPrescriptionTemplate,
  setPrescriptionValues,
} from "../../../store/slices/doctor/prescription";
import { setShowPermissionAlert } from "../../../store/slices/permission";

import useDebounce from "../../../utils/hooks/useDebouncer";
import { searchPrescriptionAttributesApi } from "../../../utils/apis/doctor/prescription";
import { getPermissionAccess } from "../../../utils";
import DiagnosticChip from "../DiagnosticChip";

interface IButtonLabelIcon {
  text: string;
  icon: string;
}
interface IProps {
  title: string;
  hasActionButton?: boolean;
  buttonLabel?: IButtonLabelIcon | null;
  identifier: ITitleInputValuesListEnums;
  isAddMedicineTestShow?: IMedicineTestModalShow;
  setIsAddMedicineTestShow?: (value: IMedicineTestModalShow) => void;
  sliceAttr?: string;
}

const prescriptionAttributeType: any = {
  [ITitleInputValuesListEnums.TAG]: 1,
  [ITitleInputValuesListEnums.OTHER_HEALTH_PROBLEMS]: 2,
  [ITitleInputValuesListEnums.DIAGNOSIS]: 3,
};

const TitleInputValueList: React.FC<IProps> = (props) => {
  const {
    title,
    hasActionButton = false,
    buttonLabel,
    identifier,
    isAddMedicineTestShow,
    setIsAddMedicineTestShow,
    sliceAttr,
  } = props;

  const intl = useIntl();

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedDiagnosisIndex, setSelectedDiagnosisIndex] = useState<
    number | null
  >(null);
  const [searchText, setSearchText] = useState<string>("");
  const [selectSearchItem, setSelectSearchItem] = useState<string | null>(null);
  const [searchedChips, setSearchedChips] = useState<
    ISearchPrescriptionAttributesDetail[]
  >([]);
  const [activePrecriptionAttr, setActivePrecriptionAttr] =
    useState<ITitleInputValuesListEnums | null>(null);

  const debounceSearchMedicineTest = useDebounce(searchText, 700);

  const dispatch = useAppDispatch();
  const { template, create }: any = useAppSelector(
    (state) => state.prescription
  );
  const { permission } = useAppSelector((state) => state.rolePermission);

  const isComplaint = identifier === ITitleInputValuesListEnums.COMPLAINT;
  const isDientAndExercise =
    identifier === ITitleInputValuesListEnums.DIET_AND_EXERCISE;
  const isDietOrNote =
    identifier === ITitleInputValuesListEnums.DIET_AND_EXERCISE ||
    identifier === ITitleInputValuesListEnums.NOTE;
  const isActivePrescription = activePrecriptionAttr === identifier;
  const isNotMedicineOrTest =
    identifier !== ITitleInputValuesListEnums.MEDICINE &&
    identifier !== ITitleInputValuesListEnums.TEST;

  const clearSearchPrescriptionChip = () => {
    setSearchedChips([]);
    setSelectSearchItem(null);
    setActivePrecriptionAttr(null);
  };

  const handleInputValues = (event: any) => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION_ATTRIBUTES,
      IPrescriptionAttributesPermissionEnums.SEARCH_PRESCRIPTION_ATTRIBUTES,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }

    if (
      (event.key === "Enter" || event.keyCode === 13) &&
      event.target.value.trim() !== ""
    ) {
      const chipValue = [
        {
          id: selectSearchItem ?? "0",
          name: event.target.value,
        },
      ];
      if (sliceAttr && sliceAttr?.length > 0) {
        dispatch(
          setPrescriptionTemplate({
            identifier,
            value: isDientAndExercise ? event.target.value : chipValue,
          })
        );
      } else {
        dispatch(
          setPrescriptionValues({
            identifier,
            value: isComplaint
              ? [event.target.value]
              : isDietOrNote
              ? event.target.value
              : chipValue,
          })
        );
      }
      setSearchText("");
      clearSearchPrescriptionChip();
    }
  };

  const removeItemHandler = (
    identifier: ITitleInputValuesListEnums,
    itemIndex: number,
    value?: string
  ) => {
    const removeItemDetails = {
      identifier,
      value,
      itemIndex,
    };
    if (sliceAttr && sliceAttr?.length > 0) {
      dispatch(removePrescriptionTemplateItem(removeItemDetails));
    } else {
      dispatch(removePrescriptionItem(removeItemDetails));
    }
  };

  const handleAddMedicineTestModal = () => {
    const showSpecificModal =
      identifier === ITitleInputValuesListEnums.MEDICINE
        ? { medicine: true }
        : { test: true };
    if (isAddMedicineTestShow && setIsAddMedicineTestShow) {
      setIsAddMedicineTestShow({
        ...isAddMedicineTestShow,
        ...showSpecificModal,
      });
    }
  };

  const searchChipHandler = async () => {
    const searchPrescriptionAttributesResponse =
      await searchPrescriptionAttributesApi(
        prescriptionAttributeType[identifier],
        searchText
      );
    if ("result" in searchPrescriptionAttributesResponse) {
      setSearchedChips(searchPrescriptionAttributesResponse?.result);
    } else {
      setSearchedChips([]);
      console.error(searchPrescriptionAttributesResponse);
    }
  };

  const searchTextHandler = (
    value: string,
    identity: ITitleInputValuesListEnums
  ) => {
    setSearchText(value);
    setActivePrecriptionAttr(identity);
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = event.currentTarget;
    const value = event.target.value;

    textarea.style.height = "0rem";
    textarea.style.height = `${textarea.scrollHeight / 10}rem`;

    if (isDientAndExercise) {
      if (sliceAttr && sliceAttr?.length > 0) {
        dispatch(
          setPrescriptionTemplate({
            identifier,
            value: value,
          })
        );
      } else {
        dispatch(
          setPrescriptionValues({
            identifier,
            value: value,
          })
        );
      }
    } else {
      dispatch(
        setPrescriptionValues({
          identifier,
          value: value,
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "2.5rem";
    }
  }, [textareaRef]);

  useEffect(() => {
    if (
      !isDietOrNote &&
      !isComplaint &&
      isNotMedicineOrTest &&
      debounceSearchMedicineTest?.length
    ) {
      searchChipHandler();
    }
    // else {
    //   clearSearchPrescriptionChip();
    // }
  }, [debounceSearchMedicineTest]);

  useEffect(() => {
    if (selectSearchItem && selectSearchItem?.length > 0) {
      const { name } =
        searchedChips.find((list) => list.id === selectSearchItem) || {};
      const chipValue = [
        {
          id: selectSearchItem ?? "0",
          name: name,
        },
      ];
      if (sliceAttr && sliceAttr?.length > 0) {
        dispatch(
          setPrescriptionTemplate({
            identifier,
            value: chipValue,
          })
          // setSearchedChips([]);
        );
      }
      setSearchText("");
      clearSearchPrescriptionChip();
    }
  }, [selectSearchItem]);

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        backgroundColor: "var(--neutral-100)",
        borderRadius: { xs: "0rem", md: "0.8rem" },
        overflow: "hidden",
      }}
    >
      <Grid
        item
        className={` ${
          hasActionButton ? style.activeContainer : style.container
        }`}
      >
        <Grid
          width="100%"
          textAlign={"left"}
          sx={{ padding: hasActionButton ? "0rem 1.6rem" : "0rem" }}
        >
          <Title>
            <FormattedMessage id={title} defaultMessage="Title Input" />
          </Title>
        </Grid>
        <Grid
          item
          className={` ${
            hasActionButton
              ? style.activeInputBtnContainer
              : style.inputBtnContainer
          }`}
        >
          {hasActionButton ? (
            <>
              {create[
                identifier === ITitleInputValuesListEnums.TEST
                  ? ITitleInputValuesListEnums.TEST
                  : ITitleInputValuesListEnums.MEDICINE
              ]?.map((list: any, index: number) => (
                <MedicineTestListRemoveManager
                  key={index}
                  {...list}
                  identifier={identifier}
                  removeItemHandler={(
                    identifier: ITitleInputValuesListEnums,
                    name: string
                  ) => removeItemHandler(identifier, index, name)}
                />
              ))}
              <Grid
                sx={{
                  padding: hasActionButton
                    ? "1.6rem 1.6rem 0rem 1.6rem"
                    : "0rem",
                  paddingTop:
                    create[
                      identifier === ITitleInputValuesListEnums.TEST
                        ? ITitleInputValuesListEnums.TEST
                        : ITitleInputValuesListEnums.MEDICINE
                    ]?.length > 0
                      ? "0rem"
                      : "1.6rem",
                }}
              >
                <Button
                  variant={IButtonVariant.WHITE}
                  btnTrigger={handleAddMedicineTestModal}
                >
                  <GetImages name={buttonLabel?.icon!} width="26" height="20" />
                  {buttonLabel?.text}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item className={style.inputWithListContainer}>
              {(!isDietOrNote || (sliceAttr && sliceAttr?.length > 0)) &&
              identifier !== ITitleInputValuesListEnums.DIET_AND_EXERCISE ? (
                (sliceAttr && sliceAttr?.length > 0
                  ? template?.create
                  : create)[identifier]?.map(
                  (list: ITagOtherHealthProblemDiagnosis, index: number) => (
                    <DiagnosticChip
                      key={index}
                      chipIndex={index}
                      identifier={identifier}
                      name={list?.name ?? list}
                      removeItemHandler={removeItemHandler}
                      isSelected={selectedDiagnosisIndex === index}
                      onSelect={() => {
                        setSelectedDiagnosisIndex(
                          selectedDiagnosisIndex === index ? null : index
                        );
                      }}
                    />
                  )
                )
              ) : (
                <textarea
                  ref={textareaRef}
                  className={style.enteredOutput}
                  onChange={handleTextareaChange}
                  value={
                    (sliceAttr &&
                      sliceAttr?.length > 0 &&
                      (template?.create[identifier] || "")) ||
                    create[identifier] ||
                    ""
                  }
                  placeholder={intl.formatMessage({
                    id: "type_here",
                    defaultMessage: "Type here",
                  })}
                />
              )}
              {!isDietOrNote && (
                <input
                  type="text"
                  className={style.inputWithListContainer2}
                  placeholder={intl.formatMessage({
                    id: "type_here",
                    defaultMessage: "Type here",
                  })}
                  value={searchText}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    searchTextHandler(event.target.value, identifier)
                  }
                  onKeyDown={handleInputValues}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      {searchedChips.length > 0 && (
        <Grid item className={style.searchSuggestionContainer}>
          <SearchSuggestions
            typeOfSearch={activePrecriptionAttr!}
            clearSearchSuggestions={clearSearchPrescriptionChip}
            seachingText={searchText}
            selectSearchItem={selectSearchItem!}
            setSelectSearchItem={setSelectSearchItem}
            foundDataList={searchedChips.map((record, index) => ({
              label: record.name,
              value: record.id,
            }))}
          />
        </Grid>
      )}
    </Stack>
  );
};

export default TitleInputValueList;
