import React from "react";
import Grid from "@mui/material/Grid";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.css";

interface BreadcrumbProps {
  items: { label: string; isCurrent?: boolean; link?: string; id?: string }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  const navigate = useNavigate();

  const handleClick = (link: string, isCurrent: boolean, id?: string) => {
    if (isCurrent || !link) {
      return;
    }
    navigate(link, { state: { id } });
  };

  return (
    <Grid className={styles.breadcrumb}>
      <Grid className={styles.iconContainer}>
        <ChevronLeft
          className={styles.goBackIcon}
          onClick={() => navigate(-1)}
        />
      </Grid>
      <Grid className={styles.textContainer}>
        {items.map((item, index) => (
          <Grid
            key={index}
            onClick={() =>
              handleClick(item.link || "", item.isCurrent || false, item.id)
            }
          >
            <span className={item.isCurrent ? styles.current : styles.text}>
              {item.label}
            </span>
            {index < items.length - 1 && (
              <span className={styles.separator}>/</span>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Breadcrumb;
