import React from "react";
import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import GetImages from "../GetImages";

import { IKeyPairsValues } from "../../models/doctor";

interface IProps {
  heading?: string;
  subHeading?: string;
  secondOption?: React.ReactNode;
  actionJSX?: React.ReactNode;
  actionHandler?: () => void;
  secondOptionHandler?: () => void;
  previousPage?: (closeDialog: boolean) => void;
  centreDetails?: IKeyPairsValues;
  selectedCategory?: string;
  muiStyles?: SxProps<Theme>;
  onGoBack?: () => void;
}
const Header: React.FC<IProps> = (props) => {
  const {
    heading,
    subHeading,
    secondOption,
    secondOptionHandler,
    previousPage,
    actionJSX,
    actionHandler,
    muiStyles,
    onGoBack,
  } = props;

  const navigate = useNavigate();

  const backHandler = () => {
    onGoBack ? onGoBack() : navigate(-1);
  };

  return (
    <Grid
      item
      className={style.headerContainer}
      sx={{
        borderBottom: "0.1rem solid var(--gray-50)",
        padding: "1.6rem",
        backgroundColor: "var(--neutral-100)",
        ...muiStyles,
      }}
    >
      <Grid item className={style.backNav}>
        <Grid
          item
          className={style.backNav}
          onClick={() => {
            previousPage ? previousPage(true) : backHandler();
          }}
        >
          <GetImages name="LeftArrowIcon" width="20" height="18" />
        </Grid>
        <Grid item className={style.firstColumnWrapper}>
          <Grid item>
            {heading && (
              <Typography component={"p"} className={style.heading}>
                {heading}
              </Typography>
            )}
            {subHeading && (
              <Typography component={"p"} className={style.subHeading}>
                {subHeading}
              </Typography>
            )}
          </Grid>
          {actionJSX && (
            <Grid
              item
              className={style.actionBox}
              onClick={() => actionHandler && actionHandler()}
            >
              {actionJSX}
            </Grid>
          )}
        </Grid>
      </Grid>
      {secondOption && (
        <Grid item onClick={secondOptionHandler}>
          {" "}
          {secondOption}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
