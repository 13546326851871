import React from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import AvailabilitySettingListing from "../";
import Button from "../../../../components/Button";
import GetImages from "../../../../components/GetImages";
import CreateEditAvailability from "../CreateEditAvailability";

import { detail } from "../../../../store/slices/doctor/availabilitySettings";
import { useAppDispatch } from "../../../../store/hooks";
import { setTopBarDropdownItems } from "../../../../store/slices/doctor/topNavigation";

import { topBarDropdownDispatchingEnums } from "../../../../utils";

import { IProfileDropdownEnums } from "../../../../models/doctor";

const AvailabilitySettingWeb: React.FC = () => {
  const dispatch = useAppDispatch();

  const closeHandler = () => {
    dispatch(
      setTopBarDropdownItems(
        topBarDropdownDispatchingEnums[
          IProfileDropdownEnums.AVAILABILITY_SETTINGS
        ]
      )
    );
  };
  return (
    <Grid item className={style.container}>
      <Grid item className={style.avilabilityTopContainer}>
        <Typography variant="h4">
          <FormattedMessage
            id="availability_settings"
            defaultMessage="Availability Settings"
          />
        </Typography>
        <Grid item className={style.addNewAvailability}>
          <Button btnTrigger={() => dispatch(detail(null))}>
            <GetImages name="ScheduleIconSelected" width="30" height="24" />
            <FormattedMessage
              id="add_new_slot"
              defaultMessage="Add a new slot"
            />
          </Button>

          <IconButton className={style.closebutton} onClick={closeHandler}>
            <GetImages
              name="UniSearchDesktopCloseIcon"
              width="20"
              height="20"
            />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item className={style.availabilityListingCreateUpdateContainer}>
        <Grid item className={style.availabilityListing}>
          <AvailabilitySettingListing />
        </Grid>
        <Grid item className={style.availabilityCreateUpdateContainer}>
          <CreateEditAvailability />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvailabilitySettingWeb;
