import React from "react";
import { Button, Grid } from "@mui/material";
import { useIntl } from "react-intl";

import styles from "./index.module.css";

import GetImages from "../../../../components/GetImages";

type BlogActionsDesktopProps = {
  likeCount: number;
  isLiked?: boolean;
  isFavourited?: boolean;
  handleLike: () => void;
  handleUnlike: () => void;
  handleFavourite: () => void;
  handleUnfavourite: () => void;
  handleShare: () => void;
};

const BlogActionsDesktop: React.FC<BlogActionsDesktopProps> = ({
  likeCount,
  isLiked,
  isFavourited,
  handleLike,
  handleUnlike,
  handleFavourite,
  handleUnfavourite,
  handleShare,
}) => {
  const intl = useIntl();

  return (
    <Grid className={styles.actionsContainer}>
      <Button
        variant="contained"
        className={`${styles.actionButton} ${isLiked ? styles.liked : ""}`}
        onClick={isLiked ? handleUnlike : handleLike}
      >
        <GetImages
          name="LikeVideoDesktopIcon"
          fill={isLiked ? "white" : "#C6C6C6"}
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {likeCount === 0
            ? intl.formatMessage({ id: "like", defaultMessage: "Like" })
            : likeCount}
        </span>
      </Button>
      <Button
        variant="contained"
        className={`${styles.actionButton} ${
          isFavourited ? styles.favourited : ""
        }`}
        onClick={isFavourited ? handleUnfavourite : handleFavourite}
      >
        <GetImages
          fill={isFavourited ? "white" : "#C6C6C6"}
          name="FavouriteVideoDesktopIcon"
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {intl.formatMessage({ id: "favourite", defaultMessage: "Favourite" })}
        </span>
      </Button>
      <Button
        variant="contained"
        className={styles.actionButton}
        onClick={handleShare}
      >
        <GetImages
          name="ShareVideoDesktopIcon"
          fill="#393939"
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {intl.formatMessage({ id: "share", defaultMessage: "Share" })}
        </span>
      </Button>
    </Grid>
  );
};

export default BlogActionsDesktop;
