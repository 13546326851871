import React from "react";
import { Grid, Typography } from "@mui/material";

import style from "./index.module.css";

import { getPermissionAccess } from "../../utils";

import { IRolePermissionAccess, IModalKeyEnums } from "../../models/permission";

import { useAppSelector } from "../../store/hooks";

interface IProps {
  moduleKey: IModalKeyEnums;
  featureKey: string;
  children: React.ReactNode;
}
const PermissionWrapper = (props: IProps) => {
  const { moduleKey, featureKey, children } = props;
  const { permission } = useAppSelector((state) => state.rolePermission);

  const permissionAccess = getPermissionAccess(
    moduleKey,
    featureKey,
    permission
  );

  if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
    return (
      <Grid item className={style.permissionContainer}>
        <Typography component={"p"}>You don't have permission</Typography>
      </Grid>
    );
  }
  return <>{children}</>;
};

export default PermissionWrapper;
