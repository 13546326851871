import React, { useCallback, useMemo, useState } from "react";
import { DialogContent, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import Modal from "../Modal";
import GetImages from "../GetImages";
import Button from "../Button";
import Confirmation from "../Confirmation";
import CreatePrescription from "../../views/Doctor/Prescription/CreatePrescription";

import { IButtonVariant } from "../../models/button";
import {
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IPatientAppointmentStatus,
} from "../../models/doctor";
import {
  IAppointmentPermissionEnums,
  IMedicalRecordPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
  IPrescriptionPermissionEnums,
} from "../../models/permission";
import { IRoleType } from "../../models/role";

import { getPermissionAccess, timeInAmPmFormat } from "../../utils";
import useCancelScheduleAppointments from "../../utils/hooks/useCancelScheduleAppointments";
import { storage } from "../../utils/Storage";
import useCallNotificationApi from "../../utils/hooks/useCallNotificationApi";

import { setPrescriptionTemplateValues } from "../../store/slices/doctor/prescription";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchRTCTokenAgora,
  fetchRTMTokenAgora,
  setChannelName,
  setIsVideoCallInitiated,
} from "../../store/slices/videoAppointment";
import { setShowPermissionAlert } from "../../store/slices/permission";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useIsTablet from "../../utils/hooks/useIsTablet";
import Dialog from "../Dialog";
import MedicalRecords from "../../views/Common/MedicalRecords";

interface IProps {
  isOpen: boolean;
  platform: IDoctorAvailabilityPlatform;
  status: IPatientAppointmentStatus;
  closeHandler: () => void;
  openAppointmentSlotHandler: (type: IAppointmetEnums) => void;
}

const ScheduleEventPopUps: React.FC<IProps> = (props) => {
  const { platform, status, isOpen, closeHandler, openAppointmentSlotHandler } =
    props;
  const [showCancelSchedule, setShowCancelSchedule] = useState<boolean>(false);
  const [showCreatePrescriptionDialog, setShowCreatePrescriptionDialog] =
    useState(false);
  const [initiateVideoCallLoading, setInitiateVideoCallLoading] =
    useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cancelScheduleAppointments } = useCancelScheduleAppointments();

  const [showMedicalRecords, setShowMedicalRecords] = useState(false);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const appointmentDetail = useAppSelector(
    (state) => state.appointment.appointmentDetail
  );
  const { permission } = useAppSelector((state) => state.rolePermission);

  const { handleIncomingCallNotification } = useCallNotificationApi();

  const openCloseAppointmentCancelConfirmation = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.APPOINTMENT,
      IAppointmentPermissionEnums.DELETE_APPOINTMENT,
      permission
    );

    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    setShowCancelSchedule(!showCancelSchedule);
  };

  const handleShow = () => {
    setShowMedicalRecords((state) => !state);
    // const permissionAccess = getPermissionAccess(
    //   IModalKeyEnums.MEDICAL_RECORD,
    //   IMedicalRecordPermissionEnums.VIEW_MEDICAL_RECORDS,
    //   permission
    // );
    // if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
    //   dispatch(setShowPermissionAlert());
    //   return;
    // }
    // navigate("/patient-medical-records");
  };

  const initiateVideoCall = async () => {
    setInitiateVideoCallLoading(true);
    await dispatch(fetchRTMTokenAgora(appointmentDetail?.id));
    await dispatch(fetchRTCTokenAgora(appointmentDetail?.id));
    dispatch(setChannelName(appointmentDetail?.id));
    dispatch(setIsVideoCallInitiated());
    await handleIncomingCallNotification(
      appointmentDetail?.id,
      storage.getSessionToken("name") ?? "Unknown",
      appointmentDetail?.patient?.name,
      appointmentDetail?.patient?.patientId
    );
    navigate("/join-room");
  };

  const createPrescriptionHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.PRESCRIPTION,
      IPrescriptionPermissionEnums.CREATE_PRESCRIPTION,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    setShowCreatePrescriptionDialog(true);
  };

  const createPrescriptionBtn = useCallback(() => {
    dispatch(setPrescriptionTemplateValues({}));
    return (
      <Button
        btnTrigger={createPrescriptionHandler}
        variant={IButtonVariant.WHITE}
      >
        <GetImages name="PrescriptionIcon" width="20" height="20" />
        Create Prescription
      </Button>
    );
  }, [platform]);

  const rescheduleAppointmentHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.APPOINTMENT,
      IAppointmentPermissionEnums.RESCHEDULE_APPOINTMENT,
      permission
    );

    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }

    openAppointmentSlotHandler(IAppointmetEnums.CREATION);
  };

  const content = useMemo(() => {
    return (
      <>
        <Grid item className={style.schedulePopUpContainer}>
          <Typography component={"h4"}>
            {platform === IDoctorAvailabilityPlatform.VIDEO_CALL
              ? timeInAmPmFormat(appointmentDetail?.slotStartTime)
              : `Slot - ${appointmentDetail?.slotNo}`}{" "}
            {platform === IDoctorAvailabilityPlatform.VIDEO_CALL && (
              <Typography component={"span"}>
                {appointmentDetail?.slotDuration} min session
              </Typography>
            )}
          </Typography>
          <Typography component={"p"} className={style.patientName}>
            {appointmentDetail?.patient?.name}
          </Typography>
          <Typography component={"p"} className={style.patientAge}>
            Age - {appointmentDetail?.patient?.age}
          </Typography>
          {roleType === IRoleType.DOCTOR && (
            <Grid item className={style.medicalRecordContainer}>
              <Grid item className={style.medicalRecordCounter}>
                <GetImages name="MedicalRecordIcon" height="24" width="24" />
                <Typography component={"p"}>
                  Medical Records ({appointmentDetail?.patient?.records})
                </Typography>
              </Grid>
              {appointmentDetail?.patient?.records >= 0 && (
                <Grid item>
                  <Typography
                    component={"p"}
                    className={style.medicalRecordShow}
                    onClick={handleShow}
                  >
                    Show
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item className={style.actionsBtnContainer}>
            {status === IPatientAppointmentStatus.CANCELLED && (
              <Button
                variant={IButtonVariant.WHITE}
                btnTrigger={rescheduleAppointmentHandler}
              >
                Reschedule
              </Button>
            )}
            {status === IPatientAppointmentStatus.COMPLETED &&
            (platform === IDoctorAvailabilityPlatform.IN_CLINIC ||
              platform === IDoctorAvailabilityPlatform.VIDEO_CALL) ? (
              <>
                <Button variant={IButtonVariant.WHITE}>Message</Button>
                <Button>
                  <GetImages name="CallWhiteIcon" width="25" height="20" /> Call
                  Patient
                </Button>
              </>
            ) : (
              status !== IPatientAppointmentStatus.CANCELLED && (
                <>
                  <Button
                    variant={IButtonVariant.WHITE}
                    btnTrigger={openCloseAppointmentCancelConfirmation}
                  >
                    Cancel Appointment
                  </Button>
                  {roleType === IRoleType.DOCTOR &&
                    !appointmentDetail?.activePrescription &&
                    createPrescriptionBtn()}
                  {platform === IDoctorAvailabilityPlatform.IN_CLINIC ? (
                    <>
                      <Button>
                        <GetImages
                          name="CallWhiteIcon"
                          width="25"
                          height="20"
                        />{" "}
                        Call Patient
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        isLoading={initiateVideoCallLoading}
                        btnTrigger={initiateVideoCall}
                      >
                        <GetImages
                          name="VideoWhiteIcon"
                          width="25"
                          height="20"
                        />
                        Video Call
                      </Button>
                    </>
                  )}
                </>
              )
            )}
          </Grid>
        </Grid>
        {showCancelSchedule && (
          <Confirmation
            isOpen={showCancelSchedule}
            closeHandler={openCloseAppointmentCancelConfirmation}
            title="cancel_appointments"
            alterMessage={"confirm_cancel_appoinment"}
            actionHandler={() => {
              cancelScheduleAppointments();
              openCloseAppointmentCancelConfirmation();
              closeHandler();
            }}
          />
        )}
      </>
    );
  }, [showCancelSchedule]);

  const isSmallScreen = useMemo(() => {
    return isMobile || isTablet;
  }, [isMobile, isTablet]);

  if (isSmallScreen) {
    return (
      <>
        <Modal isOpen={isOpen} isCloseIcon closeHandler={closeHandler}>
          {content}
        </Modal>
        {showCreatePrescriptionDialog && (
          <CreatePrescription
            onClose={() => setShowCreatePrescriptionDialog(false)}
          />
        )}
        {showMedicalRecords && <MedicalRecords onBack={handleShow} />}
      </>
    );
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth closeHandler={closeHandler}>
        {content}
      </Dialog>
      {showCreatePrescriptionDialog && (
        <Dialog
          maxWidth="sm"
          fullWidth
          closeHandler={() => setShowCreatePrescriptionDialog(false)}
        >
          <CreatePrescription
            onClose={() => setShowCreatePrescriptionDialog(false)}
          />
        </Dialog>
      )}
      {showMedicalRecords && (
        <Dialog maxWidth="sm" fullWidth closeHandler={handleShow}>
          <DialogContent>
            <MedicalRecords onBack={handleShow} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ScheduleEventPopUps;
