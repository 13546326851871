import React, { useMemo } from "react";
import { Stack, Button, Grid, Typography, Box, Chip } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../../../components/GetImages";

import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IOrderStatusEnum } from "../../../models/ApiRequestResponse/pharmacy";

import {
  capitalizeFirstLetter,
  convertTimestampToDDMMM,
  getMedicalReportDocumentName,
} from "../../../utils";
import { IRoleType } from "../../../models/role";
import { storage } from "../../../utils/Storage";
import { report } from "process";

const statusStyles: Record<IOrderStatusEnum, { bg: string; color: string }> = {
  [IOrderStatusEnum.COMPLETED]: {
    bg: "var(--green-50)",
    color: "var(--green-500)",
  },
  [IOrderStatusEnum.UPLOADED]: {
    bg: "var(--green-50)",
    color: "var(--green-500)",
  },
  [IOrderStatusEnum.REJECTED]: {
    bg: "var(--red-100)",
    color: "var(--red-900)",
  },
  [IOrderStatusEnum.CANCELLED]: {
    bg: "var(--red-100)",
    color: "var(--red-900)",
  },
  [IOrderStatusEnum.PENDING]: {
    bg: "",
    color: "",
  },
  [IOrderStatusEnum.CONFIRMED]: {
    bg: "",
    color: "",
  },
};

interface IOnSelect {
  isSelected?: boolean;
  onSelect?: (order: IPharmacyDiagnosticOrderDetail) => void;
}
type IProps = IOnSelect & IPharmacyDiagnosticOrderDetail;
const OrderCard: React.FC<IProps> = (props) => {
  const {
    id,
    isSelected = false,
    name,
    tracking_id,
    reports,
    type,
    booking_timestamp,
    status,
    onSelect,
  } = props;

  const roleType = storage.getSessionToken("roleType");

  const { bg: chipBGColor, color: chipLabelColor } = useMemo(() => {
    if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
      if (status === IOrderStatusEnum.COMPLETED) {
        return reports?.length
          ? statusStyles[status]
          : {
              bg: "var(--neutral-200)",
              color: "var(--black-900)",
            };
      } else {
        return statusStyles[status];
      }
    } else {
      return statusStyles[status];
    }
  }, [status]);

  const reportContent = useMemo(() => {
    if (reports?.length) {
      return (
        <Grid item>
          <Stack
            direction={"column"}
            alignItems={"center"}
            maxHeight={"10rem"}
            overflow={"hidden"}
            gap={1}
          >
            {reports
              ?.map((report: any, index: number) => (
                <Box
                  component={Stack}
                  direction={"row"}
                  alignItems={"center"}
                  borderRadius={"1.8rem"}
                  bgcolor={"var(--gray-50)"}
                  p={1}
                  key={`${report?.id}-${index}`}
                >
                  <GetImages
                    name={
                      report?.thumbnail_url ? "PDFGreyIcon" : "GalleryImageIcon"
                    }
                    width="24"
                    height={report?.thumbnail_url ? "22" : "18"}
                  />
                  <Typography component={"p"} noWrap sx={{ width: "15ch" }}>
                    {getMedicalReportDocumentName(report?.url!)}
                  </Typography>
                </Box>
              ))
              .slice(0, 1)}
          </Stack>
        </Grid>
      );
    } else if (
      status === IOrderStatusEnum.COMPLETED &&
      !reports?.length &&
      roleType === IRoleType.DIAGNOSTIC_CENTER
    ) {
      return (
        <Chip
          variant="filled"
          label={"Not Uploaded"}
          sx={{
            height: "2.8rem",
            width: "9.9rem",
            px: "0.4rem",
            py: "0.8rem",
            backgroundColor: "var(--warm-neutral-50)",
            "& .MuiChip-label": {
              fontSize: "1.2rem",
              fontWeight: 400,
              textOverflow: "unset",
              overflow: "visible",
            },
          }}
        />
      );
    } else {
      return (
        <Typography variant="subtitle2" className={style["text-gray"]}>
          {capitalizeFirstLetter(
            type ??
              `Order Date ${convertTimestampToDDMMM(
                booking_timestamp,
                "DD MMM YYYY"
              )}`
          )}
        </Typography>
      );
    }
  }, [status, reports, type, booking_timestamp]);

  return (
    <Stack
      component={Button}
      onClick={() => onSelect?.(props)}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      className={`${style.card} ${isSelected && style.active}`}
      key={`${tracking_id}-${id}`}
    >
      <Stack direction={"column"}>
        <Typography variant="h6" className={style.consumerName}>
          {name}
        </Typography>
        {reportContent}
      </Stack>
      <Stack direction={"column"}>
        {status !== IOrderStatusEnum.CONFIRMED && (
          <Chip
            variant="filled"
            label={capitalizeFirstLetter(status)}
            sx={{
              px: 1,
              py: 0.5,
              height: "2rem",
              width: "8.4rem",
              backgroundColor: chipBGColor,
              color: chipLabelColor,
              "& .MuiChip-label": {
                fontSize: "1.2rem",
                fontWeight: 400,
                textOverflow: "unset",
                overflow: "visible",
              },
            }}
          />
        )}
        <Typography component={"p"} className={style.orderType}>
          {tracking_id}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default OrderCard;
