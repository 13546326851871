import React, { useEffect } from "react";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import style from "./index.module.css";

type ScrollContainerProps = {
  children: React.ReactNode;
  isSection?: boolean;
  isInModal?: boolean;
};

const ScrollContainer: React.FC<ScrollContainerProps> = ({
  children,
  isSection = true,
  isInModal = false,
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [showLeftButton, setShowLeftButton] = React.useState(false);
  const [showRightButton, setShowRightButton] = React.useState(false);

  useEffect(() => {
    const updateScrollButtons = () => {
      if (scrollContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } =
          scrollContainerRef.current;
        setShowLeftButton(scrollLeft > 0);
        setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1);
      }
    };

    updateScrollButtons();
    scrollContainerRef.current?.addEventListener("scroll", updateScrollButtons);
    window.addEventListener("resize", updateScrollButtons);

    return () => {
      scrollContainerRef.current?.removeEventListener(
        "scroll",
        updateScrollButtons
      );
      window.removeEventListener("resize", updateScrollButtons);
    };
  }, []);

  const handleScroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollPosition =
        scrollContainerRef.current.scrollLeft +
        (direction === "left" ? -scrollAmount : scrollAmount);

      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <Grid className={style.sliderContainer}>
      {showLeftButton && (
        <IconButton
          className={`${style.arrowButton} ${style.arrowLeftButton} ${
            isInModal && style.arrowLeftButtonModal
          }`}
          onClick={() => handleScroll("left")}
        >
          <ArrowBackIosIcon fontSize="small" />
        </IconButton>
      )}

      <Grid
        className={`${style.innerContainer} ${
          !isSection && style.sectionInnerContainer
        }`}
        ref={scrollContainerRef}
      >
        {children}
      </Grid>

      {showRightButton && (
        <IconButton
          className={`${style.arrowButton} ${style.arrowRightButton} ${
            isInModal && style.arrowRightButtonModal
          }`}
          onClick={() => handleScroll("right")}
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      )}
    </Grid>
  );
};

export default ScrollContainer;
