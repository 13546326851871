import React from "react";

import { IHelpMeChooseDoctorRequest } from "../../models/ApiRequestResponse/patient";

import { postHelpMeChooseDoctorApi } from "../apis/patient/doctor";

import { INotifyEnum, notify } from "../toaster";

const useHelpMeChooseDoctor = () => {
  const createSupportHandler = async (
    requestBody: IHelpMeChooseDoctorRequest
  ): Promise<void> => {
    try {
      const response = await postHelpMeChooseDoctorApi(requestBody);
      if ("result" in response) {
        notify(INotifyEnum.SUCCESS, response.message);
      }
    } catch (error) {
      notify(INotifyEnum.ERROR, "Failed to process your request.");
      console.error("Error in createSupportHandler:", error);
    }
  };

  return { createSupportHandler };
};

export default useHelpMeChooseDoctor;
