import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import EnterMobile from "../../../components/Verification/VerifyMobile";
import VerifyOTP from "../../../components/Verification/VerifyOTP";
import AddEditProfile from "../../../components/AddEditProfile";

import { IDropDownSelectionType } from "../../../models/doctor";
import { IMobileVerify, IOtpVerify } from "../../../models/onboarding";
import {
  IPatientMemberDetailEnums,
  IProfileInput,
} from "../../../models/patient";

import {
  getPatientNewMobileNumberApi,
  getPatientProfileDetailApi,
  updateMemberApi,
  updatePatientProfileDetailApi,
} from "../../../utils/apis/patient/profile";
import { INotifyEnum, notify } from "../../../utils/toaster";
import { IAPIStatusCode } from "../../../utils/apis/APIEndpointConfig";
import { countryCode } from "../../../utils/text";
import { storage } from "../../../utils/Storage";

import { fetchPatientMembers } from "../../../store/slices/patient/member";
import { useAppDispatch } from "../../../store/hooks";

interface IProfileDetail {
  name: string;
  age: string;
  mobileNo: string;
  gender: string;
  is_default: boolean;
}

interface IMobileChange {
  isMobileShow: boolean;
  isOTPShow: boolean;
  countryCode: string;
  newMobileNo: string;
  otpId: string;
  otpCode: string;
}

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
  selectedMemberId: string;
  type: IPatientMemberDetailEnums;
}
const Profile: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler, selectedMemberId, type } = props;
  const [isMobileChange, setIsMobileChange] = useState<IMobileChange>({
    isMobileShow: false,
    isOTPShow: false,
    countryCode: countryCode,
    newMobileNo: "",
    otpId: "",
    otpCode: "",
  });
  const [profileDetail, setProfileDetail] = useState<IProfileDetail>({
    name: "",
    age: "",
    mobileNo: "",
    gender: "",
    is_default: false,
  });
  const [isGenderOpen, setIsGenderOpen] = useState<boolean>(false);
  const openCloseGenderModal = () => setIsGenderOpen(!isGenderOpen);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const saveSelectedValues = (value: string, type: IDropDownSelectionType) => {
    if (type === IDropDownSelectionType.GENDER) {
      openCloseGenderModal();
      setProfileDetail({
        ...profileDetail,
        gender: value,
      });
    }
  };

  const fetchPatientProfileDetail = async () => {
    const patientProfileDetail = await getPatientProfileDetailApi();
    if ("result" in patientProfileDetail) {
      const { name, age, gender, phone_number, is_default } =
        patientProfileDetail?.result?.members?.filter(
          (member, index) =>
            (selectedMemberId && selectedMemberId === member.id) ??
            member.is_default
        )[0];
      setProfileDetail({
        name: name,
        age: age?.toString(),
        gender,
        mobileNo: phone_number!,
        is_default,
      });
    }
  };

  const updateProfileDetail = async () => {
    const { name, age, gender } = profileDetail;
    const payload = {
      name,
      age: +age,
      gender,
      ...(isMobileChange.newMobileNo.length > 0 && {
        country_code: isMobileChange.countryCode,
        phone_number: isMobileChange.newMobileNo,
        otp_code: isMobileChange.otpCode,
        otpId: isMobileChange.otpId,
      }),
    };
    const updatePatientProfileDetailRes = await updatePatientProfileDetailApi(
      payload
    );
    if (updatePatientProfileDetailRes.statusCode === IAPIStatusCode.SUCCESS) {
      notify(INotifyEnum.SUCCESS, updatePatientProfileDetailRes.message);
      navigate("/medical-records");
    } else {
      notify(INotifyEnum.ERROR, updatePatientProfileDetailRes.message);
    }
    closeHandler && closeHandler();
  };

  const updateMembersProfileDetail = async () => {
    const { name, age, gender, is_default } = profileDetail;
    const payload = {
      name,
      age: age,
      gender,
      is_default,
    };
    const updateMemberResponse = await updateMemberApi(
      selectedMemberId!,
      payload
    );
    if (updateMemberResponse.statusCode === IAPIStatusCode.SUCCESS) {
      notify(INotifyEnum.SUCCESS, updateMemberResponse.message);
      navigate("/medical-records");
      storage.setSessionToken("name", name);
      dispatch(fetchPatientMembers());
    } else {
      notify(INotifyEnum.ERROR, updateMemberResponse.message);
    }
    closeHandler && closeHandler();
  };

  const profileDetailHandler = (
    event: React.ChangeEvent<any>,
    type: IProfileInput
  ) => {
    switch (type) {
      case IProfileInput.NAME:
        setProfileDetail({
          ...profileDetail,
          name: event.target.value,
        });
        return;
      case IProfileInput.AGE:
        setProfileDetail({
          ...profileDetail,
          age: event.target.value,
        });
        return;
      case IProfileInput.MOBILE_NUMBER:
        setProfileDetail({
          ...profileDetail,
          mobileNo: event.target.value,
        });
        return;
      case IProfileInput.GENDER:
        setProfileDetail({
          ...profileDetail,
          gender: event.target.value,
        });
        return;
      case IProfileInput.IS_DEFAULT:
        setProfileDetail({
          ...profileDetail,
          is_default: event.target.checked,
        });
        return;
      default:
        return null;
    }
  };

  const mobileSubmitHandler = async (values?: IMobileVerify) => {
    setIsBtnLoading(true);
    try {
      const mobileVerifyRes = await getPatientNewMobileNumberApi({
        country_code: isMobileChange.countryCode,
        phone_number: values?.mobileNo
          ? values.mobileNo
          : isMobileChange.newMobileNo,
      });
      if ("result" in mobileVerifyRes) {
        if (mobileVerifyRes.statusCode === IAPIStatusCode.SUCCESS) {
          if (isMobileChange.isMobileShow) {
            setIsMobileChange({
              ...isMobileChange,
              otpId: mobileVerifyRes.result.OtpId,
              isMobileShow: !isMobileChange.isMobileShow,
              isOTPShow: !isMobileChange.isOTPShow,
              newMobileNo: values?.mobileNo
                ? values.mobileNo
                : isMobileChange.newMobileNo,
            });
          } else {
            setIsMobileChange({
              ...isMobileChange,
              otpId: mobileVerifyRes.result.OtpId,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleOTPSubmit = async (values: IOtpVerify) => {
    setIsMobileChange({
      ...isMobileChange,
      otpCode: values.otp,
      isOTPShow: !isMobileChange.isOTPShow,
    });
  };

  useEffect(() => {
    fetchPatientProfileDetail();
  }, []);
  return (
    <>
      <AddEditProfile
        isOpen={isOpen}
        isDefault={profileDetail?.is_default}
        closeHandler={closeHandler}
        title="Profile Details"
        age={profileDetail?.age}
        gender={profileDetail?.gender}
        name={profileDetail?.name}
        mobileNo={
          isMobileChange.newMobileNo.length === 0
            ? profileDetail?.mobileNo
            : isMobileChange.newMobileNo
        }
        profileDetailHandler={profileDetailHandler}
        saveGender={saveSelectedValues}
        isGenderOpen={isGenderOpen}
        openCloseGenderModal={openCloseGenderModal}
        openMobileChange={() =>
          setIsMobileChange({
            ...isMobileChange,
            isMobileShow: true,
          })
        }
        saveProfile={() =>
          type === IPatientMemberDetailEnums.HOME
            ? updateMembersProfileDetail()
            : updateProfileDetail()
        }
      />
      {isMobileChange.isMobileShow && (
        <EnterMobile
          isBtnLoading={isBtnLoading}
          isOpen={isMobileChange.isMobileShow}
          closeHandler={() =>
            setIsMobileChange({
              ...isMobileChange,
              isMobileShow: !isMobileChange.isMobileShow,
            })
          }
          heading="enter_new_number"
          mobileSubmitHandler={mobileSubmitHandler}
        />
      )}
      {isMobileChange.isOTPShow && (
        <VerifyOTP
          isBtnLoading={isBtnLoading}
          mobileNo={isMobileChange.newMobileNo}
          resendOTP={mobileSubmitHandler}
          handleOTPSubmit={handleOTPSubmit}
          navigateToMobileVerify={() =>
            setIsMobileChange({
              ...isMobileChange,
              isMobileShow: !isMobileChange.isMobileShow,
              isOTPShow: !isMobileChange.isOTPShow,
            })
          }
        />
      )}
    </>
  );
};

export default Profile;
