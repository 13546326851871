import React from "react";
import { IUniversalSearchEnum } from "../../models/doctor";

const useUniversalSearchChip = () => {
  const pharmacyChipSearchList = (isClinic: string | boolean) => [
    ...(isClinic
      ? [
          {
            label: "My Patients",
            value: IUniversalSearchEnum.CLINIC_APPOINTMENTS,
          },
        ]
      : []),
    {
      label: "Orders",
      value: IUniversalSearchEnum.PHARMACY_ORDER,
    },
  ];

  return { pharmacyChipSearchList };
};

export default useUniversalSearchChip;
