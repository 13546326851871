import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Slider as CustomSlider } from "@mui/material";
import { IDropDownSelectionType } from "../../models/doctor";

interface IProps {
  min: number;
  max: number;
  handleSliderValue: (value: number | string) => void;
  value?: number | string;
  type?: string;
}
const Slider = forwardRef((props: IProps, ref: any) => {
  const { min, max, handleSliderValue, type } = props;
  const [sliderValue, setSliderValue] = useState<number>(min);
  const [key, setKey] = useState<number>(0);
  const label = type
    ? type === IDropDownSelectionType.DISTANCE
      ? sliderValue === min
        ? "Distance"
        : "Km"
      : "Years"
    : "Days";
  useImperativeHandle(ref, () => ({
    resetValue: () => {
      setSliderValue(min);
      setKey((prev) => prev + 1);
    },
  }));

  const handleChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
    if (newValue === min && type) handleSliderValue("");
    else handleSliderValue(newValue);
  };
  useEffect(() => {
    if (min !== undefined) {
      setSliderValue(min);
    }
  }, [min]);

  return (
    <CustomSlider
      key={key}
      value={sliderValue}
      onChange={handleChange}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => (value === min && type ? "" : value)}
      min={min}
      max={max}
      sx={{
        width: "90%",
        color: "primary.main",
        "& .MuiSlider-thumb": {
          borderRadius: "0.8rem",
          background:
            sliderValue === min && type
              ? "var(--neutral-100)"
              : "var(--orange-500)",
          minWidth: "7rem",
          height: "3.2rem",
          padding: "0.8rem 1.2rem",
          "&:before": {
            display: "none",
          },
        },
        "& .MuiSlider-rail": {
          height: "0.8rem",
          backgroundColor: type ? "var(--neutral-100)" : "var(--gray-20)",
          border: "none",
        },
        "& .MuiSlider-track": {
          height: "0.8rem",
          backgroundColor: "var(--orange-500)",
          border: "none",
        },
        "& .MuiSlider-valueLabel": {
          top: "2.6rem",
          color:
            sliderValue === min && type
              ? "var(--gray-600)"
              : "var(--neutral-100)",
          fontSize: "1.2rem",
          fontWeight: "400",
          backgroundColor: "transparent",
          "&:after": {
            content: `"${label}"`,
            marginLeft: "0.2rem",
          },
        },
      }}
    />
  );
});

export default Slider;
