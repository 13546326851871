import {
  IAdmitPatientRequest,
  IAdmitPatientResponse,
  IAdmittedPatientResponse,
  IDoctorHospitalListResponse,
  IHospitalPatientListResponse,
  IHospitalRoomsResponse,
  IUpdateAdmitPatientRequest,
  IUpdateAdmitPatientResponse,
} from "../../../models/ApiRequestResponse/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { get, post, put } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDoctorHospitalListApi = async (): Promise<
  IDoctorHospitalListResponse | GBSCError
> => {
  try {
    const response = await get<IDoctorHospitalListResponse>(
      APIEndpoint.doctor.doctorHospitalList
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDoctorHospitalPatientListApi = async (
  hospitalId: string,
  start_date?: string,
  end_date?: string,
  discharged?: boolean,
  room_id?: string,
  room_bed_id?: string,
  keyword?: string
): Promise<IHospitalPatientListResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.doctor.doctorHospitalPatientList}&hospital_id=${hospitalId}`;
    url += start_date ? `&start_date=${start_date}` : "";
    url += end_date ? `&end_date=${end_date}` : "";
    url += room_id ? `&room_id=${room_id}` : "";
    url += room_bed_id ? `&room_bed_id=${room_bed_id}` : "";
    url +=
      typeof discharged === "boolean"
        ? `&discharged=${discharged}`
        : keyword
        ? `&keyword=${keyword}`
        : "";
    const response = await get<IHospitalPatientListResponse>(`${url}`);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getHospitalRoomsListApi = async (
  hospitalId: string,
  available?: boolean
): Promise<IHospitalRoomsResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.doctor.hospitalRooms}?hospital_id=${hospitalId}`;
    url += available ? `&available=${available}` : "";
    const response = await get<IHospitalRoomsResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const admitPatientApi = async (
  requestBody: IAdmitPatientRequest
): Promise<IAdmitPatientResponse | GBSCError> => {
  try {
    const response = await post<IAdmitPatientResponse>(
      APIEndpoint.doctor.admitPatientOrDischarged,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getAdmittedPatientDetailApi = async (
  admitPatientId: string
): Promise<IAdmittedPatientResponse | GBSCError> => {
  try {
    const response = await get<IAdmittedPatientResponse>(
      `${APIEndpoint.doctor.admitPatientDetail}?id=${admitPatientId}`
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updateAdmitPatientApi = async (
  requestBody: IUpdateAdmitPatientRequest
): Promise<IUpdateAdmitPatientResponse | GBSCError> => {
  try {
    const response = await put<IUpdateAdmitPatientResponse>(
      APIEndpoint.doctor.admitPatientOrDischarged,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
