import React, { useState, useRef, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import ReactDOM from "react-dom";

import GetImages from "../../../components/GetImages";

import style from "./index.module.css";

import { storage } from "../../../utils/Storage";

import { IRoleType } from "../../../models/role";

import { useAppDispatch } from "../../../store/hooks";
import {
  setIsCameraOn,
  setIsCreateCameraPrescription,
  setIsPrescriptionCaptured,
} from "../../../store/slices/doctor/prescription";
import { setMedicalReport } from "../../../store/slices/doctor/medicalRecord";

const Camera: React.FC = () => {
  const [facingMode, setFacingMode] = useState<"user" | "environment">("user");
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const dispatch = useAppDispatch();

  const roleType = storage.getSessionToken("roleType");

  const startCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({
        video: {
          facingMode: { ideal: "environment" },
        },
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
    }
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context) {
        context.drawImage(
          videoRef.current,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        const dataUrl = canvasRef.current.toDataURL("image/png");
        setCapturedImage(dataUrl);
      }
    }
  };

  const backHandler = () => {
    stopCamera();
    dispatch(setIsCameraOn());
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  useEffect(() => {
    startCamera();
  }, [facingMode]);

  useEffect(() => {
    if (capturedImage && capturedImage.length > 0) {
      if (roleType === IRoleType.DOCTOR) {
        dispatch(
          setIsPrescriptionCaptured({
            capturedImage,
            isCapturedSave: false,
          })
        );
        dispatch(setIsCameraOn());
        dispatch(setIsCreateCameraPrescription());
      } else {
        dispatch(
          setMedicalReport({
            identifier: "isCapturedSave",
            value: true,
          })
        );
        dispatch(
          setMedicalReport({
            identifier: "image",
            value: capturedImage,
          })
        );
        dispatch(setIsCameraOn());
      }
    }
    stopCamera();
  }, [capturedImage]);

  return ReactDOM.createPortal(
    <Grid item className={style.container}>
      <Grid item className={style.arrowBackBtn} onClick={backHandler}>
        <GetImages name="ArrowLeftWhiteIcon" width="16" height="16" />
      </Grid>
      <Grid item className={style.cameraView}>
        <video ref={videoRef} className={style.videoFeed}></video>
        <canvas
          ref={canvasRef}
          width="328"
          height="584"
          className={style.hidden}
        ></canvas>
      </Grid>
      <Grid item className={style.imageCaptureContainer} onClick={captureImage}>
        <GetImages name="CameraCaptureIcon" width="44" height="44" />
      </Grid>
      <Grid item className={style.switchCamera}>
        <FlipCameraIosIcon
          sx={{ fontSize: "4.4rem", color: "var(--orange-500)" }}
          onClick={switchCamera}
        />
      </Grid>

      <Grid item className={style.bottomLine}></Grid>
    </Grid>,
    document.getElementById("portal-root") as HTMLElement
  );
};

export default Camera;
