import React from "react";

import { pushCallNotificationApi } from "../apis/doctor/appointment";

const useCallNotificationApi = () => {
  const handleIncomingCallNotification = async (
    bookingId: string,
    callerName: string,
    callingUserName: string,
    callingUserId: string
  ) => {
    try {
      const response = await pushCallNotificationApi({
        bookingId,
        callerName: callerName,
        callerPhoto: "photo_url",
        callingUserName,
        callingUserId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  return { handleIncomingCallNotification };
};

export default useCallNotificationApi;
