import React, { useState } from "react";

import { cancelScheduleAppointmentsApi } from "../apis/doctor/appointment";
import { IAPIStatusCode } from "../apis/APIEndpointConfig";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  fetchScheduleAppointmentList,
  resetScheduleSelection,
} from "../../store/slices/doctor/schedule";
import { setAppointmentDetail } from "../../store/slices/doctor/appointment";

import { INotifyEnum, notify } from "../toaster";
import { setVideoAppointmentDetail } from "../../store/slices/videoAppointment";

const useCancelScheduleAppointments = () => {
  const [isCancelAppointmentLoading, setCancelAppointmentLoading] =
    useState<boolean>(false);

  const { selectedSchedules, selectedAppointments } = useAppSelector(
    (state) => state.schedule.selection
  );
  const { appointmentDetail } = useAppSelector((state) => state.appointment);
  const dispatch = useAppDispatch();

  const cancelScheduleAppointments = async () => {
    setCancelAppointmentLoading(true);
    const cancelScheduleAppointmentsResponse =
      await cancelScheduleAppointmentsApi({
        schedules: selectedSchedules,
        appointments:
          appointmentDetail?.id?.length > 0
            ? [appointmentDetail?.id]
            : selectedAppointments,
      });
    if (
      cancelScheduleAppointmentsResponse.statusCode === IAPIStatusCode.SUCCESS
    ) {
      dispatch(fetchScheduleAppointmentList({}));
      dispatch(resetScheduleSelection());
      dispatch(setAppointmentDetail({}));
      dispatch(setVideoAppointmentDetail({}));
      notify(INotifyEnum.SUCCESS, cancelScheduleAppointmentsResponse.message);
      setCancelAppointmentLoading(false);
    } else {
      setCancelAppointmentLoading(false);
    }
  };

  return { cancelScheduleAppointments, isCancelAppointmentLoading };
};

export default useCancelScheduleAppointments;
