import React from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import style from "./index.module.css";

import GetImages from "../../GetImages";
import Loader from "../../Loader";
import DischargedPatientDetail from "../DischargedPatientDetail";
import AdmitPatientShortDetail from "../AdmitPatientShortDetail";

import useIsTablet from "../../../utils/hooks/useIsTablet";
import {
  calculateDayFromTargetDate,
  convertDateYYYYMMDD,
  transformClasses,
  transformDateToDDMMYYYY,
} from "../../../utils";
import useIPDashboardDateFilter from "../../../utils/hooks/useIPDashboardDateFilter";
import useIsMobile from "../../../utils/hooks/useIsMobile";

import {
  IAdmitPatientPopUpsEnums,
  IPDashboardPatientAdmitStatus,
} from "../../../models/doctor";
import { IHospitalPatient } from "../../../models/ApiRequestResponse/doctor";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAdmitPatientDetails,
  setOpenCloseAdmittedDischargeModal,
  setReAdmitPatient,
} from "../../../store/slices/doctor/ipDashboard";
import { setUniversalSearch } from "../../../store/slices/doctor/topNavigation";
import dayjs from "dayjs";

const IPDashboardCard: React.FC = () => {
  const { hospitalPatients, isLoading, admitPatientPopUps, patientDetail } =
    useAppSelector((state) => state.ipDashboard);
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const dispatch = useAppDispatch();

  const { isTablet } = useIsTablet();
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const { applyDateRangeValues } = useIPDashboardDateFilter();

  const openCloseAdmitPatientHandler = (type: IAdmitPatientPopUpsEnums) => {
    dispatch(setOpenCloseAdmittedDischargeModal(type));
  };

  const patientDetailHandler = (patientDetail: IHospitalPatient) => {
    dispatch(fetchAdmitPatientDetails({ admitPatientId: patientDetail?.id }));
    // if (!(isTablet || isMobile)) {
    dispatch(setReAdmitPatient(false));
    // if (location.pathname == "/search") {
    //   dispatch(setUniversalSearch(true));
    //   const today = new Date().toISOString();
    //   const startDate = convertDateYYYYMMDD(patientDetail?.admission_date);
    //   applyDateRangeValues(startDate, convertDateYYYYMMDD(today));
    //   navigate("/ip-dash", {
    //     state: {
    //       navItem: 1,
    //     },
    //   });
    // }
    // return;
    // }
    if (isMobile || isTablet) {
      openCloseAdmitPatientHandler(
        patientDetail?.status === IPDashboardPatientAdmitStatus.DISCHARGED
          ? IAdmitPatientPopUpsEnums.DISCHARGED
          : IAdmitPatientPopUpsEnums.ADMITTED
      );
    }
  };

  // if ((isTablet || isMobile) && isLoading) {
  //   return <Loader />;
  // }
  if (isMobile || isTablet) {
    return (
      <>
        {hospitalPatients?.map((patient: IHospitalPatient, index: number) => (
          <Grid
            item
            className={transformClasses(
              style.container,
              patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                ? style.dischargeContainer
                : "",
              !(isMobile || isTablet) && patient?.id === patientDetail?.id
                ? style.active
                : ""
            )}
            sx={{
              backgroundColor:
                patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                  ? "var(--warm-neutral-50)"
                  : "",
            }}
            key={index}
            onClick={() => patientDetailHandler(patient)}
          >
            <Grid item className={style.patientDetail}>
              <Typography component={"p"} className={style.patientName}>
                {patient?.name}
              </Typography>
              <Typography
                component={"p"}
                className={style.admittedDateDuration}
              >
                {patient.status === IPDashboardPatientAdmitStatus.ADMIT ? (
                  <>
                    <GetImages name="GreenDotIcon" width="8" height="6" />
                    {dayjs(patient?.admission_date).format("DD MMM")}
                    &nbsp;
                    {`(${calculateDayFromTargetDate(patient?.admission_date)})`}
                  </>
                ) : (
                  dayjs(patient?.discharged_date).format("DD MMM")
                )}
              </Typography>
            </Grid>
            <Grid item className={style.roomCaseHistory}>
              <Typography
                component={"p"}
                className={transformClasses(
                  style.roomNo,
                  patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                    ? style.dischargedPatient
                    : ""
                )}
                sx={{
                  backgroundColor:
                    patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                      ? "#FFE2BE"
                      : "",
                }}
              >
                {patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                  ? "Discharged"
                  : `${patient?.room_name} - Bed ${patient?.number}`}
              </Typography>

              <Typography component={"p"} className={style.caseHistory}>
                {patient?.case_history}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {admitPatientPopUps.isDischarged && (
          <DischargedPatientDetail
            isOpen={admitPatientPopUps.isDischarged}
            closeHandler={() =>
              openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.DISCHARGED)
            }
          />
        )}
        {admitPatientPopUps.isAdmitted && (
          <AdmitPatientShortDetail
            isOpen={admitPatientPopUps.isAdmitted}
            closeHandler={() =>
              openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.ADMITTED)
            }
          />
        )}
      </>
    );
  }

  return (
    <>
      {hospitalPatients?.map((patient: IHospitalPatient, index: number) => (
        <Grid
          item
          className={transformClasses(
            style.container,
            patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
              ? style.dischargeContainer
              : "",
            !(isMobile || isTablet) && patient?.id === patientDetail?.id
              ? style.active
              : ""
          )}
          sx={{
            backgroundColor:
              patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                ? "var(--warm-neutral-50)"
                : "",
          }}
          key={index}
          onClick={() => patientDetailHandler(patient)}
        >
          <Grid item className={style.patientDetail}>
            <Typography component={"p"} className={style.patientName}>
              {patient?.name}
            </Typography>
            <Typography component={"p"} className={style.admittedDateDuration}>
              {patient.status === IPDashboardPatientAdmitStatus.ADMIT ? (
                <>
                  <GetImages name="GreenDotIcon" width="8" height="6" />
                  {dayjs(patient?.admission_date).format("DD MMM")}
                  &nbsp;
                  {`(${calculateDayFromTargetDate(patient?.admission_date)})`}
                </>
              ) : (
                dayjs(patient?.discharged_date).format("DD MMM")
              )}
            </Typography>
          </Grid>
          <Grid item className={style.roomCaseHistory}>
            <Typography
              component={"p"}
              className={transformClasses(
                style.roomNo,
                patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                  ? style.dischargedPatient
                  : ""
              )}
              sx={{
                backgroundColor:
                  patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                    ? "#FFE2BE"
                    : "",
              }}
            >
              {patient.status === IPDashboardPatientAdmitStatus.DISCHARGED
                ? "Discharged"
                : `${patient?.room_name} - Bed ${patient?.number}`}
            </Typography>

            <Grid item display={"flex"} alignItems={"center"}>
              <Typography component={"p"} className={style.messageTime}>
                3:00 pm
              </Typography>
              <Grid item className={style.messageUnReadCount}>
                <Typography component={"p"}>3</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      {admitPatientPopUps.isDischarged && isShowUniversalSearch && (
        <DischargedPatientDetail
          isOpen={admitPatientPopUps.isDischarged}
          closeHandler={() =>
            openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.DISCHARGED)
          }
        />
      )}
      {admitPatientPopUps.isAdmitted && isShowUniversalSearch && (
        <AdmitPatientShortDetail
          isOpen={admitPatientPopUps.isAdmitted}
          closeHandler={() =>
            openCloseAdmitPatientHandler(IAdmitPatientPopUpsEnums.ADMITTED)
          }
        />
      )}
    </>
  );
};

export default IPDashboardCard;
