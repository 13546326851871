import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import SaveCancelTemplate from "../../SaveCancelTemplate";
import ChipMultiSelect from "../../../ChipMultiSelect";
import ChipSelect from "../../../ChipSelect";

import style from "../styles/index.module.css";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { addValuesToExistingMedicinesTest } from "../../../../store/slices/doctor/prescription";

import { IChipVariant } from "../../../../models/button";
import { ITitleInputValuesListEnums } from "../../../../models/doctor";
import { IMedicine } from "../../../../models/ApiRequestResponse/doctor";

interface IProps {
  selectedIndex: number;
  closeHandler: () => void;
  details: Partial<IMedicine>;
}
const DoseFrequency: React.FC<IProps> = (props) => {
  const { closeHandler, selectedIndex, details } = props;

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [duration, setDuration] = useState<string>("");
  const [daysFreq, setDaysFreq] = useState<string[]>([]);

  const dispatch = useAppDispatch();
  const { config } = useAppSelector((state) => state.prescription);

  const frequencyList = config?.frequency
    .split(",")
    .map((freq, index) => ({ value: freq, available: true }));

  const chipSelectData = config?.nextVisitType?.map((nextVisit: string) => ({
    label: nextVisit,
    value: nextVisit,
  }));

  const selectedMedicineFrequency = (selectedValues: string[]) => {
    const result: string[] = [];
    selectedValues.forEach((item, index) => {
      frequencyList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(val.value);
        }
      });
    });
    setDaysFreq(result);
  };

  const saveHandler = () => {
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequency",
        value: daysFreq.join(","),
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequencyDays",
        value: +duration,
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "frequencyText",
        value: `${daysFreq.join(",")} for ${duration} ${selectedValue}`,
        itemIndex: selectedIndex,
      })
    );
    closeHandler();
  };

  useEffect(() => {
    setDuration((details?.frequencyDays ?? "")?.toString());
    setDaysFreq((details.frequency ?? "")?.split(","));
  }, [details]);

  console.log(details);

  return (
    <Grid item className={style.container}>
      <Grid item className={style.doseFreqSelection}>
        <Grid item className={style.doseFreqeChipContainer}>
          <ChipMultiSelect
            value={daysFreq}
            list={frequencyList}
            selectedValuesList={selectedMedicineFrequency}
          />
        </Grid>

        <Grid item className={style.inputBtnContainer}>
          <input
            type="number"
            placeholder="Type here"
            value={duration}
            onChange={(event) => setDuration(event.target.value)}
            autoFocus
          />
          <Grid item className={style.nextVisitTypeSelector}>
            <ChipSelect
              variant={IChipVariant.DEFAULT}
              data={chipSelectData}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              muiclass={{
                border: "0.1rem solid var(--gray-200)",
                padding: "0.8rem 0rem",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={style.saveCancelActionContainer}>
        <SaveCancelTemplate
          saveHandler={saveHandler}
          cancelHandler={closeHandler}
          containerStyles={{
            backgroundColor: "var(--blue-gray-100)",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DoseFrequency;
