import { Grid, Stack, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { useState } from "react";

import style from "./index.module.css";

import { IProps } from "../..";

import CreateMedicineTestModal from "../../../CreateMedicineTestModal";

import { ITitleInputValuesListEnums } from "../../../../../models/doctor";

const Test: React.FC<IProps> = ({
  name,
  mealType,
  frequency,
  note,
  identifier,
  removeItemHandler,
}) => {
  const intl = useIntl();
  const [showCreateTestModal, setShowCreateTestModal] = useState(false);

  return (
    <>
      <Grid
        className={style.testCardContainer}
        onClick={() => setShowCreateTestModal(true)}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Typography className={style.testName}>{name}</Typography>
          <button
            className={style.removeTestBtn}
            onClick={(e) => {
              e.stopPropagation();
              removeItemHandler(identifier, name);
            }}
          >
            {intl.formatMessage({
              id: "remove",
              defaultMessage: "remove".toLowerCase(),
            })}
          </button>
        </Stack>
        {note && (
          <Grid className={style.noteContainer}>
            <Typography className={style.note}>{note}</Typography>
          </Grid>
        )}
      </Grid>

      {showCreateTestModal && (
        <CreateMedicineTestModal
          isOpen={showCreateTestModal}
          closeHandler={() => setShowCreateTestModal(false)}
          type={ITitleInputValuesListEnums.TEST}
        />
      )}
    </>
  );
};

export default Test;
