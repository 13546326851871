import React from "react";
import { Grid } from "@mui/material";

import style from "./index.module.css";

import GetImages from "../../GetImages";
import { ITitleInputValuesListEnums } from "../../../models/doctor";

interface IProps {
  name: string;
  chipIndex: number;
  identifier: ITitleInputValuesListEnums;
  removeItemHandler: (
    identifier: ITitleInputValuesListEnums,
    chipIndex: number,
    value?: string
  ) => void;
  isSelected: boolean;
  onSelect: () => void;
}

const DiagnosticChip: React.FC<IProps> = (props) => {
  const {
    name,
    chipIndex,
    identifier,
    removeItemHandler,
    isSelected,
    onSelect,
  } = props;

  return (
    <Grid
      item
      className={`${style.container} ${isSelected ? style.selected : ""}`}
      onClick={onSelect}
    >
      <Grid item className={style.labelWithTrash}>
        <Grid item>{name}</Grid>
        {isSelected && (
          <Grid
            item
            className={style.removeItem}
            onClick={(e) => {
              e.stopPropagation();
              removeItemHandler(identifier, chipIndex, name);
            }}
          >
            <GetImages name="TrashSmallIcon" width="16" height="16" />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DiagnosticChip;
