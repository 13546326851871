import React from "react";
import { Grid } from "@mui/material";

import style from "./index.module.css";

type SectionContainerProps = {
  children: React.ReactNode;
  isSection?: boolean;
};

const SectionContainer: React.FC<SectionContainerProps> = ({
  children,
  isSection = true,
}) => {
  return (
    <Grid
      sx={{
        pt: { xs: "1rem", sm: "2rem" },
        pb: { xs: "1.5rem", sm: "2rem" },
        mt: { sm: !isSection ? "4rem" : "0rem", md: "0rem " },
      }}
      className={`${style.sectionWrapper} ${!isSection ? style.section : ""}`}
    >
      {children}
    </Grid>
  );
};

export default SectionContainer;
