import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import styles from "./index.module.css";

import GetImages from "../../../../components/GetImages";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import ShareDialog from "../../VideoDetails/ShareDialog";

import {
  favouriteDiseaseApi,
  likeDiseaseApi,
} from "../../../../utils/apis/patient/healthHub";

type DiseaseActionsDesktopProps = {
  likesCount: number;
  isLiked?: boolean;
  isFavourited?: boolean;
  diseaseId: string;
};

const DiseaseActionsDesktop: React.FC<DiseaseActionsDesktopProps> = ({
  likesCount,
  isLiked,
  isFavourited,
  diseaseId,
}) => {
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDiseaseLiked, setIsDiseaseLiked] = useState(isLiked);
  const [isDiseaseFavourited, setIsDiseaseFavourited] = useState(isFavourited);
  const [likes, setLikes] = useState(likesCount);

  useEffect(() => {
    setIsDiseaseLiked(isLiked);
    setIsDiseaseFavourited(isFavourited);
  }, [diseaseId]);

  const handleLike = async () => {
    const response = await likeDiseaseApi(diseaseId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseLiked(true);
      setLikes(likes + 1);
    }
  };

  const handleUnlike = async () => {
    const response = await likeDiseaseApi(diseaseId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseLiked(false);
      setLikes(likes - 1);
    }
  };

  const handleFavourite = async () => {
    const response = await favouriteDiseaseApi(diseaseId);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseFavourited(true);
    }
  };

  const handleUnfavourite = async () => {
    const response = await favouriteDiseaseApi(diseaseId, true);
    if (response.statusCode === IAPIStatusCode.SUCCESS) {
      setIsDiseaseFavourited(false);
    }
  };

  const handleShare = () => {
    setIsDialogOpen(true);
  };

  const shareUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/health-hub/disease"
      : "https://gbsc-web-dev.appskeeper.in/health-hub/disease";

  return (
    <Grid className={styles.actionsContainer}>
      <Button
        variant="contained"
        className={`${styles.actionButton} ${
          isDiseaseLiked ? styles.liked : ""
        }`}
        onClick={isDiseaseLiked ? handleUnlike : handleLike}
      >
        <GetImages
          name="LikeVideoDesktopIcon"
          fill={isDiseaseLiked ? "white" : "#C6C6C6"}
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {likes === 0
            ? intl.formatMessage({ id: "like", defaultMessage: "Like" })
            : likes}
        </span>
      </Button>
      <Button
        variant="contained"
        className={`${styles.actionButton} ${
          isDiseaseFavourited ? styles.favourited : ""
        }`}
        onClick={isDiseaseFavourited ? handleUnfavourite : handleFavourite}
      >
        <GetImages
          fill={isDiseaseFavourited ? "white" : "#C6C6C6"}
          name="FavouriteVideoDesktopIcon"
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {intl.formatMessage({ id: "favourite", defaultMessage: "Favourite" })}
        </span>
      </Button>
      <Button
        variant="contained"
        className={styles.actionButton}
        onClick={handleShare}
      >
        <GetImages
          name="ShareVideoDesktopIcon"
          fill="#393939"
          height="20"
          width="20"
        />{" "}
        <span className={styles.actionText}>
          {intl.formatMessage({ id: "share", defaultMessage: "Share" })}
        </span>
      </Button>
      <ShareDialog
        url={`${shareUrl}?id=${diseaseId}`}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </Grid>
  );
};

export default DiseaseActionsDesktop;
