import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { devBaseUrl, devBasicAuth } from "./Config";

import { generateBasicAuthHeader } from "./auth";

import { storage } from "./Storage";

import {
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "./apis/APIEndpointConfig";

import { generateDeviceId } from ".";

import { generateTokenFCM } from "../firebaseConfig";

axios.defaults.baseURL = devBaseUrl;

interface HttpResponse<T> {
  type: IAPIStatusType.SUCCESS;
  data: T;
  axiosResponse: AxiosResponse<T>;
}

interface HttpError<T> {
  type: IAPIStatusType.ERROR;
  axiosError?: AxiosError<T>;
  exception?: any;
  errorMessage: string;
  message: string;
  statusCode: number;
}

function getAuthzHeaderConfig(): AxiosRequestConfig {
  const authHeader = generateBasicAuthHeader(
    devBasicAuth.username,
    devBasicAuth.password
  );
  const deviceId = generateDeviceId();
  generateTokenFCM()
    .then((res) => {
      storage.setSessionToken("deviceToken", res);
      return res;
    })
    .catch((err) => err);
  const keys = ["lang", "token", "deviceToken"];
  const [lang, token, deviceToken] = keys.map((key) =>
    storage.getSessionToken(key)
  );
  let config = {
    headers: {
      Authorization: authHeader,
      "Accept-Language": lang ?? "eng",
      timezone: "Asia/Kolkata",
      "device-id": deviceId ?? "device-id",
      "device-token": deviceToken ?? "device-token",
      "app-version": "app-version",
      "device-model": "device-model",
      platform: "3",
      ...{ "Access-Token": token ?? "" },
    },
  };
  return config;
}

async function httpCall<T>(
  makeCall: () => Promise<AxiosResponse<T, any>>
): Promise<HttpResponse<T> | HttpError<T>> {
  try {
    const axiosResponse = await makeCall();
    const response: HttpResponse<T> = {
      type: IAPIStatusType.SUCCESS,
      data: axiosResponse.data,
      axiosResponse: axiosResponse,
    };
    return response;
  } catch (error) {
    let message = "",
      errorMessage = "",
      statusCode = IAPIStatusCode.INTERNAL_SERVER_ERROR;

    if (axios.isAxiosError(error)) {
      message = error?.message;
      if (
        error.response &&
        error.response.data.message &&
        error.response.data.error &&
        error.response.data.statusCode
      ) {
        errorMessage = error.response.data.error;
        message = error.response.data.message;
        statusCode = error.response.data.statusCode;
      }

      const errorResponse: HttpError<T> = {
        type: IAPIStatusType.ERROR,
        axiosError: error,
        message,
        statusCode,
        errorMessage,
      };
      return errorResponse;
    }

    const errorResponse: HttpError<T> = {
      type: IAPIStatusType.ERROR,
      exception: error,
      errorMessage: messages.somethingWentWrong,
      message,
      statusCode,
    };
    return errorResponse;
  }
}

export async function get<T>(
  path: string,
  responseType?: "blob"
): Promise<HttpResponse<T> | HttpError<T>> {
  return httpCall(() => {
    const requestConfig = getAuthzHeaderConfig();
    if (responseType !== undefined) {
      requestConfig.responseType = responseType;
    }
    return axios.get<T>(path, requestConfig);
  });
}

export async function post<T>(
  path: string,
  body: any,
  unauthenticated: boolean = false
): Promise<HttpResponse<T> | HttpError<T>> {
  return httpCall(() => {
    return axios.post<T>(
      path,
      body,
      unauthenticated ? undefined : getAuthzHeaderConfig()
    );
  });
}

export async function patch<T>(
  path: string,
  body: any,
  unauthenticated: boolean = false
): Promise<HttpResponse<T> | HttpError<T>> {
  return httpCall(() => {
    return axios.patch<T>(
      path,
      body,
      unauthenticated ? undefined : getAuthzHeaderConfig()
    );
  });
}

export async function put<T>(
  path: string,
  body: any,
  unauthenticated: boolean = false
): Promise<HttpResponse<T> | HttpError<T>> {
  return httpCall(() => {
    return axios.put<T>(
      path,
      body,
      unauthenticated ? undefined : getAuthzHeaderConfig()
    );
  });
}

export async function deleteMethod<T>(
  path: string,
  body?: any
): Promise<HttpResponse<T> | HttpError<T>> {
  const headers = getAuthzHeaderConfig();
  return httpCall(() => {
    return axios.delete<T>(path, {
      data: body,
      ...headers,
    });
  });
}
