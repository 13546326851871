import { useAppDispatch } from "../../store/hooks";
import {
  fetchScheduleAppointmentList,
  setSelectedDate,
} from "../../store/slices/doctor/schedule";

const useAppointmentDateFilter = () => {
  const dispatch = useAppDispatch();

  const applyDateRangeValues = (startDate: string, endDate: string) => {
    const filterOptions = {
      start: startDate,
      end: endDate,
    };
    dispatch(
      setSelectedDate({
        startDate: startDate,
        endDate: endDate,
      })
    );
    dispatch(fetchScheduleAppointmentList(filterOptions));
  };

  return { applyDateRangeValues };
};

export default useAppointmentDateFilter;
