import React from "react";
import { updateAdmitPatientApi } from "../apis/doctor/ipDashboard";

import { INotifyEnum, notify } from "../toaster";

import {
  fetchDoctorHospitalPatientListing,
  resetAdmitPatientDetail,
} from "../../store/slices/doctor/ipDashboard";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getTodayTomorrowDate } from "..";

import { IUpdateAdmitPatientRequest } from "../../models/ApiRequestResponse/doctor";
import { IPDashboardPatientAdmitStatus } from "../../models/doctor";

const useUpdateAdmitPatient = () => {
  const dispatch = useAppDispatch();
  const { selectedHospitalId, selectRoomId } = useAppSelector(
    (state) => state.ipDashboard
  );

  const updateAdmitPatientHandler = async (
    requedBody: IUpdateAdmitPatientRequest
  ) => {
    const reAdmitPatientResponse = await updateAdmitPatientApi(requedBody);
    if ("result" in reAdmitPatientResponse) {
      notify(INotifyEnum.SUCCESS, reAdmitPatientResponse.message);
      const { today } = getTodayTomorrowDate();
      dispatch(
        fetchDoctorHospitalPatientListing({
          hospitalId: selectedHospitalId,
          end_date: today,
          ...(selectRoomId !== "1" && { room_id: selectRoomId }),
        })
      );
      if (requedBody.status === IPDashboardPatientAdmitStatus.DISCHARGED) {
        dispatch(resetAdmitPatientDetail());
      }
      return;
    }
    notify(INotifyEnum.ERROR, reAdmitPatientResponse.message);
  };
  return { updateAdmitPatientHandler };
};

export default useUpdateAdmitPatient;
