import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import GetImages from "../../../GetImages";

import style from "../index.module.css";

import {
  timeInAmPmFromDateTime,
  transformDateToDDMMYYYY,
} from "../../../../utils";

import { IDoctorScheduleConsultationType } from "../../../../models/doctor";

interface IProps {
  slotNo?: number;
  startTime: string;
  endTime?: string;
  slotDuration: number;
  type: IDoctorScheduleConsultationType;
  clinicName?: string;
}
const AppointmentTimeDetail: React.FC<IProps> = (props) => {
  const { slotNo, startTime, slotDuration, type, clinicName, endTime } = props;
  return (
    <Grid item>
      {type === IDoctorScheduleConsultationType.VIDEO_CALL ? (
        <Typography component={"p"} className={style.videoTimeDetail}>
          <GetImages name={"VideoAppointmentIcon"} width="18" height="16" />
          {slotDuration} <FormattedMessage id="mins" defaultMessage="Mins" /> •{" "}
          {timeInAmPmFromDateTime(startTime)},{" "}
          {transformDateToDDMMYYYY(startTime)}
        </Typography>
      ) : (
        <Grid item>
          <Typography component={"p"} className={style.videoTimeDetail}>
            <GetImages
              name={"ScheduleSelectedSmallIcon"}
              width="18"
              height="16"
            />
            <FormattedMessage id="slot_no" defaultMessage="Slot No." /> {slotNo}
            , {transformDateToDDMMYYYY(startTime)},{" "}
            {timeInAmPmFromDateTime(startTime)}-
            {timeInAmPmFromDateTime(endTime!)}
          </Typography>
          <Typography component={"p"} className={style.videoTimeDetail}>
            <GetImages name={"ClinicSmallIcon"} width="18" height="16" />
            {clinicName}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AppointmentTimeDetail;
