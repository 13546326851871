import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AvailabilityListCard from "../../../components/AvailabilityListCard";
import ChipSelect from "../../../components/ChipSelect";
import NotFoundRecord from "../../../components/NotFoundRecord";
import Loader from "../../../components/Loader";
import CreateAction from "../../../components/CreateAction";
import PermissionWrapper from "../../../components/PermissionWrapper";

import style from "./index.module.css";

import { IChipVariant } from "../../../models/button";
import { IDays } from "../../../models/doctor";
import { IScheduleAvailabilitySetting } from "../../../models/ApiRequestResponse/doctor";
import {
  IAvailabilitySettingPermissionEnums,
  IModalKeyEnums,
} from "../../../models/permission";

import { dayFilterListing, getAvailabilityListByDay } from "../../../utils";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAvailabilitySettings } from "../../../store/slices/doctor/availabilitySettings";

const AvailabilitySettings: React.FC = () => {
  const [availabilityFromFilter, setAvailabilityFromFilter] = useState<{
    isFilter: boolean;
    data: IScheduleAvailabilitySetting[];
  }>({
    isFilter: false,
    data: [],
  });
  const [selectedFilterDay, setSelectedFilterDay] = useState<any>(
    dayFilterListing[0].value
  );

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const dispatch = useAppDispatch();
  const availabilitySettingInfo = useAppSelector((state) => state.availability);

  const navigate = useNavigate();

  const moveToCreateAvailablity = () => {
    if (!(isMobile || isTablet)) {
      return;
    }
    navigate("/availability-create");
  };

  const handleAvailabilityFilteration = (day: IDays) => {
    const filteredAvailability = getAvailabilityListByDay(
      availabilitySettingInfo.list,
      day
    );
    setAvailabilityFromFilter({ isFilter: true, data: filteredAvailability });
  };

  const AvailabilityListing = useCallback(
    (availabilityList: IScheduleAvailabilitySetting[]) => {
      return (
        <Grid item>
          <Grid item className={style.daysFilterContainer}>
            <ChipSelect
              data={dayFilterListing}
              selectedValue={selectedFilterDay}
              setSelectedValue={setSelectedFilterDay}
              variant={IChipVariant.ROUNDED}
              showCarousel={isMobile || isTablet}
              showClearBtn={true}
            />
          </Grid>
          <PermissionWrapper
            moduleKey={IModalKeyEnums.AVAILABILITY_SETTING}
            featureKey={
              IAvailabilitySettingPermissionEnums.GET_AVAILABILITY_LIST
            }
          >
            {availabilityList.length > 0 ? (
              <>
                <Box
                  sx={{
                    ...(!isMobile &&
                      !isTablet && {
                        maxHeight: "43rem",
                        borderRadius: "1.2rem",
                      }),
                    overflowY: "auto",
                  }}
                >
                  {availabilityList.map((list, index) => (
                    <AvailabilityListCard {...list} key={index} />
                  ))}
                </Box>
              </>
            ) : (
              <NotFoundRecord
                heading="add_your_availability"
                tapHandler={moveToCreateAvailablity}
              />
            )}
          </PermissionWrapper>
          {(isMobile || isTablet) && (
            <CreateAction createHandler={moveToCreateAvailablity} />
          )}
        </Grid>
      );
    },
    [availabilitySettingInfo.list, availabilityFromFilter]
  );

  useEffect(() => {
    if (availabilitySettingInfo.list.length === 0) {
      dispatch(fetchAvailabilitySettings());
    }
  }, []);

  useEffect(() => {
    if (selectedFilterDay !== "All") {
      handleAvailabilityFilteration(selectedFilterDay);
    } else {
      setAvailabilityFromFilter({ isFilter: false, data: [] });
    }
  }, [selectedFilterDay]);

  if (availabilitySettingInfo.isLoading) {
    return <Loader />;
  }

  return (
    <>
      {availabilityFromFilter.isFilter
        ? AvailabilityListing(availabilityFromFilter.data)
        : AvailabilityListing(availabilitySettingInfo.list)}
    </>
  );
};

export default AvailabilitySettings;
