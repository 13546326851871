import { lazy } from "react";

const Onboarding = lazy(() => import("../../views/Onboarding"));
const TermsAndConditions = lazy(
  () => import("../../views/Onboarding/TermsAndConditions")
);

const OnboardingRoutes = [
  {
    path: "/",
    component: <Onboarding />,
  },
  {
    path: "/terms-conditions",
    component: <TermsAndConditions />,
  },
];

export default OnboardingRoutes;
