import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  Grid,
  Typography,
  Dialog as MUIDialog,
  Stack,
  DialogContent,
  Box,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import Modal from "../Modal";
import DateSelectionTab from "../DateSelectionTab";
import GetImages from "../GetImages";
import Button from "../Button";
import ChipSelect from "../ChipSelect";
import {
  AppointmentTab,
  AppointmentTabsPanel,
  AppointmentTabPanel,
} from "../AppointmentTab";
import Checkout from "../../views/Patient/Checkout";

import {
  IAppointmetEnums,
  IDoctorAvailabilityPlatform,
  IMakeAppointmentEvent,
  IPatientAppointmentStatus,
} from "../../models/doctor";
import {
  IClinicAppointmentSegment,
  IVideoAppointment,
} from "../../models/ApiRequestResponse/doctor";
import { IRoleType } from "../../models/role";
import { IFilterType } from "../../models/patient";
import {
  IRolePermissionAccess,
  IScheduleSlotsPermissionEnums,
  IModalKeyEnums,
} from "../../models/permission";
import { IButtonVariant } from "../../models/button";

import { storage } from "../../utils/Storage";
import {
  clinicAppointmentSegregation,
  getAppointmentSlotDetail,
  getNextSixDates,
  getPermissionAccess,
  isClinic,
  openClinicMapLocation,
  timeInAmPmFormat,
} from "../../utils";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useIsTablet from "../../utils/hooks/useIsTablet";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  appointmentDetails,
  fetchScheduleSlots,
  makePatientAppointment,
} from "../../store/slices/doctor/appointment";
import { checkoutDetail } from "../../store/slices/patient/findDoctor";
import { setShowPermissionAlert } from "../../store/slices/permission";

// Enable custom format parsing
dayjs.extend(customParseFormat);

interface IProps {
  doctorId?: string;
  closeScheduleModal: () => void;
  openScheduleModalHandler: (type: IAppointmetEnums) => void;
  isOpen: boolean;
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  platform?: IDoctorAvailabilityPlatform;
  isReschedule?: boolean;
}

const MakeAppointment: React.FC<IProps> = (props) => {
  const {
    doctorId,
    closeScheduleModal,
    openScheduleModalHandler,
    isOpen,
    selectedTab,
    setSelectedTab,
    platform = IDoctorAvailabilityPlatform.BOTH,
    isReschedule = false,
  } = props;

  const [selectedDateTab, setSelectedDateTab] = useState<number>(0);
  const [appointmentType, setAppointmentType] =
    useState<IDoctorAvailabilityPlatform>(
      IDoctorAvailabilityPlatform.VIDEO_CALL
    );
  const [sixDays, setSixDays] = useState<
    { date: string; day: string; fullDate: string }[]
  >([]);
  const [slotsDetail, setSlotsDetail] = useState<{
    videoAppointment: IVideoAppointment[];
    clinicAppointment: IClinicAppointmentSegment[];
  }>({ videoAppointment: [], clinicAppointment: [] });
  const [selectedAppointmentSlot, setSelectedAppointmentSlot] =
    useState<string>("");
  const [isCheckoutShowDesktop, setIsCheckoutShowDesktop] =
    useState<boolean>(false);

  const roleType = storage.getSessionToken("roleType");
  const isPatient = roleType === IRoleType.PATIENT;
  const isMobileCheck = !isPatient && !isReschedule;
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const doctor_name = storage.getSessionToken("doctor_name");
  const isClinicRole = isClinic();
  const dispatch = useAppDispatch();
  const checkoutDetailInfo = useAppSelector(
    (state) => state.findDoctor.checkoutPatientDetail
  );
  const makePatientAppointmentInfo = useAppSelector(
    (state) => state.appointment.makePatientAppointment
  );
  const scheduleSlots = useAppSelector((state) => state.appointment);
  const { permission } = useAppSelector((state) => state.rolePermission);
  const appointmentDetail = useAppSelector(appointmentDetails);

  const filterScheduleSlotsByDate = (type: IDoctorAvailabilityPlatform) => {
    if (Object.keys(scheduleSlots.slot).length === 0) return [];

    const filteredSlotsDateWise: any = scheduleSlots?.slot[
      type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? "videoAppointment"
        : "clinicAppointment"
    ].filter((slots: any, index: number) => {
      const dateMonth = dayjs(slots.date, "DD-MM-YYYY").format("DD MMM");

      if (dateMonth === nextSixDays?.[selectedDateTab]?.date || "") {
        return slots;
      }
    });
    setSlotsDetail({
      ...slotsDetail,
      ...(type === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? { videoAppointment: filteredSlotsDateWise }
        : {
            clinicAppointment: clinicAppointmentSegregation(
              filteredSlotsDateWise
            ),
          }),
    });
  };

  const dateSelectionHandler = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setSelectedDateTab(newValue);
    },
    [appointmentType, selectedDateTab]
  );

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setAppointmentType(
      newValue === IMakeAppointmentEvent.VIDEO_CALL
        ? IDoctorAvailabilityPlatform.VIDEO_CALL
        : IDoctorAvailabilityPlatform.IN_CLINIC
    );
    setSelectedAppointmentSlot("");
    setSelectedTab(newValue);
  };

  const dispatchActionSlotSelection = () => {
    const appointmentSlotDetail = getAppointmentSlotDetail(
      selectedTab,
      slotsDetail,
      selectedAppointmentSlot,
      nextSixDays,
      selectedDateTab
    );
    const dateSlotTime = `${appointmentSlotDetail?.date} ${appointmentSlotDetail?.startTime} - ${appointmentSlotDetail?.endTime}`;
    const isVideoCall = selectedTab === IMakeAppointmentEvent.VIDEO_CALL;
    const platformType = isVideoCall
      ? IDoctorAvailabilityPlatform.VIDEO_CALL
      : IDoctorAvailabilityPlatform.IN_CLINIC;
    const commonDetails = {
      dateSlotTime,
      type: platformType,
      slotId: selectedAppointmentSlot,
      ...(platformType === IDoctorAvailabilityPlatform.IN_CLINIC && {
        clinicId: appointmentSlotDetail?.clinicId,
        clinicName: `${appointmentSlotDetail?.clinicName}, ${appointmentSlotDetail?.clinicAddress}, ${appointmentSlotDetail?.clinicCity}`,
      }),
      fee: appointmentSlotDetail?.consultationFee ?? 0,
    };
    if (roleType === IRoleType.PATIENT) {
      dispatch(
        checkoutDetail({
          ...checkoutDetailInfo,
          ...commonDetails,
        })
      );
    } else {
      if (selectedAppointmentSlot?.length > 0) {
        dispatch(
          makePatientAppointment({
            ...makePatientAppointmentInfo,
            ...commonDetails,
          })
        );
      }
    }
  };

  const MakeAppointmentScreen = () => (
    <Grid container className={style.container}>
      <Grid item xs={12}>
        <AppointmentTab value={selectedTab} index={0}>
          <Typography component={"p"} className={style.scheduleTitle}>
            <FormattedMessage
              id={isReschedule ? "select_slot_reschedule" : "book_appointment"}
              defaultMessage={
                isReschedule
                  ? "Select Slot to Reschedule"
                  : "Book an appointment"
              }
            />
          </Typography>
        </AppointmentTab>
      </Grid>
      <Grid item xs={12}>
        <AppointmentTab value={selectedTab} index={1}>
          <Typography component={"p"} className={style.scheduleTitle}>
            <FormattedMessage
              id={isReschedule ? "select_slot_reschedule" : "make_appointment"}
              defaultMessage={
                isReschedule
                  ? "Select Slot to Reschedule"
                  : "Make an appointment"
              }
            />
          </Typography>
        </AppointmentTab>
      </Grid>
      <Grid item xs={12}>
        {platform === IDoctorAvailabilityPlatform.BOTH && (
          <AppointmentTabsPanel
            value={selectedTab}
            onChange={handleAppointmentTab}
            aria-label="basic tabs example"
            className={style.tabBtnContainer}
          >
            <AppointmentTabPanel
              label={
                <Grid item className={style.tabInnerContainer}>
                  <GetImages
                    name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="video_call"
                      defaultMessage="Video Call"
                    />
                  </Typography>
                </Grid>
              }
            />
            <AppointmentTabPanel
              label={
                <Grid item className={style.tabInnerContainer}>
                  <GetImages
                    name={selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="in_clinic"
                      defaultMessage="In-Clinic"
                    />
                  </Typography>
                </Grid>
              }
            />
          </AppointmentTabsPanel>
        )}
        <AppointmentTab value={selectedTab} index={0}>
          <Grid item className={style.dateSelectionContainer}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={dateSelectionHandler}
            />
          </Grid>
          {slotsDetail.videoAppointment.length > 0 ? (
            <Grid item className={style.videoCallAppointInnerContainer}>
              <Typography component={"p"} className={style.slotFeeText}>
                <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
                <Typography component={"span"} className={style.slotPrice}>
                  ₹{slotsDetail.videoAppointment[0]?.slots[0]?.consultationFee}
                </Typography>
              </Typography>
              <Grid
                item
                className={style.slotsContainer}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(3,1fr)",
                    sm: "repeat(6,1fr)",
                  },
                  gap: "1.4rem",
                }}
              >
                {slotsDetail.videoAppointment[0].slots.map((slot, index) => (
                  <ChipSelect
                    key={index}
                    data={[
                      {
                        label: timeInAmPmFormat(slot.slotStartTime),
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                  />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
              <FormattedMessage
                id="no_slot_available"
                defaultMessage="No Slot Available"
              />
            </Typography>
          )}
        </AppointmentTab>
        <AppointmentTab value={selectedTab} index={1}>
          <Grid item className={style.dateSelectionContainer}>
            <DateSelectionTab
              data={nextSixDays}
              selectedDateTab={selectedDateTab}
              dateSelectionHandler={dateSelectionHandler}
            />
          </Grid>
          {slotsDetail?.clinicAppointment?.length > 0 ? (
            <Grid item className={style.inClinicContainer}>
              {slotsDetail?.clinicAppointment[0]?.slots?.map(
                (info: any, index) => (
                  <Grid
                    item
                    key={`${info.clinicName}-${index}`}
                    className={style.clinicInfoWrapper}
                  >
                    <Grid item className={style.clinicDetailContainer}>
                      <Grid item>
                        <Typography
                          component={"h6"}
                          className={style.clinicName}
                        >
                          {info.clinicName}
                        </Typography>
                        <Typography
                          component={"p"}
                          className={style.priceBranch}
                        >
                          ₹{info.consultationFee} • {info.clinicAddress},{" "}
                          {info.clinicCity}
                        </Typography>
                      </Grid>
                      {roleType === IRoleType.PATIENT && (
                        <Grid
                          item
                          className={style.directionIcon}
                          onClick={() =>
                            openClinicMapLocation(info.latitude, info.longitude)
                          }
                        >
                          <GetImages
                            name="LocationAccessSmallIcon"
                            width="20"
                            height="20"
                          />
                          <Typography component={"p"} className={style.mapText}>
                            <FormattedMessage id="maps" defaultMessage="Maps" />
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(2,1fr)",
                          sm: "repeat(4,1fr)",
                        },
                        gap: "0.8rem",
                      }}
                    >
                      {info.slots.map((slot: any) => (
                        <ChipSelect
                          data={[
                            {
                              label: `${timeInAmPmFormat(
                                slot.slotStartTime
                              )} - ${timeInAmPmFormat(slot.slotEndTime)}`,
                              value: slot.slotId,
                              isBooked: slot.isBooked,
                            },
                          ]}
                          selectedValue={selectedAppointmentSlot}
                          setSelectedValue={setSelectedAppointmentSlot}
                          showSlots={true}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
              <FormattedMessage
                id="no_slot_available"
                defaultMessage="No Slot Available"
              />
            </Typography>
          )}
        </AppointmentTab>
      </Grid>
      <Grid item xs={12} className={style.actionBtnsContainer}>
        <Button
          disabled={!selectedAppointmentSlot}
          btnTrigger={
            isPatient
              ? () => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)
              : isMobileCheck
              ? () => openScheduleModalHandler(IAppointmetEnums.MOBILE_CHECK)
              : isReschedule
              ? () => openScheduleModalHandler(IAppointmetEnums.SUCCESS)
              : undefined
          }
        >
          {isPatient ? (
            <>
              <GetImages name="ChekoutBtnIcon" width="28" height="20" />
              <FormattedMessage
                id="goto_checkout"
                defaultMessage="Go to Checkout"
              />
            </>
          ) : isReschedule ? (
            <FormattedMessage id="reserve" defaultMessage="Reserve" />
          ) : (
            <FormattedMessage id="next" defaultMessage="Next" />
          )}
        </Button>
      </Grid>
    </Grid>
  );

  const MakeAppointmentScreenWeb = () => (
    <MUIDialog
      open={isOpen}
      onClose={closeScheduleModal}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          maxWidth: "65.6rem",
        },
      }}
      scroll="paper"
    >
      <DialogContent>
        {scheduleSlots.isLoading ? (
          <Grid item xs={12}>
            <Stack alignItems={"center"}>
              <CircularProgress sx={{ color: "var(--orange-500)" }} />
            </Stack>
          </Grid>
        ) : (
          <Stack justifyContent={"center"} alignItems={"center"} gap={3}>
            <Typography variant="h5" fontWeight={500}>
              <FormattedMessage
                id={
                  isReschedule ? "select_slot_reschedule" : "book_appointment"
                }
                defaultMessage={
                  isReschedule
                    ? "Select Slot to Reschedule"
                    : "Book an appointment"
                }
              />
            </Typography>
            {isClinicRole && (
              <Grid container className={style.clinic_dr}>
                <GetImages name="DoctorOrangeIcon" width="16" height="16" />

                <Typography className={style.clinic_dr_name}>
                  Dr {doctor_name}
                </Typography>
              </Grid>
            )}
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              {platform === IDoctorAvailabilityPlatform.BOTH && (
                <Grid item xs={8}>
                  <AppointmentTabsPanel
                    value={selectedTab}
                    onChange={handleAppointmentTab}
                    aria-label="basic tabs example"
                    className={style.tabBtnContainer}
                  >
                    <AppointmentTabPanel
                      label={
                        <Grid item className={style.tabInnerContainer}>
                          <GetImages
                            name={selectedTab ? "VideoIcon" : "VideoWhiteIcon"}
                            width="20"
                            height="20"
                          />
                          <Typography component={"p"}>
                            <FormattedMessage
                              id="video_call"
                              defaultMessage="Video Call"
                            />
                          </Typography>
                        </Grid>
                      }
                    />
                    <AppointmentTabPanel
                      label={
                        <Grid item className={style.tabInnerContainer}>
                          <GetImages
                            name={
                              selectedTab ? "ClinicWhiteIcon" : "ClinicIcon"
                            }
                            width="20"
                            height="20"
                          />
                          <Typography component={"p"}>
                            <FormattedMessage
                              id="in_clinic"
                              defaultMessage="In-Clinic"
                            />
                          </Typography>
                        </Grid>
                      }
                    />
                  </AppointmentTabsPanel>
                </Grid>
              )}
              <Grid item xs={12} px={4}>
                <Box bgcolor={"white"} borderRadius={4}>
                  <DateSelectionTab
                    data={nextSixDays}
                    selectedDateTab={selectedDateTab}
                    dateSelectionHandler={dateSelectionHandler}
                  />
                  {appointmentSlots}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Stack direction={"row"} gap={4}>
                  <Button
                    btnTrigger={closeScheduleModal}
                    variant={IButtonVariant.WHITE}
                  >
                    <FormattedMessage id="cancel" defaultMessage={"Cancel"} />
                  </Button>
                  <Button
                    disabled={!selectedAppointmentSlot}
                    btnTrigger={
                      isPatient
                        ? () => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)
                        : isMobileCheck
                        ? () =>
                            openScheduleModalHandler(
                              IAppointmetEnums.MOBILE_CHECK
                            )
                        : isReschedule
                        ? () =>
                            openScheduleModalHandler(IAppointmetEnums.SUCCESS)
                        : undefined
                    }
                  >
                    {isPatient ? (
                      <>
                        <GetImages
                          name="ChekoutBtnIcon"
                          width="28"
                          height="20"
                        />
                        <FormattedMessage
                          id="goto_checkout"
                          defaultMessage="Go to Checkout"
                        />
                      </>
                    ) : isReschedule ? (
                      <FormattedMessage id="reserve" defaultMessage="Reserve" />
                    ) : (
                      <FormattedMessage id="next" defaultMessage="Next" />
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        )}
      </DialogContent>
    </MUIDialog>
  );

  const nextSixDays = useMemo(() => {
    const appointments =
      appointmentType === IDoctorAvailabilityPlatform.VIDEO_CALL
        ? scheduleSlots?.slot?.videoAppointment
        : scheduleSlots?.slot?.clinicAppointment;

    const mergedDates = sixDays?.map((item) => {
      // Find matching date in secondArray
      const matchingSlot = appointments?.find((slot) => {
        const parsedDate1 = dayjs(slot.date, "DD-MM-YYYY");
        const parsedDate2 = dayjs(item.fullDate, "YYYY MM DD");

        return parsedDate1.isSame(parsedDate2, "day");
      });

      return {
        date: item.date,
        day: item.day,
        isAvailable: matchingSlot ? matchingSlot.slots.length > 0 : false,
      };
    });

    return mergedDates;
  }, [scheduleSlots, appointmentType, sixDays]);

  const appointmentSlots = useMemo(() => {
    if (
      appointmentType === IDoctorAvailabilityPlatform.VIDEO_CALL &&
      slotsDetail?.videoAppointment?.length > 0
    ) {
      return (
        <Stack width={"100%"}>
          <Typography
            textAlign={"center"}
            component={"p"}
            className={style.slotFeeText}
          >
            <FormattedMessage id="slot_fee" defaultMessage="Slot fee" />
            <Typography component={"span"} className={style.slotPrice}>
              ₹{slotsDetail.videoAppointment[0]?.slots[0]?.consultationFee}
            </Typography>
          </Typography>
          <Grid
            container
            rowGap={2}
            sx={{
              overflowY: "scroll",
              maxHeight: "30vh",
              pb: 1,
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {slotsDetail?.videoAppointment[0]?.slots?.map((slot) => (
              <Grid item md={3} key={slot.slotId}>
                <Stack alignItems={"center"}>
                  <ChipSelect
                    data={[
                      {
                        label: timeInAmPmFormat(slot.slotStartTime),
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      );
    }

    if (
      appointmentType === IDoctorAvailabilityPlatform.IN_CLINIC &&
      slotsDetail?.clinicAppointment?.length > 0
    ) {
      return (
        <Grid item className={style.inClinicContainer}>
          {slotsDetail?.clinicAppointment[0]?.slots?.map((info, index) => (
            <Grid
              item
              key={`${info.clinicName}-${index}`}
              className={style.clinicInfoWrapper}
            >
              <Grid item className={style.clinicDetailContainer}>
                <Grid item>
                  <Typography component={"h6"} className={style.clinicName}>
                    {info.clinicName}
                  </Typography>
                  <Typography component={"p"} className={style.priceBranch}>
                    ₹{info.consultationFee} • {info.clinicAddress},{" "}
                    {info.clinicCity}
                  </Typography>
                </Grid>
                {roleType === IRoleType.PATIENT && (
                  <Grid
                    item
                    className={style.directionIcon}
                    onClick={() =>
                      openClinicMapLocation(info.latitude, info.longitude)
                    }
                  >
                    <GetImages
                      name="LocationAccessSmallIcon"
                      width="20"
                      height="20"
                    />
                    <Typography component={"p"} className={style.mapText}>
                      <FormattedMessage id="maps" defaultMessage="Maps" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3,1fr)",
                  gap: "0.8rem",
                }}
              >
                {info.slots.map((slot) => (
                  <ChipSelect
                    key={slot.slotId}
                    data={[
                      {
                        label: `${timeInAmPmFormat(
                          slot.slotStartTime
                        )} - ${timeInAmPmFormat(slot.slotEndTime)}`,
                        value: slot.slotId,
                        isBooked: slot.isBooked,
                      },
                    ]}
                    selectedValue={selectedAppointmentSlot}
                    setSelectedValue={setSelectedAppointmentSlot}
                    showSlots={true}
                    muiclass={{
                      px: 0,
                    }}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      );
    }

    return (
      <Typography component={"h1"} align="center" padding={"4rem 0rem"}>
        <FormattedMessage
          id="no_slot_available"
          defaultMessage="No Slot Available"
        />
      </Typography>
    );
  }, [appointmentType, slotsDetail, selectedAppointmentSlot]);

  useEffect(() => {
    if (
      roleType === IRoleType.DOCTOR ||
      roleType === IRoleType.JR_DOCTOR ||
      roleType === IRoleType.PHARMACY
    ) {
      const permissionAccess = getPermissionAccess(
        IModalKeyEnums.SCHEDULE_SLOTS,
        IScheduleSlotsPermissionEnums.VIEW_SCHEDULE_SLOT_BY_DOCTOR,
        permission
      );
      if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
        dispatch(setShowPermissionAlert());
        return;
      }
      if (appointmentDetail?.status === IPatientAppointmentStatus.CANCELLED) {
        dispatch(
          fetchScheduleSlots({ clinicId: appointmentDetail?.clinic_id! })
        );
      } else {
        dispatch(fetchScheduleSlots({}));
      }
    } else if (roleType === IRoleType.PATIENT) {
      dispatch(fetchScheduleSlots({ doctorId }));
    }
    setSixDays(getNextSixDates(6));
  }, []);

  useEffect(() => {
    if (
      appointmentType ||
      checkoutDetailInfo?.type ||
      makePatientAppointmentInfo?.type
    )
      filterScheduleSlotsByDate(
        appointmentType ||
          checkoutDetailInfo?.type ||
          makePatientAppointmentInfo?.type
      );
  }, [
    appointmentType,
    selectedTab,
    selectedDateTab,
    scheduleSlots,
    nextSixDays,
  ]);

  useEffect(() => {
    dispatchActionSlotSelection();
    setAppointmentType(
      selectedTab === IMakeAppointmentEvent.VIDEO_CALL
        ? IDoctorAvailabilityPlatform.VIDEO_CALL
        : IDoctorAvailabilityPlatform.IN_CLINIC
    );
  }, [selectedAppointmentSlot, selectedDateTab, selectedTab]);

  return (
    <>
      {isMobile || isTablet ? (
        <Modal isOpen={isOpen} closeHandler={closeScheduleModal}>
          {MakeAppointmentScreen()}
        </Modal>
      ) : (
        MakeAppointmentScreenWeb()
      )}
      {isCheckoutShowDesktop && (
        <Checkout
          isOpen={isCheckoutShowDesktop}
          closeHandler={() => setIsCheckoutShowDesktop(!isCheckoutShowDesktop)}
          type={
            selectedTab === 0 ? IFilterType.VIDEO_CALL : IFilterType.IN_CLINIC
          }
        />
      )}
    </>
  );
};

export default MakeAppointment;
