import { Grid } from "@mui/material";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { handleIsInfiniteScrollFilterChanges } from "../../store/slices/config";

type Props = {
  list: any[];
  direction?: "row" | "column";
  onLoadMore: () => void;
  muiClasses?: any;
} & PropsWithChildren;

const ListWithIntersection = ({
  list,
  direction = "column",
  onLoadMore,
  children,
  muiClasses,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isInfiniteScrollFilterChanges } = useAppSelector(
    (state) => state.config
  );

  const targetRef = useRef<HTMLDivElement>(null);
  const observedItems = useRef(new Set<string>()); // Track observed items
  const observerRef = useRef<IntersectionObserver | null>(null); // Store the observer instance

  useEffect(() => {
    if (isInfiniteScrollFilterChanges) {
      if (observerRef.current) {
        observedItems.current.clear(); // Reset observed items
      }
      dispatch(handleIsInfiniteScrollFilterChanges());
    }
  }, [isInfiniteScrollFilterChanges]);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect(); // Cleanup the previous observer
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const itemKey = (entry.target as HTMLElement).dataset.key;

            if (itemKey && !observedItems.current.has(itemKey)) {
              observedItems.current.add(itemKey); // Mark this item as observed
              onLoadMore();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    const items = Array.from(targetRef?.current?.children ?? []);
    const lastItem = items.at(-1); // Only observe the last item

    if (lastItem) {
      observerRef.current.observe(lastItem);
    }

    // Cleanup observer on unmount or when list changes
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        // observedItems.current.clear(); // Reset observed items
      }
    };
  }, [list]);

  return (
    <Grid
      container
      ref={targetRef}
      direction={{ lg: direction, xs: "column" }}
      spacing={{ lg: direction === "row" ? 2 : 0, xs: 0 }}
      alignItems={{
        lg: direction === "row" ? "center" : "flex-start",
        xs: "flex-start",
      }}
      flexWrap={"wrap"}
      sx={muiClasses}
    >
      {children}
    </Grid>
  );
};

ListWithIntersection.Item = function ListWithIntersectionItem({
  children,
  dataId = "1",
}: PropsWithChildren & { dataId: string }) {
  return (
    <Grid
      item
      xs={12}
      lg={2}
      flexGrow={1}
      width={"100%"}
      data-key={dataId} // Unique key for each item
    >
      {children}
    </Grid>
  );
};

export default ListWithIntersection;
