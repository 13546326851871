import React from "react";
import { Dialog, Grid, Slide, styled } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import style from "./index.module.css";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    borderRadius: "0",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  children: React.ReactNode;
  lineBackgroundColor?: string;
  backgroundColor?: string;
}
const FullScreenDialog: React.FC<IProps> = (props) => {
  const {
    children,
    lineBackgroundColor = "var(--gray-200)",
    backgroundColor = "var(--neutral-300)",
  } = props;

  return (
    <CustomDialog
      PaperProps={{
        sx: {
          backgroundColor,
        },
      }}
      fullScreen
      open={true}
      TransitionComponent={Transition}
    >
      {children}
      <Grid item className={style.lineContainer}>
        <Grid
          item
          sx={{
            backgroundColor: lineBackgroundColor
              ? lineBackgroundColor
              : "var(--gray-20)",
          }}
          className={style.lineBottom}
        ></Grid>
      </Grid>
    </CustomDialog>
  );
};

export default FullScreenDialog;
