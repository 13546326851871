import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Typography,
  Stack,
  Box,
  Button as MUIButton,
  Dialog,
  InputAdornment,
  DialogContent,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

import FullScreenDialog from "../../../components/FullScreenDialog";
import Header from "../../../components/Header";
import GetImages from "../../../components/GetImages";
import OrderRequestCard from "../../../components/Pharmacy/OrderRequestCard";
import Button from "../../../components/Button";
import { InputField } from "../../../components/InputField";
import OrderDetailsTopBar from "../OrderWebPanel/OrderDetailsTopBar";
import OrdersModal from "../OrderModals";
import ListWithIntersection from "../../../components/ListWithIntersection";
import Confirmation from "../../../components/Confirmation";
import PaymentDetail from "../../../components/Pharmacy/PaymentDetail";

import style from "./index.module.css";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  saveSelectedOrder,
  setIsPendingOrderRequestsOpen,
  fetchPharmacyOrdersList,
  fetchDiagnosticRequestOrdersList,
  fetchMorePharmacyRequestOrdersList,
  fetchPharmacyRequestOrdersList,
} from "../../../store/slices/pharmacyClinicDiagnostic";

import { IOrderStatusEnum } from "../../../models/ApiRequestResponse/pharmacy";
import { IButtonVariant } from "../../../models/button";
import { IPharmacyDiagnosticOrderDetail } from "../../../models/ApiRequestResponse/diagnostic";
import { IRoleType } from "../../../models/role";
import { IOrderDateRangeParams } from "../../../models/doctor";

import useDebounce from "../../../utils/hooks/useDebouncer";
import usePagination from "../../../utils/hooks/usePagination";
import { storage } from "../../../utils/Storage";
import useHandlePharmacyOrderUpdate from "../../../utils/hooks/useHandlePharmacyOrderUpdate";
import useIsTablet from "../../../utils/hooks/useIsTablet";
import useIsMobile from "../../../utils/hooks/useIsMobile";

const OrdersRequest: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [selectedOrderRequest, setSelectedOrderRequest] =
    useState<IPharmacyDiagnosticOrderDetail | null>(null);

  const dispatch = useAppDispatch();
  const { pendingOrdersList, order } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const debouncedSearchKeyword = useDebounce(keyword, 500);

  const { handleFetchNext } = usePagination<IOrderDateRangeParams>({
    fetchFn: fetchPharmacyRequestOrdersList,
    fetchNextFn: fetchMorePharmacyRequestOrdersList,
  });

  const roleType = storage.getSessionToken("roleType");
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const isWebScreen = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );

  const { handlePharmacyOrderUpdate } = useHandlePharmacyOrderUpdate();

  const backHandler = () => {
    dispatch(setIsPendingOrderRequestsOpen());
    dispatch(saveSelectedOrder(null));
  };

  const handleSelect = (orderDetails: IPharmacyDiagnosticOrderDetail) => {
    setSelectedOrderRequest(orderDetails);
    if (!isWebScreen) {
      setIsModalOpen((state) => !state);
    }
    dispatch(saveSelectedOrder(orderDetails));
  };

  const openCloseConfirmModal = () => setIsConfirm(!isConfirm);

  const isPDFFile = useMemo(() => {
    const pdfRegex = /^https?:\/\/.*\.pdf(\?.*)?$/;

    return pdfRegex.test(order?.pdfurl as string);
  }, [order]);

  const isWebScreenOrder = useMemo(
    () => !!(isWebScreen && Object.keys(order).length),
    [order, isWebScreen]
  );

  useEffect(() => {
    if (!(isMobile || isTablet) && pendingOrdersList?.length > 0) {
      dispatch(saveSelectedOrder(pendingOrdersList[0]));
      setSelectedOrderRequest(pendingOrdersList[0]);
    }
  }, [pendingOrdersList, dispatch]);

  useEffect(() => {
    if (debouncedSearchKeyword) {
      if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
        dispatch(fetchDiagnosticRequestOrdersList({ keyword }));
        return;
      }
      dispatch(
        fetchPharmacyOrdersList({
          keyword,
        })
      );
    }
  }, [debouncedSearchKeyword]);

  if (isWebScreen) {
    return (
      <Dialog
        open
        maxWidth={"lg"}
        fullWidth
        PaperProps={{
          sx: {
            height: "100%",
          },
        }}
      >
        <DialogContent sx={{ p: 1 }}>
          <Grid
            container
            className={style.container}
            sx={{ borderRadius: { lg: "2.4rem" } }}
          >
            {/* --------------------- ,Order List, Title & Back Button --------------------------------*/}
            <Grid item xs={12} md={3.5} className={style["list-container"]}>
              {/* ------------------------- Title & Back Button --------------------------------------*/}
              <Stack direction={"row"} alignItems={"center"}>
                <Button variant={IButtonVariant.TEXT} btnTrigger={backHandler}>
                  <GetImages name="LeftArrowIcon" width="20" height="18" />
                </Button>
                <Typography variant="h6">
                  <FormattedMessage
                    defaultMessage={"Order Requests"}
                    id="order_requests"
                  />
                </Typography>
              </Stack>
              {/* --------------------------- End of Title & Back Button --------------------------------- */}

              {/* ---------------------------- Order List ---------------------------------------------- */}
              <Typography component={"p"} className={style.resultCounter}>
                {pendingOrdersList?.length} results
              </Typography>
              <ListWithIntersection
                list={pendingOrdersList}
                onLoadMore={() =>
                  handleFetchNext({
                    status: IOrderStatusEnum.PENDING,
                  })
                }
              >
                <>
                  {pendingOrdersList?.map(
                    (item: IPharmacyDiagnosticOrderDetail, id: number) => (
                      <ListWithIntersection.Item
                        key={`${item.name}-${id}`}
                        dataId={item.id}
                      >
                        <OrderRequestCard
                          {...item}
                          isSelected={item.id === selectedOrderRequest?.id}
                          onSelect={() => {
                            setSelectedOrderRequest(item);
                            handleSelect(item);
                          }}
                        />
                      </ListWithIntersection.Item>
                    )
                  )}
                </>
              </ListWithIntersection>
              {/* -------------------------------- End of Order List ------------------------------------------------------------ */}
            </Grid>
            {/* ------------------------ End of Order List, Title & Back Button -------------------------- */}
            {/* ----------------------------- Order Request Panel ------------------------------ */}
            <Grid item md={8.5}>
              {pendingOrdersList?.length > 0 &&
                order &&
                Object.keys(order).length > 0 &&
                isWebScreenOrder && (
                  <Stack height={"100%"}>
                    <Stack flexGrow={1}>
                      <OrderDetailsTopBar showActionsButton={false} />
                      {roleType === IRoleType.DIAGNOSTIC_CENTER ? (
                        <PaymentDetail />
                      ) : (
                        <Box height={"100%"}>
                          {isPDFFile ? (
                            <iframe
                              className={style["pdf-container"]}
                              title="pdf"
                              src={order?.pdfurl}
                            />
                          ) : (
                            <img
                              src={order?.pdfurl}
                              alt="document"
                              className={style["img-wrap"]}
                            />
                          )}
                        </Box>
                      )}
                    </Stack>
                    <Grid
                      container
                      justifyContent={"space-around"}
                      alignItems={"center"}
                      p={2}
                      className={style["actions-container"]}
                    >
                      <Grid item xs={5}>
                        <MUIButton
                          variant={"outlined"}
                          color={"primary"}
                          className={style["actions-item"]}
                          onClick={openCloseConfirmModal}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <GetImages
                              name="CancelIcon"
                              width="28"
                              height="24"
                            />
                            <Typography
                              component={"p"}
                              className={style.cancelText}
                            >
                              <FormattedMessage
                                id="reject"
                                defaultMessage="Reject"
                              />
                            </Typography>
                          </Stack>
                        </MUIButton>
                      </Grid>
                      <Grid item xs={5}>
                        <MUIButton
                          variant={"contained"}
                          color={"primary"}
                          disableElevation
                          className={style["actions-item"]}
                          onClick={() => {
                            dispatch(saveSelectedOrder(null));
                            dispatch(setIsPendingOrderRequestsOpen());
                            handlePharmacyOrderUpdate(
                              IOrderStatusEnum.CONFIRMED
                            );
                          }}
                        >
                          <FormattedMessage
                            defaultMessage={"Accept"}
                            id="accept"
                          />
                        </MUIButton>
                      </Grid>
                    </Grid>
                  </Stack>
                )}
            </Grid>
            {/* ------------------------------End of Order Request Panel ----------------------------- */}
          </Grid>
        </DialogContent>

        {isConfirm && (
          <Confirmation
            isOpen={isConfirm}
            closeHandler={openCloseConfirmModal}
            title="reject_order"
            alterMessage="reject_order_msg"
            actionHandler={() => {
              dispatch(saveSelectedOrder(null));
              dispatch(setIsPendingOrderRequestsOpen());
              handlePharmacyOrderUpdate(IOrderStatusEnum.REJECTED);
              openCloseConfirmModal();
            }}
          />
        )}
      </Dialog>
    );
  }

  return (
    <>
      <FullScreenDialog>
        <Grid item className={style.container}>
          <Header
            heading={isSearch ? "" : "Order Requests"}
            subHeading={isSearch ? "" : `${pendingOrdersList?.length} Requests`}
            secondOption={
              isSearch ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item xs={10}>
                    <InputField
                      fullWidth
                      placeholder="Type here"
                      value={keyword}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setKeyword(event.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <GetImages
                              name="NavSearchIcon"
                              width="24"
                              height="24"
                            />
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant={IButtonVariant.TEXT}
                      btnTrigger={() => setIsSearch(false)}
                    >
                      <GetImages name="CloseIcon" width="44" height="24" />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button
                  btnTrigger={() => setIsSearch(true)}
                  variant={IButtonVariant.TEXT}
                >
                  <GetImages name="NavSearchIcon" width="24" height="24" />
                </Button>
              )
            }
            previousPage={backHandler}
          />
          <ListWithIntersection
            list={pendingOrdersList}
            onLoadMore={() => handleFetchNext({})}
          >
            <>
              {pendingOrdersList?.map(
                (item: IPharmacyDiagnosticOrderDetail, id: number) => (
                  <ListWithIntersection.Item
                    key={`${item.name}-${id}`}
                    dataId={item.id}
                  >
                    <OrderRequestCard
                      {...item}
                      onSelect={() => handleSelect(item)}
                      isSelected={order?.id === item.id}
                    />
                  </ListWithIntersection.Item>
                )
              )}
            </>
          </ListWithIntersection>
        </Grid>
      </FullScreenDialog>
      <OrdersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen((state) => !state)}
      />
    </>
  );
};

export default OrdersRequest;
