import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import FullScreenDialog from "../../FullScreenDialog";
import Header from "../../Header";

import style from "./index.module.css";

import { setIsViewPrescriptionOpen } from "../../../store/slices/pharmacyClinicDiagnostic";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { pdfRegex } from "../../../utils";

const ViewMedicalRecord: React.FC = () => {
  const { order } = useAppSelector((state) => state.pharmacyClinicDiagnostic);
  const dispatch = useAppDispatch();

  const closeViewMedicalRecordHandler = () => {
    dispatch(setIsViewPrescriptionOpen());
  };

  const isPDFFile = useMemo(() => {
    return pdfRegex.test(order?.pdfurl!);
  }, [order]);

  return (
    <FullScreenDialog lineBackgroundColor="var(--neutral-100)">
      <Stack height={"100%"} width={"100%"} flexDirection={"column"} gap={1}>
        <Box>
          <Header
            heading="View Medical Record"
            previousPage={closeViewMedicalRecordHandler}
          />
        </Box>
        <Box flexGrow={1}>
          {isPDFFile ? (
            <iframe
              title="pdf"
              src={order.pdfurl}
              height={"100%"}
              width={"100%"}
            />
          ) : (
            <img
              src={order?.pdfurl}
              alt="medical record"
              className={style["img-wrap"]}
            />
          )}
        </Box>
      </Stack>
    </FullScreenDialog>
  );
};

export default ViewMedicalRecord;
