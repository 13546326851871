export const style = {
  clearBtn: {
    borderRadius: "1.8rem",
    background: "var(--gray-500)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.8rem 1.2rem",
    width: "max-content",
  },
  clearText: {
    marginLeft: "0.4rem",
    color: "var(--neutral-100)",
    fontSize: "1.2rem",
    fontWeight: 400,
    textTransform: "capitalize",
    cursor: "pointer",
  },
};
