import { LIMIT } from "../..";
import {
  IPharmacyDiagnosticOrderListResponse,
  IDiagnosticProfileResponse,
  IDiagnosticSalesExportResponse,
  IUpdateDiagnosticOrderDetailRequest,
  IUpdateDiagnosticOrderResponse,
} from "../../../models/ApiRequestResponse/diagnostic";
import { IProfileSalesResponse } from "../../../models/ApiRequestResponse/pharmacy";
import { IOrderDateRangeParams } from "../../../models/doctor";
import { GBSCError } from "../../../models/GBSCError";

import { get, patch } from "../../TypedApi";

import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDiagnosticOrderListingApi = async ({
  status,
  startDate,
  endDate,
  keyword,
  offset,
}: IOrderDateRangeParams): Promise<
  IPharmacyDiagnosticOrderListResponse | GBSCError
> => {
  try {
    let url = `${APIEndpoint.diagnostic.orders}?limit=${LIMIT}&offset=${
      offset ?? 0
    }`;
    url +=
      startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : "";
    url += status ? `&status=${status}` : "";
    url += keyword ? `&keyword=${keyword ?? ""}` : "";

    const response = await get<IPharmacyDiagnosticOrderListResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const updateDiagnosticOrderApi = async (
  requestBody: IUpdateDiagnosticOrderDetailRequest
): Promise<IUpdateDiagnosticOrderResponse | GBSCError> => {
  try {
    const response = await patch<IUpdateDiagnosticOrderResponse>(
      APIEndpoint.diagnostic.updateOrder,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDiagnosticProfileApi = async (): Promise<
  IDiagnosticProfileResponse | GBSCError
> => {
  try {
    const response = await get<IDiagnosticProfileResponse>(
      APIEndpoint.diagnostic.profile
    );
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDiagnosticProfileMonthlyAnalysisApi = async ({
  startTime,
  endTime,
}: IOrderDateRangeParams): Promise<IProfileSalesResponse | GBSCError> => {
  let url = `${APIEndpoint.diagnostic.sales}?startDate=${
    startTime ?? 0
  }&endDate=${endTime ?? 0}`;

  try {
    const response = await get<IProfileSalesResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDiagnosticProfileMonthlyAnalysisExportCSVApi = async ({
  startTime,
  endTime,
}: IOrderDateRangeParams): Promise<
  IDiagnosticSalesExportResponse | GBSCError
> => {
  let url = `${APIEndpoint.diagnostic.salesExport}?startDate=${
    startTime ?? 0
  }&endDate=${endTime ?? 0}`;
  try {
    const response = await get<IDiagnosticSalesExportResponse>(url);
    if (response?.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }
  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
