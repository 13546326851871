import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  agoraRTCTokenApi,
  agoraRTMTokenApi,
} from "../../utils/apis/doctor/appointment";

import {
  IAppointments,
  IDoctorAssociatedClinicMemberProfile,
} from "../../models/ApiRequestResponse/doctor";

export const fetchRTCTokenAgora = createAsyncThunk(
  "fetch-token-rtc",
  async (channelName: string) => {
    const response = await agoraRTCTokenApi(channelName);
    return response;
  }
);

export const fetchRTMTokenAgora = createAsyncThunk(
  "fetch-token-rtm",
  async (channelName: string) => {
    const response = await agoraRTMTokenApi(channelName);
    return response;
  }
);
const videoAppointmentSlice = createSlice({
  name: "videoAppointment",
  initialState: {
    rtcToken: "" as string,
    rtmToken: "" as string,
    rtmUID: "" as string,
    channelName: "" as string,
    addMemberShow: false as boolean,
    isVideoCallInitiated: false as boolean,
    isCameraOn: true as boolean,
    isVideoMinimizedOrPrescInitiated: false as boolean,
    isVideoMinimizedAndChatSelected: false as boolean,
    isVideoMinimizedAndPrescriptionSelected: false as boolean,
    callerDetails: null as any,
    addToCallMember: null as IDoctorAssociatedClinicMemberProfile | null,
    appointmentDetail: {} as IAppointments,
    fcmDeviceToken: "" as string,
    isLoading: false as boolean,
  },
  reducers: {
    resetRTCOrRTMToken: (state) => {
      state.rtcToken = "";
      state.rtmToken = "";
      state.rtmUID = "";
    },
    setChannelName: (state, action) => {
      state.channelName = action.payload;
    },
    showHideAddMember: (state) => {
      state.addMemberShow = !state.addMemberShow;
    },
    setIsVideoCallInitiated: (state) => {
      state.isVideoCallInitiated = !state.isVideoCallInitiated;
    },
    setIsCameraOn: (state) => {
      state.isCameraOn = !state.isCameraOn;
    },
    setIsVideoMinimizedOrPrescInitiated: (state, action) => {
      state.isVideoMinimizedOrPrescInitiated = action.payload.value;
    },
    setIsVideoUnMinimized: (state, action) => {
      state.isVideoMinimizedOrPrescInitiated = !action.payload.value;
      state.isVideoMinimizedAndChatSelected = !action.payload.value;
      state.isVideoMinimizedAndPrescriptionSelected = !action.payload.value;
    },
    setIsVideoCallChatInitiated: (state, action) => {
      state.isVideoMinimizedAndChatSelected = action.payload.value;
      state.isVideoMinimizedAndPrescriptionSelected = action.payload.value
        ? !action.payload.value
        : action.payload.value;
    },
    setIsVideoCallPrescriptionInitiated: (state, action) => {
      state.isVideoMinimizedAndPrescriptionSelected = action.payload.value;
      state.isVideoMinimizedAndChatSelected = action.payload.value
        ? !action.payload.value
        : action.payload.value;
    },
    setCallerDetails: (state, action) => {
      state.callerDetails = action.payload;
    },
    setAddToCallMember: (state, action) => {
      state.addToCallMember = action.payload;
    },
    setFCMDeviceToken: (state, action) => {
      state.fcmDeviceToken = action.payload;
    },
    setVideoAppointmentDetail: (state, action) => {
      state.appointmentDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // RTC token
      .addCase(fetchRTCTokenAgora.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRTCTokenAgora.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.rtcToken = action.payload.result;
        }
      })
      .addCase(fetchRTCTokenAgora.rejected, (state) => {
        state.isLoading = false;
      })
      // RTM token
      .addCase(fetchRTMTokenAgora.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRTMTokenAgora.fulfilled, (state, action: any) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.rtmToken = action.payload.result.rtmToken;
          state.rtmUID = action.payload.result.uid;
        }
      })
      .addCase(fetchRTMTokenAgora.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetRTCOrRTMToken,
  setChannelName,
  showHideAddMember,
  setIsVideoCallInitiated,
  setIsCameraOn,
  setIsVideoMinimizedOrPrescInitiated,
  setIsVideoCallChatInitiated,
  setIsVideoUnMinimized,
  setIsVideoCallPrescriptionInitiated,
  setCallerDetails,
  setAddToCallMember,
  setFCMDeviceToken,
  setVideoAppointmentDetail,
} = videoAppointmentSlice.actions;
export default videoAppointmentSlice.reducer;
