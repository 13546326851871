import { Grid, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

import style from "./index.module.css";

import { IHealthHubPlaylistVideo } from "../../../../models/ApiRequestResponse/patient";

type PlayListCardProps = {
  video: IHealthHubPlaylistVideo;
};

const PlayListCard: React.FC<PlayListCardProps> = ({ video }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate("/health-hub/video", { state: { videoId: video.id } });
  };

  return (
    <Grid onClick={handleCardClick} className={style.outerContainer}>
      <img
        src={video.thumbnailUrl}
        alt={video.name}
        className={style.thumbnail}
      />
      <Grid className={style.metaDataContainer}>
        <Grid className={style.topContainer}>
          <Typography variant="caption" className={style.date}>
            {format(new Date(video.createdAt), "MMM dd, yyyy")}
          </Typography>
          <Grid className={style.categoryContainer}>
            <Typography variant="caption" className={style.category}>
              {video.category}
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" className={style.title}>
          {video.name}
        </Typography>
        <Typography variant="body2" className={style.description}>
          {video.description}
        </Typography>
        <Typography variant="body1" className={style.readCount}>
          {video.views}{" "}
          {intl.formatMessage({ id: "views", defaultMessage: "Views" })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PlayListCard;
