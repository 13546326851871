import React from "react";
import { Dialog, DialogContent, Grid, styled, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import Button from "../Button";

import { IButtonVariant } from "../../models/button";

const CustomDialog = styled(Dialog)(({}) => ({
  "& .MuiDialog-paper": {
    borderRadius: "1.2rem",
    padding: "1.6rem 2.4rem 2.4rem",
    textAlign: "center",
  },
}));

interface IProps {
  title: string;
  name?: string;
  alterMessage: string;
  isOpen: boolean;
  actionHandler?: () => void;
  closeHandler: () => void;
  takeActionLabel?: string;
  dontActionLabel?: string;
  showActionBtn?: boolean;
}
const Confirmation: React.FC<IProps> = (props) => {
  const {
    isOpen,
    alterMessage,
    name,
    title,
    actionHandler,
    closeHandler,
    takeActionLabel = "yes",
    dontActionLabel = "no",
    showActionBtn = true,
  } = props;

  return (
    <CustomDialog open={isOpen}>
      <Typography component={"h3"} className={style.heading}>
        <FormattedMessage id={title} defaultMessage="Confirmation Title" />
      </Typography>
      <DialogContent className={style.content}>
        <Typography component={"p"}>
          <FormattedMessage id={alterMessage} defaultMessage="Alert Message" />{" "}
          <Typography component={"span"}>{name ?? ""}</Typography> ?
        </Typography>
        <Grid item className={style.actionBtnsContainer}>
          {showActionBtn && (
            <Grid item>
              <Button btnTrigger={actionHandler}>
                <FormattedMessage
                  id={takeActionLabel}
                  defaultMessage="Action Button"
                />
              </Button>
            </Grid>
          )}
          <Grid item className={style.closePopBtn}>
            <Button variant={IButtonVariant.WHITE} btnTrigger={closeHandler}>
              <FormattedMessage
                id={dontActionLabel}
                defaultMessage="Action Button"
              />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default Confirmation;
