import React, { useEffect, useReducer, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useIntl, FormattedMessage } from "react-intl";

import FullScreenDialog from "../../../../components/FullScreenDialog";
import Header from "../../../../components/Header";
import { InputField } from "../../../../components/InputField";
import DateSelect from "../../../../components/DateSelect";
import Button from "../../../../components/Button";
import Select from "../../../../components/Select";
import DropDownSelect from "../../../../components/DropDownSelect";
import Section from "../../../../components/IPDashboard/Section";
import InputWithAdornedText from "../../../../components/IPDashboard/InputWithAdornedText";
import Dialog from "../../../../components/Dialog";
import Confirmation from "../../../../components/Confirmation";

import style from "./index.module.css";

import { IButtonVariant } from "../../../../models/button";
import {
  IBed,
  IDoctorHospitalDetail,
} from "../../../../models/ApiRequestResponse/doctor";
import {
  IAddPatient,
  IAdmitPatientModalTypes,
  IAdmitPatientType,
  IChangeHospitalModuleName,
  IKeyPairsValues,
  IProfileDropdownEnums,
  IDropDownSelectionType,
  IPDashboardPatientAdmitStatus,
  IAdmitPatientPopUpsEnums,
} from "../../../../models/doctor";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setAddPatient,
  setChangeHospitalModuleName,
  setTopBarDropdownItems,
} from "../../../../store/slices/doctor/topNavigation";
import {
  fetchDoctorHospitalPatientListing,
  fetchHospitalRoomsListing,
  resetAdmitPatientDetail,
  setEditAdmitPatient,
  setOpenCloseAdmittedDischargeModal,
  setReAdmitPatient,
} from "../../../../store/slices/doctor/ipDashboard";
import {
  resetPatientDetailErrorMessages,
  setModuleName,
} from "../../../../store/slices/doctor/checkMobilePatientExistOrNot";

import {
  admitPatientApi,
  updateAdmitPatientApi,
} from "../../../../utils/apis/doctor/ipDashboard";
import { storage } from "../../../../utils/Storage";
import { INotifyEnum, notify } from "../../../../utils/toaster";
import useIsMobile from "../../../../utils/hooks/useIsMobile";

import {
  admitPatientDispatcher,
  admitPatientinitialState,
  topBarDropdownDispatchingEnums,
  removeAttributesAdmitPatientDetail,
  transformClasses,
} from "../../../../utils";
import useUpdateAdmitPatient from "../../../../utils/hooks/useUpdateAdmitPatient";
import useIsTablet from "../../../../utils/hooks/useIsTablet";

interface IProps {
  isOpen?: boolean;
  closeHandler?: () => void;
}

const AdmitPatient: React.FC<IProps> = (props) => {
  const {
    admitPatientRooms: rooms,
    hospitals,
    isReAdmitPatient,
    patientDetail,
    isEditAdmitPatient,
    rooms: selectedHospitalRooms,
    admitPatientPopUps,
  } = useAppSelector((state) => state.ipDashboard);
  const { isOpen, closeHandler } = props;
  const [bedNumber, setBedNumber] = useState<IBed[]>([]);

  const [admitPatientModal, setAdmitPatientModal] = useState({
    gender: false,
    sponsor: false,
    member: false,
  });
  const [stopPrefilling, setStopPrefilling] = useState<boolean>(false);
  const [isDischarge, setIsDischarge] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const [state, dispatch] = useReducer(
    admitPatientDispatcher,
    admitPatientinitialState
  );
  const [isAdmitBtnDisabled, setIsAdmitBtnDisabled] = useState<boolean>(true);

  const navigate = useNavigate();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const intl = useIntl();

  const { topBarItems, changeHospitalModuleName } = useAppSelector(
    (state) => state.topNavigation
  );
  const { fechedPatientDetail } = useAppSelector(
    (state) => state.patientExistOrNot
  );

  const appDispatch = useAppDispatch();

  const [isEditMode, setIsEditMode] = useState(
    !isEditAdmitPatient || isReAdmitPatient || isMobile || isTablet
  );
  const { updateAdmitPatientHandler } = useUpdateAdmitPatient();

  const icuWardSelectionHandler = (roomId: string) => {
    dispatch({
      type: IAdmitPatientType.SET_FIELD,
      field: "hospital_room_id",
      value: roomId,
    });
    const room = rooms?.allRooms?.filter((room) => room?.id === roomId)[0];
    if (
      (isEditAdmitPatient && !isReAdmitPatient && !isEditMode) ||
      ((isMobile || isTablet) && initial)
    ) {
      setBedNumber([
        ...(room?.beds ?? []),
        {
          id: patientDetail.room_bed_id,
          number: patientDetail.number,
        },
      ]);
      setInitial(false);
    } else setBedNumber(room?.beds ?? []);
  };

  const selectedBedRoom = (roomBedId: string) => {
    dispatch({
      type: IAdmitPatientType.SET_FIELD,
      field: "room_bed_id",
      value: roomBedId,
    });
  };

  const openCloseAdmitPatientModal = (key: IAdmitPatientModalTypes) => {
    setAdmitPatientModal({
      ...admitPatientModal,
      [key]: !admitPatientModal[key],
    });
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveChanges = () => {
    setIsEditMode(false);
    addEditPatientHandler();
  };

  const saveSelectedValuesFromDropdown = (
    value: string,
    type: IDropDownSelectionType
  ) => {
    switch (type) {
      case IDropDownSelectionType.GENDER:
        openCloseAdmitPatientModal(IAdmitPatientModalTypes.GENDER);
        dispatch({
          type: IAdmitPatientType.SET_FIELD,
          field: "gender",
          value: value,
        });
        return;
      case IDropDownSelectionType.HOSPITAL_CLINICS:
        dispatch({
          type: IAdmitPatientType.SET_FIELD,
          field: "hospital_id",
          value: value,
        });
        appDispatch(
          fetchHospitalRoomsListing({
            hospitalId: value,
            isAdmitPatient: true,
            available: true,
          })
        );
        dispatch({
          type: IAdmitPatientType.SET_FIELD,
          field: "hospital_room_id",
          value: "",
        });
        selectedBedRoom("");
        setBedNumber([]);
        openCloseHandler();
        return;
      case IDropDownSelectionType.SPONSORS:
        dispatch({
          type: IAdmitPatientType.SET_FIELD,
          field: "sponsor_name",
          value: value,
        });
        openCloseAdmitPatientModal(IAdmitPatientModalTypes.SPONSOR);
        return;
      case IDropDownSelectionType.CHANGE_NAME:
        const [memberId, name, age, gender] = value.split("|");
        const changeMembers: IKeyPairsValues = {
          member_id: memberId,
          name,
          age,
          gender,
        };
        for (let key in changeMembers) {
          dispatch({
            type: IAdmitPatientType.SET_FIELD,
            field: key,
            value: changeMembers[key],
          });
        }
        openCloseAdmitPatientModal(IAdmitPatientModalTypes.MEMBER);
        return;
      default:
        break;
    }
  };

  const openCloseHandler = () => {
    appDispatch(
      setChangeHospitalModuleName(IChangeHospitalModuleName.FROM_ADMIT_PATIENT)
    );
    appDispatch(
      setTopBarDropdownItems(
        topBarDropdownDispatchingEnums[IProfileDropdownEnums.CHANGE_HOSPITAL]
      )
    );
  };

  const backHandler = () => {
    appDispatch(resetPatientDetailErrorMessages());
    appDispatch(setEditAdmitPatient(false));
    appDispatch(setReAdmitPatient(false));
    if (isMobile || isTablet) {
      closeHandler?.();
      if (admitPatientPopUps.isAdmitted)
        appDispatch(
          setOpenCloseAdmittedDischargeModal(IAdmitPatientPopUpsEnums.ADMITTED)
        );
      navigate("/ip-dash", { state: { navItem: 1 } });
    } else {
      appDispatch(
        setAddPatient({
          field: IAddPatient.ADMIT_PATIENT,
          value: false,
        })
      );
    }
  };

  const addEditPatientHandler = async () => {
    delete state.mobile_number;
    let updateAdmitPatient = {};
    const { is_readmitted, ...filteredState } = state;

    const requestPayload = {
      ...filteredState,
      member_id:
        state?.member_id?.length !== 0
          ? state?.member_id
          : fechedPatientDetail?.members
          ? fechedPatientDetail?.members[0]?.id
          : "",
      doctor_id: storage.getSessionToken("user_id") ?? "",
      patient_id: fechedPatientDetail?.userId,
      ...(patientDetail?.status ===
        IPDashboardPatientAdmitStatus.DISCHARGED && {
        status: IPDashboardPatientAdmitStatus.ADMIT,
      }),
    };

    if (isEditAdmitPatient) {
      const { name, age, gender, ...rest } = requestPayload;
      updateAdmitPatient = rest;
    }
    const admitPatientResponse = await (isEditAdmitPatient
      ? updateAdmitPatientApi({ ...updateAdmitPatient, id: patientDetail?.id })
      : admitPatientApi(requestPayload));

    if ("result" in admitPatientResponse) {
      notify(INotifyEnum.SUCCESS, admitPatientResponse.message);
      navigate("/ip-dash", { state: { navItem: 1 } });
      appDispatch(
        fetchDoctorHospitalPatientListing({
          hospitalId: patientDetail.hospital_id,
        })
      );
      appDispatch(resetPatientDetailErrorMessages());
      appDispatch(resetAdmitPatientDetail());
      appDispatch(setModuleName(""));
      appDispatch(
        setAddPatient({ field: IAddPatient.ADMIT_PATIENT, value: false })
      );
      if (admitPatientPopUps.isAdmitted && (isMobile || isTablet))
        appDispatch(
          setOpenCloseAdmittedDischargeModal(IAdmitPatientPopUpsEnums.ADMITTED)
        );
      return;
    }

    notify(INotifyEnum.ERROR, admitPatientResponse.message);
  };

  const openCloseDischargeConfirmation = () => {
    setIsDischarge(!isDischarge);
  };

  const getRoomData = () => {
    let alreadyExist = false;
    let data =
      rooms?.allRooms?.map((room) => {
        if (room.id === state.hospital_room_id) alreadyExist = true;
        return {
          key: room.name,
          value: room.id,
        };
      }) || [];

    if (
      isEditAdmitPatient &&
      !alreadyExist &&
      patientDetail.hospital_id === state.hospital_id &&
      !isReAdmitPatient
    ) {
      const currentRoom = selectedHospitalRooms.allRooms.find(
        (room) => room.id === state.hospital_room_id
      );
      return [
        ...data,
        {
          key: currentRoom?.name || "",
          value: currentRoom?.id || "",
        },
      ];
    }
    return data;
  };

  const dischargePatientHandler = async () => {
    updateAdmitPatientHandler({
      id: patientDetail?.id,
      status: IPDashboardPatientAdmitStatus.DISCHARGED,
    });
    openCloseDischargeConfirmation();
  };

  const admitPatientScreen = () => (
    <>
      <Grid item className={style.container}>
        {!(isMobile || isTablet) && !isEditAdmitPatient && (
          <Typography component={"h4"} className={style.admitPatientTitle}>
            <FormattedMessage
              id="admit_patient"
              defaultMessage="Admit Patient"
            />
          </Typography>
        )}

        <Section title="patient_details">
          <Grid item className={style.inputSpacing}>
            <InputWithAdornedText
              type="number"
              value={state?.mobile_number}
              label="mobile_number"
              adornText="verified"
              disabled
            />
          </Grid>
          <Grid item className={style.inputSpacing}>
            {fechedPatientDetail?.members?.length > 1 ? (
              <InputWithAdornedText
                value={state?.name}
                label="name"
                adornText={"select"}
                onClickAdorn={() =>
                  openCloseAdmitPatientModal(IAdmitPatientModalTypes.MEMBER)
                }
                disabled
                disabledSelect={isEditAdmitPatient}
              />
            ) : (
              <InputField
                value={state?.name}
                onChange={(event) =>
                  dispatch({
                    type: IAdmitPatientType.SET_FIELD,
                    field: "name",
                    value: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "name",
                  defaultMessage: "Name",
                })}
                fullWidth
                disabled={isEditAdmitPatient}
              />
            )}
          </Grid>
          <Grid
            item
            className={transformClasses(
              style.inputSpacing,
              style.ageGenderContainer
            )}
          >
            <Grid item className={style.ageInput}>
              <InputField
                disabled={isEditAdmitPatient}
                value={state?.age}
                onChange={(event) => {
                  if (!/^\d*$/.test(event.target.value)) return;
                  dispatch({
                    type: IAdmitPatientType.SET_FIELD,
                    field: "age",
                    value: event.target.value,
                  });
                }}
                label={intl.formatMessage({ id: "age", defaultMessage: "Age" })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "age",
                  defaultMessage: "Age",
                })}
                fullWidth
              />
            </Grid>
            <Grid item className={style.genderSelector}>
              <InputWithAdornedText
                label="gender"
                value={state?.gender || ""}
                adornText="select"
                onClickAdorn={() =>
                  openCloseAdmitPatientModal(IAdmitPatientModalTypes.GENDER)
                }
                disabledSelect={isEditAdmitPatient}
                disabled
              />
            </Grid>
          </Grid>
        </Section>

        <Section title="hospital_details">
          <Grid
            item
            className={!(isMobile || isTablet) ? style.sideByside : ""}
          >
            <Grid
              item
              className={transformClasses(
                style.inputSpacing,
                style.spaceBySideItem
              )}
            >
              <InputWithAdornedText
                label="hospital_name"
                value={
                  hospitals.filter(
                    (hospital) => hospital.hospital_id === state?.hospital_id
                  )[0]?.name || ""
                }
                adornText="select"
                onClickAdorn={openCloseHandler}
                disabledSelect={!isEditMode}
                disabled
              />
            </Grid>
            <Grid item className={style.inputSpacing}>
              <InputField
                value={storage.getSessionToken("name") ?? ""}
                label={intl.formatMessage({
                  id: "doctor_name",
                  defaultMessage: "Doctor Name",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "doctor_name",
                  defaultMessage: "Doctor Name",
                })}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={transformClasses(style.inputSpacing, style.wardIcuRooms)}
          >
            <Grid item className={style.spaceBySideItem}>
              <Select
                value={state?.hospital_room_id}
                label={intl.formatMessage({
                  id: "icu_ward",
                  defaultMessage: "ICU/Ward",
                })}
                data={getRoomData()}
                receivedSelectedValue={icuWardSelectionHandler}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item className={style.bedNumber}>
              <Select
                value={state?.room_bed_id}
                label={intl.formatMessage({
                  id: "bed_number",
                  defaultMessage: "Bed Number",
                })}
                data={bedNumber?.map((bed) => ({
                  key: `Bed ${bed.number}`,
                  value: bed.id,
                }))}
                receivedSelectedValue={selectedBedRoom}
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={!(isMobile || isTablet) ? style.sideByside : ""}
          >
            <Grid
              item
              className={transformClasses(
                style.inputSpacing,
                style.spaceBySideItem
              )}
            >
              <InputWithAdornedText
                value={state?.sponsor_name}
                label="select_sponsor"
                adornText="select"
                onClickAdorn={() =>
                  openCloseAdmitPatientModal(IAdmitPatientModalTypes.SPONSOR)
                }
                disabled
                disabledSelect={!isEditMode}
              />
            </Grid>
            <Grid item className={style.inputSpacing}>
              <InputField
                value={state?.case_history}
                onChange={(event) =>
                  dispatch({
                    type: IAdmitPatientType.SET_FIELD,
                    field: "case_history",
                    value: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "case_history",
                  defaultMessage: "Case History",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "case_history",
                  defaultMessage: "Case History",
                })}
                fullWidth
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={!(isMobile || isTablet) ? style.sideByside : ""}
          >
            <Grid
              item
              className={transformClasses(
                style.inputSpacing,
                style.spaceBySideItem
              )}
            >
              <DateSelect
                value={state?.admission_date}
                onChange={(e: any) =>
                  dispatch({
                    type: IAdmitPatientType.SET_FIELD,
                    field: "admission_date",
                    value: e,
                  })
                }
                label={intl.formatMessage({
                  id: "admission_date",
                  defaultMessage: "Admission date",
                })}
                maxDate={dayjs()}
                disabled={!isEditMode}
              />
            </Grid>
            <Grid item className={style.inputSpacing}>
              <InputField
                value={state?.hospital_uhid}
                onChange={(event) =>
                  dispatch({
                    type: IAdmitPatientType.SET_FIELD,
                    field: "hospital_uhid",
                    value: event.target.value,
                  })
                }
                label={intl.formatMessage({
                  id: "hospital_UHID",
                  defaultMessage: "Hospital UHID Number",
                })}
                variant="outlined"
                placeholder={intl.formatMessage({
                  id: "hospital_UHID",
                  defaultMessage: "Hospital UHID Number",
                })}
                fullWidth
                disabled={!isEditMode}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item className={style.btnContainer}>
        <Grid item className={style.btn}>
          {(isMobile || isTablet) && isEditAdmitPatient && (
            <Button variant={IButtonVariant.WHITE} btnTrigger={backHandler}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          )}
          {!isEditAdmitPatient && (
            <Button variant={IButtonVariant.WHITE} btnTrigger={backHandler}>
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
          )}
          {!(isMobile || isTablet) &&
            isEditAdmitPatient &&
            patientDetail?.status === IPDashboardPatientAdmitStatus.ADMIT && (
              <div className={style.admitBtnContainer}>
                {!isEditMode ? (
                  <>
                    <Button
                      variant={IButtonVariant.WHITE}
                      btnTrigger={openCloseDischargeConfirmation}
                    >
                      <FormattedMessage
                        id="discharge"
                        defaultMessage="Discharge"
                      />
                    </Button>
                    <Button variant={IButtonVariant.WHITE}>
                      <FormattedMessage
                        id="disable-chat"
                        defaultMessage="Disable Chat"
                      />
                    </Button>
                    <Button
                      variant={IButtonVariant.WHITE}
                      btnTrigger={handleEditClick}
                    >
                      <FormattedMessage id="Edit" defaultMessage="Edit" />
                    </Button>
                  </>
                ) : (
                  <Button
                    variant={IButtonVariant.ORANGE}
                    btnTrigger={handleSaveChanges}
                  >
                    <Typography paddingInline={"1.4rem"}>
                      <FormattedMessage
                        id="save_changes"
                        defaultMessage="save Changes"
                      />
                    </Typography>
                  </Button>
                )}
              </div>
            )}
        </Grid>
        {!isEditAdmitPatient ? (
          <Button
            disabled={isAdmitBtnDisabled}
            btnTrigger={addEditPatientHandler}
          >
            <FormattedMessage id="admit" defaultMessage="Admit" />
          </Button>
        ) : null}
        {(isMobile || isTablet) && isEditAdmitPatient && (
          <Button
            disabled={isAdmitBtnDisabled}
            btnTrigger={addEditPatientHandler}
          >
            <FormattedMessage id="admit" defaultMessage="Admit" />
          </Button>
        )}
        {!(isMobile || isTablet) &&
        patientDetail?.status === IPDashboardPatientAdmitStatus.DISCHARGED &&
        isReAdmitPatient &&
        isEditAdmitPatient &&
        !state.is_readmitted ? (
          <Button
            disabled={isAdmitBtnDisabled}
            btnTrigger={addEditPatientHandler}
          >
            <FormattedMessage
              id="admit_patient"
              defaultMessage="Admit Patient"
            />
          </Button>
        ) : null}
      </Grid>
      {admitPatientModal?.gender && (
        <DropDownSelect
          type={IDropDownSelectionType.GENDER}
          isOpen={admitPatientModal?.gender}
          closeHandler={() =>
            openCloseAdmitPatientModal(IAdmitPatientModalTypes.GENDER)
          }
          title="gender"
          values={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
          ]}
          selectedValue={state?.gender}
          saveSelectedValues={saveSelectedValuesFromDropdown}
        />
      )}
      {changeHospitalModuleName ===
        IChangeHospitalModuleName.FROM_ADMIT_PATIENT &&
        topBarItems?.isChangeHospitalOpen && (
          <DropDownSelect
            type={IDropDownSelectionType.HOSPITAL_CLINICS}
            btnLabel="save"
            isOpen={topBarItems?.isChangeHospitalOpen}
            closeHandler={openCloseHandler}
            title="select_hospitals"
            values={hospitals?.map(
              (item: IDoctorHospitalDetail, index: number) => ({
                label: item.name,
                value: item.hospital_id,
              })
            )}
            selectedValue={state?.hospital_id}
            backgroundColor={"white"}
            saveSelectedValues={saveSelectedValuesFromDropdown}
          />
        )}
      {admitPatientModal?.sponsor && (
        <DropDownSelect
          type={IDropDownSelectionType.SPONSORS}
          btnLabel="save"
          isOpen={admitPatientModal?.sponsor}
          closeHandler={() =>
            openCloseAdmitPatientModal(IAdmitPatientModalTypes.SPONSOR)
          }
          title="select_sponsors"
          values={hospitals
            ?.filter((hospital) => hospital?.hospital_id === state?.hospital_id)
            ?.flatMap((item: IDoctorHospitalDetail, index: number) =>
              item?.sponsors?.split(",")?.map((sponsor: string) => ({
                label: sponsor.trim(),
                value: sponsor.trim(),
              }))
            )}
          selectedValue={state?.sponsor_name}
          backgroundColor={"white"}
          saveSelectedValues={saveSelectedValuesFromDropdown}
        />
      )}
      {admitPatientModal.member && (
        <DropDownSelect
          type={IDropDownSelectionType.CHANGE_NAME}
          isOpen={admitPatientModal.member}
          closeHandler={() =>
            openCloseAdmitPatientModal(IAdmitPatientModalTypes.MEMBER)
          }
          title="change_member"
          values={
            fechedPatientDetail?.members &&
            fechedPatientDetail?.members?.map((member) => ({
              label: member.name,
              value: `${member.id}|${member.name}|${member.age}|${member.gender}`,
            }))
          }
          selectedValue={`${state?.member_id}|${state.name}|${state.age}|${state.gender}`}
          saveSelectedValues={saveSelectedValuesFromDropdown}
        />
      )}
    </>
  );

  useEffect(() => {
    if (patientDetail.hospital_id)
      appDispatch(
        fetchHospitalRoomsListing({
          hospitalId: patientDetail.hospital_id,
          isAdmitPatient: true,
          available: true,
        })
      );
  }, []);

  useEffect(() => {
    if (
      Object.keys(fechedPatientDetail).length > 0 &&
      fechedPatientDetail?.members?.length > 0
    ) {
      const { name, age, gender } = fechedPatientDetail?.members?.filter(
        (member) => member.is_default
      )[0];
      dispatch({
        type: IAdmitPatientType.SET_API_DATA,
        data: {
          name,
          age,
          gender,
          mobile_number: fechedPatientDetail?.phoneNumber,
        },
      });
    }
  }, [fechedPatientDetail]);

  useEffect(() => {
    const requiredFields = [
      "hospital_id",
      "hospital_room_id",
      "room_bed_id",
      "sponsor_name",
      "case_history",
      "admission_date",
      "hospital_uhid",
      "name",
      "age",
      "gender",
    ];

    const areAllFieldsFilled = requiredFields.every((field) =>
      Boolean(state[field])
    );

    setIsAdmitBtnDisabled(!areAllFieldsFilled);
  }, [state]);

  useEffect(() => {
    if (
      (!stopPrefilling && Object.keys(patientDetail).length > 0) ||
      patientDetail?.member_id !== state?.member_id
    ) {
      const values = removeAttributesAdmitPatientDetail(patientDetail);
      dispatch({
        type: IAdmitPatientType.SET_API_DATA,
        data: {
          ...values,
          admission_date: dayjs(values?.admission_date),
        },
      });
      const hos_roomId = values?.hospital_room_id || state?.hospital_room_id;
      setIsEditMode(
        !isEditAdmitPatient || isReAdmitPatient || isMobile || isTablet
      );
      setTimeout(() => {
        icuWardSelectionHandler(hos_roomId);
      });
      setStopPrefilling(true);
    }
  }, [patientDetail, isEditAdmitPatient, rooms]);

  return (
    <>
      {isMobile || isTablet ? (
        <FullScreenDialog>
          <Header heading="Admit Patient" previousPage={backHandler} />
          {admitPatientScreen()}
        </FullScreenDialog>
      ) : !(isMobile || isTablet) && isEditAdmitPatient ? (
        admitPatientScreen()
      ) : (
        <Dialog
          fullWidth
          maxWidth="md"
          isOpen={!!isOpen}
          closeHandler={closeHandler}
        >
          {admitPatientScreen()}
        </Dialog>
      )}

      {isDischarge && (
        <Confirmation
          title="discharge"
          isOpen={isDischarge}
          closeHandler={openCloseDischargeConfirmation}
          alterMessage={"confirm_discharge"}
          name={patientDetail?.name}
          actionHandler={dischargePatientHandler}
        />
      )}
    </>
  );
};

export default AdmitPatient;
