import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ICheckMobileNumberDetail,
  ICreatePatientVerifyOtpRequest,
} from "../../../models/ApiRequestResponse/doctor";
import {
  ICheckNumberErrors,
  IPatientExistOrNotModule,
} from "../../../models/doctor";

import { countryCode } from "../../../utils/text";
import { INotifyEnum, notify } from "../../../utils/toaster";
import {
  checkMobileNumberApi,
  createPatientVerifyOtpApi,
} from "../../../utils/apis/doctor/profile";

export const checkMobileNumber = createAsyncThunk(
  "doctor/checkMobileNumber",
  async (mobileNo: string) => {
    const response = await checkMobileNumberApi({
      countryCode,
      mobileNo,
    });
    return response;
  }
);

export const createPatientVerifyOTP = createAsyncThunk(
  "doctor/createPatientVerifyOTP",
  async (requestPayload: ICreatePatientVerifyOtpRequest) => {
    const response = await createPatientVerifyOtpApi(requestPayload);
    return response;
  }
);

const initialState = {
  checkNumberError: {} as ICheckNumberErrors,
  fechedPatientDetail: {} as ICheckMobileNumberDetail,
  moduleName: "" as IPatientExistOrNotModule,
  isLoading: false as boolean,
};
const checkMobilePatientExistOrNotSlice = createSlice({
  name: "checkMobilePatientExistOrNot",
  initialState,
  reducers: {
    resetPatientDetailErrorMessages: (state) => {
      state.checkNumberError = {} as ICheckNumberErrors;
      state.fechedPatientDetail = {} as ICheckMobileNumberDetail;
    },
    setModuleName: (state, action) => {
      state.moduleName = action.payload;
    },
    resetPatientExistOrNot: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // fetch patient profile if patient exist
      .addCase(checkMobileNumber.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkMobileNumber.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.fechedPatientDetail = action.payload.result;
        }
        state.checkNumberError.message = action.payload.message;
        state.checkNumberError.statusCode = action.payload.statusCode;
      })
      .addCase(checkMobileNumber.rejected, (state) => {
        state.isLoading = false;
      })
      // create patient if not exist
      .addCase(createPatientVerifyOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPatientVerifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          notify(INotifyEnum.DISMISS, "");
          notify(INotifyEnum.SUCCESS, action.payload?.message);
          state.fechedPatientDetail = action.payload.result;
        }
      })
      .addCase(createPatientVerifyOTP.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  resetPatientDetailErrorMessages,
  setModuleName,
  resetPatientExistOrNot,
} = checkMobilePatientExistOrNotSlice.actions;
export default checkMobilePatientExistOrNotSlice.reducer;
