import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  selectedChat: null,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSelectedChat: (
      state,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      state.selectedChat = action.payload;
    },
  },
});

export const { setSelectedChat } = chatSlice.actions;

export default chatSlice.reducer;
