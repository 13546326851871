import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  IHealthHubData,
  IHealthCategory,
} from "../../../models/ApiRequestResponse/patient";
import {
  getHealthHubCategoriesApi,
  getHealthHubDataApi,
  getHealthHubSearchDataApi,
} from "../../../utils/apis/patient/healthHub";

interface IHealthHubState {
  isLoading: boolean;
  isError: boolean;
  data: IHealthHubData;
  categories: IHealthCategory[];
  isSearchError: boolean;
  searchData: any;
  isFetchingData: boolean;
}

const initialState: IHealthHubState = {
  isLoading: true,
  isError: false,
  data: {} as IHealthHubData,
  categories: [],
  isSearchError: false,
  searchData: {
    result: {
      video: [],
      playlist: [],
    },
  },
  isFetchingData: false,
};

export const fetchHealthHubData = createAsyncThunk(
  "patient/fetchHealthHubData",
  async () => {
    const response = await getHealthHubDataApi();
    return response;
  }
);

export const getAllCategories = createAsyncThunk(
  "patient/getAllCategories",
  async () => {
    const response = await getHealthHubCategoriesApi();
    return response;
  }
);

export const searchHealthHubData = createAsyncThunk(
  "patient/searchHealthHubData",
  async ({ searchTerm, offset }: { searchTerm: string; offset: number }) => {
    if (!searchTerm) {
      return {};
    }
    const response = await getHealthHubSearchDataApi(searchTerm, offset);
    return response;
  }
);

export const healthHubSlice = createSlice({
  name: "healthHub",
  initialState,
  reducers: {
    resetHealthHubSearch: (state) => {
      state.searchData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHealthHubData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHealthHubData.fulfilled, (state, action) => {
      state.isLoading = false;
      if ("result" in action.payload) {
        state.data = {
          result: action.payload.result,
        };
      }
    });
    builder.addCase(fetchHealthHubData.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.data = {} as IHealthHubData;
    });
    builder.addCase(getAllCategories.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      if ("result" in action.payload) {
        state.categories = action.payload.result;
      }
    });
    builder.addCase(getAllCategories.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.categories = [];
    });
    builder.addCase(searchHealthHubData.pending, (state) => {
      state.isFetchingData = true;
    });
    builder.addCase(searchHealthHubData.fulfilled, (state, action) => {
      state.isFetchingData = false;
      if ("result" in action.payload) {
        const data = {
          result: {
            video: [
              ...(state?.searchData?.result?.video || []),
              // @ts-ignore
              ...(action?.payload?.result?.video || []),
            ],
            playlist: [
              ...(state?.searchData?.result?.playlist || []),
              // @ts-ignore
              ...(action?.payload?.result?.playlist || []),
            ],
          },
        };
        state.searchData = { ...data };
      }
    });
    builder.addCase(searchHealthHubData.rejected, (state) => {
      state.isFetchingData = false;
      state.isSearchError = true;
      state.searchData = {};
    });
  },
});

export const { resetHealthHubSearch } = healthHubSlice.actions;

export default healthHubSlice.reducer;
