import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IAddDeliveryAddressDetailReqRes,
  IMedicalRecordsPatientDetail,
  IPharmacyClinicListing,
} from "../../../models/ApiRequestResponse/patient";
import { IKeyPairsValues } from "../../../models/doctor";

import {
  getPatientDeliveryAddressApi,
  getPatientMedicalRecordsApi,
  getPatientMedicalRecordsByMemberIdApi,
  getPharmacyClinicsListApi,
} from "../../../utils/apis/patient/medicine";
import { IDELIVERY_TYPE } from "../../../utils";

export const fetchPharmacyClinicList = createAsyncThunk(
  "patient/pharmacyList",
  async ({
    latitude,
    longitude,
    search,
  }: {
    latitude: number;
    longitude: number;
    search?: string;
  }) => {
    const response = await getPharmacyClinicsListApi(
      latitude,
      longitude,
      search
    );
    return response;
  }
);

export const fetchPatientDeliveryAddress = createAsyncThunk(
  "patient/patient-address",
  async () => {
    const response = await getPatientDeliveryAddressApi();
    return response;
  }
);

export const fetchPatientMedicalRecords = createAsyncThunk(
  "patient/patient-medical-records",
  async () => {
    const response = await getPatientMedicalRecordsApi();
    return response;
  }
);

export const fetchPatientMedicalRecordsByMemberId = createAsyncThunk(
  "patient/patient-medical-records-memberid",
  async ({ memberId }: { memberId: string }) => {
    const response = await getPatientMedicalRecordsByMemberIdApi(memberId);
    return response;
  }
);

const fetchMedicineSlice = createSlice({
  name: "fetchMedicines",
  initialState: {
    pharmacies: [] as IPharmacyClinicListing[],
    selectedPharmacy: {} as IPharmacyClinicListing,
    isPlaceOrderModalOpen: false as boolean,
    isDeliveryAddressOpen: false as boolean,
    isUploadPrescriptionOpen: false as boolean,
    patientDeliveryAddress: {} as IAddDeliveryAddressDetailReqRes,
    patientMedicalRecords: {} as IMedicalRecordsPatientDetail,
    medicineOrderPlaced: {} as IKeyPairsValues,
    selectedMedicalRecords: {} as IKeyPairsValues,
    medicineDeliveryType: IDELIVERY_TYPE.HOME_DELIVERY as IDELIVERY_TYPE,
    isPrescriptionSelectionModalOpen: false as boolean,
    isPharmacyClinicLoading: true as boolean,
    isPatientDeliveryAddressLoading: true as boolean,
    isPatientMedicalRecordsLoading: true as boolean,
    isPatientMedicalRecordsByMemberIdLoading: true as boolean,
    isPrescriptionPreviewModal: false as boolean,
  },
  reducers: {
    setSelectedPharmacy: (state, action) => {
      state.selectedPharmacy = action.payload;
    },
    setMedicineDeliveryType: (state, action) => {
      state.medicineDeliveryType = action.payload;
    },
    setMedicineOrderPlacedRequest: (state, action) => {
      state.medicineOrderPlaced[action.payload.key] = action.payload.value;
    },
    setPrescriptionSelectionModalOpenClosed: (state) => {
      state.isPrescriptionSelectionModalOpen =
        !state.isPrescriptionSelectionModalOpen;
    },
    resetMedicineOrderPlaced: (state) => {
      state.medicineOrderPlaced = {};
    },
    resetCompleteMedicinePlacedOrder: (state) => {
      state.medicineOrderPlaced = {};
      state.selectedPharmacy = {} as IPharmacyClinicListing;
      state.isPrescriptionSelectionModalOpen = false;
      state.selectedMedicalRecords = {};
      state.isPlaceOrderModalOpen = false;
      state.isDeliveryAddressOpen = false;
    },
    setSelectedMedicalRecords: (state, action) => {
      state.selectedMedicalRecords[action.payload.key] = action.payload.value;
    },
    setPlaceOrderModalOpenClosed: (state) => {
      state.isPlaceOrderModalOpen = !state.isPlaceOrderModalOpen;
    },
    setDeliveryAddressOpenClosed: (state) => {
      state.isDeliveryAddressOpen = !state.isDeliveryAddressOpen;
    },
    setUploadPrescriptionOpenClosed: (state) => {
      state.isUploadPrescriptionOpen = !state.isUploadPrescriptionOpen;
    },
    setIsPrescriptionPreviewModal: (state) => {
      state.isPrescriptionPreviewModal = !state.isPrescriptionPreviewModal;
    },
  },
  extraReducers: (builder) => {
    builder
      // pharmacy listing
      .addCase(fetchPharmacyClinicList.pending, (state) => {
        state.isPharmacyClinicLoading = true;
      })
      .addCase(fetchPharmacyClinicList.fulfilled, (state, action) => {
        state.isPharmacyClinicLoading = false;
        if ("result" in action.payload) {
          state.pharmacies = action.payload.result;
        }
      })
      .addCase(fetchPharmacyClinicList.rejected, (state) => {
        state.isPharmacyClinicLoading = false;
      })
      // patient delivery address
      .addCase(fetchPatientDeliveryAddress.pending, (state) => {
        state.isPatientDeliveryAddressLoading = true;
      })
      .addCase(fetchPatientDeliveryAddress.fulfilled, (state, action) => {
        state.isPatientDeliveryAddressLoading = false;
        if ("result" in action.payload) {
          state.patientDeliveryAddress = action.payload.result;
        }
      })
      .addCase(fetchPatientDeliveryAddress.rejected, (state) => {
        state.isPatientDeliveryAddressLoading = false;
      })
      // patient medical records
      .addCase(fetchPatientMedicalRecords.pending, (state) => {
        state.isPatientMedicalRecordsLoading = true;
      })
      .addCase(fetchPatientMedicalRecords.fulfilled, (state, action) => {
        state.isPatientMedicalRecordsLoading = false;
        if ("result" in action.payload) {
          state.patientMedicalRecords = action.payload.result;
        }
      })
      .addCase(fetchPatientMedicalRecords.rejected, (state) => {
        state.isPatientMedicalRecordsLoading = false;
      })
      // patient medical records by member id
      .addCase(fetchPatientMedicalRecordsByMemberId.pending, (state) => {
        state.isPatientMedicalRecordsByMemberIdLoading = true;
      })
      .addCase(
        fetchPatientMedicalRecordsByMemberId.fulfilled,
        (state, action) => {
          state.isPatientMedicalRecordsByMemberIdLoading = false;
          if ("result" in action.payload) {
            state.patientMedicalRecords.records = action.payload.result.records;
          }
        }
      )
      .addCase(fetchPatientMedicalRecordsByMemberId.rejected, (state) => {
        state.isPatientMedicalRecordsByMemberIdLoading = false;
      });
  },
});
export const {
  setMedicineDeliveryType,
  setSelectedPharmacy,
  setMedicineOrderPlacedRequest,
  setPrescriptionSelectionModalOpenClosed,
  resetMedicineOrderPlaced,
  setSelectedMedicalRecords,
  setPlaceOrderModalOpenClosed,
  setDeliveryAddressOpenClosed,
  resetCompleteMedicinePlacedOrder,
  setUploadPrescriptionOpenClosed,
  setIsPrescriptionPreviewModal,
} = fetchMedicineSlice.actions;
export default fetchMedicineSlice.reducer;
