import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { FormattedMessage, useIntl } from "react-intl";

import style from "../styles/index.module.css";

import Modal from "../../Modal";
import { InputField } from "../../InputField";
import Button from "../../Button";
import Dialog from "../../Dialog";

import { IButtonType, IButtonVariant } from "../../../models/button";
import { IMobileVerify } from "../../../models/onboarding";

import { countryCode } from "../../../utils/text";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";
interface IProps {
  isBtnLoading: boolean;
  heading: string;
  mobileSubmitHandler: (values: IMobileVerify) => void;
  btnLabel?: string;
  showCountryCode?: boolean;
  showHeaderNavigation?: boolean;
  headerNavigationText?: string;
  isOpen: boolean;
  closeHandler: () => void;
  mobileNo?: string;
}

const VerifyMobile: React.FC<IProps> = (props) => {
  const [mobileNumber, setMobileNumber] = useState<string>("");

  const {
    heading,
    mobileSubmitHandler,
    btnLabel = "getOTP",
    showCountryCode = true,
    showHeaderNavigation = false,
    headerNavigationText,
    isOpen,
    closeHandler,
    mobileNo,
    isBtnLoading,
  } = props;

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const intl = useIntl();

  const handleChangeMobileNo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, "");
    setMobileNumber(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mobileNumber.trim().length === 10) {
      mobileSubmitHandler({ mobileNo: mobileNumber });
    }
  };

  const mobileInputField = () => {
    return (
      <>
        {showHeaderNavigation && (
          <Grid
            item
            className={style.headerBackNav}
            onClick={() => closeHandler && closeHandler()}
          >
            <ArrowBackIosNewIcon fontSize={"inherit"} />{" "}
            <Typography component={"h3"} className={style.heading}>
              <FormattedMessage
                id={headerNavigationText}
                defaultMessage="headerNavigationText"
              />
            </Typography>
          </Grid>
        )}
        <Grid item className={style.headerContainer}>
          <Typography component={"h3"} className={style.heading}>
            <FormattedMessage
              id={heading}
              defaultMessage="Login using Phone Number"
            />
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid item>
            <Grid item className={style.mobileBox}>
              <Grid item className={style.mobileInputContainer}>
                {showCountryCode && (
                  <InputField
                    className={style.countryCodeInput}
                    label={intl.formatMessage({
                      id: "code",
                      defaultMessage: "Code",
                    })}
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "code",
                      defaultMessage: "Code",
                    })}
                    value={countryCode}
                    disabled
                  />
                )}
                <InputField
                  type="text"
                  inputMode="numeric"
                  inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  value={mobileNumber}
                  onChange={handleChangeMobileNo}
                  label={intl.formatMessage({
                    id: "mobileNumberText",
                    defaultMessage: "Mobile Number",
                  })}
                  variant="outlined"
                  placeholder={intl.formatMessage({
                    id: "mobileNumberText",
                    defaultMessage: "Mobile Number",
                  })}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item className={style.buttonContainer}>
              <Button
                variant={
                  mobileNumber.trim().length !== 10
                    ? IButtonVariant.GREY
                    : IButtonVariant.ORANGE
                }
                type={IButtonType.SUBMIT}
                disabled={mobileNumber.trim().length !== 10}
                isLoading={isBtnLoading}
              >
                <FormattedMessage id={btnLabel} defaultMessage="Get OTP" />
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  return (
    <>
      {isMobile || isTablet ? (
        <Modal
          isOpen={isOpen}
          closeHandler={closeHandler}
          lineBottomBackground="white"
        >
          {mobileInputField()}
        </Modal>
      ) : (
        <Dialog
          fullWidth
          maxWidth="xs"
          isOpen={isOpen}
          closeHandler={closeHandler}
          paperProps={{
            sx: {
              position: "absolute",
              top: 80, // Adjust distance from top
              right: 60, // Adjust distance from right
              margin: 0,
              borderRadius: "1.6rem",
            },
          }}
        >
          {mobileInputField()}
        </Dialog>
      )}
    </>
  );
};

export default VerifyMobile;
