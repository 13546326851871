import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  IMedicine,
  IPrescription,
  IPrescriptionTemplateCreateUpdatePayload,
  IPrescriptionTemplateList,
  ITagOtherHealthProblemDiagnosis,
  ITest,
  IUploadFileToS3ResultDetail,
} from "../../../models/ApiRequestResponse/doctor";
import {
  IAccordionInputChipListEnums,
  ICreatePrescription,
  IPrescriptionFilterType,
  ITemplateCreateEnum,
  ITitleInputValuesListEnums,
} from "../../../models/doctor";

import {
  prescriptionConfigApi,
  prescriptionTemplateListApi,
} from "../../../utils/apis/doctor/prescription";

interface IPrescriptionTemplate {
  list: IPrescriptionTemplateList[];
  create: IPrescriptionTemplateCreateUpdatePayload | {};
  isCreateTemplate: boolean;
}

export const fetchPrescriptionConfig = createAsyncThunk(
  "config/prescription",
  async () => {
    const response = await prescriptionConfigApi(
      IPrescriptionFilterType.PRESCRIPTION
    );
    return response;
  }
);

export const fetchPrescriptionTemplateList = createAsyncThunk(
  "prescription/template",
  async () => {
    const response = await prescriptionTemplateListApi();
    return response;
  }
);

const initialStatePrescriptionTemplate = {
  list: [],
  create: {},
  isCreateTemplate: true,
};
const prescriptionSlice = createSlice({
  name: "prescription",
  initialState: {
    isLoading: true as boolean,
    config: {} as IPrescription,
    create: {} as ICreatePrescription,
    isCameraOn: false as boolean,
    isCreateCameraPrescription: false as boolean,
    capturedImage: "" as string,
    isCapturedSave: false as boolean,
    isCameraPrescriptionInitiated: false as boolean,
    uploadedS3URL: {} as IUploadFileToS3ResultDetail,
    template: initialStatePrescriptionTemplate as IPrescriptionTemplate,
  },
  reducers: {
    setVitals: (state: any, action) => {
      state.create[action.payload.identifier] = action.payload.value;
    },
    setPrescriptionValues: (state: any, action) => {
      const isDietOrNote =
        action.payload.identifier ===
          ITitleInputValuesListEnums.DIET_AND_EXERCISE ||
        action.payload.identifier === ITitleInputValuesListEnums.NOTE;
      const isHealthCategories =
        action.payload.identifier ===
        IAccordionInputChipListEnums.HEALTH_CATEGORIES;
      const isNextVisit =
        action.payload.identifier === IAccordionInputChipListEnums.NEXT_VISIT;
      if (isDietOrNote) {
        state.create[action.payload.identifier] = action.payload.value;
        return;
      } else if (isHealthCategories) {
        state.create.healthCategoryId = action.payload.value;
        return;
      } else if (isNextVisit) {
        const [nextVisitType, nextVisitNo] = action.payload.value;
        state.create.nextVisitType = nextVisitType;
        state.create.nextVisitNo = +nextVisitNo;
        return;
      } else {
        state.create[action.payload.identifier] = [
          ...(state?.create[action.payload.identifier] || []),
          ...action.payload.value,
        ];
      }
    },
    setMedicineTest: (state: any, action) => {
      state.create[action.payload.identifier] = [
        ...(state?.create[action.payload.identifier] || []),
        action.payload.value,
      ];
      return;
    },
    removePrescriptionItem: (state: any, action) => {
      state.create[action.payload.identifier] = state.create[
        action.payload.identifier
      ]?.filter(
        (item: any, index: number) =>
          (item.name ?? item) !== action.payload.value &&
          index !== action.payload.itemIndex
      );
    },
    setUploadReportByCamera: (state: any, action) => {
      const { image, isCapturedSave, selectedTab } = action.payload;
      state.medicalReport.image = image;
      state.medicalReport.isCapturedSave = isCapturedSave;
      state.medicalReport.selectedTab = selectedTab;
    },
    setPrescriptionTemplate: (state: any, action) => {
      if (
        action.payload.identifier === ITemplateCreateEnum.NAME ||
        action.payload.identifier === ITemplateCreateEnum.DIET_AND_EXERCISE
      ) {
        state.template.create[action.payload.identifier] =
          action?.payload?.value;
      } else {
        state.template.create[action.payload.identifier] = [
          ...(state?.template?.create[action.payload.identifier] || []),
          ...action?.payload?.value,
        ];
      }
    },
    removePrescriptionTemplateItem: (state: any, action) => {
      const { identifier, value, itemIndex } = action.payload;
      state.template.create[identifier] = state.template.create[
        identifier
      ]?.filter(
        (
          item: IMedicine | ITest | ITagOtherHealthProblemDiagnosis,
          index: number
        ) => (item.name ?? item) !== value && index !== itemIndex
      );
      state.create[identifier] = state.create[identifier]?.filter(
        (
          item: IMedicine | ITest | ITagOtherHealthProblemDiagnosis,
          index: number
        ) => (item.name ?? item) !== value && index !== itemIndex
      );
    },
    addValuesToExistingMedicinesTest: (state: any, action) => {
      const { identifier, value, itemIndex, field } = action.payload;
      state.template.create[identifier] = state.template.create[
        identifier
      ]?.map((item: IMedicine | ITest, idx: number) =>
        idx === itemIndex ? { ...item, [field]: value } : item
      );
      state.create[identifier] = state.create[identifier]?.map(
        (item: IMedicine | ITest, idx: number) =>
          idx === itemIndex ? { ...item, [field]: value } : item
      );
    },
    setViewPrescriptionTemplate: (state, action) => {
      state.template.create = action.payload;
      state.template.isCreateTemplate = false;
    },
    setCreateAndResetPrescriptionTemplateDetail: (state) => {
      state.template.isCreateTemplate = true;
      state.template.create = {};
    },
    setMakeCopyPrescriptionTemplate: (state) => {
      state.template.isCreateTemplate = true;
    },
    setPrescriptionTemplateValues: (state: any, action) => {
      state.create = action.payload;
    },
    setIsCameraOn: (state) => {
      state.isCameraOn = !state.isCameraOn;
    },
    setIsCreateCameraPrescription: (state) => {
      state.isCreateCameraPrescription = !state.isCreateCameraPrescription;
    },
    setIsPrescriptionCaptured: (state, action) => {
      state.capturedImage = action.payload.capturedImage;
      state.isCapturedSave = action.payload.isCapturedSave;
    },
    setIsCameraPrescriptionInitiated: (state, action) => {
      state.isCameraPrescriptionInitiated = action.payload;
    },
    setUploadedS3URL: (state, action) => {
      state.uploadedS3URL = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrescriptionConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPrescriptionConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.config = action.payload.result.prescription;
        }
      })
      .addCase(fetchPrescriptionConfig.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchPrescriptionTemplateList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPrescriptionTemplateList.fulfilled, (state, action) => {
        state.isLoading = false;
        if ("result" in action.payload) {
          state.template.list = action.payload.result;
        }
      })
      .addCase(fetchPrescriptionTemplateList.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setVitals,
  setPrescriptionValues,
  removePrescriptionItem,
  setMedicineTest,
  setUploadReportByCamera,
  setPrescriptionTemplate,
  removePrescriptionTemplateItem,
  addValuesToExistingMedicinesTest,
  setViewPrescriptionTemplate,
  setCreateAndResetPrescriptionTemplateDetail,
  setMakeCopyPrescriptionTemplate,
  setPrescriptionTemplateValues,
  setIsCameraOn,
  setIsCreateCameraPrescription,
  setIsPrescriptionCaptured,
  setIsCameraPrescriptionInitiated,
  setUploadedS3URL,
} = prescriptionSlice.actions;
export default prescriptionSlice.reducer;
