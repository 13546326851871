import { downloadReport, isClinic, transformClasses } from "..";

import {
  IDoctorConsultationSelection,
  IProfileDropdownEnums,
  IKeyPairsValues,
  IMedicalRecordTypeEnum,
  IPatientDetailEnumWeb,
} from "../../models/doctor";

import style from "./index.module.css";

import ChatIcon from "../../assets/chat.png";
import Team1 from "../../assets/team-1.png";
import Team2 from "../../assets/team-2.png";
import Team3 from "../../assets/team-3.png";

import GetImages from "../../components/GetImages";
import { useAppDispatch } from "../../store/hooks";
import { showHideAddMember } from "../../store/slices/videoAppointment";

import { IOrderTypeEnums } from "../../models/ApiRequestResponse/patient";
import {
  IOrderStatusEnum,
  IProfileAnalyticsEnum,
} from "../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../models/role";

import { socialPlatformLinks } from "../Config";

export const getStartedTutorial = [
  {
    image: <GetImages name="StayConnectedImage" width="304" height="245" />,
    title: "stayConnectedTitle",
    description: "stayConnectedDescription",
  },
  {
    image: <GetImages name="TestOnlineImage" width="268" height="279" />,
    title: "bookTestTitle",
    description: "bookTestDescription",
  },
  {
    image: <GetImages name="BookDoctorImage" width="310" height="199" />,
    title: "bookDoctorTitle",
    description: "bookDoctorDescription",
  },
];

export const locationAccessDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="ClinicIcon" width="20" height="20" />,
    title: "clinicsText",
    subHeding: "nearbyText",
  },
  {
    icon: <GetImages name="LocationAccessIcon" width="36" height="36" />,
    title: "directionsText",
    subHeding: "toThemText",
  },
];

export const appointmentActionList = [
  {
    identifier: IPatientDetailEnumWeb.CANCEL,
    label: "Cancel",
    icon: "CancelIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RESCHEDULE,
    label: "Reschedule",
    icon: "RescheduleIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.PRESCRIPTION,
    label: "Prescription",
    icon: "PrescriptionWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
];

export const ipDashboardActionList = [
  {
    identifier: IPatientDetailEnumWeb.CLINICAL_TEAM,
    label: "Clinical Team",
    icon: "searchDoctor",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.CHAT,
    label: "Chat",
    icon: "ChatIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.IP_DETAIL,
    label: "Details",
    icon: "SettingGreyedIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
  {
    identifier: IPatientDetailEnumWeb.RECORD,
    label: "Record",
    icon: "RecordWebIcon",
    labelStyle: style.actionText,
    backStyle: style.actionEventTriggerContainer,
  },
];

export const clinicTeamList = [
  {
    userId: "USR-1",
    name: "Chat",
    role: "JR_DOCTOR",
    profilePhoto: ChatIcon,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-2",
    name: "Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team1,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-3",
    name: "Jr Doctor",
    role: "JR_DOCTOR",
    profilePhoto: Team2,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
  {
    userId: "USR-4",
    name: "Co-or...",
    role: "JR_DOCTOR",
    profilePhoto: Team3,
    phoneNumber: "9999955555",
    countryCode: "+91",
    age: 10,
    gender: "Male",
  },
];

export const videoToolbarTop = () => {
  const dispatch = useAppDispatch();
  const videoToolbarTopList = [
    {
      icon: "VideoChatIcon",
      css: style.btnLayer,
      // handler: () => {
      //   dispatch(setIsVideoCallChatInitiated({ value: true }));
      //   dispatch(setIsVideoMinimizedOrPrescInitiated({ value: true }));
      // },
    },
    {
      icon: "AddMemberIcon",
      css: transformClasses(style.btnLayer, style.spacing),
      handler: () => dispatch(showHideAddMember()),
    },
    {
      icon: "CameraSwitchIcon",
      css: style.btnLayer,
      handler: () => {},
    },
  ];
  return { videoToolbarTopList };
};

export const socialLinks = [
  {
    iconName: "InstagramIcon",
    link: socialPlatformLinks?.instagram,
  },
  {
    iconName: "FBIcon",
    link: socialPlatformLinks?.facebook,
  },
  {
    iconName: "YoutubeIcon",
    link: socialPlatformLinks?.youtube,
  },
  {
    iconName: "LinkedInIcon",
    link: socialPlatformLinks?.linkedin,
  },
];

export const contactBtns = (token: string | null) => [
  {
    preText: null,
    btnText: "Call Us",
    iconName: "TelePhoneIcon",
    link: "tel:+919876543210",
    type: "tel",
  },
  {
    preText: null,
    btnText: "FAQ",
    iconName: "BookPlusIcon",
    link: "/faq",
    type: "route",
  },
  ...(token
    ? []
    : [
        {
          preText: "Already have a Account ?",
          btnText: "Log in",
          iconName: null,
          link: "/",
          type: "route",
        },
      ]),
];

export const homeTitleDetail = [
  {
    icon: <GetImages name="DoctorSearchIcon" width="20" height="20" />,
    title: "doctors",
    subHeding: "available",
    moveTo: "/find-doctor",
  },
  {
    icon: <GetImages name="MedicineSmallIcon" width="20" height="20" />,
    title: "medicines",
    subHeding: "free_delivery",
    moveTo: "/medicines",
  },
  {
    icon: <GetImages name="TestSmallIcon" width="20" height="20" />,
    title: "tests",
    subHeding: "flat_off",
    moveTo: "/test-reports",
  },
];

export const medicalRecordFilterList = [
  {
    label: "All",
    value: IMedicalRecordTypeEnum.All,
  },
  {
    label: "Health Categories",
    value: IMedicalRecordTypeEnum.HEALTHCATEGORY,
  },
  {
    label: "Prescriptions",
    value: IMedicalRecordTypeEnum.PRESCRIPTION,
  },
  {
    label: "Reports",
    value: IMedicalRecordTypeEnum.REPORT,
  },
];

export const orderFilterList = [
  {
    label: "All",
    value: IOrderTypeEnums.ALL,
  },
  {
    label: "Clinic Visit",
    value: IOrderTypeEnums.IN_CLINIC,
  },
  {
    label: "Video Call",
    value: IOrderTypeEnums.VIDEO_CONSULTATION,
  },
  {
    label: "Medicine",
    value: IOrderTypeEnums.MEDICINE,
  },
  {
    label: "Tests",
    value: IOrderTypeEnums.TESTS,
  },
];

export const faqAllTag = [
  {
    id: "all",
    tag: "All",
  },
];

export const allFilterList = [
  {
    label: "All",
    value: "all",
  },
];

export const metriceLabelByStatus: IKeyPairsValues = {
  [IProfileAnalyticsEnum.TOTAL_SALES]: "Total Sales",
  [IOrderStatusEnum.PENDING]: "Order Request",
  [IOrderStatusEnum.CONFIRMED]: "Order Accepted",
  [IOrderStatusEnum.REJECTED]: "Order Rejected",
  [IOrderStatusEnum.COMPLETED]: "Order Completed",
  [IOrderStatusEnum.CANCELLED]: "Order Cancelled",
};

export const offeringData = [
  {
    icon: "searchDoctor",
    title: "Consult Top Neuro Doctors",
    desc: "Connect instantly with Top Neuro Specialist through Video consultation or Nearby Clinics",
  },
  {
    icon: "searchDoctor2",
    title: "Choose Free Second Opinion",
    desc: "Get Free Second opinion of your MRI reports and Check you need Surgery of Not ",
  },
  {
    icon: "MedicineNavIcon",
    title: "Get Flat 10% OFF on Medicine and Tests",
    desc: "Order all Neuro Medicines and Test from The Brain Spine App and Get Flat 10% OFF",
  },
  {
    icon: "contactIcon",
    title: "Get 24X7 Helpline Support",
    desc: "Get 24X7 Support For any Queries related to Appointment Booking or Medical Emergency",
  },
  {
    icon: "plusDropIcon",
    title: "Get free 30 Days Medical Support",
    desc: "Avail 30 Days Free Medical Support in case on any Medical Emergencies",
  },
];
export const statsData = [
  { name: "Specialty", number: 10 },
  { name: "Brain and Spine Surgeries", number: 10000 },
  { name: "Patients Life Touched", number: 60000 },
];

export enum IDeleteAfterEnum {
  NEVER = "Never",
  SELECT_DATE = "Select a Date",
}

export const daysList = [
  {
    available: true,
    value: "Mon",
  },
  {
    available: true,
    value: "Tue",
  },
  {
    available: true,
    value: "Wed",
  },
  {
    available: true,
    value: "Thu",
  },
  {
    available: true,
    value: "Fri",
  },
  {
    available: true,
    value: "Sat",
  },
  {
    available: true,
    value: "Sun",
  },
];

export const deleteAfterList = [
  {
    label: IDeleteAfterEnum.NEVER,
    value: IDeleteAfterEnum.NEVER,
  },
  {
    label: IDeleteAfterEnum.SELECT_DATE,
    value: IDeleteAfterEnum.SELECT_DATE,
  },
];

export const typeOfConsultation = [
  {
    label: IDoctorConsultationSelection.IN_CLINIC,
    value: "1",
  },
  {
    label: IDoctorConsultationSelection.VIDEO_CONSULTATION,
    value: "2",
  },
];

export const consultationTypes: IKeyPairsValues = {
  "1": IDoctorConsultationSelection.IN_CLINIC,
  "2": IDoctorConsultationSelection.VIDEO_CONSULTATION,
};

export const availabilityInputsNames: IKeyPairsValues = {
  startTime: "startTime",
  endTime: "endTime",
  slotDuration: "slotDuration",
  bufferDuration: "bufferDuration",
  consultationFee: "consultationFee",
  appointmentDisplay: "appointmentDisplay",
  totalSlots: "totalSlots",
};

const notificationDropdownItems = [
  {
    iconName: "NotificationBellIcon",
    text: "notification",
    identifier: IProfileDropdownEnums.NOTIFICATION,
  },
];
const logoutDropdownItems = [
  {
    iconName: "LogoutIcon",
    text: "logout",
    identifier: IProfileDropdownEnums.LOGOUT,
  },
];

const patientProfileMenuItems = [
  {
    iconName: "CartOrdersIcon",
    text: "your_orders",
    identifier: IProfileDropdownEnums.YOUR_ORDERS,
  },
  {
    iconName: "ProfileChatIcon",
    text: "chat",
    identifier: IProfileDropdownEnums.CHAT,
  },
  {
    iconName: "MemeberGroupIcon",
    text: "profiles",
    identifier: IProfileDropdownEnums.MEMBER_PROFILE,
  },
];
export const profileDropdownItems = [
  {
    iconName: "SmileSmallIcon",
    text: "profile",
    identifier: IProfileDropdownEnums.PROFILE,
  },
];

const helpSupportDropdownItem = [
  {
    iconName: "HelpSupportIcon",
    text: "help_support",
    identifier: IProfileDropdownEnums.HELP_SUPPORT,
  },
];

const doctorProfileDropdownItems = [
  ...profileDropdownItems,
  {
    iconName: "SettingSmallIcon",
    text: "availability",
    identifier: IProfileDropdownEnums.AVAILABILITY_SETTINGS,
  },
  {
    iconName: "StarSmallIcon",
    text: "reviews",
    identifier: IProfileDropdownEnums.REVIEW,
  },
  {
    iconName: "ClinicTeamSmallIcon",
    text: "clinical_team",
    identifier: IProfileDropdownEnums.CLINICAL_TEAM,
  },
  {
    iconName: "TemplatesSmallIcon",
    text: "templates",
    identifier: IProfileDropdownEnums.TEMPLATES,
  },
  ...helpSupportDropdownItem,
  ...notificationDropdownItems,
  ...logoutDropdownItems,
];

const pharmacyDiagnosticDropdownItems = [
  ...profileDropdownItems,
  ...notificationDropdownItems,
  ...logoutDropdownItems,
];

export const doctorPharmacyDiagnosticDropdownNavItems: any = {
  [IRoleType.DOCTOR]: doctorProfileDropdownItems,
  [IRoleType.JR_DOCTOR]: doctorProfileDropdownItems,
  [IRoleType.PHARMACY]: pharmacyDiagnosticDropdownItems,
  [IRoleType.DIAGNOSTIC_CENTER]: pharmacyDiagnosticDropdownItems,
};

export const patientDropdownNavItem = [
  ...patientProfileMenuItems,
  ...logoutDropdownItems,
];

export const patientTopNavItems = [
  {
    path: "/home",
    label: "home",
  },
  {
    path: "/find-doctor",
    label: "doctors",
  },
  {
    path: "/medicines",
    label: "medicines",
  },
  {
    path: "/test-reports",
    label: "tests",
  },
  {
    path: "/health-hub",
    label: "health",
  },
];

const doctorBottomNavigation = [
  {
    path: "/schedule",
    icon: "ScheduleIcon",
    selectedIcon: "ScheduleSelectedIcon",
    label: "schedule",
  },
  {
    path: "/ip-dash",
    icon: "IpDashIcon",
    selectedIcon: "IpDashSelectedIcon",
    label: "ip_dash",
  },
  {
    path: "/chats",
    icon: "ChatIcon",
    selectedIcon: "ChatSelectedIcon",
    label: "chat",
  },
  {
    path: "/profile",
    icon: "ProfileIcon",
    selectedIcon: "ProfileSelectedIcon",
    label: "profile",
  },
];

const pharmacyBottomNavigation = [
  {
    path: "/orders",
    icon: "OrderSmileIcon",
    selectedIcon: "OrderSmileSelectedIcon",
    label: "orders",
  },
  {
    path: "/clinic-patient",
    icon: "MyPatientsIcon",
    selectedIcon: "MyPatientsSelectedIcon",
    label: "my_patients",
  },
  {
    path: "/profile-details",
    icon: "PharmacyProfileSmileIcon",
    selectedIcon: "PharmacyProfileSmileSelectedIcon",
    label: "profile",
  },
];

const patientBottomNavigation = [
  {
    path: "/home",
    icon: "HomeIcon",
    selectedIcon: "HomeSelectedIcon",
    label: "home",
  },
  {
    path: "/find-doctor",
    icon: "DoctorIcon",
    selectedIcon: "DoctorSelectedIcon",
    label: "doctors",
  },
  {
    path: "/medicines",
    icon: "MedicineIcon",
    selectedIcon: "MedicineSelectedIcon",
    label: "medicines",
  },
  {
    path: "/test-reports",
    icon: "TestIcon",
    selectedIcon: "TestSelectedIcon",
    label: "tests",
  },
  {
    path: "/health-hub",
    icon: "TvIcon",
    selectedIcon: "TvSelectedIcon",
    label: "health",
  },
];
export const bottomNavigation = {
  [IRoleType.PATIENT]: patientBottomNavigation,
  [IRoleType.DOCTOR]: doctorBottomNavigation,
  [IRoleType.JR_DOCTOR]: doctorBottomNavigation,
  [IRoleType.PHARMACY]: pharmacyBottomNavigation,
};

export const patientFooterData = {
  secondColumnLinks: [
    {
      title: "company",
      items: [
        "patient_side",
        "healthy_individual",
        "about_us",
        "our_app",
        "careers",
        "contact",
      ],
    },
    {
      title: "terms_utility",
      items: [
        "cookies_policy",
        "terms_and_conditions",
        "privacyPolicyText",
        "licenses",
      ],
    },
  ],
  thirdColumnLinks: [
    {
      title: "patient",
      items: ["treatment", "success_stories"],
    },
    {
      title: "health_hub",
      items: ["exercise_videos", "health_tips"],
    },
    {
      title: "faq",
      items: [],
    },
  ],
  fourthColumnLinks: [
    {
      title: "featured",
      items: [
        "appointment_booking",
        "second_opinion",
        "find_doctor",
        "free_MRI_CT_review",
        "medicine_delivery",
        "international_patient",
        "mental_and_brain_check",
        "spine_health_check",
      ],
    },
  ],
  socialIconsList: socialLinks,
};

export const doctorPharmacyDiagnosticDesktopTopNav = () => ({
  [IRoleType.DOCTOR]: [
    {
      path: "/schedule",
      icon: <GetImages name="ScheduleLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedLargeIcon" width="32" height="32" />
      ),
      label: "schedule",
    },
    {
      path: "/ip-dash",
      icon: <GetImages name="IpDashLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="IpDashLargeSelectedIcon" width="32" height="32" />
      ),
      label: "ip_dashboard",
    },
    {
      path: "/opd-reception",
      icon: <GetImages name="ReceptionIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="ReceptionSelectedIcon" width="32" height="32" />
      ),
      label: "reception",
    },
  ],
  [IRoleType.PHARMACY]: [
    ...(isClinic()
      ? [
          {
            path: "/clinic-patient",
            icon: <GetImages name="MyPatientIcon" width="36" height="36" />,
            selectedIcon: (
              <GetImages name="MyPatientSelectedIcon" width="36" height="36" />
            ),
            label: "my_patients",
          },
        ]
      : []),
    {
      path: "/orders",
      icon: <GetImages name="PharmacyOrdersIcon" width="36" height="36" />,
      selectedIcon: (
        <GetImages name="PharmacyOrdersSelectedIcon" width="36" height="36" />
      ),
      label: "orders",
    },
  ],
  [IRoleType.DIAGNOSTIC_CENTER]: [
    {
      path: "/orders",
      icon: <GetImages name="ScheduleLargeIcon" width="32" height="32" />,
      selectedIcon: (
        <GetImages name="ScheduleSelectedLargeIcon" width="32" height="32" />
      ),
      label: "orders",
    },
  ],
});

export const doctorSpecialitiesBreakpoints = {
  0: {
    slidesPerView: 1.9,
    spaceBetween: 10,
  },
  450: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 3.5,
    spaceBetween: 40,
  },
  900: {
    slidesPerView: 5,
    spaceBetween: 30,
  },
  1400: {
    slidesPerView: 6,
    spaceBetween: 30,
  },
  1600: {
    slidesPerView: 8,
    spaceBetween: 50,
  },
};
export enum IShareButtonIcon {
  GMAIL = "RecordsGmailIcon",
  WHATSAPP = "RecordsWhatsappIcon",
  PRINT = "RecordsPrintIcon",
}

export interface IShareButton {
  icon: IShareButtonIcon;
  onClick: (url?: string, title?: string) => void;
}

const handleGmailShare = (url: string, title: string) => {
  const body = `Please find link: ${url}`;
  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&su=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(body)}`;
  window.open(gmailUrl, "_blank");
};

const handleWhatsAppShare = (url: string, title: string) => {
  const text = `${title}: ${url}`;
  const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(text)}`;
  window.open(whatsappUrl, "_blank");
};

export const shareButtons: IShareButton[] = [
  {
    icon: IShareButtonIcon.GMAIL,
    onClick: (pdfUrl: string | undefined, title: string | undefined) => {
      if (pdfUrl && title) handleGmailShare(pdfUrl, title);
    },
  },
  {
    icon: IShareButtonIcon.WHATSAPP,
    onClick: (pdfUrl: string | undefined, title: string | undefined) => {
      if (pdfUrl && title) handleWhatsAppShare(pdfUrl, title);
    },
  },
  {
    icon: IShareButtonIcon.PRINT,
    onClick: (pdfUrl: string | undefined) => {
      if (pdfUrl) downloadReport(pdfUrl, "prescription");
    },
  },
];
