import { IStatusCodeMessage } from "..";

import {
  IDoctorScheduleConsultationType,
  IDoctorScheduleOrderType,
  IFindDoctorAvailabilityPlatform,
} from "../doctor";
import { IMedicalRecord } from "./doctor";

import { IAssessmentResultEnums } from "../patient";

export interface IReserveAppointmentPatientRequest {
  doctorId?: string;
  patientId?: string;
  memberId?: string;
  clinicId?: string;
  slotId: string;
  consultationType: IDoctorScheduleConsultationType;
  paymentMethodId?: number;
  paymentAmount: number;
  patientName?: string;
  patientAge?: string;
  patientGender?: string;
}

export interface IReserveAppointmentDetail {
  bookingId: string;
  slotNo: number;
}

export interface IReserveAppointmentInfo {
  result: IReserveAppointmentDetail;
}

export type IReserveAppointmentResponse = IStatusCodeMessage &
  IReserveAppointmentInfo;

export interface ICreateEditMemberRequest {
  name?: string;
  gender?: string;
  age?: string;
  is_default: boolean;
}

export interface IClinic {
  id: string;
  name: string;
  address: string;
  city: string;
  pincode: string;
  phone_number: string;
  services: string[];
  photo_url: string;
  consultation_fee: number;
  latitude: number;
  longitude: number;
  distance?: number;
}

export interface IQualificationDetail {
  year: number;
  certificate: string;
  degree_name: string;
  college_name: string;
}

export interface IDoctorProfleDetail {
  id: string;
  name: string;
  photo_url: string;
  experience_years: number;
  speciality: string;
  gender: string;
  language: string[];
  qualification_details: IQualificationDetail[];
  doctor_registration_no: string;
  about_me: string;
  tags: string;
  expertise: string;
  clinics: IClinic[];
  reviews: number;
  videoReviews: any[];
  commentedReviews: any[];
  averageRating: string;
}
export interface IDoctorProfleInfo {
  result: IDoctorProfleDetail;
}

export type IDoctorProfile = IStatusCodeMessage & IDoctorProfleInfo;

export interface IDoctor {
  doctor_id: string;
  doctor_name: string;
  doctor_photo_url: string;
  speciality: string;
  year_of_experience: number;
  qualification_details: IQualificationDetail[];
  language: string[];
  doctor_registered_on: string;
  clinic_id: string;
  clinic_name: string;
  clinic_address: string;
  clinic_phone_number: string;
  clinic_photo_url: string;
  distance: number;
  next_in_clinic_slot: any;
  next_video_consultation_slot: string;
  in_clinic_consultation_fee: any;
  video_consultation_fee: number;
  type: IFindDoctorAvailabilityPlatform;
  reviews: number;
  commentedReviews: any[];
}

export interface IFindDoctorDetail {
  doctors: IDoctor[];
  limit: number;
  offset: number;
}
export interface IFindDoctorInfo {
  result: IFindDoctorDetail;
}
export type IFindDoctorList = IStatusCodeMessage & IFindDoctorInfo;

export interface IFindDoctorFilter {
  speciality: string[];
  experience: number[];
  TypeOfConsultation: IDoctorScheduleConsultationType[];
  language: string[];
  distance: number[];
}
export interface IFindOrderFilter {
  TypeOfConsultation: IDoctorScheduleOrderType[];
  date: string;
  status: string[];
}

export interface IFindDoctorFilterConfig {
  findDoctor: IFindDoctorFilter;
}
export interface IFindOrderFilterConfig {
  findOrder: IFindOrderFilter;
}

export interface IMemberListDetail {
  id: string;
  name?: string;
  gender: string;
  age?: number;
  phone_number: string;
  country_code: string;
  is_default: boolean;
  is_self_member: boolean;
}

export interface IMemeberListInfo {
  result: IMemberListDetail[];
}

export type IMemeberList = IStatusCodeMessage & IMemeberListInfo;

export interface ICreateMemberInfo {
  result: string;
}

export type ICreateMember = IStatusCodeMessage & ICreateMemberInfo;

export interface IUpdateMemberInfo {
  result: boolean;
}
export type IUpdateMember = IStatusCodeMessage & IUpdateMemberInfo;

export interface IPatientProfileDetail {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: any;
  country_code: string;
  phone_number: string;
  user_id: string;
  language: string;
  age: any;
  gender: any;
  members: IMember[];
}

export interface IPatientProfileInfo {
  result: IPatientProfileDetail;
}

export type IPatientProfile = IStatusCodeMessage & IPatientProfileInfo;

export interface IPatientProfileUpdateRequest {
  name: string;
  age: number;
  gender: string;
}

export interface IPatientPhoneNumber {
  phone_number: string;
  country_code: string;
}

export interface ISpecialitiesList {
  speciality: string;
  image_url: string;
  description: string;
  doctors: number;
}

export interface ISpecialitiesListInfo {
  result: ISpecialitiesList[];
}

export type ISpecialitiesListResponse = IStatusCodeMessage &
  ISpecialitiesListInfo;

export interface IDoctorReviewRequest {
  doctorId: string;
  bookingId: string;
  noOfStars: number;
  reviewMessage: string;
}

export interface IDoctorReviewInfo {
  result: string;
}

export type IDoctorReviewResponse = IStatusCodeMessage & IDoctorReviewInfo;

export interface IPendingAppointment {
  id: string;
  name: string;
  experience_years: number;
  speciality: string;
  profile_photo?: string;
  slot_start_time: string;
  slot_end_time: string;
  slot_time: number;
  status: IOrderStatus;
  qualification_details: QualificationDetail2[];
  consultation_type: IDoctorScheduleConsultationType;
  liveQueue?: number;
}

export interface IBanner {
  id: string;
  appUrl: string;
  webUrl: string;
  is_deleted: boolean;
  type: string;
  action: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IVideo {
  id: string;
  video_url: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  language: string;
  category: string;
  type: string;
  is_active: boolean;
  is_deleted: boolean;
  createdAt: string;
  updatedAt: string;
}
export interface IPlaylist {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  language: string;
  is_deleted: boolean;
  number: number;
  createdAt: string;
  updatedAt: string;
  videos: IVideo[];
}

export interface ICompleteAppointment {
  id: string;
  schedule_id: string;
  slot_no: number;
  clinic_id: string;
  consultation_type: IDoctorScheduleConsultationType;
  doctor_id: string;
  name: string;
  experience_years: number;
  speciality: string;
  qualification_details: QualificationDetail2[];
  slot_start_time: string;
  slot_end_time: string;
  status: IOrderStatus;
  slot_time: number;
  slot_id: string;
  address: string;
  phone_number: string;
}

export interface IMember {
  id: string;
  user_id: string;
  name: string;
  age: number;
  gender: string;
  is_deleted: boolean;
  is_default: boolean;
  is_self_member: boolean;
  created_at: string;
  updated_at: string;
  createdAt: string;
  updatedAt: string;
  phone_number?: string;
}

export interface ISpeciality {
  speciality: string;
  doctors: number;
  id: string;
  image_url: string;
  description: string;
  is_deleted: boolean;
  created_at: string;
  updated_at: string;
  createdAt: string;
  updatedAt: string;
}

type Speciality = {
  speciality_id: string;
  speciality: {
    speciality: string;
  };
};

export interface ISymptom {
  id: string;
  imageUrl: string;
  is_deleted: boolean;
  name: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
  speciality: Speciality[];
}

export enum IHealthAssesmentTypeEnums {
  Spine = "Spine",
  Brain = "Brain",
  HEAD = "Head",
}
export interface IHealthAssessment {
  type: IHealthAssesmentTypeEnums;
  count: number;
}
export interface QualificationDetail2 {
  year: number;
  certificate: string;
  degree_name: string;
  college_name: string;
}

export enum IMedicineOrderType {
  HOME_DELIVERY = "HOME_DELIVERY",
  PICKUP = "PICKUP",
}
export interface ICompleteOrPendingMedicineOrder {
  id: string;
  memberid?: string;
  pharmacyid?: string;
  type?: IMedicineOrderType;
  status: IOrderStatus;
  medicalrecordid?: string;
  createdat: string;
  name: string;
  pharmacyname?: string;
  pharmacyprofilephoto?: string;
  address: string;
  city?: string;
  pincode?: string;
  latitude?: number;
  longitude?: number;
  tracking_id?: string;
  delivery_partner?: string;
}

export interface ITestBookingDetail {
  id: string;
  order_no?: string;
  memberid?: string;
  diagnosticid?: string;
  status: IOrderStatus;
  date?: string;
  name: string;
  city?: string;
  address: string;
  pincode?: string;
  photo?: string;
  tests?: string[];
  phone_number?: string;
  latitude?: number;
  longitude?: number;
  ordertype?: string;
}

export interface IHomeDetail {
  pendingAppointment: IPendingAppointment[];
  banners: IBanner[];
  speciality: ISpeciality[];
  symptoms: ISymptom[];
  healthAssessment: IHealthAssessment[];
  playlist: IHealthHubPlaylistResult;
  completeAppointment: ICompleteAppointment[];
  members: IMember[];
  recentMedicineOrder: ICompleteOrPendingMedicineOrder[];
  pendingMedicineOrder: ICompleteOrPendingMedicineOrder[];
  pendingTestBooking: ITestBookingDetail[];
  recentTestBooking: ITestBookingDetail[];
}

export interface IHomeInfo {
  result: IHomeDetail;
}
export type IHomeResponse = IStatusCodeMessage & IHomeInfo;

export interface IAppLogout {
  result: boolean;
}

export type IAppLogoutResponse = IStatusCodeMessage & IAppLogout;

export interface IFAQTag {
  id: string;
  tag: string;
  createdAt: string;
  updatedAt: string;
}

export interface IFAQTagDetail {
  result: IFAQTag[];
}

export type IFAQTagResponse = IStatusCodeMessage & IFAQTagDetail;

export interface IFAQDetail {
  id: string;
  question: string;
  answer: string;
  tag: string;
  createdAt: string;
  updatedAt: string;
}

export interface IFAQInfo {
  result: IFAQDetail[];
}

export type IFAQResponse = IStatusCodeMessage & IFAQInfo;

export interface IHealthAssessmentBanner {
  id: string;
  appUrl: string;
  webUrl: string;
  is_deleted: boolean;
  type: string;
  action: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IAssessment {
  id: string;
  name: string;
  image_url: string;
  type: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IHealthAssessmentDetail {
  banner: IHealthAssessmentBanner;
  assessments: IAssessment[];
}

export interface IHealthAssessmentInfo {
  result: IHealthAssessmentDetail;
}

export type IHealthAssessmentResponse = IStatusCodeMessage &
  IHealthAssessmentInfo;

export interface IPastResult {
  id: string;
  assessment_id: string;
  heading: IAssessmentResultEnums;
  message: string;
  speciality: string;
  suggestion_text: string;
  start_score: number;
  end_score: number;
  playlist: string;
  createdAt: string;
  updatedAt: string;
  created_at?: string;
}

export enum IQuestionType {
  MCQ = "MCQ",
  TEXT = "TEXT",
  CHECKBOX = "CHECKBOX",
}
export interface IOptions {
  text: string;
  weight: number;
  sequence: number;
  is_correct: boolean;
}

export interface IQuestion {
  id: string;
  assessment_id: string;
  question_text: string;
  type: IQuestionType;
  weight: number;
  sequence: number;
  createdAt: string;
  updatedAt: string;
  options: IOptions[];
}

export interface IHealthAssesmentQuestionDetail {
  pastResult: IPastResult;
  questions: IQuestion[];
}

export interface IHealthAssesmentQuestion {
  result: IHealthAssesmentQuestionDetail;
}

export type IHealthAssesmentQuestionResponse = IStatusCodeMessage &
  IHealthAssesmentQuestion;

export interface IHealthAssessmentQuestion {
  id: string;
  name: string;
  image_url: string;
  type: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IHealthAssessmentQuestionBanner {
  id: string;
  appUrl: string;
  webUrl: string;
  is_deleted: boolean;
  type: string;
  action: string;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IHealthAssessmentQuestionSubmission {
  id: string;
  assessment_id: string;
  heading: IAssessmentResultEnums;
  message: string;
  speciality: string[];
  suggestion_text: string;
  start_score: number;
  end_score: number;
  playlist: string[];
  createdAt: string;
  updatedAt: string;
  assessments: IHealthAssessmentQuestion[];
  banner: IHealthAssessmentQuestionBanner;
  specialities: ISpeciality[];
  playlists: IPlaylist[];
}

export interface IHealthAssessmentQuestionSubmissionInfo {
  result: IHealthAssessmentQuestionSubmission;
}

export type IHealthAssessmentQuestionSubmissionResponse = IStatusCodeMessage &
  IHealthAssessmentQuestionSubmissionInfo;

export interface IHealthAssessmentQuestionSubmissionRequest {
  assessment_id: string;
  score: number;
}

export enum IOrderTypeEnums {
  ALL = "ALL",
  IN_CLINIC = "IN_CLINIC",
  VIDEO_CONSULTATION = "VIDEO_CONSULTATION",
  MEDICINE = "MEDICINE",
  TESTS = "TESTS",
}

export enum IOrderStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  REJECTED = "REJECTED",
  ONGOING = "ONGOING",
  UPLOADED = "UPLOADED",
  UPCOMING = "UPCOMING",
}
export interface IQualificationDetail {
  year: number;
  certificate: string;
  degree_name: string;
  college_name: string;
}

export interface IAllOrdersDetail {
  id: string;
  schedule_id?: string;
  slot_no?: number;
  clinic_id?: string;
  consultation_type?: IDoctorScheduleConsultationType;
  doctor_id?: string;
  name: string;
  experience_years?: number;
  speciality?: string;
  qualification_details?: IQualificationDetail[];
  slot_start_time?: string;
  slot_end_time?: string;
  status: IOrderStatus;
  slot_time?: number;
  slot_id?: string;
  address: string;
  phone_number?: string;
  createdat: string;
  ordertype: string;
  liveQueue?: number;
  memberid?: string;
  pharmacyid?: string;
  type?: IMedicineOrderType;
  medicalrecordid?: string;
  pharmacyname?: string;
  pharmacyprofilephoto?: string;
  city?: string;
  pincode?: string;
  latitude?: number;
  longitude?: number;
  member_id?: string;
  patient_id?: string;
  diagnostic_id?: string;
  date?: string;
  photo?: string;
  tests?: string[];
}

export interface IAllOrdersInfo {
  result: IAllOrdersDetail[];
}

export type IAllOrdersResponse = IStatusCodeMessage & IAllOrdersInfo;

export interface IPharmacyClinic {
  startTime: string;
  endTime: string;
}

export interface IPharmacyClinicListing {
  id: string;
  name: string;
  city: string;
  address: string;
  pincode: string;
  distance: number;
  deliveryEnabled: boolean;
  pickupEnabled: boolean;
  profilePhoto: string;
  latitude: number;
  longitude: number;
  startTime: string;
  endTime: string;
  pharmacyClinic: IPharmacyClinic;
}

export interface IPharmacyClinicInfo {
  result: IPharmacyClinicListing[];
}

export type IPharmacyClinicResponse = IStatusCodeMessage & IPharmacyClinicInfo;

export interface IAddDeliveryAddressDetailReqRes {
  id?: string;
  userId?: string;
  city: string;
  address: string;
  pincode: string;
  latitude: number;
  longitude: number;
}

export interface IAddDeliveryAddressDetail {
  result: string;
}

export type IAddDeliveryAddressResponse = IStatusCodeMessage &
  IAddDeliveryAddressDetail;

export interface IPatientDeliveryAddressDetail {
  result: IAddDeliveryAddressDetailReqRes;
}

export type IPatientDeliveryAddressResponse = IStatusCodeMessage &
  IPatientDeliveryAddressDetail;

export interface IHealthCategory {
  id: string;
  name: string;
}

export type IHealthCategoryApiResponse = IStatusCodeMessage & {
  result: IHealthCategory[];
};

export interface IMedicalRecordsPatientDetail {
  records: IMedicalRecord[];
  members: IMember[];
}

export interface IMedicalRecordsPatientInfo {
  result: IMedicalRecordsPatientDetail;
}
export type IMedicalRecordsPatientResponse = IStatusCodeMessage &
  IMedicalRecordsPatientInfo;

export interface IMedicalRecordsByMemberId {
  records: IMedicalRecord[];
}

export interface IMedicalRecordsByMemberIdDetail {
  result: IMedicalRecordsByMemberId;
}
export type IMedicalRecordsByMemberIdResponse = IStatusCodeMessage &
  IMedicalRecordsByMemberIdDetail;

export interface IMedicinePlaceOrderRequest {
  memberId: string;
  pharmacyId: string;
  medicalRecordId: string;
  type: IMedicineOrderType;
}

export interface IMedicineOrderPlacedDetail {
  result: string;
}

export type IMedicineOrderPlacedResponse = IStatusCodeMessage &
  IMedicineOrderPlacedDetail;

export interface IDiagnosticTestList {
  id: string;
  test_category_id: string;
  name: string;
  price: number;
  testCategory: string;
}

export interface IDiagnosticTestInfo {
  result: IDiagnosticTestList[];
}

export type IDiagnosticTestListResponse = IStatusCodeMessage &
  IDiagnosticTestInfo;

export interface IDiagnosticCenterList {
  user_id: string;
  name: string;
  city: string;
  address: string;
  pincode: string;
  distance: number;
  landmark: string;
  profile_photo: string;
  latitude: number;
  longitude: number;
  start_time: string;
  end_time: string;
  working_days: string;
  selectedDate?: number;
}

export interface IDiagnosticCenterInfo {
  result: IDiagnosticCenterList[];
}

export type IDiagnosticCenterListResponse = IStatusCodeMessage &
  IDiagnosticCenterInfo;
export interface IHealthHubData {
  result: IHealthHubResult[];
}

export enum IHealthHubResultType {
  PLAYLIST = "playlist",
  BLOGS = "blogs",
  TREATMENT = "TREATMENT",
  VIDEO = "video",
  VIDEO_UPPER = "VIDEO",
  SHORT = "short",
  SHORT_UPPER = "SHORT",
  LONG_UPPER = "LONG",
}

export enum IHealthHubPlaylistVideoType {
  SHORT = "short",
  LONG = "long",
}
export enum IHealthHubPlaylistVideoTypeUpperCase {
  SHORT = "SHORT",
  LONG = "LONG",
}

export interface IHealthHubBaseResult {
  id: string;
  name: string;
}
export interface IHealthHubPlaylistResult extends IHealthHubBaseResult {
  type: IHealthHubResultType;
  data: IHealthHubPlaylistVideo[];
}

export interface IHealthHubBlogsResult extends IHealthHubBaseResult {
  type: IHealthHubResultType.BLOGS;
  data: IHealthHubBlog[];
}
export interface IHealthHubDiseasesResult extends IHealthHubBaseResult {
  type: IHealthHubResultType.TREATMENT;
  data: IHealthHubDisease[];
}

export type IHealthHubResult =
  | IHealthHubPlaylistResult
  | IHealthHubBlogsResult
  | IHealthHubDiseasesResult;

export enum IHealthHubInteractionType {
  LIKE = "LIKE",
  FAVOURITE = "FAVORITE",
  VIEW = "VIEW",
}

export interface IHealthHubUserInteraction {
  type: IHealthHubInteractionType;
}

export interface IHealthHubPlaylistVideo {
  id: string;
  video_url: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  language: string;
  category: string;
  type: IHealthHubPlaylistVideoType | IHealthHubPlaylistVideoTypeUpperCase;
  is_active: boolean;
  is_deleted: boolean;
  createdAt: string;
  updatedAt: string;
  views: number;
  likes: number;
  user_interaction: IHealthHubUserInteraction[];
}

export interface IHealthHubBlog {
  id: string;
  title: string;
  thumbnail: string;
  is_deleted: boolean;
  description: string;
  createdAt: string;
  updatedAt: string;
  reads: number;
  likes: number;
  content: string;
  tag_id: string;
  user_interaction: IHealthHubUserInteraction[];
}

export interface IHealthHubDiseaseContent {
  id: string;
  title: string;
  thumbnail: string;
  language: string;
  is_deleted: boolean;
  description: string;
  playlist_id: string;
  reads: number | null;
  createdAt: string;
  updatedAt: string;
  content: string;
  likes: number;
  user_interaction: IHealthHubUserInteraction[];
}

export interface IHealthHubDisease {
  id: string;
  name: string;
  icon_url: string;
  createdAt: string;
  updatedAt: string;
  content: IHealthHubDiseaseContent;
}

export type IHealthHubApiResponse = IStatusCodeMessage & IHealthHubData;

export interface IAddToCartReqBody {
  diagnosticId?: string;
  diagnosticTestIds?: string[];
  bookingTimestamp?: number;
  action?: string;
}

export interface IAddToCartInfo {
  result: string;
}

export type IAddToCartReqRes = IStatusCodeMessage & IAddToCartInfo;

export interface Test {
  id: string;
  name: string;
  price: number;
}

export interface Diagnostic {
  id: string;
  name: string;
  address: string;
  city: string;
  pincode: string;
  startTime: string;
  endTime: string;
}
export interface IGetCartResponse {
  id: string;
  diagnosticId: string;
  diagnosticTestIds: string[];
  patientId: string;
  bookingTimestamp: number;
  tests: Test[];
  diagnostic: Diagnostic;
}

export interface IGetCartInfo {
  result: IGetCartResponse;
}
export type IGetCartReqRes = IStatusCodeMessage & IGetCartInfo;

export interface ITestBookingReqBody {
  cartId?: string;
  memberId: string;
}

export interface IDiagnosticCenter {
  name: string;
  address: string;
  city: string;
  pincode: string;
  country_code: string;
  phone_number: string;
  latitude: number;
  longitude: number;
  start_time: string;
  end_time: string;
  tests: string[];
}
export interface ITestBookResponse {
  id: string;
  orderNo: string;
  bookingTimestamp: number;
  diagnosticCenter: IDiagnosticCenter;
}
export interface ITestBookingInfo {
  result: ITestBookResponse;
}

export type ITestBookingRes = IStatusCodeMessage & ITestBookingInfo;

export interface IBannerResultResponse {
  id: string;
  appUrl: string;
  webUrl: string;
  is_deleted: boolean;
  type: string;
  action: any;
  is_active: boolean;
  createdAt: string;
  updatedAt: string;
  content: IHealthHubDiseaseContent;
}

export interface IBannerInfo {
  result: IBannerResultResponse[];
}

export type IBannerResponse = IStatusCodeMessage & IBannerInfo;

export interface IHelpMeChooseDoctorRequest {
  name: string;
  phoneNumber: string;
  countryCode: string;
  note: string;
}

export interface IHelpMeChooseDoctorDetail {
  result: string;
}

export type IHelpMeChooseDoctorResponse = IStatusCodeMessage &
  IHelpMeChooseDoctorDetail;
