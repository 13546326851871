export const styles = {
  ml2: {
    marginLeft: "0.2rem",
  },
  ml6: { marginLeft: "0.6rem" },
  ml8: {
    marginLeft: "0.8rem",
  },
  mb20: {
    marginBottom: "2rem",
  },
};
