import React from "react";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";

import style from "./index.module.css";

import GetImages from "../GetImages";

import { IMedicalRecord } from "../../models/ApiRequestResponse/doctor";

import { downloadReport, pdfRegex, transformClasses } from "../../utils";
import useIsMobile from "../../utils/hooks/useIsMobile";
import useIsTablet from "../../utils/hooks/useIsTablet";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setMedicineOrderPlacedRequest,
  setSelectedMedicalRecords,
} from "../../store/slices/patient/medicine";

interface IGalleryView {
  isGalleryView: boolean;
  isSelection?: boolean;
}
type IProps = IGalleryView & IMedicalRecord;
const MedicalRecordGridList: React.FC<IProps> = (props) => {
  const {
    isSelection = false,
    healthCategory,
    url,
    documentDate,
    doctorName,
    isGalleryView,
    id,
    memberId,
    thumbnailUrl,
  } = props;

  const dispatch = useAppDispatch();

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const { medicineOrderPlaced } = useAppSelector((state) => state.medicine);

  const selectionHandler = () => {
    if (!isSelection) return;
    dispatch(
      setMedicineOrderPlacedRequest({
        key: "medicalRecordId",
        value: id,
      })
    );
    dispatch(
      setMedicineOrderPlacedRequest({
        key: "memberId",
        value: memberId,
      })
    );
    dispatch(
      setSelectedMedicalRecords({ key: "url", value: url })
    );
    dispatch(
      setSelectedMedicalRecords({ key: "documentDate", value: documentDate })
    );
    dispatch(
      setSelectedMedicalRecords({ key: "doctorName", value: doctorName })
    );
  };

  return (
    <Grid
      item
      sx={!isGalleryView ? { width: "100%" } : {}}
      onClick={selectionHandler}
      className={style.notSelected}
    >
      <Grid
        item
        className={transformClasses(
          !(isMobile || isTablet)
            ? style.medicalRecordContainer
            : style.medicalRecordContainerMobile,
          !isGalleryView ? style.medicalRecordList : style.medicalRecordGallery
        )}
      >
        {medicineOrderPlaced?.medicalRecordId === id && (
          <div className={style.selected} />
        )}
        {pdfRegex.test(thumbnailUrl ?? url) ? (
          <iframe title="pdf" src={thumbnailUrl ?? url} />
        ) : (
          <img src={thumbnailUrl ?? url} alt={"medical record"} />
        )}
        <div className={style.imageOverlay}></div>
        <Grid item className={style.downloadIconContainer}>
          <DownloadIcon
            sx={{ color: "var(--neutral-100)" }}
            onClick={() => downloadReport(url, "prescription")}
          />
        </Grid>
        <Grid item className={style.medicalRecordDateCategoryContainer}>
          <Grid item>
            <Typography component={"p"} className={style.categoryName}>
              <img
                src={healthCategory?.icon}
                alt="Category icon"
                width={22}
                height={16}
              />
              {healthCategory?.name?.toLowerCase()}
            </Typography>

            <Typography component={"p"} className={style.recordUploadedDate}>
              {documentDate ? dayjs(documentDate).format("DD MMM, YYYY") : ""}
            </Typography>
          </Grid>
          <Grid item className={style.prescIcon}>
            <GetImages name="PrescriptionSmallIcon" width="20" height="20" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MedicalRecordGridList;
