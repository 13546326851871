import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import GetImages from "../../../GetImages";
import Confirmation from "../../../Confirmation";
import UniversalSearch from "../../../../views/Common/UniversalSearch";
import OrdersRequest from "../../../../views/PharmacyClinicDiagnostic/OrdersRequest";
import ViewMedicalRecord from "../../../Pharmacy/ViewMedicalRecord";

import { IRoleType } from "../../../../models/role";
import {
  IDoctorConsultationSelection,
  IDoctorConsultationType,
  IOrderDateRangeParams,
} from "../../../../models/doctor";
import {
  IAppointmentPermissionEnums,
  IAvailabilitySettingPermissionEnums,
  IRolePermissionAccess,
  IModalKeyEnums,
} from "../../../../models/permission";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  detail,
  fetchAvailabilitySettings,
} from "../../../../store/slices/doctor/availabilitySettings";
import { resetScheduleSelection } from "../../../../store/slices/doctor/schedule";
import { setShowPermissionAlert } from "../../../../store/slices/permission";

import { deleteAvailabilitySettingApi } from "../../../../utils/apis/doctor/availabilitySetting";
import { IAPIStatusCode } from "../../../../utils/apis/APIEndpointConfig";
import { storage } from "../../../../utils/Storage";
import { getPermissionAccess } from "../../../../utils";
import useCancelScheduleAppointments from "../../../../utils/hooks/useCancelScheduleAppointments";
import usePagination from "../../../../utils/hooks/usePagination";
import useMobileTopNavigation from "../../../../utils/hooks/useMobileTopNavigation";
import { INotifyEnum, notify } from "../../../../utils/toaster";

import { setIsShowUniversalSearch } from "../../../../store/slices/config";
import {
  fetchDiagnosticRequestOrdersList,
  fetchMorePharmacyRequestOrdersList,
  fetchPharmacyRequestOrdersList,
  saveSelectedOrder,
} from "../../../../store/slices/pharmacyClinicDiagnostic";

interface INavItem {
  path: string;
  icon: React.ReactNode;
  title: string;
  subText: string;
  subTextHandler?: () => void;
  locationIcon: boolean;
  secondIcon: React.ReactNode;
  isShowSearch: boolean;
}
const MobileTopNavigation: React.FC = () => {
  const [confirmDeleteAvailability, setConfirmDeleteAvailability] =
    useState<boolean>(false);
  const roleType = storage.getSessionToken("roleType");
  const [navItem, setNavItem] = useState<INavItem>();
  const [showCancelSchedule, setShowCancelSchedule] = useState<boolean>(false);
  const [selectedHospital, setSelectedHospital] = useState<string>("");

  const {
    isPendingOrderRequestsOpen,
    isViewPrescriptionOpen,
    pendingOrdersList,
  } = useAppSelector((state) => state.pharmacyClinicDiagnostic);
  const { cartDetails } = useAppSelector((state) => state.tests);
  const { cancelScheduleAppointments } = useCancelScheduleAppointments();

  const { handleFetch } = usePagination<IOrderDateRangeParams>({
    fetchFn: fetchPharmacyRequestOrdersList,
    fetchNextFn: fetchMorePharmacyRequestOrdersList,
  });

  const subText = useLocation();
  const navigate = useNavigate();
  const { generateNavItems } = useMobileTopNavigation();

  const dispatch = useAppDispatch();
  const availabilityDetail = useAppSelector(
    (state) => state.availability.detail
  );
  const scheduleSelection = useAppSelector((state) => state.schedule.selection);

  const { hospitals, selectedHospitalId } = useAppSelector(
    (state) => state.ipDashboard
  );
  const { permission } = useAppSelector((state) => state.rolePermission);
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);

  const universalSearchHandler = () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.APPOINTMENT,
      IAppointmentPermissionEnums.SEARCH_APPOINTMENT,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    dispatch(setIsShowUniversalSearch(true));
  };

  const setNavItemsData = () => {
    const navItems = generateNavItems(
      setConfirmDeleteAvailability,
      selectedHospital,
      pendingOrdersList
    );
    if (!roleType) {
      setNavItem(navItems[IRoleType.PATIENT][0]);
      return;
    }
    if (
      roleType === IRoleType.PATIENT ||
      roleType === IRoleType.DOCTOR ||
      roleType === IRoleType.JR_DOCTOR ||
      roleType === IRoleType.PHARMACY ||
      roleType === IRoleType.DIAGNOSTIC_CENTER
    ) {
      navItems[roleType].forEach((item, index) => {
        if (item.path.includes(subText.pathname)) {
          setNavItem({
            ...item,
            title: selectedHospital ? selectedHospital : item.title,
          });
        }
      });
    }
  };

  const deleteAvailabilitySetting = async () => {
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.AVAILABILITY_SETTING,
      IAvailabilitySettingPermissionEnums.DELETE_AVAILABILITY,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    try {
      const deleteAvailabilityResp = await deleteAvailabilitySettingApi(
        availabilityDetail?.id!
      );
      if (deleteAvailabilityResp.statusCode === IAPIStatusCode.SUCCESS) {
        navigate(-1);
        dispatch(fetchAvailabilitySettings());
        dispatch(detail(null));
        setConfirmDeleteAvailability(!confirmDeleteAvailability);
        notify(INotifyEnum.SUCCESS, deleteAvailabilityResp.message);
      } else {
        notify(INotifyEnum.ERROR, deleteAvailabilityResp.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const openCloseCancelAppointmentConfirmation = () =>
    setShowCancelSchedule(!showCancelSchedule);

  const cancelAppointmentHandler = () => {
    if (
      scheduleSelection?.selectedSchedules?.length === 0 &&
      scheduleSelection?.selectedAppointments?.length === 0
    )
      return;
    const permissionAccess = getPermissionAccess(
      IModalKeyEnums.APPOINTMENT,
      IAppointmentPermissionEnums.DELETE_APPOINTMENT,
      permission
    );
    if (permissionAccess === IRolePermissionAccess.NOT_ACCESSIBLE) {
      dispatch(setShowPermissionAlert());
      return;
    }
    openCloseCancelAppointmentConfirmation();
  };

  useEffect(() => {
    setNavItemsData();
  }, [subText, selectedHospital, pendingOrdersList, cartDetails]);

  useEffect(() => {
    if (subText.pathname.includes("/ip-dash")) {
      const hospital = hospitals.filter(
        (hospital) => hospital.hospital_id === selectedHospitalId
      )[0];
      setSelectedHospital(hospital?.name);
    } else {
      setSelectedHospital("");
    }
  }, [selectedHospitalId, subText.pathname]);

  useEffect(() => {
    dispatch(saveSelectedOrder(null));
    if (roleType === IRoleType.DIAGNOSTIC_CENTER) {
      dispatch(fetchDiagnosticRequestOrdersList({}));
    } else {
      handleFetch({});
    }
  }, []);

  return (
    <>
      <Grid item className={style.topNavigationContainer}>
        <Grid item className={style.topNavigationTitleContainer}>
          {scheduleSelection.isSelectionOn ? (
            <Grid item className={style.selectedScheduleContainer}>
              <CloseIcon
                sx={{ height: 24, weight: 24 }}
                onClick={() => dispatch(resetScheduleSelection())}
              />
              <Grid item className={style.selectedScheduleAppointmentCount}>
                <Typography component={"h4"}>
                  <FormattedMessage
                    id="select_items"
                    defaultMessage="Select items"
                  />
                </Typography>
                <Typography component={"p"}>
                  {scheduleSelection.selectedSchedules.length}{" "}
                  <FormattedMessage id="slots" defaultMessage="Slots" />,{" "}
                  {scheduleSelection.selectedAppointments.length}{" "}
                  <FormattedMessage
                    id="appointments"
                    defaultMessage="appointments"
                  />
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid item className={style.topNavigationIcon}>
                {navItem?.icon}
              </Grid>
              <Grid item className={style.topNavigationTextContainer}>
                <Typography
                  component={"p"}
                  className={style.topNavigationTitle}
                >
                  {navItem?.title}
                </Typography>
                {navItem?.subText && (
                  <Grid item className={style.topNavigationLocation}>
                    {navItem?.locationIcon && (
                      <GetImages
                        name="DoctorNavLocationIcon"
                        width="12"
                        height="12"
                      />
                    )}
                    <Typography
                      component={"p"}
                      className={style.topNavigationLocationText}
                      onClick={() =>
                        navItem?.subTextHandler && navItem?.subTextHandler()
                      }
                    >
                      {navItem?.subText}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
        <Grid item className={style.topNavigationIconContainer}>
          {scheduleSelection.isSelectionOn ||
            (navItem?.isShowSearch && (
              <Grid item onClick={universalSearchHandler}>
                <GetImages name="NavSearchIcon" width="24" height="24" />
              </Grid>
            ))}
          {scheduleSelection.isSelectionOn ||
            (navItem?.secondIcon && (
              <Grid item className={style.topNavigationSecondIconContainer}>
                {navItem?.secondIcon}
              </Grid>
            ))}
          {scheduleSelection.isSelectionOn && (
            <Grid
              item
              className={style.topNavigationSecondIconContainer}
              onClick={cancelAppointmentHandler}
            >
              <GetImages name="CancelIcon" width="28" height="24" />
              <Typography component={"p"} className={style.cancelText}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Typography>
            </Grid>
          )}
        </Grid>
        {confirmDeleteAvailability && (
          <Confirmation
            isOpen={confirmDeleteAvailability}
            closeHandler={() =>
              setConfirmDeleteAvailability(!confirmDeleteAvailability)
            }
            title="delete_availability"
            alterMessage={"confirm_delete"}
            name={
              availabilityDetail?.type_of_consultation ===
              IDoctorConsultationType.IN_CLINIC
                ? availabilityDetail?.clinic_name ?? ""
                : IDoctorConsultationSelection.VIDEO_CONSULTATION
            }
            actionHandler={deleteAvailabilitySetting}
          />
        )}
        {showCancelSchedule && (
          <Confirmation
            isOpen={showCancelSchedule}
            closeHandler={openCloseCancelAppointmentConfirmation}
            title="cancel_appointments"
            alterMessage={"confirm_cancel_appoinment"}
            actionHandler={() => {
              cancelScheduleAppointments();
              openCloseCancelAppointmentConfirmation();
            }}
          />
        )}
      </Grid>
      {isPendingOrderRequestsOpen && <OrdersRequest />}
      {isViewPrescriptionOpen && <ViewMedicalRecord />}
      {isShowUniversalSearch && <UniversalSearch />}
    </>
  );
};

export default MobileTopNavigation;
