import { createTheme } from "@mui/material";

const MUITheme = createTheme();

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: "#F15A29",
      light: "#FFF6EA",
    },
    secondary: {
      light: "#8d8d8d",
      main: "#6F6F6F",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        placeholder: "",
      },
      styleOverrides: {
        root: {
          borderRadius: "0.8rem",
          "& input::placeholder": {
            color: "transparent", // Hides placeholder even if it's set
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--neutral-100)",
          height: "100%",
        },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { fullScreen: true },
          style: {
            "& .MuiPaper-root:not(.MuiPaper-root .MuiPaper-root)": {
              borderRadius: "0px",
            },
          },
        },
      ],
      styleOverrides: {
        paper: {
          backgroundColor: "var(--gray-50)",
          textAlign: "left",
          borderRadius: "2.4rem", // Apply borderRadius only on medium screens and larger
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.8rem",
          boxShadow: "none",
        },
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff",
          cursor: "pointer",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0.8rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: "none",
          borderRadius: "1rem",
          boxShadow: "none",
          "&:first-of-type": {
            borderTopLeftRadius: "1rem",
            borderTopRightRadius: "1rem",
          },
          "&:last-of-type": {
            borderBottomLeftRadius: "1rem",
            borderBottomRightRadius: "1rem",
          },
          "&:before": {
            display: "none",
          },
          // Remove borderRadius on small screens
          [theme.breakpoints.down("md")]: {
            borderRadius: "0",
            "&:first-of-type": {
              borderTopLeftRadius: "0",
              borderTopRightRadius: "0",
            },
            "&:last-of-type": {
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            },
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          "&.Mui-expanded": {
            margin: 0, // Height when expanded
          },
        },
        root: {
          fontSize: 18,
          fontWeight: 500,
          [MUITheme.breakpoints.down("md")]: {
            fontSize: 12,
          },
          "&.Mui-expanded": {
            minHeight: 40, // Height when expanded
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          fontSize: 16,
          fontWeight: 400,
          [MUITheme.breakpoints.down("md")]: {
            fontSize: 10,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          minWidth: "30ch",
        },
        list: {
          p: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid var(--gray-20)",
          paddingInline: 4,
          paddingBlock: 10,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: "none",
        },
      },
    },
  },
});
