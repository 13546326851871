export const universalSearchStyles = {
  container: {
    display: { xs: "block", sm: "block", md: "block", lg: "block" },
    width: "100%",
    overflowX: "hidden",
  },
  searchHeaderContainer: {
    display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
    padding: "1.6rem 4.8rem",
    backgroundColor: "var(--neutral-100)",
    marginBottom: "1.6rem",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    "& input": {
      padding: "1.2rem",
      width: {
        sm: "50rem",
        md: "72rem",
        lg: "72rem",
      },
      outline: "none",
      border: "none",
      borderRadius: "2rem",
      background: "var(--gray-50)",
    },
  },
  searchIcon: {
    marginLeft: "-4rem",
  },
  closeIconContainer: {
    position: "absolute",
    right: "1.6rem",
  },
  closeButton: {
    backgroundColor: "var(--gray-50)",
    width: "4.8rem",
    height: "4.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchMobileContainer: {
    display: {
      xs: "block",
      sm: "none",
      md: "none",
      lg: "none",
      xl: "none",
    },
    marginBottom: "1.6rem",
  },
  searchCloseMobile: { height: 24, weight: 24 },
  chipSelectContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  searchedResultContainer: {
    height: "min-content",
    margin: {
      xs: "1.4rem auto",
      sm: "2.4rem auto",
      md: "2.4rem auto",
      lg: "2.4rem auto",
    },
    padding: {
      xs: "0rem",
      sm: "0rem 1.5rem",
      md: "0rem 2.3rem",
      lg: "0rem 3.2rem",
    },
    width: "100%",
  },
  appointmentsContainer: {
    margin: "0rem 1.2rem 0.8rem 0rem",
    width: "30rem",
  },
  cardContainer: {
    display: {
      xs: "grid",
      sm: "grid",
      md: "grid",
      lg: "grid",
    },
    gridTemplateColumns: {
      xs: "repeat(1, 1fr)",
      sm: "repeat(2, 1fr)",
      md: "repeat(2, 1fr)",
      lg: "repeat(3, 1fr)",
      xl: "repeat(4, 1fr)",
    },
    gap: {
      xs: "0rem 0rem",
      sm: "0rem 1rem",
      md: "0.3rem 1.5rem",
      lg: "0.6rem 2rem",
      xl: "0.8rem 2rem",
    },
  },
  pharmacyOrDiagnosesCardContainer: {
    gap: {
      sm: "1rem",
    },
    gridTemplateColumns: {
      sm: "repeat(2, 1fr)",
      md: "repeat(3, 1fr)",
      lg: "repeat(4, 1fr)",
      xl: "repeat(5, 1fr)",
    },
  },
  searchResultCount: {
    color: "#898991",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "normal",
    margin: "0rem 0rem 0.8rem 1.6rem",
  },
};
