import React, { useState } from "react";

import { IProfileInput } from "../../models/patient";
import { IDropDownSelectionType } from "../../models/doctor";

import { addMemberApi } from "../apis/patient/profile";

import { INotifyEnum, notify } from "../toaster";

import { useAppDispatch } from "../../store/hooks";
import { fetchPatientMembers } from "../../store/slices/patient/member";

interface IProfileDetail {
  name: string;
  age: string;
  gender: string;
  isDefault: boolean;
}

const useCreatePatientMember = () => {
  const [isGenderOpen, setIsGenderOpen] = useState<boolean>(false);
  const [profileDetail, setProfileDetail] = useState<IProfileDetail>({
    name: "",
    age: "",
    gender: "",
    isDefault: false,
  });
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const openCloseAddMemberModal = () =>
    setIsAddMemberModalOpen(!isAddMemberModalOpen);

  const openCloseGenderModal = () => setIsGenderOpen(!isGenderOpen);

  const saveGenderValue = (value: string, type: IDropDownSelectionType) => {
    if (type === IDropDownSelectionType.GENDER) {
      openCloseGenderModal();
      setProfileDetail({
        ...profileDetail,
        gender: value,
      });
    }
  };

  const createPatientMember = async () => {
    const { name, age, gender, isDefault } = profileDetail;
    const addMemberResponse = await addMemberApi({
      age,
      gender,
      name,
      is_default: isDefault,
    });
    if ("result" in addMemberResponse) {
      notify(INotifyEnum.SUCCESS, addMemberResponse.message);
      openCloseAddMemberModal();
      dispatch(fetchPatientMembers());
      setProfileDetail({ name: "", age: "", gender: "", isDefault: false });
    }
  };

  const profileDetailHandler = (
    event: React.ChangeEvent<any>,
    type: IProfileInput
  ) => {
    switch (type) {
      case IProfileInput.NAME:
        setProfileDetail({
          ...profileDetail,
          name: event.target.value,
        });
        return;
      case IProfileInput.AGE:
        setProfileDetail({
          ...profileDetail,
          age: event.target.value,
        });
        return;
      case IProfileInput.GENDER:
        setProfileDetail({
          ...profileDetail,
          gender: event.target.value,
        });
        return;
      case IProfileInput.IS_DEFAULT:
        setProfileDetail({
          ...profileDetail,
          isDefault: event.target.checked,
        });
        return;
      default:
        return null;
    }
  };

  return {
    isGenderOpen,
    profileDetail,
    isAddMemberModalOpen,
    profileDetailHandler,
    openCloseGenderModal,
    createPatientMember,
    saveGenderValue,
    openCloseAddMemberModal,
  };
};

export default useCreatePatientMember;
