import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import GetImages from "../../../components/GetImages";
import TagChip from "../../../components/TagChip";
import DoctorReviews from "../../../components/DoctorReviews";
import Loader from "../../../components/Loader";
import ViewClinics from "./ViewClinics";
import Breadcrumb from "../../../components/Breadcrumb";
import MakeAppointment from "../../../components/DoctorProfile/MakeAppointment";

import style from "./index.module.css";

import FirstVideoThumbNail from "../../../assets/videoReviews/1.png";
import SecondVideoThumbNail from "../../../assets/videoReviews/2.png";
import ThirdVideoThumbNail from "../../../assets/videoReviews/3.png";
import FourthVideoThumbNail from "../../../assets/videoReviews/4.png";

import { IRoleType } from "../../../models/role";

import { doctorDegreeName, doctorSpeakLanguages } from "../../../utils";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useMakeAppointment from "../../../utils/hooks/useMakeAppointment";
import useIsTablet from "../../../utils/hooks/useIsTablet";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchDoctorProfile,
  fetchDoctorReviewRatingList,
  fetchDoctorVideoReviews,
} from "../../../store/slices/doctor/profile";
import CollapsibleText from "../../../components/CollapsibleText";
import ContactForm from "../../../components/ContactForm";
import {
  AppointmentTabPanel,
  AppointmentTabsPanel,
} from "../../../components/AppointmentTab";
import { IMakeAppointmentEvent } from "../../../models/doctor";

const doctorProfiles = {
  videoThumbnail: [
    FirstVideoThumbNail,
    SecondVideoThumbNail,
    ThirdVideoThumbNail,
    FourthVideoThumbNail,
  ],
};

const breadcrumbsItems = [
  { label: "Home", link: "/home" },
  { label: "Doctors", link: "/find-doctor" },
];

interface IProps {
  doctorID?: string;
}
const DoctorProfile: React.FC<IProps> = (props) => {
  const { doctorID } = props;
  const [viewMoreClinics, setViewMoreClinics] = useState<boolean>(false);
  const [openAppointmentSheet, setOpenAppointmentSheet] = useState(false);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const isSmallScreen = useMemo(
    () => isMobile || isTablet,
    [isMobile, isTablet]
  );

  const { openScheduleModalHandler, selectedTab, setSelectedTab } =
    useMakeAppointment();

  const keys = ["roleType"];
  const [roleType] = keys.map((keyItem) => storage.getSessionToken(keyItem));

  const navigate = useNavigate();

  const { state } = useLocation();
  const doctorId = state?.doctorId ?? doctorID;

  const backToFindDoctorPage = () => {
    if (roleType === IRoleType.DOCTOR) {
      navigate("/profile", { state: { navItem: 3 } });
      return;
    }
    navigate("/find-doctor", { state: { navItem: 1 } });
  };

  const handleViewMore = () => {
    setViewMoreClinics((state) => !state);
  };

  const handleAppointmentTab = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setSelectedTab(newValue);
    setOpenAppointmentSheet((state) => !state);
  };

  const dispatch = useAppDispatch();
  const doctorProfileInfo = useAppSelector((state) => state.doctorProfile);
  const doctorProfile = doctorProfileInfo?.detail;
  const { videoReviews } = useAppSelector((state) => state.doctorProfile);
  useEffect(() => {
    if (roleType === IRoleType.PATIENT || !doctorProfileInfo?.detail) {
      dispatch(fetchDoctorProfile(doctorId));
      dispatch(fetchDoctorReviewRatingList({ doctorId: doctorId! }));
      dispatch(fetchDoctorVideoReviews(doctorId!));
    }
  }, []);

  if (
    doctorProfileInfo?.isDetailLoading ||
    doctorProfileInfo?.isRatingReviewLoading ||
    doctorProfileInfo?.isVideoReviewsLoading
  ) {
    return <Loader />;
  }

  const getVideoThumbnail = (videoLink: string) => {
    const videoId = videoLink.split("v=")[1];
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  if (roleType === IRoleType.DOCTOR || roleType === IRoleType.JR_DOCTOR) {
    return (
      <Container maxWidth={"xl"} disableGutters>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={12} overflow={"hidden"} borderRadius={{ lg: 2.5 }}>
            <Stack direction={"column"}>
              <Box position={"relative"}>
                <img
                  src={doctorProfile?.clinics[0]?.photo_url}
                  alt="clinicImage"
                  className={style.clinicImage}
                />
                <Box
                  position={"absolute"}
                  top={roleType === IRoleType.DOCTOR ? "66%" : "80%"}
                  left={"50%"}
                  sx={{ transform: "translateX(-50%)" }}
                >
                  <img
                    className={style.doctorImage}
                    src={doctorProfile?.photo_url}
                    alt="doctorPhoto"
                  />
                  <Box className={style.doctorProfileStatus}>
                    <Chip
                      label="Verified"
                      variant="filled"
                      sx={{
                        bgcolor: "var(--peach-200)",
                        fontWeight: 500,
                        py: 0,
                        height: 24,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              {isSmallScreen && (
                <Grid
                  item
                  className={style.backNavigation}
                  onClick={backToFindDoctorPage}
                >
                  <GetImages name="LeftArrowIcon" width="16" height="16" />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                className={style.container}
                sx={{
                  flex: 1,
                  paddingTop: roleType === IRoleType.DOCTOR ? "8rem" : "5rem",
                }}
              >
                <Grid item className={style.doctorDetailContainer}>
                  <Grid className={style.doctorInfoContainer}>
                    <Typography component={"h4"}>
                      {doctorProfile?.name}
                    </Typography>
                    <Typography component={"h6"}>
                      {doctorProfile?.experience_years} yrs •{" "}
                      {doctorProfile?.speciality} •{" "}
                      {doctorProfile?.qualification_details &&
                        doctorDegreeName(doctorProfile?.qualification_details)}
                    </Typography>
                    <Typography component={"h6"}>
                      {doctorProfile?.language &&
                        doctorSpeakLanguages(doctorProfile?.language)}
                    </Typography>
                    {doctorProfile?.clinics?.slice(0, 3).map((item) => (
                      <Typography component={"p"} key={item.address}>
                        {item?.address}, {item?.city}-{item?.pincode},{" "}
                        {item?.distance!.toFixed(2)}km
                      </Typography>
                    ))}
                  </Grid>
                  {doctorProfile && doctorProfile?.clinics.length > 3 && (
                    <Grid item>
                      <Typography
                        component={"p"}
                        className={style.totalClinicCount}
                        onClick={handleViewMore}
                      >
                        {isSmallScreen ? (
                          `${doctorProfile?.clinics.length} more clinics`
                        ) : (
                          <FormattedMessage
                            id="view_more"
                            defaultMessage={"View More"}
                          />
                        )}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    xs={12}
                    item
                    border={`1px solid`}
                    borderColor={"var(--gray-200)"}
                    borderLeft={"none"}
                    borderRight={"none"}
                    p={1}
                  >
                    <CollapsibleText
                      text={doctorProfile?.about_me ?? ""}
                      color="var(--gray-500)"
                      fontWeight={400}
                    />
                  </Grid>
                  <Grid item className={style.tagsContainer}>
                    <Typography component={"p"}>
                      <FormattedMessage
                        id="profile_help_text"
                        defaultMessage={"I can help you with"}
                      />
                    </Typography>
                    <Grid item className={style.tagItemsContainer}>
                      {doctorProfile?.tags.split(",").map((tagName, index) => (
                        <TagChip
                          tagName={tagName}
                          key={`${tagName}-${index}`}
                        />
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item className={style.reviewCountHeader}>
                      <GetImages name="LikeIcon" width="20" height="16" />
                      <Typography component={"p"}>
                        {doctorProfile?.reviews} Reviews
                      </Typography>
                    </Grid>
                    <Grid item className={style.expertiseContainer}>
                      <Typography component={"p"}>
                        {doctorProfile?.expertise}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {viewMoreClinics && (
                  <ViewClinics
                    isOpen={viewMoreClinics}
                    closeModalHandler={handleViewMore}
                    clinics={doctorProfile?.clinics!}
                  />
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <Container
        maxWidth={"xl"}
        disableGutters={isSmallScreen}
        sx={{ my: { lg: 4 }, mb: { xs: 20, lg: 0 } }}
      >
        <Grid container gap={4} justifyContent={"center"} mb={5}>
          <Grid item xs={11} sx={{ display: { xs: "none", md: "block" } }}>
            <Breadcrumb
              items={[
                ...breadcrumbsItems,
                {
                  label: doctorProfile?.speciality ?? "",
                  link: `/find-doctor?type=${doctorProfile?.speciality}`,
                },
                {
                  label: doctorProfile?.name ?? "",
                  isCurrent: true,
                },
              ]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            overflow={"hidden"}
            borderRadius={{ lg: 2.5 }}
          >
            <Stack direction={"column"} position={"relative"}>
              <Box>
                <img
                  src={doctorProfile?.clinics[0]?.photo_url}
                  alt="clinicImage"
                  className={style.clinicImage}
                />
              </Box>
            </Stack>
            {isSmallScreen && (
              <IconButton
                className={style.backNavigation}
                onClick={backToFindDoctorPage}
              >
                <GetImages name="LeftArrowIcon" width="16" height="16" />
              </IconButton>
            )}
            <Grid
              container
              width={"100%"}
              className={style.container}
              sx={{
                flex: 1,
                paddingTop: roleType === IRoleType.DOCTOR ? "8rem" : "5rem",
              }}
            >
              <Grid
                item
                xs={12}
                position={"relative"}
                mt={{ xs: "-30%", sm: "-15%", md: "-20%", lg: "-15%" }}
              >
                <img
                  className={style.doctorImage}
                  src={doctorProfile?.photo_url}
                  alt="doctorPhoto"
                />
                <Box className={style.doctorProfileStatus}>
                  <Chip
                    label="Verified"
                    variant="filled"
                    sx={{
                      bgcolor: "var(--peach-200)",
                      fontWeight: 500,
                      py: 0,
                      height: 24,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={style.doctorDetailContainer}>
                <Stack
                  direction={"column"}
                  gap={0.5}
                  mb={1}
                  className={style.doctorInfoContainer}
                >
                  <Typography component={"h4"}>
                    {doctorProfile?.name}
                  </Typography>
                  <Typography component={"h6"}>
                    {doctorProfile?.experience_years} yrs •{" "}
                    {doctorProfile?.speciality} •{" "}
                    {doctorProfile?.qualification_details &&
                      doctorDegreeName(doctorProfile?.qualification_details)}
                  </Typography>
                  <Typography component={"h6"}>
                    {doctorProfile?.language &&
                      doctorSpeakLanguages(doctorProfile?.language)}
                  </Typography>
                  {doctorProfile?.clinics?.slice(0, 3).map((item) => (
                    <Typography component={"p"} key={item.address}>
                      {item?.address}, {item?.city}-{item?.pincode},{" "}
                      {item?.distance!.toFixed(2)}km
                    </Typography>
                  ))}
                </Stack>
                {doctorProfile && doctorProfile?.clinics.length > 3 && (
                  <Grid item>
                    <Typography
                      component={"p"}
                      className={style.totalClinicCount}
                      onClick={handleViewMore}
                    >
                      {isSmallScreen ? (
                        `${doctorProfile?.clinics.length} more clinics`
                      ) : (
                        <FormattedMessage
                          id="view_more"
                          defaultMessage={"View More"}
                        />
                      )}
                    </Typography>
                  </Grid>
                )}
                <Grid
                  xs={12}
                  item
                  border={`1px solid`}
                  borderColor={"var(--gray-200)"}
                  borderLeft={"none"}
                  borderRight={"none"}
                  p={1}
                >
                  <CollapsibleText
                    text={doctorProfile?.about_me ?? ""}
                    color="var(--gray-500)"
                    fontWeight={400}
                  />
                </Grid>
                <Grid item className={style.tagsContainer}>
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="profile_help_text"
                      defaultMessage={"I can help you with"}
                    />
                  </Typography>
                  <Grid item className={style.tagItemsContainer}>
                    {doctorProfile?.tags.split(",").map((tagName, index) => (
                      <TagChip tagName={tagName} key={`${tagName}-${index}`} />
                    ))}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item className={style.reviewCountHeader}>
                    <GetImages name="LikeIcon" width="20" height="16" />
                    <Typography component={"p"}>
                      {doctorProfile?.reviews} Reviews
                    </Typography>
                  </Grid>
                  <Grid item className={style.expertiseContainer}>
                    <Typography component={"p"}>
                      {doctorProfile?.expertise}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {roleType === IRoleType.PATIENT && videoReviews?.length && (
                <>
                  <Grid item xs={12} className={style.videoReviewContainer}>
                    <Box className={style.videoReviewHeader}>
                      <Typography component={"h6"}>
                        <FormattedMessage
                          id="video_reviews"
                          defaultMessage={"Video Reviews"}
                        />
                      </Typography>
                      <Typography component={"p"}>
                        <FormattedMessage id="view_all" />
                      </Typography>
                    </Box>
                    <Stack
                      direction={"row"}
                      className={style.videoReviewThumbnail}
                    >
                      {videoReviews.map((review, index) => (
                        <img
                          key={review.id}
                          src={getVideoThumbnail(review.video_link)}
                          alt={review.heading}
                          className={style.videoReviewsThumbnail}
                          loading="lazy"
                          onClick={() =>
                            window.open(review.video_link, "_blank")
                          }
                        />
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <DoctorReviews />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={4.5} lg={3.8} display={{ xs: "none", md: "block" }}>
            <Box
              width={"100%"}
              sx={{
                bgcolor: "var(--neutral-100)",
                borderRadius: 5,
                padding: 0,
              }}
            >
              <MakeAppointment
                doctorId={doctorId}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                openScheduleModalHandler={openScheduleModalHandler}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        bgcolor={"var(--neutral-100)"}
        display={{ xs: "none", lg: "flex" }}
        py={3}
      >
        <ContactForm />
      </Stack>
      <Stack
        position={"fixed"}
        bottom={0}
        display={{ xs: "flex", md: "none" }}
        width={"calc(100% - 30px)"}
        bgcolor={"var(--gray-100)"}
        p={2}
        gap={1}
        justifyContent={"center"}
      >
        <Typography variant="subtitle2" textAlign={"center"}>
          <FormattedMessage
            id="book_appointment"
            defaultMessage={"Book an appointment"}
          />
        </Typography>
        <Box width={"100%"}>
          <AppointmentTabsPanel
            onChange={handleAppointmentTab}
            aria-label="basic tabs example"
            className={style.tabBtnContainer}
          >
            <AppointmentTabPanel
              value={IMakeAppointmentEvent.VIDEO_CALL}
              sx={{
                maxWidth: "100%",
              }}
              label={
                <Grid item className={style.tabInnerContainer}>
                  <GetImages
                    name={false ? "VideoIcon" : "VideoWhiteIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="video_call"
                      defaultMessage="Video Call"
                    />
                  </Typography>
                </Grid>
              }
            />
            <Divider orientation="vertical" flexItem />
            <AppointmentTabPanel
              value={IMakeAppointmentEvent.IN_CLINIC}
              sx={{
                maxWidth: "100%",
              }}
              label={
                <Grid item className={style.tabInnerContainer} width={"100%"}>
                  <GetImages
                    name={false ? "ClinicWhiteIcon" : "ClinicIcon"}
                    width="20"
                    height="20"
                  />
                  <Typography component={"p"}>
                    <FormattedMessage
                      id="in_clinic"
                      defaultMessage="In-Clinic"
                    />
                  </Typography>
                </Grid>
              }
            />
          </AppointmentTabsPanel>
        </Box>
      </Stack>
      <ViewClinics
        isOpen={viewMoreClinics}
        closeModalHandler={handleViewMore}
        clinics={doctorProfile?.clinics!}
      />
      {isSmallScreen && (
        <MakeAppointment
          open={openAppointmentSheet}
          doctorId={doctorId}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          openScheduleModalHandler={openScheduleModalHandler}
          onClose={() => setOpenAppointmentSheet(false)}
        />
      )}
    </>
  );
};

export default DoctorProfile;
