import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Email,
  Facebook,
  X,
  WhatsApp,
  LinkedIn,
  Reddit,
  Pinterest,
} from "@mui/icons-material";
import { useIntl } from "react-intl";

import styles from "./index.module.css";

import Dialog from "../../../../components/Dialog";
import ScrollContainer from "../../HealthHub/ScrollContainer";

import { INotifyEnum, notify } from "../../../../utils/toaster";

interface ShareDialogProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ url, isOpen, onClose }) => {
  const shareOptions = [
    {
      name: "WhatsApp",
      link: `https://wa.me/?text=${encodeURIComponent(url)}`,
      icon: WhatsApp,
      backgroundColor: "#23d366",
    },
    {
      name: "Facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`,
      icon: Facebook,
      backgroundColor: "#3c5997",
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      icon: X,
      backgroundColor: "#000000",
    },
    {
      name: "Email",
      link: `mailto:?subject=Check this out&body=${encodeURIComponent(url)}`,
      icon: Email,
      backgroundColor: "#888888",
    },
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url
      )}`,
      icon: LinkedIn,
      backgroundColor: "#0077b5",
    },
    {
      name: "Reddit",
      link: `https://www.reddit.com/submit?url=${encodeURIComponent(url)}`,
      icon: Reddit,
      backgroundColor: "#ff4500",
    },
    {
      name: "Pinterest",
      link: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        url
      )}`,
      icon: Pinterest,
      backgroundColor: "#bd081c",
    },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    notify(INotifyEnum.SUCCESS, "Link copied to clipboard");
  };

  const intl = useIntl();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      isOpen={isOpen}
      closeHandler={onClose}
      isCloseIcon={true}
    >
      <Grid className={styles.shareDialog}>
        <Typography variant="body1" className={styles.shareTitle}>
          {intl.formatMessage({
            id: "share",
            defaultMessage: "Share",
          })}
        </Typography>

        <Grid className={styles.iconWrapper}>
          <ScrollContainer isInModal isSection={false}>
            {shareOptions.map((option) => (
              <a
                href={option.link}
                className={styles.shareOption}
                target="_blank"
                rel="noopener noreferrer"
                key={option.name}
              >
                <Grid
                  className={styles.iconContainer}
                  sx={{ backgroundColor: option.backgroundColor }}
                >
                  <option.icon fontSize="large" className={styles.icon} />
                </Grid>
                <span className={styles.iconName}>
                  {intl.formatMessage({
                    id: option.name.toLowerCase(),
                    defaultMessage: option.name,
                  })}
                </span>
              </a>
            ))}
          </ScrollContainer>
        </Grid>

        <Grid className={styles.copyLink}>
          <Grid className={styles.inputContainer}>
            <div>{url}</div>
          </Grid>
          <button onClick={copyToClipboard}>
            {intl.formatMessage({
              id: "copy",
              defaultMessage: "Copy",
            })}
          </button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ShareDialog;
