import React from "react";
import { styled, Tab, Tabs } from "@mui/material";

import { TabPanelProps } from "../../models/doctor";

const AppointmentTab = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const AppointmentTabsPanel = styled(Tabs)(() => ({
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const AppointmentTabPanel = styled(Tab)(() => ({
  width: "50%",
  color: "var(--orange-500)",
  fontSize: "1.4rem",
  fontWeight: 500,
  background: "var(--neutral-100)",
  textTransform: "none",
  "&.Mui-selected": {
    border: "0.1rem solid var(--orange-500)",
    background: "var(--orange-500)",
    borderBottom: "none",
    color: "var(--neutral-100)",
  },
}));

export { AppointmentTab, AppointmentTabsPanel, AppointmentTabPanel };
