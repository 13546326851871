import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import style from "./index.module.css";

import Modal from "../../Modal";
import GetImages from "../../GetImages";
import Section from "../Section";
import AddClinicTeamPatientCard from "../../AddClinicTeamPatientCard";
import Button from "../../Button";
import Confirmation from "../../Confirmation";
import ShiftBed from "../ShiftBed";

import { IButtonVariant } from "../../../models/button";
import {
  IPDashboardPatientAdmitStatus,
  IShiftBedDischaggePatientModal,
} from "../../../models/doctor";
import { IAddClinicTeamMemberEnums } from "../../../models";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setEditAdmitPatient,
  setReAdmitPatient,
} from "../../../store/slices/doctor/ipDashboard";

import {
  calculateDayFromTargetDate,
  transformDateToDDMMYYYY,
} from "../../../utils";
import useUpdateAdmitPatient from "../../../utils/hooks/useUpdateAdmitPatient";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";
import Dialog from "../../Dialog";
import AdmitPatient from "../../../views/Doctor/IPDashboard/AdmitPatient";

interface IProps {
  isOpen: boolean;
  closeHandler: () => void;
}
const AdmitPatientShortDetail: React.FC<IProps> = (props) => {
  const { isOpen, closeHandler } = props;

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();

  const navigate = useNavigate();

  const intl = useIntl();

  const [isShiftBedDischargeShow, setIsShiftBedDischargeShow] = useState({
    shiftBed: false,
    discharge: false,
  });

  const { updateAdmitPatientHandler } = useUpdateAdmitPatient();

  const { patientDetail } = useAppSelector((state) => state.ipDashboard);
  const { isShowUniversalSearch } = useAppSelector((state) => state.config);
  const dispatch = useAppDispatch();

  const {
    id,
    name,
    case_history,
    sponsor_name,
    hospital_uhid,
    room_name,
    number,
    admission_date,
  } = patientDetail;

  const [editDetails, setEditDetails] = useState(false);

  const openCloseDischargeShiftBed = (key: IShiftBedDischaggePatientModal) =>
    setIsShiftBedDischargeShow({
      ...isShiftBedDischargeShow,
      [key]: !isShiftBedDischargeShow[key],
    });

  const dischargePatientHandler = async () => {
    updateAdmitPatientHandler({
      id,
      status: IPDashboardPatientAdmitStatus.DISCHARGED,
    });
    closeHandler();
  };

  const editAdmitPatientDetail = () => {
    dispatch(setEditAdmitPatient(true));
    dispatch(setReAdmitPatient(false));
    // closeHandler();
    setEditDetails((state) => !state);
    if (!isShowUniversalSearch) {
      navigate("/admit-patient");
    }
  };

  const content = () => {
    return (
      <>
        <Grid item className={style.container}>
          <Grid item className={style.patientDetail}>
            <Typography component={"p"}>{name}</Typography>
            <Typography component={"p"} className={style.admittedDateDuration}>
              <GetImages name="GreenDotIcon" width="12" height="6" />
              {transformDateToDDMMYYYY(admission_date)}{" "}
              {`(${calculateDayFromTargetDate(admission_date)})`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component={"p"} className={style.roomNo}>
              {room_name} - Bed {number}
            </Typography>
          </Grid>
          <Grid item className={style.caseHistory}>
            <Typography component={"p"}>{case_history}</Typography>
          </Grid>
          <Grid item className={style.sponsorNameAdmitPatientNo}>
            <Typography component={"p"} className={style.sponsorName}>
              {sponsor_name}
            </Typography>
            <Typography component={"p"} className={style.admitPatientNo}>
              {hospital_uhid}
            </Typography>
          </Grid>
          <Grid item className={style.actionBtns}>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={editAdmitPatientDetail}
            >
              {intl.formatMessage({
                id: "edit",
                defaultMessage: "Edit",
              })}
            </Button>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() =>
                openCloseDischargeShiftBed(
                  IShiftBedDischaggePatientModal.SHIFTBED
                )
              }
            >
              {intl.formatMessage({
                id: "shift_bed",
                defaultMessage: "Shift bed",
              })}
            </Button>
            <Button
              variant={IButtonVariant.WHITE}
              btnTrigger={() =>
                openCloseDischargeShiftBed(
                  IShiftBedDischaggePatientModal.DISCHARGE
                )
              }
            >
              {intl.formatMessage({
                id: "discharge",
                defaultMessage: "Discharge",
              })}
            </Button>
          </Grid>
          <Grid item className={style.clinicTeams}>
            <Grid item className={style.clinicTeamTitle}>
              <Section title="clinical_team" />
            </Grid>
            <AddClinicTeamPatientCard type={IAddClinicTeamMemberEnums.STATIC} />
          </Grid>
          <Grid item className={style.btnsContainer}>
            <Button variant={IButtonVariant.WHITE}>
              <GetImages name="CallOrangeIcon" width="25" height="20" />
              Call SICU-02
            </Button>
            <Button>
              {" "}
              <GetImages name="CallWhiteIcon" width="25" height="20" />
              {intl.formatMessage({
                id: "call_patient",
                defaultMessage: "Call Patient",
              })}
            </Button>
          </Grid>
        </Grid>
        {isShiftBedDischargeShow?.discharge && (
          <Confirmation
            title="discharge"
            isOpen={isShiftBedDischargeShow?.discharge}
            closeHandler={() =>
              openCloseDischargeShiftBed(
                IShiftBedDischaggePatientModal.DISCHARGE
              )
            }
            alterMessage={"confirm_discharge"}
            name={name}
            actionHandler={dischargePatientHandler}
          />
        )}
        {isShiftBedDischargeShow?.shiftBed && (
          <ShiftBed
            isOpen={isShiftBedDischargeShow?.shiftBed}
            closeHandler={() =>
              openCloseDischargeShiftBed(
                IShiftBedDischaggePatientModal.SHIFTBED
              )
            }
            closeHandlerParentModal={closeHandler}
          />
        )}
      </>
    );
  };

  if (isMobile || isTablet) {
    return (
      <Modal
        isOpen={isOpen}
        closeHandler={closeHandler}
        isCloseIcon
        backgroundColor="white"
        lineBottomBackground="white"
      >
        {content()}
        {editDetails && (
          <AdmitPatient
            closeHandler={() => setEditDetails((state) => !state)}
          />
        )}
      </Modal>
    );
  }

  return (
    <>
      <Dialog maxWidth="sm" fullWidth closeHandler={closeHandler}>
        {content()}
      </Dialog>
      {editDetails && (
        <Dialog
          maxWidth="sm"
          fullWidth
          closeHandler={() => setEditDetails((state) => !state)}
        >
          <AdmitPatient
            closeHandler={() => setEditDetails((state) => !state)}
          />
        </Dialog>
      )}
    </>
  );
};

export default AdmitPatientShortDetail;
