import React, { useMemo, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box, Dialog, IconButton, InputAdornment } from "@mui/material";

import Button from "../../../../components/Button";
import { InputField } from "../../../../components/InputField";
import GetImages from "../../../../components/GetImages";
import PaymentDetail from "../../../../components/Pharmacy/PaymentDetail";

import style from "./index.module.css";

import { storage } from "../../../../utils/Storage";

import { IMedicineOrderType } from "../../../../models/ApiRequestResponse/patient";
import {
  IOrderStatusEnum,
  IPharmacyOrderDetailUpdate,
} from "../../../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../../../models/role";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { saveSelectedOrder } from "../../../../store/slices/pharmacyClinicDiagnostic";
import useIsMobile from "../../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../../utils/hooks/useIsTablet";
import { INotifyEnum, notify } from "../../../../utils/toaster";

interface IProps {
  onUpdate: (values: IPharmacyOrderDetailUpdate) => void;
  onBack: () => void;
}

const OrderCompleteUpdate: React.FC<IProps> = ({ onUpdate, onBack }) => {
  const dispatch = useAppDispatch();
  const { order } = useAppSelector((state) => state.pharmacyClinicDiagnostic);

  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const [values, setValues] = useState<IPharmacyOrderDetailUpdate>({
    deliveryPartner: "",
    trackingId: "",
    totalBill: String(order?.amount ?? ""),
    discount: String(order?.discount_amount ?? ""),
    totalPayable: Number(order.payable_amount ?? 0),
  });
  const roleType = storage.getSessionToken("roleType");

  const handleUpdate = () => {
    if (
      roleType !== IRoleType.DIAGNOSTIC_CENTER &&
      (!values?.trackingId || !values?.totalBill || !values?.deliveryPartner)
    ) {
      notify(INotifyEnum.ERROR, "All feilds are required");
      return;
    }
    if (order) {
      dispatch(
        saveSelectedOrder({
          ...order,
          status: IOrderStatusEnum.COMPLETED,
          amount: values.totalPayable,
          tracking_id: values?.trackingId,
          delivery_partner: values?.deliveryPartner,
          discountAmount: values?.discount,
          totalPayable: values?.totalPayable,
        })
      );
      onUpdate(values);
      onBack();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues((state) => {
      let totalPayable = values.totalPayable;
      switch (event.target.name) {
        case "totalBill":
          totalPayable = Math.max(
            0,
            Number(event.target.value) - Number(values.discount)
          );
          break;
        case "discount":
          totalPayable = Math.max(
            0,
            Number(
              roleType === IRoleType.DIAGNOSTIC_CENTER
                ? order?.total_amount ?? 0
                : values.totalBill
            ) - Number(event.target.value)
          );
          break;
      }

      return {
        ...state,
        totalPayable,
        [event.target.name]: event.target.value,
      };
    });
  };

  const isHomeDelivery = useMemo(
    () => order?.type === IMedicineOrderType.HOME_DELIVERY,
    [order]
  );

  const renderInputs = () => (
    <Stack gap={2} className={style.inputContainer}>
      {roleType === IRoleType.DIAGNOSTIC_CENTER && <PaymentDetail />}
      {isHomeDelivery && (
        <>
          <InputField
            label="Delivery Partner"
            name="deliveryPartner"
            value={values.deliveryPartner}
            onChange={handleChange}
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: { xs: "var(--gray-20)", lg: "transparent" },
              },
            }}
          />
          <InputField
            label="Tracking ID"
            name="trackingId"
            value={values.trackingId}
            onChange={handleChange}
            fullWidth
            InputProps={{
              sx: {
                backgroundColor: { xs: "var(--gray-20)", lg: "transparent" },
              },
            }}
          />
        </>
      )}
      {roleType !== IRoleType.DIAGNOSTIC_CENTER && (
        <InputField
          label="Total Bill"
          name="totalBill"
          value={values.totalBill}
          onChange={handleChange}
          fullWidth
          type="number"
          InputProps={{
            sx: {
              borderBottom: "1px solid var(--gray-200)",
              backgroundColor: { xs: "var(--gray-20)", lg: "transparent" },
            },
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <InputField
          label="Discount"
          name="discount"
          value={values.discount}
          onChange={handleChange}
          className={style.inputBackground}
          type="number"
          InputProps={{
            sx: {
              ...(roleType === IRoleType.DIAGNOSTIC_CENTER
                ? {
                    backgroundColor: "var(--gray-20)",
                    color: "var(--gray-400)",
                  }
                : {
                    backgroundColor: {
                      xs: "var(--gray-20)",
                      lg: "transparent",
                    },
                  }),
            },
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
        <InputField
          disabled
          label="Total Payable"
          name="totalPayable"
          value={values.totalPayable}
          onChange={handleChange}
          className={style.inputBackground}
          InputProps={{
            sx: {
              ...(roleType === IRoleType.DIAGNOSTIC_CENTER
                ? {
                    backgroundColor: "var(--gray-20)",
                    color: "var(--gray-400)",
                  }
                : {
                    backgroundColor: {
                      xs: "var(--gray-20)",
                      lg: "transparent",
                    },
                  }),
            },
            startAdornment: (
              <InputAdornment position="start">Rs.</InputAdornment>
            ),
          }}
        />
      </Stack>
    </Stack>
  );

  const renderHeading = () => (
    <Stack direction={"row"} alignItems={"center"}>
      <IconButton onClick={onBack} sx={{ p: 0, justifyContent: "flex-start" }}>
        <GetImages name="BackBlackIcon" width="26" height="16" />
      </IconButton>
      <Typography variant="subtitle1" component="p" className={style.heading}>
        {isHomeDelivery
          ? "Update Tracking ID & Payment Details"
          : "Update Payment Details"}
      </Typography>
    </Stack>
  );

  const renderContent = () => (
    <Stack gap={2}>
      <Stack gap={2} justifyContent="space-between">
        {renderHeading()}
      </Stack>
      {renderInputs()}
      <Button btnTrigger={handleUpdate}>Update</Button>
    </Stack>
  );

  const isWebScreen = useMemo(
    () => !(isMobile || isTablet),
    [isMobile, isTablet]
  );

  if (isWebScreen) {
    return (
      <Dialog
        open={true}
        maxWidth="xs"
        PaperProps={{
          sx: { borderRadius: "1.6rem" },
        }}
      >
        <Box display="flex" alignItems="center" p={4}>
          {renderContent()}
        </Box>
      </Dialog>
    );
  }

  return renderContent();
};

export default OrderCompleteUpdate;
