import React from "react";
import { Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

interface IProps {
  icon: React.ReactNode;
  title: string;
  subHeding: string;
  moveTo?: string;
  gridItemIndex?: number;
}

const GridIconText: React.FC<IProps> = (props) => {
  const { icon, title, subHeding, moveTo, gridItemIndex } = props;
  const navigate = useNavigate();

  const cardClickHandler = () => {
    if (!moveTo) return;
    navigate(moveTo, { state: { navItem: gridItemIndex } });
  };
  return (
    <Grid item className={style.cardContainer} onClick={cardClickHandler}>
      <Grid item className={style.cardIcon}>
        {icon}
      </Grid>
      <Grid item className={style.titleContainer}>
        <Typography component={"p"} className={style.cardTitle}>
          <FormattedMessage id={title} defaultMessage="Title Text" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography component={"p"} className={style.cardSubHeading}>
          <FormattedMessage id={subHeding} defaultMessage="Sub Heading Text" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GridIconText;
