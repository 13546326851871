import React, { useState } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import MedicalRecordGridList from "../../../../components/MedicalRecordGridList";

const TriggerZone = styled(Box)({
  position: "absolute",
  right: 0,
  top: 60,
  bottom: 0,
  width: "15%",
  zIndex: 99,
});

const SidebarContainer = styled(Box)<{ isVisible: boolean }>(
  ({ isVisible }) => ({
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "12rem",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    transform: isVisible ? "translateX(0)" : "translateX(100%)",
    transition: "transform 0.3s ease-in-out",
    zIndex: 100,
    overflowY: "scroll",
    height: "65rem",
    padding: "1.2rem",
  })
);

interface IHoverableMedicalSidebarProps {
  records: any[];
  onRecordClick?: (record: any) => void;
  selectedRecord?: string;
}

const HoverableMedicalSidebar: React.FC<IHoverableMedicalSidebarProps> = ({
  records,
  onRecordClick,
  selectedRecord,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <>
      <TriggerZone
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <SidebarContainer
        isVisible={isVisible}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {records.map((record, index) => (
          <Box
            key={`${record.id}-${index}`}
            onClick={() => onRecordClick?.(record)}
          >
            <MedicalRecordGridList {...record} isGalleryView={false} />
          </Box>
        ))}
      </SidebarContainer>
    </>
  );
};

export default HoverableMedicalSidebar;
