import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import ChipMultiSelect from "../../../ChipMultiSelect";
import ChipSelect from "../../../ChipSelect";
import SaveCancelTemplate from "../../SaveCancelTemplate";

import style from "../styles/index.module.css";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { addValuesToExistingMedicinesTest } from "../../../../store/slices/doctor/prescription";

import { ITitleInputValuesListEnums } from "../../../../models/doctor";
import { IMedicine } from "../../../../models/ApiRequestResponse/doctor";

interface IProps {
  closeHandler: () => void;
  selectedIndex: number;
  details: Partial<IMedicine>;
}

const MealTime: React.FC<IProps> = (props) => {
  const { closeHandler, selectedIndex, details } = props;
  const [mealTypeTime, setMealTypeTime] = useState<string>("");
  const [mealType, setMealType] = useState<string[]>([]);

  const { config } = useAppSelector((state) => state.prescription);

  const dispatch = useAppDispatch();

  const mealTypeList = config?.mealType
    ?.split(",")
    .map((item, index) => ({ value: item, available: true }));
  const mealTypTimeList = config?.mealTypeTime
    ?.split(",")
    .map((item, index) => ({ label: item, value: item }));

  const selectedMealTypesList = (selectedValues: string[]) => {
    const result: string[] = [];
    selectedValues.forEach((item, index) => {
      mealTypeList.forEach((val, ind) => {
        if (val.value === item) {
          result.push(val.value);
        }
      });
    });
    setMealType(result);
  };

  const saveHandler = () => {
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "mealType",
        value: mealType.join(","),
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "mealTypeTime",
        value: mealTypeTime,
        itemIndex: selectedIndex,
      })
    );
    dispatch(
      addValuesToExistingMedicinesTest({
        identifier: ITitleInputValuesListEnums.MEDICINE,
        field: "whenText",
        value: `${mealTypeTime} ${mealType.join(",")}`,
        itemIndex: selectedIndex,
      })
    );
    closeHandler();
  };

  useEffect(() => {
    setMealType((details?.mealType ?? "")?.split(","));
    setMealTypeTime(details?.mealTypeTime ?? "");
  }, [details]);

  return (
    <Grid item className={style.container}>
      <Grid item className={style.mealTimeSelection}>
        <Grid item className={style.mealTypeChipContainer}>
          <ChipMultiSelect
            value={mealType}
            list={mealTypeList}
            selectedValuesList={selectedMealTypesList}
          />
        </Grid>
        <Grid item className={style.mealTimeContainer}>
          <ChipSelect
            data={mealTypTimeList}
            selectedValue={mealTypeTime}
            setSelectedValue={setMealTypeTime}
            muiclass={{
              border: "0.1rem solid var(--gray-200)",
              padding: "0.8rem 0rem",
            }}
          />
        </Grid>
      </Grid>
      <Grid item className={style.saveCancelActionContainer}>
        <SaveCancelTemplate
          saveHandler={saveHandler}
          cancelHandler={closeHandler}
          containerStyles={{
            backgroundColor: "var(--blue-gray-100)",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default MealTime;
