import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import style from "./index.module.css";

import GenericCardHeader from "../../GenericCardHeader";
import AppointmentTimeDetail from "./AppointmentTimeDetail";
import Button from "../../Button";
import GetImages from "../../GetImages";

import {
  IDoctorProfileStatus,
  IDoctorScheduleConsultationType,
} from "../../../models/doctor";
import { IButtonVariant } from "../../../models/button";
import {
  IOrderStatus,
  QualificationDetail2,
} from "../../../models/ApiRequestResponse/patient";

import { useAppDispatch } from "../../../store/hooks";
import {
  fetchRTCTokenAgora,
  fetchRTMTokenAgora,
  setChannelName,
} from "../../../store/slices/videoAppointment";

import { timeInAmPmFromDateTime } from "../../../utils";

interface IProps {
  id: string;
  name: string;
  experience_years?: number;
  speciality?: string;
  profile_photo?: string;
  slot_start_time?: string;
  slot_end_time?: string;
  slot_time?: number;
  status: IOrderStatus;
  qualification_details?: QualificationDetail2[];
  consultation_type?: IDoctorScheduleConsultationType;
  liveQueue?: number;
  slot_no?: number;
  address?: string;
}
const AppointmentCard: React.FC<IProps> = (props) => {
  const {
    id,
    status,
    consultation_type,
    experience_years,
    name,
    speciality,
    liveQueue,
    profile_photo,
    qualification_details,
    slot_start_time,
    slot_time,
    slot_no,
    address,
    slot_end_time,
  } = props;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const joinVideoCall = (bookingId: string): void => {
    const startTime = dayjs(slot_start_time);
    const currentTime = dayjs();

    if (currentTime.isAfter(startTime)) {
      navigate("/join-room");
      dispatch(fetchRTCTokenAgora(bookingId));
      dispatch(fetchRTMTokenAgora(bookingId));
      dispatch(setChannelName(bookingId));
    }
  };

  return (
    <Grid item className={style.cardContainer}>
      <GenericCardHeader
        orderStatus={status}
        doctorId={""}
        image={profile_photo}
        name={name}
        bio={`${experience_years} yrs • ${speciality} ${
          qualification_details && `• ${qualification_details[0]?.degree_name}`
        }`}
        status={IDoctorProfileStatus.VERIFIED}
        liveQueue={liveQueue}
        date={slot_start_time}
      />
      <Grid item className={style.borderLine}></Grid>
      <AppointmentTimeDetail
        startTime={slot_start_time ?? ""}
        endTime={slot_end_time}
        slotDuration={slot_time ?? 0}
        type={consultation_type || IDoctorScheduleConsultationType.VIDEO_CALL}
        slotNo={slot_no}
        clinicName={`${address}`}
      />
      <Grid item>
        <Grid item className={style.openChat}>
          <Button
            variant={IButtonVariant.WHITE}
            btnTrigger={() => {
              navigate("/chat");
            }}
          >
            <GetImages name="OpenChatIcon" width="26" height="20" />
            <FormattedMessage id="open_chat" defaultMessage="Open Chat" />
          </Button>
        </Grid>
        {(status === IOrderStatus.PENDING ||
          status === IOrderStatus.UPCOMING) && (
          <>
            {consultation_type ===
              IDoctorScheduleConsultationType.VIDEO_CALL && (
              <Button
                variant={IButtonVariant.GREY}
                btnTrigger={(): void => joinVideoCall(id)}
              >
                <GetImages name="VideoWhiteIcon" width="26" height="20" />
                <FormattedMessage
                  id="join_call_at"
                  defaultMessage="Join Call at"
                />
                &nbsp; {timeInAmPmFromDateTime(slot_start_time ?? "")}
              </Button>
            )}
            {consultation_type ===
              IDoctorScheduleConsultationType.IN_CLINIC && (
              <Button>
                <GetImages name="TelePhoneWhiteIcon" width="26" height="20" />
                <FormattedMessage
                  id="call_clinic"
                  defaultMessage="Call Clinic"
                />
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AppointmentCard;
