import { Bounce, toast } from "react-toastify";

export enum INotifyEnum {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  DISMISS = "DISMISS",
}

const toasterConfig: any = {
  position: "top-center",
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};

export const notify = (type: INotifyEnum, message: string) => {
  switch (type) {
    case INotifyEnum.SUCCESS:
      return toast.success(message, toasterConfig);
    case INotifyEnum.ERROR:
      return toast.error(message, toasterConfig);
    case INotifyEnum.DISMISS:
      return toast.dismiss();
    default:
      return toast.error(message, toasterConfig);
  }
};
