import {
  IDoctorAvailabilityPlatform,
  IDoctorScheduleConsultationType,
  IDoctorScheduleOrderType,
} from "./doctor";

export enum IProfileInput {
  NAME,
  AGE,
  MOBILE_NUMBER,
  GENDER,
  IS_DEFAULT,
}

export enum IFilterType {
  FIND_DOCTOR = "findDoctor",
  VIDEO_CALL = "videosConsultationPaymentMethod",
  IN_CLINIC = "inClinicPaymentMethod",
}

export interface ICheckoutPatientDetail {
  doctorId: string;
  doctorImage: string;
  doctorName: string;
  bio: string;
  languages: string;
  type: IDoctorAvailabilityPlatform;
  clinicName: string;
  fee: number;
  dateSlotTime: string;
  slotId: string;
  memberId: string;
  name?: string;
  clinicId?: string;
}

export interface IConsultationTypes {
  [IDoctorScheduleConsultationType.IN_CLINIC]: string;
  [IDoctorScheduleConsultationType.VIDEO_CALL]: string;
}
export interface IOrderTypes {
  [IDoctorScheduleOrderType.IN_CLINIC]: string;
  [IDoctorScheduleOrderType.VIDEO_CALL]: string;
  [IDoctorScheduleOrderType.MEDICINE]: string;
  [IDoctorScheduleOrderType.TEST]: string;
}

export enum IOnboardingSteps {
  LANGUAGE = "language",
  TUTORIAL = "tutorial",
  MOBILE_OTP_VERIFY = "mobileOTPVerify",
}

export enum ISettingMedicalRecordTypeEnums {
  SETTINGS = "SETTINGS",
  MEDICAL_RECORDS = "MEDICAL_RECORDS",
}

export enum IPatientMemberDetailEnums {
  CHECKOUT = "CHECKOUT",
  HOME = "HOME",
}

export enum IAssessmentResultEnums {
  BAD = "Bad",
  POOR = "Poor",
  AVERAGE = "Average",
  GOOD = "Good",
  EXCELLENT = "Excellent",
}

export enum IAddDeliveryAddressDetailEnums {
  CITY = "city",
  ADDRESS = "address",
  PINCODE = "pincode",
}

export enum IMedicineModuleEnums {
  MEDICINE = "MEDICINE",
}
export enum ITestBookingEnums {
  TEST_BOOKING = "TEST_BOOKING",
}

export enum ITestOrderTypeEnums {
  DIAGNOSTIC_TEST = "diagnosticTest",
}

export interface IAllOrderListProps {
  orderType?: string;
  keyword?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  offset?: string;
}
