import React from "react";

import { ITitleInputValuesListEnums } from "../../../models/doctor";

import Medicine from "./components/Medicine";
import Test from "./components/Test";

export interface IProps {
  identifier: ITitleInputValuesListEnums;
  name: string;
  removeItemHandler: (
    identifier: ITitleInputValuesListEnums,
    name: string
  ) => void;
  mealType?: string;
  frequency?: string;
  note: string;
}

const MedicineTestListRemoveManager: React.FC<IProps> = ({
  identifier,
  ...props
}) => {
  return (
    <>
      {identifier === ITitleInputValuesListEnums.MEDICINE && (
        <Medicine identifier={identifier} {...props} />
      )}
      {identifier === ITitleInputValuesListEnums.TEST && (
        <Test identifier={identifier} {...props} />
      )}
    </>
  );
};

export default MedicineTestListRemoveManager;
