import { useState } from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";

import css from "./index.module.css";

import Title from "../../../../components/Title";
import GetImages from "../../../../components/GetImages";
import Button from "../../../../components/Button";
import OrderCompleteUpdate from "../../OrderModals/OrderCompleteUpdate";
import Confirmation from "../../../../components/Confirmation";

import { IButtonVariant } from "../../../../models/button";
import {
  IOrderStatusEnum,
  IPharmacyOrderDetailUpdate,
} from "../../../../models/ApiRequestResponse/pharmacy";
import { IRoleType } from "../../../../models/role";

import { handleUpdateOrderApi } from "../../../../utils/apis/pharmacy";
import useHandlePharmacyOrderUpdate from "../../../../utils/hooks/useHandlePharmacyOrderUpdate";
import { capitalizeFirstLetter } from "../../../../utils";
import { storage } from "../../../../utils/Storage";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { saveSelectedOrder } from "../../../../store/slices/pharmacyClinicDiagnostic";

type Props = {
  showActionsButton?: boolean;
};

const OrderDetailsTopBar = ({ showActionsButton = true }: Props) => {
  const [isShowPaymentDetailsModal, setIsShowPaymentDetailsModal] =
    useState(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { order, list } = useAppSelector(
    (state) => state.pharmacyClinicDiagnostic
  );

  const roleType = storage.getSessionToken("roleType");

  const { handlePharmacyOrderUpdate } = useHandlePharmacyOrderUpdate();

  const handleCancelOrderModal = () => {
    setIsCancel((state) => !state);
  };

  const renderActions = () => {
    if (showActionsButton) {
      switch (order.status) {
        case IOrderStatusEnum.PENDING:
          return (
            <Stack
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              <Button
                variant={IButtonVariant.WHITE}
                btnTrigger={() => {
                  handleUpdateOrderApi(IOrderStatusEnum.CONFIRMED);
                  dispatch(saveSelectedOrder(null));
                }}
              >
                <FormattedMessage defaultMessage={"Accept"} id="accept" />
              </Button>
              <Button
                variant={IButtonVariant.ORANGE}
                btnTrigger={() => {
                  handleUpdateOrderApi(IOrderStatusEnum.REJECTED);
                  dispatch(saveSelectedOrder(null));
                }}
              >
                <FormattedMessage defaultMessage={"Reject"} id="reject" />
              </Button>
            </Stack>
          );
        case IOrderStatusEnum.CONFIRMED:
          return (
            <Stack direction={"row"} gap={5}>
              <Button
                variant={IButtonVariant.TEXT}
                btnTrigger={handleCancelOrderModal}
              >
                <Stack alignItems={"center"} justifyContent={"center"}>
                  <GetImages name="CancelIcon" width="28" height="24" />
                  <Typography component={"p"} className={css.cancelText}>
                    <FormattedMessage id="cancel" defaultMessage="Cancel" />
                  </Typography>
                </Stack>
              </Button>
              <Button
                variant={IButtonVariant.ORANGE}
                btnTrigger={() => {
                  setIsShowPaymentDetailsModal(true);
                }}
              >
                &nbsp;&nbsp;
                <FormattedMessage
                  defaultMessage={"Mark Order Complete"}
                  id="mark_order_complete"
                />
                &nbsp;&nbsp;
              </Button>
            </Stack>
          );
        case IOrderStatusEnum.COMPLETED:
          return (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              height={"100%"}
            >
              <GetImages name="TickOrangeIcon" width="26" height="20" />
              <Typography component="p" color={"primary"}>
                <FormattedMessage
                  defaultMessage={"Order Completed"}
                  id="order_complete"
                />
              </Typography>
            </Stack>
          );
        case IOrderStatusEnum.REJECTED:
          return (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              height={"100%"}
            >
              <Typography component="p" color={"primary"}>
                <FormattedMessage
                  defaultMessage={"Order Rejected"}
                  id="order_rejected"
                />
              </Typography>
            </Stack>
          );
        case IOrderStatusEnum.CANCELLED:
          return (
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={"row"}
              height={"100%"}
            >
              <Typography component="p" color={"primary"}>
                <FormattedMessage
                  defaultMessage={"Order Cancelled"}
                  id="order_cancelled"
                />
              </Typography>
            </Stack>
          );
      }
    }
  };

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          backgroundColor: "var(--neutral-100)",
          borderBottom: "0.1rem solid var(--gray-50)",
          borderTopRightRadius: "1rem",
          px: 2,
          py: 1,
        }}
      >
        <Stack>
          <Title>{order?.name}</Title>
          <Box
            flexDirection={"row"}
            alignItems={"center"}
            display={"flex"}
            gap={1}
          >
            {roleType !== IRoleType.DIAGNOSTIC_CENTER ? (
              <Typography component={"p"} className={css.orderType}>
                {capitalizeFirstLetter(order?.type!)}
              </Typography>
            ) : (
              <Typography component={"p"} className={css.orderType}>
                Age {order?.age}, {order?.gender}
              </Typography>
            )}
            &bull;
            <Typography component={"p"} className={css.orderType}>
              <FormattedMessage id="order_no" defaultMessage={"Order No"} />{" "}
              {order?.order_no}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={4}
        >
          <Button variant={IButtonVariant.TEXT}>
            <GetImages name="CallOrangeIcon" width="25" height="20" />
            <Typography component={"p"} color={"primary"}>
              {order?.phone_number}
            </Typography>
          </Button>

          {renderActions()}
        </Stack>
      </Stack>
      {isShowPaymentDetailsModal && (
        <OrderCompleteUpdate
          onUpdate={(values: IPharmacyOrderDetailUpdate) =>
            handlePharmacyOrderUpdate(IOrderStatusEnum.COMPLETED, values)
          }
          onBack={() => setIsShowPaymentDetailsModal(false)}
        />
      )}
      <Confirmation
        isOpen={isCancel}
        closeHandler={handleCancelOrderModal}
        title="cancel_order"
        alterMessage="cancel_order_alert_message"
        actionHandler={() => {
          handlePharmacyOrderUpdate(IOrderStatusEnum.CANCELLED);
          dispatch(saveSelectedOrder(null));
          handleCancelOrderModal();
        }}
      />
    </>
  );
};
export default OrderDetailsTopBar;
