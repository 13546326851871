import React from "react";
import { Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";

import { style } from "./style";

interface IProps {
  children: React.ReactNode;
  clearDateChipFilterHandler: () => void;
}
const DateChipFilterClearWrapper: React.FC<IProps> = (props) => {
  const { children, clearDateChipFilterHandler } = props;
  return (
    <>
      {children}
      <Grid item sx={style.clearBtn} onClick={clearDateChipFilterHandler}>
        <CloseIcon
          sx={{ width: 16, height: 16, color: "var(--neutral-100)" }}
        />
        <Typography component={"p"} sx={style.clearText}>
          <FormattedMessage id="clear" defaultMessage="Clear" />
        </Typography>
      </Grid>
    </>
  );
};

export default DateChipFilterClearWrapper;
