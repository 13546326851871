import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: process.env?.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env?.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env?.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env?.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env?.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env?.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env?.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const messaging = getMessaging(app);

export { messaging, db };

export const generateTokenFCM = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");

      const token = await getToken(messaging, {
        vapidKey: process.env?.REACT_APP_FIREBASE_VAPID_KEY,
      });

      if (token) {
        return token;
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
      }
    } else {
      console.error("Notification permission not granted.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token:", error);
  }
};
