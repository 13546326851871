export const routes = {
  onboardingRoutes: ["/", "/get-started", "/mobile-otp-verify"],
  hideTopNavRoutes: ["/profile"],
  patientRoutes: [
    "/home",
    "/find-doctor",
    "/medicines",
    "/test-reports",
    "/health-hub",
    "/health-hub/video",
    "/health-hub/blog-list",
    "/health-hub/playlist",
    "/health-hub/blog",
    "/health-hub/disease",
    "/doctor-profile",
    "/faq",
    "/chat",
  ],
  routesWithoutNavsOnMobile: [
    "/health-hub/video",
    "/health-hub/blog",
    "/health-hub/disease",
    "/health-hub/playlist",
    "/health-hub/blog-list",
    "/faq",
    "/doctor-profile",
    "/chat",
  ],
  routesWithoutNavsOnTablet: ["/faq", "/doctor-profile", "/chat"],
  doctorRoutes: [
    "/availability-settings",
    "/availability-create",
    "/schedule",
    "/ip-dash",
    "/chats",
    "/profile",
  ],
  doctorBottomNavRoutes: ["/schedule", "/ip-dash", "/chats", "/profile"],
  pharmacyTopNavRoutes: ["/orders", "/clinic-patient"],
};
