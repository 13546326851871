import { INotifyEnum, notify } from "../toaster";
import { useAppSelector } from "../../store/hooks";
import { addToCartApi } from "../apis/patient/tests";
import useGetCartDetails from "./useGetCartDetails";

const useClearCart = () => {
  const { getCartDetails } = useGetCartDetails();

  const diagnosticTestList = useAppSelector((state) => state.tests);
  const { cartDetails, centreDetails } = diagnosticTestList;
  const cartList = cartDetails?.diagnosticTestIds;

  const onClearCart = async () => {
    try {
      const clearCartRes = await addToCartApi({
        diagnosticId: cartDetails?.diagnosticId,
        diagnosticTestIds: cartList,
        bookingTimestamp: cartDetails?.bookingTimestamp,
        action: "CLEAR",
      });
      if ("result" in clearCartRes) {
        notify(INotifyEnum.SUCCESS, clearCartRes?.message);
        getCartDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onCartFun = async (testId: string, action: string) => {
    const currentCart = cartDetails?.diagnosticTestIds || [];
    const updatedCart = currentCart.includes(testId)
      ? currentCart.filter((id) => id !== testId)
      : [...currentCart, testId];

    try {
      const addToCartResponse = await addToCartApi({
        diagnosticId: centreDetails?.user_id || cartDetails?.diagnosticId,
        diagnosticTestIds: updatedCart,
        bookingTimestamp:
          parseInt(centreDetails?.selectedDate) ||
          cartDetails?.bookingTimestamp,
        action: action,
      });

      if ("result" in addToCartResponse) {
        getCartDetails();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // getCartDetails is already called via useGetCartDetails so no extra effect is needed here

  return { onClearCart, onCartFun };
};

export default useClearCart;
