import {
  IAddToCartReqBody,
  IAddToCartReqRes,
  IBannerResponse,
  IDiagnosticCenterListResponse,
  IDiagnosticTestListResponse,
  IGetCartReqRes,
  ITestBookingReqBody,
  ITestBookingRes,
} from "../../../models/ApiRequestResponse/patient";
import { GBSCError } from "../../../models/GBSCError";
import { get, post } from "../../TypedApi";
import {
  APIEndpoint,
  IAPIStatusCode,
  IAPIStatusType,
  messages,
} from "../APIEndpointConfig";

export const getDiagnosticTestListApi = async (
  diagnosticId?: string,
  filter?: string,
  search?: string
): Promise<IDiagnosticTestListResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.patient.diagnosticTestList}?diagnosticId=${diagnosticId}&limit=100&offset=0`;
    if (filter) {
      url += `&filter=${filter}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    const response = await get<IDiagnosticTestListResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getDiagnosticCenterListApi = async (
  latitude?: number,
  longitude?: number,
  day?: string,
  search?: string
): Promise<IDiagnosticCenterListResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.patient.diagnosticCenterList}?latitude=${latitude}&longitude=${longitude}&limit=100&offset=0`;
    if (day) {
      url += `&day=${day}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    const response = await get<IDiagnosticCenterListResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const addToCartApi = async (
  requestBody: IAddToCartReqBody
): Promise<IAddToCartReqRes | GBSCError> => {
  try {
    const response = await post<IAddToCartReqRes>(
      APIEndpoint.patient.addAndGetCart,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getCartListApi = async (): Promise<IGetCartReqRes | GBSCError> => {
  try {
    let url = `${APIEndpoint.patient.addAndGetCart}`;
    const response = await get<IGetCartReqRes>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const testBookingApi = async (
  requestBody: ITestBookingReqBody
): Promise<ITestBookingRes | GBSCError> => {
  try {
    const response = await post<ITestBookingRes>(
      APIEndpoint.patient.testBooking,
      requestBody
    );
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};

export const getBannerApi = async (
  type?: string
): Promise<IBannerResponse | GBSCError> => {
  try {
    let url = `${APIEndpoint.patient.getBanner}`;
    if (type) {
      url += `?type=${type}`;
    }
    const response = await get<IBannerResponse>(url);
    if (response.type === IAPIStatusType.SUCCESS) {
      return response.data;
    }
    const { errorMessage, message, statusCode } = response;
    return new GBSCError(errorMessage, message, statusCode);
  } catch (ex) {
    console.log(ex);
  }

  return new GBSCError(
    IAPIStatusType.ERROR,
    messages.somethingWentWrong,
    IAPIStatusCode.INTERNAL_SERVER_ERROR
  );
};
