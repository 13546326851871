export const style = {
  container: {
    padding: {
      xs: "1.6rem",
      sm: "1.6rem",
      md: "2.4rem 3.2rem 1.6rem 3.2rem",
      lg: "2.4rem 3.2rem 1.6rem 3.2rem",
      xl: "2.4rem 3.2rem 1.6rem 3.2rem",
    },
    textAlign: "left",
    "& > h4": {
      color: "var(--black-900)",
      fontSize: {
        xs: "1.6rem",
        sm: "1.6rem",
        md: "2rem",
        lg: "2rem",
        xl: "2rem",
      },
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
  },
  fieldSpacing: {
    marginBottom: "1.6rem",
  },
  attachmentButton: {
    display: "inline-block",
  },
  helpSupportActions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    width: { xs: "100%", sm: "100%", md: "18rem", lg: "18rem", xl: "18rem" },
  },
  cancelBtnSpace: {
    marginRight: "1.6rem",
  },
  delete: {
    marginLeft: "0.8rem",
    cursor: "pointer",
  },
  pdfContainer: {
    margin: "0.8rem 0rem",
    display: "flex",
    alignItems: "center",
    borderRadius: "1.8rem",
    backgroundColor: "var(--gray-50)",
    width: "min-content",
    "& > p": {
      marginLeft: "0.4rem",
      color: "var(--gray-500)",
      fontSize: "1.4rem",
      fontWeight: 400,
    },
  },
};
