import React from "react";
import { Grid } from "@mui/material";

import Loader from "../../../../components/Loader";
import HealthHubSearchCard from "../HealthHubSearchCard";
import ListWithIntersection from "../../../../components/ListWithIntersection";

import style from "./index.module.css";

import { IHealthHubPlaylistResult } from "../../../../models/ApiRequestResponse/patient";

import { useAppSelector } from "../../../../store/hooks";
type HealthHubSearchListProps = {
  onLoadMore: () => void;
  onCardClick: () => void;
};

const HealthHubSearchList: React.FC<HealthHubSearchListProps> = ({
  onLoadMore,
  onCardClick,
}) => {
  const { searchData, isFetchingData } = useAppSelector(
    (state) => state.healthHub
  );

  const playlistData =
    searchData?.result?.playlist?.map((i: IHealthHubPlaylistResult) => {
      return {
        ...i,
        thumbnailUrl: i.data[0].thumbnailUrl,
        type: "playlist",
      };
    }) || [];

  const videos = searchData?.result?.video || [];

  const mappableData = [...playlistData, ...videos];

  return (
    <>
      <ListWithIntersection
        list={mappableData ?? []}
        onLoadMore={onLoadMore}
        direction="row"
      >
        <>
          {mappableData?.map((item: any) => {
            const category =
              item?.type === "playlist"
                ? "Playlist"
                : item?.type === "long"
                ? "Video"
                : "Short";
            return (
              <ListWithIntersection.Item key={item?.id} dataId={item.id}>
                <HealthHubSearchCard
                  onClick={onCardClick}
                  id={item?.id}
                  title={item?.name}
                  category={category}
                  thumbnailUrl={item?.thumbnailUrl}
                />
              </ListWithIntersection.Item>
            );
          })}
        </>
      </ListWithIntersection>
      {isFetchingData && (
        <Grid sx={{ width: "100%", marginTop: "4rem" }}>
          <Loader height="2rem" />
        </Grid>
      )}
    </>
  );
};

export default HealthHubSearchList;
