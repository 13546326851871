import React, { useCallback, useEffect, useMemo, useState, memo } from "react";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import style from "./index.module.css";

import CreateAction from "../../../components/CreateAction";
import Header from "../../../components/Header";
import FullScreenDialog from "../../../components/FullScreenDialog";
import GetImages from "../../../components/GetImages";
import MedicalRecordGridList from "../../../components/MedicalRecordGridList";
import ChipSelect from "../../../components/ChipSelect";
import Loader from "../../../components/Loader";
import Profile from "../../Patient/Profile";
import NotFoundRecord from "../../../components/NotFoundRecord";
import CreateMedicalReport from "../../../components/CreateMedicalReport";
import Button from "../../../components/Button";
import Camera from "../Camera";
import DropDownSelect from "../../../components/DropDownSelect";
import CreatePrescription from "../../Doctor/Prescription/CreatePrescription";
import HoverableMedicalSidebar from "./HoverableMedicalSidebar";

import { IButtonVariant, IChipVariant } from "../../../models/button";
import {
  IDropDownSelectionType,
  IMedicalRecordTypeEnum,
  IPatientAppointmentStatus,
} from "../../../models/doctor";
import { IRoleType } from "../../../models/role";
import { IMemberListDetail } from "../../../models/ApiRequestResponse/patient";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchPatientMembers,
  setMemberModalOpenClose,
} from "../../../store/slices/patient/member";
import {
  fetchMedicalRecordList,
  setMedicalReport,
} from "../../../store/slices/doctor/medicalRecord";

import {
  IShareButton,
  IShareButtonIcon,
  medicalRecordFilterList,
  shareButtons,
} from "../../../utils/common";
import { storage } from "../../../utils/Storage";
import useIsMobile from "../../../utils/hooks/useIsMobile";
import useIsTablet from "../../../utils/hooks/useIsTablet";
import { isClinic, pdfRegex, transformClasses } from "../../../utils";

import doctorImage from "../../../assets/doctorImage.png";
import { IPatientMemberDetailEnums } from "../../../models/patient";

interface IProps {
  createPrescriptionHandler?: () => void;
  patientSelectedId?: string | null;
  onBack?: () => void;
}

// Memoize the RecordWrapper component
const RecordWrapper = memo(
  ({
    record,
    children,
    isPdf,
    onShare,
  }: {
    record: any;
    children?: React.ReactNode;
    isPdf?: boolean;
    onShare: (button: IShareButton, url: string) => void;
  }) => {
    // Memoize the share button handlers
    const shareButtonHandlers = useMemo(
      () =>
        shareButtons.map((button) => ({
          button,
          handler: () => onShare(button, record?.url),
        })),
      [record?.url, onShare]
    );

    return (
      <Grid
        item
        xs={12}
        sx={{
          width: "100%",
          position: "relative",
          overflow: "visible",
          marginTop: "4.5rem",
          ...(isPdf ? { height: "100%" } : {}),
        }}
      >
        <Box className={style.headerBox}>
          <Grid container className={style.headerGrid}>
            <Grid item className={style.shareButtonsContainer}>
              {shareButtonHandlers?.map(({ button, handler }, index) => (
                <IconButton
                  key={`share-button-${index}`}
                  className={style.sharebuttons}
                  onClick={handler}
                >
                  <GetImages name={button.icon} width="20" height="20" />
                </IconButton>
              ))}
            </Grid>

            <Grid item className={style.recordInfoContainer}>
              <Grid className={style.recordTypeContainer}>
                <GetImages
                  name="PrescriptionDefaultSmallIcon"
                  width="20"
                  height="20"
                />
                <Typography className={style.recordText}>
                  {record.type}
                </Typography>
              </Grid>
              <Typography className={style.recordText}>
                {dayjs(record.documentDate).format("DD MMM, YYYY")}
              </Typography>
              <Grid className={style.category}>
                {record.healthCategory?.icon && (
                  <img
                    src={record.healthCategory.icon}
                    alt="Category icon"
                    width={22}
                    height={16}
                  />
                )}
                <Typography className={style.categoryName}>
                  {record.healthCategory?.name?.toLowerCase() ?? "Unknown"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {children}
      </Grid>
    );
  }
);

// Add display name for debugging
RecordWrapper.displayName = "RecordWrapper";

// Modify the PDFViewer to include preview state
const PDFViewer = memo(({ url }: { url: string }) => {
  return (
    <iframe
      title="pdf"
      src={`${url}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`}
      height="100%"
      width="100%"
      style={{ border: "none" }}
    />
  );
});

PDFViewer.displayName = "PDFViewer";

// Memoize the image viewer component
const ImageViewer = memo(({ url }: { url: string }) => (
  <img src={url} alt="document" className={style["img-wrap"]} />
));

ImageViewer.displayName = "ImageViewer";

const MedicalRecords: React.FC<IProps> = (props) => {
  const { createPrescriptionHandler, patientSelectedId, onBack } = props;
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [showCreatePrescriptionDialog, setShowCreatePrescriptionDialog] =
    useState<boolean>(false);
  const [isGalleryView, setIsGalleryView] = useState<boolean>(true);
  const [selectedMedicalRecord, setSelectedMedicalRecord] = useState<string>(
    medicalRecordFilterList[0].value
  );
  const [defaultMember, setDefaultMember] = useState<IMemberListDetail>();
  const [isEditProfileShow, setIsEditProfileShow] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const {
    medicalReport,
    medicalRecords,
    isLoading: recordsIsLoading,
  } = useAppSelector((state) => state.medicalRecords);

  const { patientDetail } = useAppSelector((state) => state.ipDashboard);
  const appointmentDetail = useAppSelector(
    (state) => state.appointment.appointmentDetail
  );
  const { list, isMemberModalOpenClose, isLoading } = useAppSelector(
    (state) => state.member
  );
  const { isCameraOn } = useAppSelector((state) => state.prescription);

  const [createMedicalRecord, setCreateMedicalRecord] = useState<boolean>(
    medicalReport?.isCapturedSave ?? false
  );
  const { isMobile } = useIsMobile();
  const { isTablet } = useIsTablet();
  const isMobileOrTablet = isMobile || isTablet;

  const location = useLocation();

  const roleType = storage.getSessionToken("roleType");

  const openCloseEditProfile = () => setIsEditProfileShow(!isEditProfileShow);

  const handleShare = useCallback((shareButton: IShareButton, url: string) => {
    switch (shareButton.icon) {
      case IShareButtonIcon.GMAIL:
        shareButton.onClick(url, "Medical Record");
        break;
      case IShareButtonIcon.WHATSAPP:
        shareButton.onClick(url, "Please find the Medical Record here");
        break;
      case IShareButtonIcon.PRINT:
        shareButton.onClick(url);
        break;
    }
  }, []);

  const medicalRecordViewOption = useCallback(() => {
    return (
      <Grid item className={style.recordViewOptions}>
        <GetImages name="EyeIcon" width="28" height="24" />
        <Typography component={"p"}>
          {isGalleryView ? "List" : "Gallery"}
        </Typography>
      </Grid>
    );
  }, [isGalleryView]);

  const editProfileDetail = useCallback(() => {
    return (
      <Grid item className={style.recordViewOptions}>
        <GetImages name="EditProfileIcon" width="28" height="24" />
        <Typography component={"p"}>Edit</Typography>
      </Grid>
    );
  }, [isEditProfileShow]);

  const navigateToDoctorListForBookAppointment = () =>
    navigate("/find-doctor", { state: { navItem: 1 } });

  const openCloseCreateMedicalRecordPopUp = () =>
    setCreateMedicalRecord(!createMedicalRecord);

  const closeHandler = () => {
    openCloseCreateMedicalRecordPopUp();
    dispatch(
      setMedicalReport({
        identifier: "date",
        value: null,
      })
    );
    dispatch(
      setMedicalReport({
        identifier: "healthCategoryId",
        value: "",
      })
    );
  };

  const createHandler = () => {
    if (roleType !== IRoleType.DOCTOR) {
      openCloseCreateMedicalRecordPopUp();
      return;
    }
    if (isMobileOrTablet) {
      return setShowCreatePrescriptionDialog(true);
    }
    if (appointmentDetail?.status === IPatientAppointmentStatus.PENDING) {
      return createPrescriptionHandler && createPrescriptionHandler();
    }
    return null;
  };

  const openCloseMemberModalHandler = () => dispatch(setMemberModalOpenClose());

  const saveSelectedMemberValues = async (value: string) => {
    openCloseMemberModalHandler();
    setSelectedMember(value);
    const memberInfo = list?.filter(
      (member: IMemberListDetail, index) => member.id === value
    )[0];
    setDefaultMember(memberInfo);
    return;
  };
  const noRecords = useMemo(() => {
    if (pathname === "/ip-dash") {
      return (
        <Stack
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="subtitle1" color={"secondary"}>
            <FormattedMessage id="no_records" />
          </Typography>
        </Stack>
      );
    } else {
      return (
        <NotFoundRecord
          icon={false}
          heading="add_medical_record"
          tapHandler={createHandler}
        />
      );
    }
  }, [pathname]);

  // Memoize the records rendering
  const renderedRecords = useMemo(() => {
    if (!medicalRecords?.records?.length) {
      return noRecords;
    }

    return medicalRecords.records.map((record) => {
      const url = record.url ?? record.thumbnailUrl;
      const isPDF = pdfRegex.test(url);

      return (
        <RecordWrapper
          key={record.id}
          record={record}
          isPdf={isPDF}
          onShare={handleShare}
        >
          {isPDF ? <PDFViewer url={url} /> : <ImageViewer url={url} />}
        </RecordWrapper>
      );
    });
  }, [medicalRecords?.records, handleShare, noRecords]);

  useEffect(() => {
    if (
      selectedMedicalRecord === IMedicalRecordTypeEnum.All ||
      selectedMedicalRecord === IMedicalRecordTypeEnum.PRESCRIPTION ||
      selectedMedicalRecord === IMedicalRecordTypeEnum.REPORT
    ) {
      dispatch(
        fetchMedicalRecordList({
          memberId:
            roleType === IRoleType.DOCTOR
              ? appointmentDetail?.patient?.memberId ?? patientDetail?.member_id
              : patientSelectedId ?? defaultMember?.id!,
          ...(selectedMedicalRecord === IMedicalRecordTypeEnum.PRESCRIPTION ||
          selectedMedicalRecord === IMedicalRecordTypeEnum.REPORT
            ? { type: selectedMedicalRecord }
            : {}),
          bookingId: roleType === IRoleType.DOCTOR ? appointmentDetail?.id : "",
        })
      );
    }
  }, [selectedMedicalRecord, defaultMember]);

  useEffect(() => {
    if (roleType === IRoleType.PATIENT) {
      dispatch(fetchPatientMembers());
    }
  }, []);

  useEffect(() => {
    const memberInfo = list?.filter(
      (member: IMemberListDetail, index) =>
        (location.state?.memberId && member?.id === location.state?.memberId) ??
        member.is_default
    )[0];
    setDefaultMember(memberInfo);
    setSelectedMember(memberInfo?.id!);
  }, [list]);

  useEffect(() => {
    if (roleType === IRoleType.DOCTOR || isClinic()) {
      dispatch(
        fetchMedicalRecordList({
          memberId:
            appointmentDetail?.patient?.memberId ?? patientDetail?.member_id,
          bookingId: roleType === IRoleType.DOCTOR ? appointmentDetail?.id : "",
        })
      );
    }
  }, [appointmentDetail, patientDetail]);

  if (roleType === IRoleType.PATIENT && isLoading) {
    return <Loader />;
  }
  if (isCameraOn) {
    return <Camera />;
  }

  if (isMobileOrTablet) {
    return (
      <FullScreenDialog>
        <Grid item className={style.container}>
          <Grid item className={style.medicalRecordHeaderContainer}>
            <Header
              onGoBack={onBack}
              heading={
                roleType === IRoleType.DOCTOR
                  ? "Medical Records"
                  : defaultMember?.name!
              }
              subHeading={
                roleType === IRoleType.DOCTOR
                  ? appointmentDetail?.patient?.name
                  : `${defaultMember?.age} yrs, ${defaultMember?.gender}`
              }
              actionJSX={
                <>
                  {roleType === IRoleType.PATIENT ? (
                    <GetImages
                      name="DownArrowGreyIcon"
                      width="14"
                      height="14"
                    />
                  ) : (
                    <></>
                  )}
                </>
              }
              actionHandler={openCloseMemberModalHandler}
              secondOption={
                roleType === IRoleType.DOCTOR
                  ? medicalRecordViewOption()
                  : editProfileDetail()
              }
              secondOptionHandler={() =>
                roleType === IRoleType.DOCTOR
                  ? setIsGalleryView(!isGalleryView)
                  : setIsEditProfileShow(!isEditProfileShow)
              }
            />
            {roleType === IRoleType.PATIENT && (
              <Grid
                item
                className={style.appointmentBannerMedicalRecordCountContainer}
              >
                <Grid item className={style.appointmentBanner}>
                  <Grid item className={style.appointmentBannerTextDetail}>
                    <Typography component={"h3"}>
                      <FormattedMessage
                        defaultMessage={"Time to Reconnect"}
                        id="time_to_reconnect"
                      />
                    </Typography>
                    <Typography component={"p"}>
                      <FormattedMessage
                        defaultMessage={"Book Your Follow Up Appointment Today"}
                        id="book_your_follow_up_appointment"
                      />
                    </Typography>
                    <button onClick={navigateToDoctorListForBookAppointment}>
                      <FormattedMessage
                        defaultMessage={"Book Appointment"}
                        id="book_appointment"
                      />
                    </button>
                  </Grid>
                  <Grid item>
                    <img src={doctorImage} alt="Doctor Image" />
                  </Grid>
                </Grid>
                <Grid item className={style.medicalRecordCountContainer}>
                  <Typography
                    component={"p"}
                    className={style.medicalRecordHeading}
                  >
                    <FormattedMessage
                      defaultMessage={"All Medical Records"}
                      id="all_medical_records"
                    />
                  </Typography>
                  <Typography component={"p"} className={style.recordCount}>
                    {medicalRecords?.records?.length} records
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item className={style.medicalFilterRecordCountContainer}>
              <Grid item className={style.medicalRecordFilterContainer}>
                <ChipSelect
                  data={medicalRecordFilterList}
                  selectedValue={selectedMedicalRecord}
                  setSelectedValue={setSelectedMedicalRecord}
                  variant={IChipVariant.ROUNDED}
                  showCarousel={true}
                  showClearBtn={true}
                />
              </Grid>
              {roleType === IRoleType.DOCTOR && (
                <Typography component={"p"} className={style.recordCount}>
                  {medicalRecords?.records?.length}{" "}
                  <FormattedMessage defaultMessage={"records"} id="records" />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            className={transformClasses(
              medicalRecords?.records?.length
                ? isGalleryView
                  ? style.medicalRecordContainer
                  : style.recordContainer
                : style.emptyRecordContainer
            )}
          >
            {medicalRecords?.records?.length > 0
              ? medicalRecords?.records?.map((record, index) => (
                  <MedicalRecordGridList
                    {...record}
                    isGalleryView={isGalleryView}
                    key={`${record.id}-${index}`}
                  />
                ))
              : noRecords}
            {medicalRecords?.activePrescription &&
              Object.keys(medicalRecords?.activePrescription)?.length > 0 && (
                <Grid item className={style.activePrescriptionBtn}>
                  <Button
                    variant={IButtonVariant.WHITE}
                    btnTrigger={createHandler}
                  >
                    <GetImages name="PrescriptionIcon" width="26" height="20" />
                    <FormattedMessage
                      defaultMessage={" Active Prescription"}
                      id="active_prescription"
                    />
                  </Button>
                </Grid>
              )}
            {appointmentDetail?.status !==
              IPatientAppointmentStatus.COMPLETED &&
              !medicalRecords?.activePrescription && (
                <CreateAction createHandler={createHandler} />
              )}
          </Grid>
        </Grid>
        {isEditProfileShow && (
          <Profile
            isOpen={isEditProfileShow}
            closeHandler={openCloseEditProfile}
            selectedMemberId={defaultMember?.id!}
            type={IPatientMemberDetailEnums.HOME}
          />
        )}
        {createMedicalRecord && (
          <CreateMedicalReport
            isOpen={createMedicalRecord}
            closeHandler={closeHandler}
            memberId={defaultMember?.id!}
          />
        )}
        {isMemberModalOpenClose && (
          <DropDownSelect
            btnLabel="apply"
            type={IDropDownSelectionType.CHANGE_NAME}
            isOpen={isMemberModalOpenClose}
            closeHandler={openCloseMemberModalHandler}
            title="select_member"
            values={list?.map((member, index: number) => ({
              label: `${member.name}(${member.age!})`,
              value: member.id,
            }))}
            selectedValue={selectedMember}
            saveSelectedValues={saveSelectedMemberValues}
          />
        )}
        {showCreatePrescriptionDialog && (
          <CreatePrescription
            onClose={() => setShowCreatePrescriptionDialog(false)}
          />
        )}
      </FullScreenDialog>
    );
  }

  return (
    <Grid container position={"relative"} height={"100%"} overflow={"hidden"}>
      <Grid item xs={12} className={style.chipSelectWeb}>
        <ChipSelect
          data={medicalRecordFilterList}
          selectedValue={selectedMedicalRecord}
          setSelectedValue={setSelectedMedicalRecord}
          variant={IChipVariant.ROUNDED}
          showCarousel={true}
          showClearBtn={true}
        />
      </Grid>
      {recordsIsLoading ? (
        <Grid container display="flex" justifyContent="center">
          <Loader height="50rem" />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "90%",
            px: "2rem",
          }}
        >
          {renderedRecords}
        </Grid>
      )}
      <HoverableMedicalSidebar
        records={medicalRecords?.records || []}
        onRecordClick={() => {}}
      />
      {createMedicalRecord && (
        <CreateMedicalReport
          isOpen={createMedicalRecord}
          closeHandler={closeHandler}
          memberId={patientSelectedId || defaultMember?.id!}
        />
      )}
    </Grid>
  );
};

export default memo(MedicalRecords);
