import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { appLogoutApi } from "../apis/config";

import { useAppDispatch } from "../../store/hooks";
import { setFCMDeviceToken } from "../../store/slices/videoAppointment";
import { fetchHomeModules } from "../../store/slices/patient/home";

import { IResetAppState } from "../../models";
import { generateTokenFCM } from "../../firebaseConfig";

import { storage } from "../Storage";

const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logoutOnExpire = async () => {
    dispatch({ type: IResetAppState.RESET_ALL });
    localStorage.clear();
    const userId = storage.getSessionToken("user_id");
    const deviceToken = await generateTokenFCM();
    dispatch(setFCMDeviceToken(deviceToken));
    await dispatch(fetchHomeModules({ userId: userId! }));
    navigate("/home");
  };

  const logout = useCallback(async () => {
    try {
      const appLogoutResponse = await appLogoutApi();
      if ("result" in appLogoutResponse) {
        await logoutOnExpire();
      }
    } catch (error) {
      console.error("Logout Error:", error);
    }
  }, []);

  return { logout, logoutOnExpire };
};

export default useLogout;
