import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getDiagnosticCenterListApi,
  getDiagnosticTestListApi,
} from "../../../utils/apis/patient/tests";
import {
  IDiagnosticCenterList,
  IDiagnosticTestList,
  IGetCartResponse,
} from "../../../models/ApiRequestResponse/patient";
import { IKeyPairsValues } from "../../../models/doctor";

export const fetchDiagnosticTestList = createAsyncThunk(
  "patient/testList",
  async ({
    diagnosticId,
    filter,
    search,
  }: {
    diagnosticId?: string;
    filter?: string;
    search?: string;
  }) => {
    const response = await getDiagnosticTestListApi(
      diagnosticId,
      filter,
      search
    );
    return response;
  }
);

export const fetchDiagnosticCenterList = createAsyncThunk(
  "patient/diagnosticList",
  async ({
    latitude,
    longitude,
    day,
    search,
  }: {
    latitude?: number;
    longitude?: number;
    day?: string;
    search?: string;
  }) => {
    const response = await getDiagnosticCenterListApi(
      latitude,
      longitude,
      day,
      search
    );
    return response;
  }
);

const fetchTestsSlice = createSlice({
  name: "fetchMedicines",
  initialState: {
    testList: [] as IDiagnosticTestList[],
    isTestListLoading: true as boolean,
    centerList: [] as IDiagnosticCenterList[],
    centreDetails: {} as IKeyPairsValues,
    cartDetails: {} as IGetCartResponse,
    selectedDay: "" as string,
    steps: 0 as number,
  },
  reducers: {
    setSelectedCenterDetails: (state, action) => {
      state.centreDetails = action.payload;
    },
    resetCentreDetails: (state) => {
      state.centreDetails = {};
    },
    setSelectedCartDetails: (state, action) => {
      state.cartDetails = action.payload;
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    setStepsTest: (state, action) => {
      state.steps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Diagnostic Test listing
      .addCase(fetchDiagnosticTestList.pending, (state) => {
        state.isTestListLoading = true;
      })
      .addCase(fetchDiagnosticTestList.fulfilled, (state, action) => {
        state.isTestListLoading = false;
        if ("result" in action.payload) {
          state.testList = action.payload.result;
        }
      })
      .addCase(fetchDiagnosticTestList.rejected, (state) => {
        state.isTestListLoading = false;
      })

      // Diagnostic Center listing
      .addCase(fetchDiagnosticCenterList.pending, (state) => {
        state.isTestListLoading = true;
      })
      .addCase(fetchDiagnosticCenterList.fulfilled, (state, action) => {
        state.isTestListLoading = false;
        if ("result" in action.payload) {
          state.centerList = action.payload.result;
        }
      })
      .addCase(fetchDiagnosticCenterList.rejected, (state) => {
        state.isTestListLoading = false;
      });
  },
});
export const {
  setSelectedCenterDetails,
  resetCentreDetails,
  setSelectedCartDetails,
  setSelectedDay,
  setStepsTest,
} = fetchTestsSlice.actions;
export default fetchTestsSlice.reducer;
