import { createSlice } from "@reduxjs/toolkit";

import { IPermission } from "../../models/onboarding";

const fetchRolePermissionSlice = createSlice({
  name: "fetchRolePermission",
  initialState: {
    permission: [] as IPermission[],
    showAlert: false as boolean,
  },
  reducers: {
    setRoleAndPermission: (state, action) => {
      state.permission = action.payload;
    },
    setShowPermissionAlert: (state) => {
      state.showAlert = !state.showAlert;
    },
  },
});
export const { setRoleAndPermission, setShowPermissionAlert } =
  fetchRolePermissionSlice.actions;
export default fetchRolePermissionSlice.reducer;
